import * as Sentry from '@sentry/react'
import { Text } from '@chakra-ui/react'
import { Button } from 'components'
import { images } from 'theme'

const FallbackComponent = ({ error, componentStack, resetError }) => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          margin: 'auto',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            marginBottom: 30,
          }}
        >
          <img src={images.errorPic} alt="logo" />
        </div>
        <div
          style={{
            width: '200px',
            height: '29px',
            fontFamily: 'Inter',
            fontSize: '22px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            textAlign: 'center',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#7A1ADB',
            margin: '20px auto',
          }}
        >
          Error 500
        </div>
        <div
          style={{
            width: '400px',
            height: '21px',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '500',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '20px',
            textAlign: 'center',
            letterSpacing: 'normal',
            color: '#1A1A1A',
            margin: '20px auto',
          }}
        >
          Something went wrong. Don’t worry, your files are still safe and Tipbox team have been notified.
        </div>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            margin: '30px 0px',
          }}
        >
          <Text textAlign="center" maxW="23rem" mx="auto" fontSize={14} lineHeight="1.2">
            Back to
            <Button
              onClick={() => {
                window.location.href = '/'
              }}
              label="home"
              variant="inlineLinkButton"
              style={{ marginTop: '0.125rem' }}
            />
          </Text>
        </div>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {error && error.toString()}
          <br />
          {componentStack}
        </details>
        <button
          style={{
            width: '130px',
            height: '50px',
            borderRadius: '25px',
            backgroundColor: '#0081ec',
            color: 'white',
          }}
          onClick={() => {
            // When resetError() is called it will remove the Fallback component
            // and render the Sentry ErrorBoundary's children in their initial state
            resetError()
          }}
        >
          Click here to reset!
        </button>
      </div>
    </div>
  )
}

const tipboxFallback = ({ error, componentStack, resetError }) => (
  <FallbackComponent error={error} componentStack={componentStack} resetError={resetError} />
)

export default ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={tipboxFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  )
}
