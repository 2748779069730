import { useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useBreakpointValue,
  Text,
} from '@chakra-ui/react'

interface AlertWithActionProps {
  action: (() => Promise<void>) | (() => void)
  cancelAction?: (() => Promise<void>) | (() => void)
  actionText: string
  warningText: string[]
  isOpen: boolean
  onClose: () => void
  confirmButtonText?: string
  cancelButtonText?: string
}

const AlertWithAction = ({
  action,
  cancelAction,
  actionText,
  warningText,
  isOpen,
  onClose,
  confirmButtonText,
  cancelButtonText,
}: AlertWithActionProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      autoFocus={false}
      returnFocusOnClose={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          bg="white"
          color="textBlack"
          fontWeight={500}
          minW={!isMobile ? 'max-content' : undefined}
          px={4}
          maxW={isMobile ? '80%' : '600px'}
        >
          <AlertDialogHeader fontSize="lg" fontWeight="bold" color="inherit">
            {actionText}
          </AlertDialogHeader>
          <AlertDialogBody color="textBlack">
            {warningText.map((line, index) => (
              <Text key={index} fontWeight={400} color="textBlack">
                {line}
              </Text>
            ))}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={() => (cancelAction ? cancelAction() : onClose())}
              ref={cancelRef}
              variant="outline"
              color="textBlack"
              _hover={{ bg: '#EFEFEF' }}
              px={6}
              py={5}
            >
              {cancelButtonText || 'Cancel'}
            </Button>
            <Button variant="defaultButton" px={6} ml={3} onClick={action}>
              {confirmButtonText || actionText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default AlertWithAction
