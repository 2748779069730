import React from 'react'
import { IconType } from 'react-icons'
import { Button as ChakraButton, Icon, Flex, Image, Text } from '@chakra-ui/react'

type IconButtonProps = {
  variant?: string
  colorScheme?: string
  icon?: IconType | any
  iconSize?:
    | { height: number | string; width: number | string }
    | { height: (number | string | null)[]; width: (number | string | null)[] }
  image?: string
  fillColor?: string
  onClick: (ariaLabel: string) => void
  ariaLabel: string
  label?: string | React.ReactNode | null
  iconMarginRight?: number | string
  iconMarginLeft?: number | string
  justify?: (string | null)[]
  flexDir?: any
  buttonLabelWidth?: number | string
  iconColor?: string
  color?: string
  style?: React.CSSProperties
  isDisable?: boolean
  labelSize?: number | string
  align?: string
  disableMarginTransition?: boolean
  testId?: string
  customRef?: any
}

const IconButton = ({
  variant,
  colorScheme,
  icon,
  iconSize = { height: 6, width: 6 },
  image,
  ariaLabel,
  onClick,
  label,
  iconMarginRight,
  iconMarginLeft,
  justify = ['center', null, 'flex-start'],
  flexDir = ['column', null, 'row'],
  buttonLabelWidth = 'auto',
  iconColor,
  color = 'inherit',
  fillColor,
  style,
  isDisable = false,
  labelSize,
  align = 'center',
  disableMarginTransition = false,
  testId,
  customRef,
  ...rootProps
}: IconButtonProps) => (
  <ChakraButton
    ref={customRef}
    variant={variant}
    colorScheme={colorScheme}
    aria-label={ariaLabel}
    onClick={() => onClick(ariaLabel)}
    sx={{ ...style }}
    isDisabled={isDisable}
    data-testid={testId}
    className={disableMarginTransition ? undefined : 'iconButton'}
    _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
    {...rootProps}
  >
    <Flex flexDir={flexDir} justify={justify} align={align} h="full" w={buttonLabelWidth}>
      {icon && (
        <Icon
          as={icon}
          w={iconSize.width}
          h={iconSize.height}
          mr={iconMarginRight}
          ml={iconMarginLeft}
          my={1}
          color={iconColor}
          fill={fillColor}
        />
      )}

      {image && <Image src={image} alt={typeof label === 'string' ? label : ''} mr={[null, null, label ? 2 : null]} />}
      <Flex className="buttonText">
        {labelSize ? (
          <Text color={color || iconColor || 'accent'} fontSize={[14, null, null, labelSize || 16]}>
            {label}
          </Text>
        ) : (
          label
        )}
      </Flex>
    </Flex>
  </ChakraButton>
)

export default IconButton
