import { useNavigate, useLocation } from 'react-router-dom'
import { useAppSlice, useProjectSlice, useTipSlice } from 'features/redux'
import { nodeState } from 'helpers/constant'
import { Toast } from 'components'

// Format tip image url so that it can be accessed
export default function useTipRedirect() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { dispatch, setIsLoading } = useAppSlice()
  const { getProject } = useProjectSlice()
  const { getTip, setCurrentTip } = useTipSlice()

  const handleRedirectClick = async (tip: ITip | any, pageType?: string, redirect = false, notificationType = '') => {
    dispatch(setIsLoading(true))
    const { project, projectId } = tip
    const redirectPath =
      pageType === 'follow' || pageType === 'search' || pageType === 'trash'
        ? `project/${project?.id || projectId}/file/${tip.id}`
        : `${pathname}/file/${tip.id}`
    dispatch(setCurrentTip(tip))
    dispatch(setIsLoading(false))
    // Redirect default is false. Always fires the code below
    // when clicked on a tip from inside of a project or a box
    if (!redirect) {
      navigate(redirectPath)
    } else if (pathname.includes('link')) {
      navigate(`project/${project?.id || projectId}/file/${tip.id}`, { replace: true })
    } else {
      // Redirect default is false. Always fires the code below
      // when clicked on a tip from inside of a project or a box
      navigate(`/project/${project?.id || projectId}/file/${tip.id}`)
    }
    return {}
  }

  const tipRedirect = async (tipId: string, redirect: boolean, getCurrentProject = false, notificationType = '') => {
    const { payload } = await dispatch(getTip(tipId))
    if (!payload) return {}
    const { state } = payload
    if (state === nodeState.inactive) {
      Toast.show({ icon: 'error', message: 'The item has been deleted.' })
      return {}
    }
    if (getCurrentProject) {
      await dispatch(getProject(payload.project.id))
    }
    return handleRedirectClick(payload, '', redirect, notificationType)
  }

  return { handleRedirectClick, tipRedirect }
}
