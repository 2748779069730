/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { client as apollo } from 'helpers/apollo'
import { AppDispatch, RootState } from 'helpers/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  LINK_SHARE_VALID,
  RANDOM_PATH_NODE,
  UPDATE_LINK_SHARE,
  DELETE_LINK_SHARE,
  LINK_SHARE_LOGIN,
  RANDOM_PATH_NODE_CHILD,
  LINK_SHARE_USER_ACCESS,
} from './linkShare.schema'
import { Node } from 'types/graphqlSchema'

// ---------------------------------------
// Thunks
// ---------------------------------------

const updateNodeLinkShare = createAsyncThunk('linkShare/updateNodeLinkShare', async (params: any) => {
  try {
    const {
      data: { updateLinkShare },
    } = await apollo.mutate({
      mutation: UPDATE_LINK_SHARE,
      variables: { input: { ...params } },
    })
    return updateLinkShare
  } catch (error: any) {
    throw new Error(error)
  }
})

const getIsLinkShareValid = createAsyncThunk('linkShare/getIsLinkShareValid', async (randomPath: string) => {
  try {
    const {
      data: { linkShareValid, error },
    } = await apollo.query({
      query: LINK_SHARE_VALID,
      variables: { randomPath },
    })
    if (error) {
      throw new Error(error.message)
    }
    return linkShareValid
  } catch (error: any) {
    throw new Error(error)
  }
})

const getRandomPathNode = createAsyncThunk('linkShare/getRandomPathNode', async (randomPath: string) => {
  try {
    const {
      data: { randomPathNode },
    } = await apollo.query({
      query: RANDOM_PATH_NODE,
      variables: { randomPath },
    })
    return randomPathNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const getRandomPathNodeChild = createAsyncThunk(
  'linkShare/getRandomPathNodeChild',
  async (params: { randomPath: string; nodeId: string; isUsedForDownload?: boolean }) => {
    const isQueryUsedToDownloadZipFile = params?.isUsedForDownload || false
    delete params.isUsedForDownload
    try {
      const {
        data: { randomPathChild },
      } = await apollo.query({
        query: RANDOM_PATH_NODE_CHILD,
        variables: { ...params },
      })
      return { randomPathChild, isQueryUsedToDownloadZipFile }
    } catch (error: any) {
      throw new Error(error)
    }
  },
)

const deleteNodeLinkShare = createAsyncThunk('linkShare/updateNodeLinkShare', async (nodeId: any) => {
  try {
    const {
      data: { deleteLinkShare },
    } = await apollo.mutate({
      mutation: DELETE_LINK_SHARE,
      variables: { nodeId },
    })
    return deleteLinkShare
  } catch (error: any) {
    throw new Error(error)
  }
})

const linkShareLogin = createAsyncThunk(
  'linkShare/linkShareLogin',
  async (params: { password: string; randomPath: string }) => {
    try {
      const {
        data: { linkShareSignIn },
      } = await apollo.mutate({
        mutation: LINK_SHARE_LOGIN,
        variables: { ...params },
      })
      return linkShareSignIn
    } catch (error: any) {
      throw new Error(error)
    }
  },
)

const checkUserAccess = createAsyncThunk('linkShare/checkUserAccess', async (randomPath: string) => {
  try {
    const { data } = await apollo.query({
      query: LINK_SHARE_USER_ACCESS,
      variables: { randomPath },
    })
    return data.checkUserAccess
  } catch (error: any) {
    throw new Error(error)
  }
})

interface LinkShareState {
  originalNode: INode | undefined
  node?: IProject | IBox | undefined
  tip?: ITip | undefined
  parentNode?: string | undefined
  children: Node[] | []
  publicLink: any
  error: any
}

const initialState: LinkShareState = {
  originalNode: undefined,
  node: undefined,
  tip: undefined,
  children: [],
  publicLink: {},
  error: null,
}

const linkShareSlice = createSlice({
  name: 'linkShare',
  initialState,
  reducers: {
    clearLinkShare(state) {
      state = initialState
      return state
    },
    clearLinkShareTip(state) {
      state.tip = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRandomPathNode.fulfilled, (state, { payload }: any) => {
      if (!payload) return

      if (!state.originalNode) {
        state.originalNode = payload.node
      }
      if (payload.node.type === 'TIP') {
        state.tip = payload.node
        state.node = undefined
      } else {
        state.node = payload.node
        state.tip = undefined
      }
      if (payload.children) {
        state.children = payload.children
      }
      state.parentNode = undefined
    })
    builder.addCase(getRandomPathNodeChild.fulfilled, (state, { payload }: any) => {
      if (!payload) return
      const { randomPathChild, isQueryUsedToDownloadZipFile } = payload
      if (!isQueryUsedToDownloadZipFile) {
        state.parentNode = randomPathChild.node.parent?.id || undefined
        if (randomPathChild.node.type === 'TIP') {
          state.tip = randomPathChild.node
        } else {
          state.node = randomPathChild.node
        }
        if (randomPathChild.children) {
          state.children = randomPathChild.children
        }
      }
    })
    builder.addCase(getIsLinkShareValid.fulfilled, (state, { payload }) => {
      if (!payload) return
      state.publicLink = payload
    })
    builder.addCase(linkShareLogin.fulfilled, (state, { payload }) => {
      state.publicLink.login = payload
    })
  },
})

export function useLinkShareSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.linkShare)
  return {
    dispatch,
    ...state,
    ...linkShareSlice.actions,
    getRandomPathNodeChild,
    getRandomPathNode,
    deleteNodeLinkShare,
    updateNodeLinkShare,
  }
}

export default linkShareSlice.reducer
