import { gql } from '@apollo/client'

// ---------------------------
// FRAGMENTS
// ---------------------------

const shareMember = `
  id
  shareType
  firstName
  lastName
  avatar
  shareTypeId
  permissions
`
const linkShare = `
  randomPath
  linkType
  expiryDate
  hasPassword
  permissionType
`

const nodeOutput = `
  id
  name
  type
  coverImage
  coverImageSecureUrl
  createdAt
  updatedAt
  totalSize
  project {
    id
    name
  }
  parent {
    id
    name
    type
    private
  }
  owner {
    id
    firstName
    lastName
    avatar
  }
  updatedBy {
    id
    firstName
    lastName
  }
  blocks {
    extension
    preview
    previewSecureUrl
  }
`
// sharedMembers {
//   ${shareMember}
// }
// linkShare {
//   ${linkShare}
// }

// ---------------------------
// QUERIES
// ---------------------------

export const SEARCH_NODES = gql`
  query Query($indexes: [SearchIndex!]!, $term: String, $filter: JSON, $limit: Int) {
    search(indexes: $indexes, term: $term, filter: $filter, limit: $limit) {
      nodes {
        tags {
          id
          name
        }
        name
        type
        id
        coverImage
        coverImageSecureUrl
        createdAt
        absolutePaths
        color
        projectId
        state
        owner {
          id
          lastName
          firstName
        }
        updatedAt
        blocks {
          size
          extension
          preview
          previewSecureUrl
        }
        sharedMembers {
          sharedWith {
            firstName
            lastName
            id
            avatar
          }
        }
        children {
          tags {
            id
            name
          }
          name
          __typename
          type
          private
          id
          coverImage
          coverImageSecureUrl
          createdAt
          absolutePaths
          color
          projectId
          owner {
            id
            lastName
            firstName
          }
          updatedAt
          blocks {
            size
            extension
            preview
            previewSecureUrl
          }
          sharedMembers {
            sharedWith {
              firstName
              lastName
              id
              avatar
            }
          }
        }
      }

      blocks {
        content
        contentSecureUrl
        node {
          name
        }
        tags {
          name
          id
        }
        type
      }

      tags {
        name
        id
      }

      flows {
        id
        createdAt
        dueDate
        urgent
        requester {
          firstName
          lastName
          avatar
        }
        participants {
          id
          lastName
          firstName
          avatar
        }
        state {
          stateName
        }
        blocks {
          size
          type
        }
        metadata
        workflow {
          name
        }
        workflowId
        updatedAt
        name
        node {
          name
          id
          project {
            name
          }
        }
      }
    }
  }
`

export const SEARCH_IN_LOCATION = gql`
  query searchInLocation($params: searchParamsObj) {
    searchLocation(searchParams: $params) {
      count
      nodes {
        ${nodeOutput}
      }
    }
  }
`

export const SEARCH_FILTERS = gql`
  query searchWithFilters($filterParams: searchFiltersObj) {
    searchFilters(filterParams: $filterParams) {
      count
      nodes {
        ${nodeOutput}
      }
    }
  }
`

// filter loctions on search page
export const GET_SEARCH_LOCATION = gql`
  query getNode($id: ID!) {
    node(id: $id) {
      id
      name
      children {
        name
        id
      }
      blocks {
        extension
        preview
        previewSecureUrl
      }
    }
  }
`

export const GET_SEARCH_TRASH_LOCATION = gql`
  query getNode($id: ID!) {
    node(id: $id) {
      id
      name
      children {
        name
        id
      }
      blocks {
        extension
        preview
        previewSecureUrl
      }
    }
  }
`

export const GET_SAVED_SEARCHES = gql`
  query GetSavedSearches {
    getSavedSearches {
      id
      name
      searchParameters
      updatedAt
      createdAt
    }
  }
`

export const GET_SUGGESTED_SEARCHES = gql`
  query GetSuggestedSearches($term: String!, $indexes: [SearchIndex!]!) {
    getSuggestions(term: $term, indexes: $indexes) {
      flows {
        score
        text
      }
      nodes {
        score
        text
      }
      blocks {
        score
        text
      }
      tags {
        score
        text
      }
      users {
        score
        text
      }
    }
  }
`

// ---------------------------
// MUTATIONS
// ---------------------------

export const SAVE_SEARCH = gql`
  mutation AddSavedSearch($name: String!, $searchParameters: JSON!) {
    addSavedSearch(name: $name, searchParameters: $searchParameters) {
      name
      id
      searchParameters
    }
  }
`

export const UPDATE_SEARCH = gql`
  mutation UpdateSavedSearch($updateSavedSearchId: String!, $name: String, $searchParameters: JSON) {
    updateSavedSearch(id: $updateSavedSearchId, name: $name, searchParameters: $searchParameters) {
      name
      id
      searchParameters
    }
  }
`

export const DELETE_SEARCH = gql`
  mutation DeleteSearch($deleteSavedSearchId: String!) {
    deleteSavedSearch(id: $deleteSavedSearchId)
  }
`
