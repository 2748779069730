import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import { truncateString } from 'helpers/utils'
import { images } from 'theme'

const FlowFormProjectsSection = ({ isQuickstart, attachedProject, setAttachedProject, setShowProjectsPane }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const textLength = isQuickstart ? 18 : 28

  return (
    <Flex alignItems={isMobile ? 'flex-start' : 'center'} flexDir={isMobile ? 'column' : 'row'}>
      <Text ml={isMobile ? 0 : 6} mr="46px" color={'textBlack'}>
        * Project:
      </Text>

      <Flex
        w="full"
        flex={1}
        gap={{ base: '.5rem', md: '1.75rem' }}
        flexDir={{ base: 'column', md: 'row' }}
        alignItems={'center'}
        mb={{ base: '.5rem', md: 0 }}
      >
        <Box
          fontSize="inherit"
          display="flex"
          justifyContent={'flex-start'}
          gap="1rem"
          w="full"
          borderBottom="1.5px solid var(--chakra-colors-borderLight)"
          py={attachedProject ? 2 : 3}
          onClick={() => {
            setShowProjectsPane(true)
          }}
          cursor="pointer"
        >
          {attachedProject ? (
            <Flex bg="var(--chakra-colors-textSoft)" gap={3} px={3} py={1} rounded="md" align="center">
              <Text color="textBlack" cursor="default" onClick={(e) => e.stopPropagation()}>
                {truncateString(attachedProject.name, textLength)}
              </Text>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setAttachedProject(undefined)
                }}
              >
                <Box flex="none" minW="8px">
                  <img src={images.xSmall} />
                </Box>
              </button>
            </Flex>
          ) : (
            <>
              <Text color="textBlack" fontWeight={400}>
                What project are you referring to?
              </Text>
            </>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}

export default FlowFormProjectsSection
