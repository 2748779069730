import { useState } from 'react'
import {
  Flex,
  Avatar,
  Text,
  Divider,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useAppSlice, useUserSettingsSlice } from 'features/redux'
import { useApp } from 'features/app/hooks'
import { getResizedImage } from 'helpers/storage'
import { IconButton } from 'components/elements'
import { customIcons } from 'theme'
import { Role } from 'types/graphqlSchema'
import { UserSettingsModal } from 'features/userSettings'

export default ({ isDesktopNav = false }) => {
  const {
    user: { avatarSecureUrl, firstName, lastName, role },
  } = useAppSlice()
  const { handleLogout } = useApp()
  const navigate = useNavigate()
  const [isAccountSettings, setIsAccountSettings] = useState(false)
  const { dispatch, setShowModalBody, setShowChangePasswordPanel, setShowAvatarChangePanel, setShowSMSActivation } =
    useUserSettingsSlice()

  const handleClickAccountSettings = () => {
    setIsAccountSettings(true)
    dispatch(setShowModalBody(true))
    dispatch(setShowChangePasswordPanel(false))
    dispatch(setShowAvatarChangePanel(false))
    dispatch(setShowSMSActivation(false))
    dispatch(setShowModalBody(true))
  }

  return (
    <>
      <Popover isLazy placement="bottom-end" closeOnBlur>
        <PopoverTrigger>
          <Flex
            alignItems="center"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <Text px={2} color="textHighlight" order={isDesktopNav ? 2 : 1} fontSize={[11, null, null, 14]}>
              {`${firstName} ${lastName}`}
            </Text>
            <Avatar
              name={avatarSecureUrl ? '' : `${firstName} ${lastName}`}
              size={['xs', 'xs', null, 'sm', 'sm']}
              src={avatarSecureUrl ? getResizedImage(avatarSecureUrl, 'profile.main') : ''}
              order={isDesktopNav ? 1 : 2}
            />
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            px={1}
            py={2}
            _focus={{ boxShadow: 'none' }}
            borderRadius={8}
            w="286px"
            border="1px solid var(--chakra-colors-borderRegular)"
            bg="textHighlight"
            color="textBlack"
          >
            <PopoverHeader>
              <Flex
                alignItems="center"
                _hover={{
                  cursor: 'pointer',
                }}
                py={2}
              >
                <Text variant="mediumHighlight" px={2} order={isDesktopNav ? 2 : 1}>
                  {`${firstName} ${lastName}`}
                </Text>
                <Avatar
                  name={avatarSecureUrl ? '' : `${firstName} ${lastName}`}
                  size={['xs', 'xs', null, 'sm', 'sm']}
                  src={avatarSecureUrl ? getResizedImage(avatarSecureUrl, 'profile.main') : ''}
                  order={isDesktopNav ? 1 : 2}
                />
              </Flex>
            </PopoverHeader>
            <Divider borderColor="textSoft" width="90%" margin="0 auto" />
            <PopoverBody p={0}>
              <IconButton
                ariaLabel="Account settings"
                onClick={handleClickAccountSettings}
                icon={customIcons.userAccount}
                iconSize={{ width: 4, height: 4 }}
                iconMarginRight={3}
                label="Account settings"
                variant={'dropdownOptionButton'}
              />
              {role === Role.Admin && (
                <IconButton
                  ariaLabel="Admin settings"
                  onClick={() => navigate('/admin')}
                  icon={customIcons.gearIcon}
                  iconSize={{ width: 4, height: 4 }}
                  iconMarginRight={3}
                  label="Admin settings"
                  variant={'dropdownOptionButton'}
                />
              )}
              <IconButton
                ariaLabel="Sign out of Tipbox"
                onClick={handleLogout}
                icon={customIcons.logout}
                iconSize={{ width: 4, height: 4 }}
                iconMarginRight={3}
                label="Sign out of Tipbox"
                variant={'dropdownOptionButton'}
              />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      {/* ACCOUNT SETTINGS MODAL */}
      <UserSettingsModal isOpen={isAccountSettings} close={() => setIsAccountSettings(false)} />
    </>
  )
}
