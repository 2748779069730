import TagManager from 'react-gtm-module'

export const initializeGTM = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  }

  TagManager.initialize(tagManagerArgs)
}

export const trackPageView = () => {
  window.dataLayer.push({
    event: 'pageview',
  })
}

export const trackGTMEvent = (category, action, label, value) => {
  window.dataLayer.push({
    event: 'event',
    eventProps: {
      category,
      action,
      label,
      value,
    },
  })
}
