import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { client as apollo } from 'helpers/apollo'
import { AppDispatch, RootState } from 'helpers/store'
import { GET_FLOWS, GET_WORKFLOWS } from './workflow.schema'
import { Workflow, Flow } from 'types/graphqlSchema'

// ---------------------------------------
// Thunks
// ---------------------------------------

const getWorkflows = createAsyncThunk('workflow/getWorkflows', async () => {
  try {
    const {
      data: { workflows },
    } = await apollo.query({
      query: GET_WORKFLOWS,
      variables: {
        filter: {
          active: true,
        },
      },
    })
    return workflows
  } catch (error) {
    throw new Error('fetch error')
  }
})

const fetchFlows = createAsyncThunk('workflow/fetchFlows', async () => {
  try {
    const {
      data: { flows },
    } = await apollo.query({ query: GET_FLOWS })
    return flows
  } catch (error) {
    return error
  }
})

const initialState = {
  workflows: [] as Workflow[],
  flows: [] as Flow[],
  error: null as any,
}

// ---------------------------------------
// Slice
// ---------------------------------------

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setFlows(state, { payload }) {
      state.flows = payload
    },
    clearWorkflows(state) {
      state.workflows = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWorkflows.pending, (state) => {
      state.error = null
    })
    builder.addCase(getWorkflows.fulfilled, (state, { payload }: any) => {
      state.workflows = payload
    })
    builder.addCase(getWorkflows.rejected, (state, { payload }) => {
      state.error = payload
    })
    builder.addCase(fetchFlows.pending, (state) => {
      state.error = null
    })
    builder.addCase(fetchFlows.fulfilled, (state, { payload }) => {
      state.error = null
      state.flows = payload
    })
    builder.addCase(fetchFlows.rejected, (state, { payload }) => {
      state.error = payload
    })
  },
})

// export const { actions } = workflowSlice
export function useWorkflowSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.workflow)
  return { dispatch, ...state, getWorkflows, fetchFlows, ...workflowSlice.actions }
}

export default workflowSlice.reducer
