export enum FlowTab {
  all = 'All Flows',
  urgent = 'Urgent',
  overdue = 'Overdue',
  approval = 'Approvals',
  feedback = 'Input',
  archived = 'Archived',
}

export enum FlowFilter {
  all = 'All Flows',
  urgent = 'Urgent',
  overdue = 'Overdue',
  today = 'Due Today',
}

export enum FlowDisplayType {
  list = 'list',
  kanban = 'kanban',
  archive = 'archive',
}

export enum FlowResponsibleDisplayType {
  list = 'list',
  kanban = 'Kanban',
  archiveList = 'archive-list',
  archiveKanban = 'archive-kanban',
}

export enum FlowStatus {
  todo = 'TO DO',
  inProgress = 'IN PROGRESS',
  loop = 'IN THE LOOP',
  result = 'DONE',
}

export enum FlowSortOption {
  sentDate = 'Sent Date',
  dueDate = 'Due Date',
  /**
   * Sort by sender (on hold).
   */
  // sender = 'Sender',
}

export enum FlowSortDirection {
  ascending = 'Ascending',
  descending = 'Descending',
}
