import {
  Breadcrumb,
  BreadcrumbItem,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { AnimatedCheckLarge } from 'components'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { colors, images } from 'theme'

interface Breadcrumb {
  id: string
  type: string
  name: string
}

interface ButtonIconProps {
  imageUrl: string
  style?: React.CSSProperties
}

interface SaveModalProps {
  documentTitle: string
  isOpen: boolean
  onClose: () => void
  isMoveTo?: boolean
  breadcrumbs: Breadcrumb[]
}

const SavedConfirmationModal = ({ isOpen, onClose, isMoveTo, documentTitle, breadcrumbs }: SaveModalProps) => {
  const ButtonIcon = ({ imageUrl, style }: ButtonIconProps) => <Image src={imageUrl} style={style}></Image>

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          bg={'white'}
          color="textBlack"
          fontFamily={'Inter'}
          w={'600px'}
          maxW={'80%'}
          h={'519px'}
        >
          <ModalHeader py={0} display={'flex'} alignItems={'baseline'}>
            <Text fontSize="1rem" color="textBlack">
              {documentTitle}
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody display={'flex'} flexDirection={'column'} mx={6} px={0} maxH={'350px'}>
            <Flex
              flexDir={'column'}
              my="auto"
              minH={'10rem'}
              overflowY={'scroll'}
              overflowX={'hidden'}
              css={{
                '&::-webkit-scrollbar': {
                  width: '0',
                },
                '&::-webkit-scrollbar-track': {
                  width: '0',
                },
              }}
            >
              <motion.div key="saved" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Flex flexDir={'column'} mx="auto" alignItems={'center'} gap={'1.5rem'} maxH={'342px'} pt={'3rem'}>
                  <AnimatedCheckLarge caption={isMoveTo ? 'Document Moved.' : 'Document Saved.'} />
                  <Text fontWeight={500} color="textBlack" fontSize=".875rem" w="20ch">
                    To Share or Flow this item, please move it to a project.
                  </Text>
                </Flex>
              </motion.div>
            </Flex>
          </ModalBody>

          <ModalFooter borderTop={'none'} mx={6} px={0}>
            <Flex mr={'auto'} gap=".5rem" maxW={'100%'} overflow={'hidden'} mt={1.5} mb={1.5}>
              <Text minW={'max-content'} fontWeight={'semibold'} fontSize={14} color="textBlack">
                Saved To:
              </Text>

              {breadcrumbs?.length > 3 && (
                <Text fontSize={14} minW={'max'}>
                  {breadcrumbs[2].name.length > 25 ? breadcrumbs[2].name.substring(0, 25) : breadcrumbs[2].name}
                </Text>
              )}
              <Breadcrumb
                separator={<ButtonIcon imageUrl={images.chevronRightBreadcrumb} style={{ marginTop: '-2px' }} />}
              >
                {breadcrumbs?.length > 2 && (
                  <BreadcrumbItem mb={'.5rem'}>
                    <Text fontSize={14}>...</Text>
                  </BreadcrumbItem>
                )}
                {breadcrumbs?.length > 2
                  ? breadcrumbs?.slice(-1).map((crumb) => (
                      <BreadcrumbItem key={crumb.id} mb={'.5rem'} minWidth={'max-content'}>
                        <Text color={colors.folder.darkGray.body} fontSize={14}>
                          {crumb.name?.length > 20 ? crumb.name?.substring(0, 20) + '...' : crumb.name}
                        </Text>
                      </BreadcrumbItem>
                    ))
                  : breadcrumbs?.map((crumb) => (
                      <BreadcrumbItem key={crumb.id} mb={'.5rem'} minWidth={'max-content'}>
                        <Text color={colors.folder.darkGray.body} fontSize={14}>
                          {breadcrumbs.length === 1 && breadcrumbs[0].name === 'Tipbox'
                            ? 'Personal'
                            : crumb.name?.length > 14
                            ? `${crumb.name.substring(0, 14)}...`
                            : crumb.name}
                        </Text>
                      </BreadcrumbItem>
                    ))}
              </Breadcrumb>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SavedConfirmationModal
