import React from 'react'
import { Flex, Icon } from '@chakra-ui/react'
import { customIcons, icons } from 'theme'
import { Badge } from 'components'
import { FollowedNode, Node } from 'types/graphqlSchema'

interface IconSectionProps {
  node: Node
  isMobile: boolean
  followedNodes: FollowedNode[]
  nodeNotifications: number
}

const IconSection = ({ node, isMobile, followedNodes, nodeNotifications }: IconSectionProps) => {
  const { sharedMembers, id } = node

  const shared = () => {
    if (sharedMembers && sharedMembers.length > 0) return true
    if (node.type === 'PROJECT') {
      const project: any = { ...node }
      if (project?.category?.includes('SHARE')) return true
    }
    return false
  }
  return (
    <Flex align="center" justify={['flex-start', null, null, 'flex-end']} w="full">
      {shared() && (
        <Icon
          as={isMobile ? customIcons.addUserMobile : customIcons.userPlusIcon}
          color="textRegular"
          h={[3, null, null, 4]}
          w={[3, null, null, 4]}
          mr={2}
        />
      )}
      {followedNodes?.some((f) => f.nodeId === id) && (
        <Icon as={icons.follow} color="textRegular" h={[3, null, null, 4]} w={[3, null, null, 4]} />
      )}
      {nodeNotifications > 0 && (
        <Badge message={nodeNotifications} top={0} right={0} isMobile={isMobile} absolute={false} />
      )}
    </Flex>
  )
}

export default IconSection
