import { useState, useEffect, useMemo } from 'react'
import { partition } from 'lodash'
import { isWithinInterval, isSameDay } from 'date-fns'
import { useBreakpointValue } from '@chakra-ui/react'
import { workflowConstant } from 'helpers/constant'
import { userLastFlowLog } from 'helpers/utils'
import { useWorkflowSlice } from 'features/redux'
import { DateRange, SEARCH_BOOLS, SearchItem } from 'features/search/Search.types'
import { useAppSelector } from 'hooks'
import { Node, Flow } from 'types/graphqlSchema'
import { FlowTab, FlowDisplayType, FlowResponsibleDisplayType, FlowSortOption, FlowSortDirection } from '../Flows.types'

export default function useFlowFilters() {
  const { flows, fetchFlows, dispatch } = useWorkflowSlice()
  const { user } = useAppSelector((state) => state.app)
  const isSmallScreen = useBreakpointValue({ base: true, md: true, lg: false, xl: false }, { ssr: false })

  // filter options
  const [tab, setTab] = useState<FlowTab>(FlowTab.all)
  const [searchItems, setSearchItems] = useState<SearchItem[]>([])
  const project = searchItems.find((si) => si.type === 'Project')
  const [urgency, setUrgency] = useState<string | undefined>(undefined)
  const [timeframe, setTimeframe] = useState<DateRange>([undefined, undefined])

  // sort options
  const [sortOption, setSortOption] = useState<FlowSortOption>(FlowSortOption.sentDate)
  const [sortDirection, setSortDirection] = useState<FlowSortDirection | undefined>(undefined)

  // display options
  const [displayType, setDisplayType] = useState<FlowDisplayType>(FlowDisplayType.kanban)
  const [lastDisplayType, setLastDisplayType] = useState<FlowDisplayType>(FlowDisplayType.kanban)
  const [responsibleDisplayType, setResponsibleDisplayType] = useState<FlowResponsibleDisplayType>(
    FlowResponsibleDisplayType.kanban,
  )

  // flows
  const [todoFlows, setTodoFlows] = useState<Flow[]>([])
  const [inProgressFlows, setInProgressFlows] = useState<Flow[]>([])
  const [inLoopFlows, setInLoopFlows] = useState<Flow[]>([])
  const [resultFlows, setResultFlows] = useState<Flow[]>([])
  const [archiveFlows, setArchiveFlows] = useState<Flow[]>([])
  const [todoUrgentFlows, setTodoUrgentFlows] = useState<Flow[]>([])
  const [todoOverdueFlows, setTodoOverdueFlows] = useState<Flow[]>([])
  const [todoDueTodayFlows, setTodoDueTodayFlows] = useState<Flow[]>([])

  useEffect(() => {
    dispatch(fetchFlows())
  }, [])

  /**
   * categorize flows
   * - TODO
   * - IN PROGRESS
   * - IN LOOP
   * - RESULT
   */
  useEffect(() => {
    // filter by tab
    try {
      if (!flows || !Array.isArray(flows)) return
      const tabFlows =
        tab === FlowTab.all
          ? [...flows]
          : flows?.filter((flow) => {
              if (tab === FlowTab.urgent) return flow.urgent
              if (tab === FlowTab.overdue) {
                if (flow.state?.stateName === 'Pending') {
                  const flowDue = new Date(flow.dueDate)
                  const now = new Date()
                  return flowDue < now
                } else {
                  return false
                }
              }
              if (tab === FlowTab.approval) return flow?.workflow?.name === 'Approval'
              if (tab === FlowTab.feedback) return flow?.workflow?.name === 'Feedback'
              return flow
            })
      // sort by option
      let sortedFlows: Flow[]
      const isAscending = sortDirection === FlowSortDirection.ascending

      if (sortOption === FlowSortOption.sentDate) {
        // sort by sent date
        sortedFlows = tabFlows.sort((a, b) => {
          const createdDateA = new Date(a.createdAt).getTime()
          const createdDateB = new Date(b.createdAt).getTime()
          return isAscending ? createdDateA - createdDateB : createdDateB - createdDateA
        })
      } else if (sortOption === FlowSortOption.dueDate) {
        // sort by due date
        sortedFlows = tabFlows.sort((a, b) => {
          const dueDateA = new Date(a.dueDate).getTime()
          const dueDateB = new Date(b.dueDate).getTime()
          return isAscending ? dueDateA - dueDateB : dueDateB - dueDateA
        })
      } else {
        // sort by requester
        sortedFlows = tabFlows.sort((a, b) => {
          const userAId = a.requester?.id || ''
          const userBId = b.requester?.id || ''
          return isAscending ? userAId.localeCompare(userBId) : userBId.localeCompare(userAId)
        })
      }

      // filter by timeframe
      const flowsFilteredByTimeframe = timeframe[0]
        ? sortedFlows.filter((flow) => {
            const dueDate = new Date(flow.dueDate)
            const createdDate = new Date(flow.createdAt)
            if (timeframe[0] && !timeframe[1]) {
              const timeframe0 = timeframe[0]
              return isSameDay(dueDate, timeframe0) || isSameDay(createdDate, timeframe0)
            } else if (timeframe[0] && timeframe[1]) {
              const timeframe0 = timeframe[0]
              const timeframe1 = timeframe[1]
              const inRange =
                isWithinInterval(dueDate, { start: timeframe0, end: timeframe1 }) ||
                isWithinInterval(createdDate, { start: timeframe0, end: timeframe1 })
              return inRange
            } else return false
          })
        : sortedFlows

      // filter by urgency
      const flowsFilteredByUrgency = urgency
        ? flowsFilteredByTimeframe.filter((flow) => {
            if (urgency === 'Urgent') return flow.urgent
            if (urgency === 'Not Urgent') return !flow.urgent
            if (urgency === 'Overdue') {
              const flowDue = new Date(flow.dueDate)
              const now = new Date()
              return flowDue < now
            }
            return flow
          })
        : flowsFilteredByTimeframe

      // filter by project
      const projectFlows = project?.item
        ? flowsFilteredByUrgency.filter((flow) => {
            return flow?.project?.name === project?.item
          })
        : flowsFilteredByUrgency

      // Function to check if a flow matches a keyword based on type
      const matchesKeyword = (flow, searchItem) => {
        // Lowercasing the flow properties for comparison
        const lowerCaseItem = searchItem.item.trim().toLowerCase()
        const lowerCaseNodeName = flow?.node?.name?.toLowerCase() ?? ''
        const lowerCaseFlowName = flow?.name?.toLowerCase() ?? ''
        const lowerCaseProjectName =
          flow?.project?.name?.toLowerCase() || flow?.node?.project?.name?.toLowerCase() || ''
        const lowerCaseTags = flow?.tags?.map((tag) => tag.name.toLowerCase())

        const lowerCaseParticipantsName =
          flow?.participants
            ?.map((p) => `${p?.firstName} ${p?.lastName}`)
            .join(' ')
            .toLowerCase() ?? ''
        const lowerCaseRequesterName = `${flow?.requester?.firstName} ${flow?.requester?.lastName}`.toLowerCase() ?? ''
        const lowerCaseWatchersName =
          flow?.watchers
            ?.map((p) => `${p?.firstName} ${p?.lastName}`)
            .join(' ')
            .toLowerCase() ?? ''

        // checking various properties based on the searchItem type
        switch (searchItem.type) {
          case 'File':
            return lowerCaseNodeName.includes(lowerCaseItem)
          case 'Project':
            return lowerCaseNodeName.includes(lowerCaseItem)
          case 'Tag':
            return lowerCaseTags.includes(lowerCaseItem)
          case 'Comment':
          case 'Feedback':
            console.warn(`${searchItem.type} search not yet implemented`)
            return false
          default:
            return (
              lowerCaseTags.includes(lowerCaseItem) ||
              lowerCaseFlowName.includes(lowerCaseItem) ||
              lowerCaseProjectName.includes(lowerCaseItem) ||
              lowerCaseNodeName.includes(lowerCaseItem) ||
              lowerCaseParticipantsName.includes(lowerCaseItem) ||
              lowerCaseRequesterName.includes(lowerCaseItem) ||
              lowerCaseWatchersName.includes(lowerCaseItem)
            )
        }
      }

      const filterFlowsByKeywords = (flows, searchItems) => {
        if (searchItems.length === 0) return flows
        return flows.filter((flow) => {
          const notItems = searchItems.filter((si) => si.bool === SEARCH_BOOLS.NOT)
          const andItems = searchItems.filter((si) => si.bool === SEARCH_BOOLS.AND)
          const orItems = searchItems.filter((si) => si.bool === SEARCH_BOOLS.OR)

          const matchesANDKeywords = andItems.every((item) => matchesKeyword(flow, item))
          const matchesORKeywords = orItems.some((item) => matchesKeyword(flow, item))
          const matchesNOTKeywords = notItems.some((item) => matchesKeyword(flow, item))

          return (matchesANDKeywords || matchesORKeywords) && !matchesNOTKeywords
        })
      }

      const keywordFilteredFlows = filterFlowsByKeywords(projectFlows, searchItems)

      // ACTIVE or ARCHIVED flows
      const [active, archived] = partition(keywordFilteredFlows, (flow) => {
        // the flow has not been archived
        const flowIsArchived = flow.state?.stateName === workflowConstant.approval.state.archived
        const myLastLog = userLastFlowLog(user.id, flow.flowLogs)
        return (
          !flowIsArchived &&
          (myLastLog ? myLastLog.toState?.stateName !== workflowConstant.approval.state.archived : true)
        )
      })

      // IN LOOP or NOT IN LOOP flows
      const [inLoops, notInLoops] = partition(
        active,
        (flow) =>
          // I'm one of the watchers. Unless flow is done, it stays at 'in the loop'
          flow.watchers?.some((watcher) => watcher?.id === user.id) &&
          (flow.state?.stateName === '' || flow.state?.stateName === workflowConstant.approval.state.pending),
      )

      // PENDING or COMPLETED flows
      const [pending, completed] = partition(
        notInLoops,
        (flow) =>
          // the flow is in pending state
          flow.state?.stateName === '' || flow.state?.stateName === workflowConstant.approval.state.pending,
      )

      // TO DO or IN PROGRESS flows
      const [todo, inProgress] = partition(pending, (flow) => {
        const amIParticipated = flow.participants?.some((p) => p?.id === user.id)
        const myLastLog = userLastFlowLog(user.id, flow.flowLogs)

        // I'm one of the participants and I'm in pending state
        return (
          amIParticipated &&
          (myLastLog ? myLastLog.toState?.stateName === workflowConstant.approval.state.pending : true)
        )
      })

      setTodoUrgentFlows(todo.filter((flow: Flow) => flow.urgent === true))
      setTodoOverdueFlows(todo.filter((flow: Flow) => new Date(flow.dueDate) < new Date()))
      setTodoDueTodayFlows(todo.filter((flow: Flow) => isSameDay(new Date(flow.dueDate), new Date())))

      setTodoFlows(todo)
      setInProgressFlows(inProgress)
      setInLoopFlows(inLoops)
      setResultFlows(completed)
      setArchiveFlows(archived)
    } catch (error) {
      console.error(error)
    }
  }, [flows, tab, project, sortOption, sortDirection, searchItems, urgency, timeframe, displayType])

  /**
   * set projects
   */

  const projects = useMemo(() => {
    try {
      if (!flows || !Array.isArray(flows)) return []
      const flowsNotArchived = flows.filter(
        (f) =>
          f.state?.stateName !== workflowConstant.approval.state.archived &&
          !f?.flowLogs
            ?.filter((log) => log?.user?.id === user.id)
            .some((log) => log?.toState?.stateName === workflowConstant.approval.state.archived),
      )
      const flowProjects = flowsNotArchived?.flatMap((flow) => flow.project ?? [])
      return flowProjects.filter((flowProject, i, flowProjectAll) => {
        const targetIndex = flowProjectAll.findIndex((p) => p.id === flowProject.id)
        return targetIndex === i
      })
    } catch (error) {
      console.error(error)
      return []
    }
  }, [flows])

  /**
   * set responsibleDisplayType
   */
  useEffect(() => {
    // show kanban style when displayType is kanban or list on mobile
    if (displayType === FlowDisplayType.kanban || (isSmallScreen && displayType === FlowDisplayType.list)) {
      return setResponsibleDisplayType(FlowResponsibleDisplayType.kanban)
    }
    // show list style when displayType is list on desktop
    if (!isSmallScreen && displayType === FlowDisplayType.list) {
      return setResponsibleDisplayType(FlowResponsibleDisplayType.list)
    }
    // show kanban style when displayType is archive on mobile
    if (isSmallScreen && displayType === FlowDisplayType.archive) {
      return setResponsibleDisplayType(FlowResponsibleDisplayType.archiveKanban)
    }
    // show list style when displayType is archive on desktop
    setResponsibleDisplayType(FlowResponsibleDisplayType.archiveList)
  }, [displayType, isSmallScreen])

  /**
   * Archive tab logic
   */
  useEffect(() => {
    if (tab === FlowTab.archived) {
      setLastDisplayType(displayType)
      setDisplayType(FlowDisplayType.archive)
    } else {
      setDisplayType(lastDisplayType)
    }
  }, [tab])

  return {
    tab,
    searchItems,
    setSearchItems,
    projects,
    sortOption,
    sortDirection,
    displayType,
    responsibleDisplayType,
    todoFlows,
    todoUrgentFlows,
    todoOverdueFlows,
    todoDueTodayFlows,
    inProgressFlows,
    inLoopFlows,
    resultFlows,
    archiveFlows,
    urgency,
    setUrgency,
    timeframe,
    setTimeframe,
    setTab,
    setSortOption,
    setSortDirection,
    setDisplayType,
  }
}
