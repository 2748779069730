import React, { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { User, Block } from 'types/graphqlSchema'
import ViewSDKClient from './ViewSDKClient'
import './AdobePDFEmbed.css'

type PreviewConfig = {
  showZoomControl: boolean
  showAnnotationTools: boolean
  showFullScreen: boolean
  /**
     * This variable takes a string value of "FIT_WIDTH", "FIT_PAGE", "TWO_COLUMN" or "TWO_COLUMN_FIT_PAGE".
        1. FIT_WIDTH: Expands the page horizontally to the full width of the document pane.
        2. FIT_PAGE: Displays the entire page in the current view pane.
        3. TWO_COLUMN: Displays two pages of the PDF side by side in the current view pane.
        4. TWO_COLUMN_FIT_PAGE: Displays two pages of the PDF side by side where the entire two pages are displayed in
        the current view pane. Note that end users can also toggle the view mode via the Fit Width, Fit Page
        or Two-Column button on the right-hand panel.

        In addition to these, there are two other view modes which are supported only in mobile browsers:
        1. CONTINUOUS: This mode displays all the document pages one after the other and users can easily navigate through the pages by scrolling up or down.
        2. SINGLE_PAGE: This mode displays only a single document page at a time and doesn’t show any adjoining page.
        Users can use the swipe gesture to navigate to other pages which will be displayed one at a time.
     */
  defaultViewMode: 'FIT_WIDTH' | 'FIT_PAGE' | 'TWO_COLUMN' | 'TWO_COLUMN_FIT_PAGE' | 'CONTINUOUS' | 'SINGLE_PAGE'
  enableFormFilling: boolean
  showDownloadPDF: boolean
  showPrintPDF: boolean
  showLeftHandPanel: boolean
  showPageControls: boolean

  /**
   * The top bar in lightbox embed mode contains the close button by default to
   * close the PDF preview which can be configured to Back button by setting exitPDFViewerType to "RETURN".
   */
  exitPDFViewerType: 'CLOSE' | 'BACK'
  showThumbnails: boolean
  showBookmarks: boolean
  /**
   * Set this to true to enable PDF linearization. For more details, see the section PDF linearization.
   */
  enableLinearization: boolean
  /**
   * Set this to true to add, update and delete PDF annotations programmatically in full window embed mode.
   * For more details, see the section Annotations API overview.
   * https://developer.adobe.com/document-services/docs/overview/pdf-embed-api/howtos_comments/#annotations-api-overview
   */
  enableAnnotationAPIs: boolean
  /** Leave blank for default as FULL WINDOW MODE
   * https://documentservices.adobe.com/view-sdk-demo/index.html#/view/FULL_WINDOW/Bodea%20Brochure.pdf
   */
  embedMode: 'LIGHT_BOX' | 'SIZED_CONTAINER' | 'IN_LINE' | string
  enableSearchAPIs: boolean
  showDisabledSaveButton: boolean
  /**
   * With this configuration, website developers have the flexibility to control if the
   * PDF should take focus when it is rendered. For more details, see the section Focus on PDF rendering.
   */
  focusOnRendering: any
}

type AdobePDFViewerProps = {
  user?: User
  resource: Block
  readOnly?: boolean
  previewConfig?: Partial<PreviewConfig>
  refresh?: boolean
}

let viewSDKClient

const AdobePDFViewer = ({
  user,
  resource,
  readOnly = false,
  previewConfig = {},
  refresh = false,
}: AdobePDFViewerProps) => {
  const previewRef = useRef(null)
  const loadPreview = () => {
    try {
      viewSDKClient = new ViewSDKClient()
      viewSDKClient.ready().then(() => {
        /* Invoke file preview */
        viewSDKClient.previewFile(`adobe-dc-view-${resource.id}`, previewConfig, resource, readOnly)
        // setTimeout(() => {
        //   if (previewRef.current) {
        //     const iframe = document.getElementById(`iframe-adobe-dc-view-${resource.id}`)
        //     const emptyDiv = previewRef.current.contentWindow.document.getElementById('div').innerHTML === ''
        //     if (emptyDiv) loadPreview()
        //   }
        // }, 1000)
      })
    } catch (error) {
      console.log('Failed to load Preview', error)
    }
  }

  useEffect(() => {
    loadPreview()
  }, [])

  useEffect(() => {
    if (refresh) loadPreview()
  }, [refresh])

  return (
    <Box position="relative" h={[180, 250, 350, '75vh']} w={[240, 350, 500, 842]} borderRadius="6px" mt="70px">
      {/* <div className="pdf-wrapper"> */}
      <Box
        ref={previewRef}
        id={`adobe-dc-view-${resource.id}`}
        position="absolute"
        w="full"
        h="full"
        top={0}
        left={0}
      />
      {/* </div> */}
    </Box>
  )
}

export default AdobePDFViewer
