import { createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../helpers/store'

type UserSettingsState = {
  showModalBody: boolean
  showAvatarChangePanel: boolean
  showChangePasswordPanel: boolean
  showSMSActivation: boolean
  showDeleteSettingsPanel: boolean
  tabIndex: number
  userFirstName: string
  userLastName: string
}

const initialState: UserSettingsState = {
  showModalBody: true,
  showAvatarChangePanel: false,
  showChangePasswordPanel: false,
  showSMSActivation: false,
  showDeleteSettingsPanel: false,
  tabIndex: 0,
  userFirstName: '',
  userLastName: '',
}

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setShowAvatarChangePanel(state, { payload }) {
      state.showAvatarChangePanel = payload
    },
    setShowChangePasswordPanel(state, { payload }) {
      state.showChangePasswordPanel = payload
    },
    setShowModalBody(state, { payload }) {
      state.showModalBody = payload
    },
    setShowSMSActivation(state, { payload }) {
      state.showSMSActivation = payload
    },
    setShowDeleteSettingsPanel(state, { payload }) {
      state.showDeleteSettingsPanel = payload
    },
    setTabIndex(state, { payload }) {
      state.tabIndex = payload
    },
    setUserFirstName(state, { payload }) {
      state.userFirstName = payload
    },
    setUserLastName(state, { payload }) {
      state.userLastName = payload
    },
  },
})

export function useUserSettingsSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.userSettings)
  return {
    dispatch,
    ...state,
    ...userSettingsSlice.actions,
  }
}

export default userSettingsSlice.reducer
