import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { client as apollo } from 'helpers/apollo'
import { pathLocation } from 'helpers/constant'
import { AppDispatch, RootState } from 'helpers/store'
import { GET_NODES, GET_SEARCH_NODES } from '../node/node.schema'
import { SEARCH_IN_LOCATION, SEARCH_FILTERS, GET_SEARCH_LOCATION } from './search.schema'
import { Node } from 'types/graphqlSchema'

interface ISearchParams {
  keyword: string
  location: string
  rootId?: string
}

// ---------------------------------------
// Thunks
// ---------------------------------------

const searchInTipbox = createAsyncThunk('search/searchInTipbox', async (keyword: string) => {
  try {
    const {
      data: { nodes },
    } = await apollo.query({
      query: GET_SEARCH_NODES,
      variables: {
        filter: {
          type: 'search',
          data: keyword,
        },
      },
    })
    return { nodes, count: nodes.length }
  } catch (error: any) {
    throw new Error(error.message)
  }
})

const searchInLocation = createAsyncThunk(
  'search/searchInLocation',
  async ({ keyword, pathname }: { keyword: string; pathname: string }) => {
    try {
      // search home all projects
      let params: ISearchParams = { keyword, location: '' }
      const pathnames = pathname.split('/').filter((x) => x)
      if (pathnames[0] === pathLocation.project) {
        // search in a project
        params = {
          keyword,
          location: pathLocation.project,
          rootId: pathnames.length > 1 ? pathnames.pop() : undefined,
        }
      } else if (pathnames[0] === pathLocation.personal) {
        // search in personal
        params = {
          keyword,
          location: pathLocation.personal,
          rootId: pathnames.length > 1 ? pathnames.pop() : undefined,
        }
      } else if (pathnames[0] === pathLocation.following) {
        // search in following
        params = { keyword, location: pathLocation.following }
      } else if (pathnames[0] === pathLocation.trash) {
        // search trash
        params = { keyword, location: pathLocation.trash }
      } else {
        params = { keyword, location: '' }
      }

      const {
        data: {
          searchLocation: { nodes, count },
        },
      } = await apollo.query({
        query: SEARCH_IN_LOCATION,
        variables: { params },
      })
      return { nodes, count }
    } catch (error) {
      throw new Error(error.message)
    }
  },
)

const searchByLocationSuggestions = createAsyncThunk(
  'search/searchByLocationSuggestions',
  async (keyword: string, { getState }) => {
    try {
      const {
        data: {
          nodes: { nodes, count },
          error,
        },
      } = await apollo.query({
        query: GET_NODES,
        variables: {
          myId: getState().app.user.id,
          filter: {
            type: 'search',
            data: keyword,
          },
        },
      })
      if (error) {
        throw new Error(error)
      }
      return { nodes, count }
    } catch (error) {
      throw new Error(error.message)
    }
  },
)

const searchFilters = createAsyncThunk('search/searchFilters', async (filterParams) => {
  try {
    const {
      data: {
        searchFilters: { nodes, count },
        error,
      },
    } = await apollo.query({
      query: SEARCH_FILTERS,
      variables: { filterParams },
    })
    if (error) {
      throw new Error(error)
    }
    return { nodes, count }
  } catch (error: any) {
    throw new Error(error.message)
  }
})

const searchNodeLocation = createAsyncThunk('search/searchNodeLocation', async (id: string) => {
  try {
    const {
      data: { node, error },
    } = await apollo.query({
      query: GET_SEARCH_LOCATION,
      variables: { id },
    })
    if (error) {
      throw new Error(error.message)
    }
    return node
  } catch (error: any) {
    throw new Error(error)
  }
})

interface SearchState {
  keyword: string
  searchResult: Node[]
  tipboxSuggestions: Node[]
  locationSuggestions: Node[]
  recentSearches: string[]
  error: any
  locationIsLoading: boolean
  tipboxIsLoading: boolean
  pendingLocation: string
  pendingSearch: string
}

const initialState: SearchState = {
  keyword: '',
  searchResult: [],
  tipboxSuggestions: [],
  locationSuggestions: [],
  recentSearches: [],
  error: null,
  pendingLocation: '',
  pendingSearch: '',
  locationIsLoading: false,
  tipboxIsLoading: false,
}

// slice
const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch(state) {
      state.keyword = ''
      state.searchResult = []
      state.tipboxSuggestions = []
      state.locationSuggestions = []
    },
    clearSearchResults(state) {
      state.searchResult = []
      state.tipboxSuggestions = []
      state.locationSuggestions = []
    },
    updateKeyword(state, { payload }) {
      state.keyword = payload
    },
    updateRecentSearches(state, { payload }) {
      if (!state.recentSearches.includes(payload)) {
        state.recentSearches.unshift(payload)
        if (state.recentSearches.length > 5) {
          state.recentSearches.pop()
        }
      }
    },
    updateSearchList(state, { payload }) {
      if (!payload) return
      state.searchResult = state.searchResult.map((node) => {
        if (node.id === payload.id) {
          node.sharedMembers = payload.sharedMembers
        }
        return node
      })
    },
    updateSearchNodeName(state, { payload }) {
      if (!payload) return
      state.searchResult = state.searchResult.map((node) => {
        if (node.id === payload.id) {
          node.name = payload.name
        }
        return node
      })
    },
  },
  extraReducers: (builder) => {
    // get all search items
    builder.addCase(searchInTipbox.pending, (state, { meta }) => {
      const { requestId } = meta
      state.pendingSearch = requestId
      state.tipboxIsLoading = true
    })
    builder.addCase(searchInTipbox.fulfilled, (state, { payload: { nodes }, meta }) => {
      if (!state.keyword || state.keyword.length < 1) return
      if (state.pendingSearch !== meta.requestId) return
      state.searchResult = nodes.filter((node) => node !== null)
      state.tipboxSuggestions = nodes?.slice(0, 5)
      state.tipboxIsLoading = false
    })
    builder.addCase(searchInTipbox.rejected, (state, { payload }) => {
      state.error = payload
    })
    // get items in particular location
    builder.addCase(searchInLocation.pending, (state, { meta }) => {
      const { requestId } = meta
      state.pendingLocation = requestId
      state.locationIsLoading = true
    })
    builder.addCase(searchInLocation.fulfilled, (state, { payload: { nodes }, meta }) => {
      if (!state.keyword || state.keyword.length < 1) return
      if (meta.requestId !== state.pendingLocation) return
      let nodeList = nodes
      if (nodeList.includes(null)) {
        nodeList = nodeList.filter((node) => node !== null)
      }
      state.locationSuggestions = nodeList?.slice(0, 5)
      state.locationIsLoading = false
    })
    builder.addCase(searchInLocation.rejected, (state, { payload }) => {
      state.error = payload
    })
    builder.addCase(searchFilters.fulfilled, (state, { payload: { nodes } }) => {
      state.searchResult = nodes.filter((node) => node !== null)
    })
    builder.addCase(searchFilters.rejected, (state, { payload }) => {
      state.searchResult = []
      state.error = payload
    })
  },
})

export function useSearchSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.search)
  return {
    dispatch,
    ...state,
    ...searchSlice.actions,
    searchNodeLocation,
    searchInTipbox,
    searchInLocation,
    searchByLocationSuggestions,
    searchFilters,
  }
}

export default searchSlice.reducer
