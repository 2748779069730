import {
  persistStore,
  persistReducer,
  createMigrate,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import logger from 'redux-logger'
import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import appReducer from 'features/app/app.slice'
import boxReducer from 'features/box/box.slice'
import linkShareReducer from 'features/linkShare/linkShare.slice'
import nodeReducer from 'features/node/node.slice'
import notificationReducer from 'features/notification/notification.slice'
import projectReducer from 'features/project/project.slice'
import searchReducer from 'features/search/search.slice'
import templateReducer from 'features/template/template.slice'
import tipReducer from 'features/tip/tip.slice'
import workflowReducer from 'features/workflow/workflow.slice'
import userSettingsReducer from 'features/userSettings/userSettings.slice'

const migrations = {
  2: (state) => {
    return {
      ...state,
      app: {
        ...state.app,
        sortType: {
          default: 'Name',
          trash: 'Name',
          search: 'Name',
          recent: 'Last Modified',
        },
      },
    }
  },
  3: (state) => {
    return {
      ...state,
      app: {
        ...state.app,
        prevAutoCollapsed: false,
        prevManualCollapsed: false,
      },
    }
  },
}

const persistConfig = {
  key: 'app',
  version: 3,
  storage,
  // blacklist: ['navigation'],
  migrate: createMigrate(migrations, { debug: false }),
}

const rootReducer = combineReducers({
  app: appReducer,
  box: boxReducer,
  linkShare: linkShareReducer,
  node: nodeReducer,
  notification: notificationReducer,
  project: projectReducer,
  search: searchReducer,
  template: templateReducer,
  tip: tipReducer,
  workflow: workflowReducer,
  userSettings: userSettingsReducer,
  // add more reducer here
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const defaultMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
  // https://docs.sentry.io/platforms/javascript/guides/react/features/redux/
})

const store = configureStore({
  // middleware:
  //   process.env.NODE_ENV === 'development'
  //     ? defaultMiddleware.concat(logger)
  //     : defaultMiddleware,
  middleware: defaultMiddleware,
  reducer: persistedReducer,
  enhancers: [sentryReduxEnhancer],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)
export default store
