import { Flex, VStack, Heading, Image, Link, Text, useColorModeValue, useMediaQuery } from '@chakra-ui/react'
import { images } from 'theme'

const AuthHeader = ({ heading, description, linkText, path, showLogo = false }: IAuthHeaderFooterProps) => {
  const imgSrc = useColorModeValue(images.Logo, images.LogoDark)
  const [shorterThan900px] = useMediaQuery('(max-height: 900px)')

  return (
    <VStack mb={shorterThan900px ? '5vh' : '5vh'} alignItems={'center'} gap={0}>
      <Flex
        mt={shorterThan900px ? '7vh' : '11vh'}
        mb={shorterThan900px ? '5vh' : '11vh'}
        width={'100%'}
        justifyContent={'center'}
      >
        {showLogo && <Image src={imgSrc} height={['2rem', null, null, '2.25rem']} />}
      </Flex>
      <Heading as="h2" fontSize={31} fontWeight={600} variant="authHeading">
        {heading}
      </Heading>
      <Text variant="autherHeaderDescription" mt={0}>
        {description}
        <Link href={path} fontWeight="bold" px="0.5rem" color="accentIcon" _hover={{ textDecoration: 'underline' }}>
          {linkText}
        </Link>
      </Text>
    </VStack>
  )
}

export default AuthHeader
