import { gql } from '@apollo/client'

// ---------------------------
// QUERIES
// ---------------------------

// eslint-disable-next-line import/prefer-default-export
export const GET_TEMPLATES = gql`
  query templates {
    templates {
      name
      id
      boxes {
        name
      }
    }
  }
`
