import React from 'react'
import { useAppSelector } from 'hooks'
import { icons } from 'theme'
import { Flex, Box } from '@chakra-ui/react'
import { Button, IconPopover } from 'components'

interface BreadcrumbDesktopMenuProps {
  breadcrumbs: IBreadcrumb[]
  handleClick: (node: IBreadcrumb) => void
  menuOpen: boolean
  setMenuOpen: (openMenu: boolean) => void
  handleNavagationParentPage: (path: string) => void
}

const BreadcrumbDesktopMenu = ({
  breadcrumbs,
  handleClick,
  menuOpen,
  setMenuOpen,
  handleNavagationParentPage,
}: BreadcrumbDesktopMenuProps) => {
  const { myProject } = useAppSelector((state) => state.app.user)

  return (
    <IconPopover
      variantBtn="iconBreadcrumbsBtn"
      icon={icons.menuIcon}
      placement="bottom-start"
      iconSize={{ height: '16px', width: '16px' }}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      buttonStyle={{
        padding: 0,
        minWidth: '30px',
        maxWidth: '30px',
        height: '30px',
      }}
    >
      <Flex w="255px" rounded={8} overflow="hidden" flexDir="column">
        {breadcrumbs.map((crumb, index) => (
          <React.Fragment key={crumb.id}>
            {crumb.type === 'PROJECT' ? (
              <Box>
                <Button
                  variant="breadcrumbBtn"
                  onClick={() => {
                    if (myProject && crumb.id === myProject.id) {
                      handleNavagationParentPage('/personal')
                    } else {
                      handleClick(crumb)
                    }
                  }}
                  label={crumb.id === myProject?.id ? 'Personal' : crumb.name}
                  style={{
                    fontSize: 16,
                    width: 'full',
                    fontWeight: 600,
                    border: '0px',
                    paddingTop: 6,
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingBottom: index === breadcrumbs.length - 1 ? 6 : 3,
                  }}
                />
              </Box>
            ) : (
              <Box>
                <Button
                  variant="breadcrumbBtn"
                  justifyContent="initial"
                  onClick={() => {
                    handleClick(crumb)
                  }}
                  leftIcon={<icons.breadcrumbMenuArrow height={4} width={4} />}
                  label={crumb.name}
                  style={{
                    fontSize: 14,
                    width: 'full',
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingTop: 3,
                    paddingBottom: index === breadcrumbs.length - 1 ? '24px' : 3,
                  }}
                />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Flex>
    </IconPopover>
  )
}

export default BreadcrumbDesktopMenu
