import React from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { Button, IconPopover } from 'components'
import { menuActions } from 'helpers/constant'
import { customIcons, icons } from 'theme'

interface TrashActionsProps {
  setOpenModal: (openModal: string) => void
  isMobile: boolean
}

const TrashActions = ({ setOpenModal, isMobile }: TrashActionsProps) => (
  <Flex>
    {isMobile ? (
      <IconPopover
        icon={icons.menuIcon}
        iconSize={{ width: 6, height: 6 }}
        variantBtn="passwordBtn"
        buttonStyle={{
          width: '48px',
          height: '40px',
          padding: 0,
          color: 'accent',
        }}
      >
        <Box w="140px" alignItems="center" rounded={8} overflow="hidden">
          <Button
            label="Delete All"
            onClick={() => {
              setOpenModal(menuActions.permanentlyDelete)
            }}
            leftIcon={<customIcons.trashIcon h={6} w={6} />}
            variant="dropDownButton"
            style={{
              flexWrap: 'nowrap',
              paddingTop: '16px',
            }}
          />
          <Button
            label="Restore All"
            onClick={() => {
              setOpenModal('restore')
            }}
            leftIcon={<customIcons.restoreIcon h={6} w={6} />}
            variant="dropDownButton"
            style={{
              flexWrap: 'nowrap',
              paddingBottom: '16px',
            }}
          />
        </Box>
      </IconPopover>
    ) : (
      <>
        <Button
          label="Delete All"
          onClick={() => {
            setOpenModal(menuActions.permanentlyDelete)
          }}
          leftIcon={<customIcons.trashIcon h={6} w={6} />}
          variant="btnOutline"
          style={{
            flexWrap: 'nowrap',
            height: '48px',
            marginRight: '16px',
            width: '144px',
          }}
        />
        <Button
          label="Restore All"
          onClick={() => {
            setOpenModal('restore')
          }}
          leftIcon={<customIcons.restoreIcon h={6} w={6} />}
          variant="defaultButton"
          style={{
            flexWrap: 'nowrap',
            height: '48px',
            width: '144px',
          }}
        />
      </>
    )}
  </Flex>
)

export default TrashActions
