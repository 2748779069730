import { Flex, Text, Avatar, useColorMode, Box } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { images } from 'theme'
import { formateDeleteDate } from 'helpers/utils'
import { useNodeDetails } from 'features/node/hooks'
import { Node } from 'types/graphqlSchema'
import { AvatarsTooltip } from 'components/elements'

type NodeDetailsInfoProps = {
  node: Node
  collapsed: boolean
  setOpenModal?: (menuActions: string) => void
}

function NodeDetailsInfo({ node, collapsed, setOpenModal }: NodeDetailsInfoProps) {
  const {
    type,
    itemName,
    ownerName,
    ownerUrl,
    members,
    totalSize,
    lastModifiedBy,
    createdDate,
    updatedDate,
    updatedAt,
  } = useNodeDetails(node)

  const { pathname } = useLocation()

  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'
  const titleStyle = { color: 'textHighlight' }
  const textStyle = { color: isDark ? 'white' : 'textBlack' }
  const sharedMembersString = members.map((member) => `${member.firstName} ${member.lastName}`).join(', ')

  return (
    <>
      {!collapsed && !type && (
        <Flex mt={16} direction="column" align="center">
          <img src={images.projectDetails} alt="details" />
          <Text mt={4}>Click to view item details</Text>
        </Flex>
      )}
      {!collapsed && type && (
        <>
          <Text mb={6} fontSize="15px" fontWeight="normal" color="textHighlight">
            {itemName}
          </Text>
          {pathname.includes('trash') && (
            <Text fontSize="15px" color="alert" mb={6}>
              {`This ${type.toLowerCase()} will be permanently deleted on ${formateDeleteDate(updatedAt, 1)}`}
            </Text>
          )}
          <Flex alignItems={'center'}>
            <Avatar name={ownerName} src={ownerUrl} border="1px solid white" mb={0} size={'sm'} />
            <Flex ml={3} mb={0} fontSize="15px" fontWeight="normal" style={titleStyle} direction={'column'}>
              <Text>Owner</Text>
              <Text style={textStyle}>{ownerName}</Text>
            </Flex>
          </Flex>
          <Box mb={2}>
            <AvatarsTooltip users={members} max={6} size="md" clickAction={() => setOpenModal?.('share')} />
          </Box>
          <Text fontSize="15px" fontWeight="normal" style={titleStyle} mb={6}>
            Shared with: <span style={textStyle}>{sharedMembersString}</span>
          </Text>
          <Text mb={4} style={titleStyle}>
            Created <span style={textStyle}>{createdDate}</span>
          </Text>
          <Text mb={4} style={titleStyle}>
            Last Modified <span style={textStyle}>{`${updatedDate} by ${lastModifiedBy}`}</span>
          </Text>
          <Flex direction="row" justify="space-between">
            <Text flex={1} style={titleStyle}>
              Size <span style={textStyle}>{totalSize}</span>
            </Text>
          </Flex>
        </>
      )}
    </>
  )
}

export default NodeDetailsInfo
