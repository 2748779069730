import { useState, useEffect, useRef } from 'react'
import {
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { endOfDay } from 'date-fns'
import { AnimatePresence } from 'framer-motion'
import { workflowConstant } from 'helpers/constant'
import { Flow, Node, User, Workflow } from 'types/graphqlSchema'
import FlowForm from './FlowForm'
import { FlowTab } from './components'

interface FlowModalProps {
  isReadOnly?: boolean
  isOpen: boolean
  onClose: () => void
  flow?: Flow
  node?: Node
}

const TabStyles = {
  fontSize: 'inherit',
  p: '0 0 8px 0 ',
  _hover: { color: 'textBlack' },
  _selected: { color: 'textBlack', borderColor: 'textBlack' },
}

const FlowModal = ({ isOpen, onClose, flow, node }: FlowModalProps) => {
  const [tabIndex, setTabIndex] = useState(-1)
  const defaultDueDate = endOfDay(new Date())
  const focusRef = useRef<HTMLInputElement | null>(null)

  // Flow Object
  const [flowState, setFlowState] = useState<FlowState>({
    name: (flow ? flow.name : node?.name) ?? undefined,
    workflow: {} as Workflow | undefined,
    participantOptions: [] as User[],
    participants: [] as User[],
    due: defaultDueDate,
    urgent: false,
    uploadNeed: false,
    message: '',
    reminder: {
      selected: false,
      interval: 2,
    },
    keepInLoop: {
      selected: false,
      watchers: [] as User[],
    },
  })

  useEffect(() => {
    if (node || flow) {
      setFlowState((prev) => ({
        ...prev,
        name: (flow ? flow.name : node?.name) ?? undefined,
      }))
    }
  }, [node, flow])

  const tabListContent = [
    { index: 0, label: 'Approval Request' },
    { index: 1, label: 'General Request' },
  ]

  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          setTabIndex(-1)
        }}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          bg="textHighlight"
          color="textBlack"
          fontFamily={'Inter'}
          w={'600px'}
          maxW={'80%'}
          py={2}
        >
          <ModalHeader
            color="inherit"
            py={0}
            display={'flex'}
            alignItems={'baseline'}
            fontSize={18}
            mx={4}
            mt={2}
            onClick={() => focusRef.current && focusRef.current.focus()}
          >
            <Text color="inherit" minW="max" mr={2} fontWeight={400}>
              Flow Name:
            </Text>
            <Input
              ref={focusRef}
              fontSize={'inherit'}
              placeholder="Give me a name"
              value={flowState.name}
              variant="tipSaveModalTitle"
              onChange={(event) => {
                const newName = event.target.value
                setFlowState({
                  ...flowState,
                  name: newName,
                })
              }}
              color={flowState.name ? 'textBlack' : 'textRegular'}
              _disabled={{ color: 'textRegular' }}
            />
            <Text
              position="absolute"
              mt="6px"
              left={'18.5rem'}
              fontStyle={'italic'}
              fontWeight={400}
              color="accent"
              display={flowState.name ? 'none' : 'initial'}
            >
              * required
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody display={'flex'} flexDirection={'column'} mx={4} px={0} color="textBlack">
            {tabIndex < 0 && (
              <Text color="inherit" fontWeight="semibold" fontSize={14} mx={6}>
                Choose a type of Flow
              </Text>
            )}
            <Tabs
              colorScheme="tipboxPrimary"
              mt={tabIndex < 0 ? 2 : 0}
              isLazy
              index={tabIndex}
              onChange={handleTabsChange}
            >
              <TabList
                display="flex"
                bg="inherit"
                fontSize={'14px'}
                borderBottom="1.5px solid"
                borderBottomColor={tabIndex === -1 ? 'transparent' : 'var(--chakra-colors-borderLight)'}
                gap={6}
                mx={6}
                mb="1.5rem"
              >
                {tabListContent.map((tab) => (
                  <FlowTab key={tab.index} index={tab.index} label={tab.label} tabIndex={tabIndex} />
                ))}
              </TabList>
              <TabPanels>
                <TabPanel as={AnimatePresence}>
                  <FlowForm
                    focusRef={focusRef}
                    flowType={workflowConstant.approval.name}
                    node={node}
                    flow={flow}
                    flowState={flowState}
                    setFlowState={setFlowState}
                  />
                </TabPanel>
                <TabPanel as={AnimatePresence}>
                  <FlowForm
                    focusRef={focusRef}
                    flowType={workflowConstant.feedback.name}
                    node={node}
                    flow={flow}
                    flowState={flowState}
                    setFlowState={setFlowState}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FlowModal
