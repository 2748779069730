/* eslint-disable no-console */
import axios from 'axios'
import _ from 'lodash'
import { getIdToken } from './auth'
import CONFIG from './config'
import { imageSizes } from './constant'

const filesApiService = CONFIG.APP.FILES_API_URL

export const uploadFile = async (file: File, setProgress: (perc: number) => void = () => {}) => {
  try {
    const token = await getIdToken()
    const encodedFilename = encodeURIComponent(file.name)
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': file.type,
        'Original-Filename': encodedFilename,
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress(percentCompleted)
      },
    }

    const response = await axios.post(`${filesApiService}/upload`, file, config)

    return response.data
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const downloadFile = async (key: string) => {
  try {
    const token = await getIdToken()
    const config = {
      responseType: 'blob' as 'json',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        'no-cache': 'true',
      },
    }
    return await axios.get(key, config)
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const downloadText = (filename, text) => {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const getResizedImage = (key: string, sizeSuffix: string) => {
  try {
    // Using the website sizing for both web and mobile, since the original mobile sizing causes blurriness
    const size: { width: number; height: number } = _.get(imageSizes.web, sizeSuffix)
    const resizedUrl = new URL(key)
    resizedUrl.searchParams.append('w', `${size.width}`)
    resizedUrl.searchParams.append('h', `${size.height}`)
    return resizedUrl.toString()
  } catch (error) {
    console.error(error)
  }
}
