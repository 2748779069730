import React, { useEffect } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Text from '@tiptap/extension-text'
import Placeholder from '@tiptap/extension-placeholder'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Link from '@tiptap/extension-link'
import { Maybe } from 'types/graphqlSchema'
import Toolbar from './Toolbar'
import './styles.scss'
import styles from './TiptapEditor.module.css'

type TiptapProps = {
  content: Maybe<string> | undefined
  placeholder?: string
  editable?: boolean
  autofocus?: boolean | null | 'end' | 'start' | 'all' | number
  onChange: (content: Maybe<string> | undefined) => void
  onBlur: () => void
  onRemove: () => void
  setShowRichTextEditor: React.Dispatch<React.SetStateAction<boolean>>
}

export default ({
  content,
  placeholder,
  editable = true,
  autofocus = false,
  onChange,
  onBlur,
  onRemove,
  setShowRichTextEditor,
}: TiptapProps) => {
  const extensions = [
    StarterKit,
    Document,
    Paragraph,
    Text,
    Highlight,
    TaskList,
    TaskItem,
    TaskItem.configure({
      nested: true,
    }),
    Link.configure({
      autolink: true,
    }),
    Link.extend({
      inclusive: false,
    }),
    Placeholder.configure({ placeholder: placeholder || 'Add a note ...' }),
  ]

  const editor = useEditor({
    content,
    extensions,
    editable,
    autofocus,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML())
    },
    onBlur,
  })

  useEffect(() => {
    if (editor && editable) {
      editor.commands.focus()
    }
  }, [editor, editable])

  if (!editor) return null

  return (
    <div className={styles.WhiteCard}>
      <Toolbar editor={editor} editable={editable} setShowRichTextEditor={setShowRichTextEditor} onRemove={onRemove} />
      <EditorContent className={styles.tiptap} editor={editor} />
    </div>
  )
}
