import React from 'react'
import { Link, Container, Text } from '@chakra-ui/react'

const AuthFooter = ({ description, path, linkText }: IAuthHeaderFooterProps) => (
  <Container centerContent p={0} borderTop="1px" borderColor="borderRegular" mt={[10, null, null, 6]} pt={8}>
    <Text>
      {description}
      <Link href={path} fontWeight="bold" px="0.5rem" color="textRegular" _hover={{ color: 'accent' }}>
        {linkText}
      </Link>
    </Text>
  </Container>
)

export default AuthFooter
