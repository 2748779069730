import { Flex, Text, FormControl, FormLabel, Box, Switch, Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAppSlice, useUserSettingsSlice } from 'features/redux'
import SettingsInput from './SettingsInput'
import DisabledSettingsInput from './DisabledSettingsInput'

export const BasicSettings = () => {
  const { user, saveMe } = useAppSlice()
  const [userName, setUserName] = useState<string>(`${user.firstName} ${user.lastName}`)
  const [password, setPassword] = useState<string>('********')

  const { dispatch, setUserFirstName, setUserLastName, setShowModalBody, setShowChangePasswordPanel } =
    useUserSettingsSlice()

  const handleNameChange = () => {
    const [firstName, ...lastName] = userName.split(' ')
    const updatedValues = { firstName, lastName: lastName.join(' ') }
    dispatch(setUserFirstName(updatedValues.firstName))
    dispatch(setUserLastName(updatedValues.lastName))
  }

  const handleClickEditPassword = () => {
    dispatch(setShowModalBody(false))
    dispatch(setShowChangePasswordPanel(true))
  }

  return (
    <Flex direction={'column'} gap={'0.75rem'} mb={'0.75rem'}>
      <Text color={'textBlack'}>Basics</Text>
      <SettingsInput inputKey={'Full Name'} value={userName} setValue={setUserName} handleData={handleNameChange} />
      {!user.username.toLowerCase().includes('google') ? (
        <SettingsInput
          inputKey={'Password'}
          value={password}
          setValue={() => setPassword('********')}
          type={'password'}
          handleData={() => {}}
          handeClickRightElement={() => handleClickEditPassword()}
        />
      ) : (
        <DisabledSettingsInput
          inputKey={'Password'}
          value={password}
          type={'password'}
          tooltipLabel={'Signed up with Google? Change your password in Google account settings.'}
        />
      )}
      <Flex justify={'space-between'} pt={'10px'} pb={'10px'}>
        <Box>2 Step Verification</Box>
        <FormControl display="flex" alignItems="center" width={'auto'}>
          <FormLabel htmlFor="email-alerts" mb="0">
            Enabled
          </FormLabel>
          <Tooltip
            label="This feature is not enabled yet, check back soon"
            hasArrow
            bg={'black'}
            shouldWrapChildren
            mt="3"
          >
            <Switch id="email-alerts" disabled={true} />
          </Tooltip>
        </FormControl>
      </Flex>
    </Flex>
  )
}
