import React, { ReactNode } from 'react'
import { motion, Transition } from 'framer-motion'
import { BoxProps } from '@chakra-ui/react'

type CustomFadeProps = BoxProps & {
  duration?: number
  delay?: number
  type?: 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out'
  exitOpacity?: boolean
  children: ReactNode
}

/**
 * This is a custom Fade prop created because the Fade prop from Chakra does not support customization.
 */
const CustomFade = ({
  duration = 0.6,
  delay = 0.6,
  type = 'ease-in',
  exitOpacity = true,
  children,
}: CustomFadeProps) => {
  const transition: Transition = {
    duration,
    delay,
    type,
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={transition}
      exit={exitOpacity ? { opacity: 0 } : {}}
    >
      {children}
    </motion.div>
  )
}

export default CustomFade
