import React from 'react'
import {
  Modal as ChakraModal,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalCloseButton,
  ModalOverlay,
  ModalBodyProps,
  Heading,
} from '@chakra-ui/react'

interface ModalExternalButtonProps extends ModalBodyProps {
  header: string
  isOpen: boolean
  close: (isOpen: boolean) => void
  size?: string
  modelWidth?: string | (string | null)[]
  modelHeight?: string | (string | null)[]
  rounded?: number
  isCentered?: boolean
  headerBg?: string
  variant?: string
}

const ModalExternalButton = ({
  header,
  isOpen,
  close,
  children,
  size,
  modelWidth = ['25rem', null, null, '31rem'],
  modelHeight,
  rounded = 8,
  isCentered = true,
  headerBg,
  variant,
}: ModalExternalButtonProps) => {
  const { onClose } = useDisclosure()

  const handleClose = () => {
    close(!isOpen)
  }

  return (
    <>
      <ChakraModal
        size={size}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered={isCentered}
        variant={variant}
        motionPreset="none"
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent
          m={0}
          rounded={rounded}
          maxW="unset"
          h={modelHeight}
          width={modelWidth}
          bg="textHighlight"
          color="textBlack"
        >
          <ModalHeader bg={headerBg} color="inherit">
            <Heading as="h3" fontSize={[16, null, null, 24]} fontWeight="600" color="inherit">
              {header}
            </Heading>
          </ModalHeader>
          <ModalCloseButton pos="absolute" top={3} onClick={handleClose} _focus={{ boxShadow: 'none' }} />
          {children}
        </ModalContent>
      </ChakraModal>
    </>
  )
}

export default ModalExternalButton
