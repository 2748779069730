import { useEffect, useState } from 'react'
import { Text, Grid, Flex, Avatar, Box, useBreakpointValue } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { components } from 'react-select'
import { CreatableSelect } from 'chakra-react-select'
import { getResizedImage } from 'helpers/storage'
import { regs } from 'helpers/vali'
import { useAppSlice } from 'features/redux'
import { User } from 'types/graphqlSchema'

interface SRSOption extends User {
  value: string
  label?: string
  __isNew__?: boolean
}

interface SRSProps {
  isOpen?: boolean
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  userSelectOptions?: SRSOption[]
  usersData?: User[]
  selectValue?: SRSOption[]
  setSelectValue: React.Dispatch<React.SetStateAction<SRSOption[] | undefined>>
  setRecipientsToList: React.Dispatch<React.SetStateAction<User[]>>
  recipientsToList: User[]
  menuHeight?: string
}

const ShareRecipientSelect = ({
  isOpen = false,
  setIsOpen,
  userSelectOptions,
  usersData,
  selectValue,
  setSelectValue,
  setRecipientsToList,
  recipientsToList,
  menuHeight,
}: SRSProps) => {
  const { user } = useAppSlice()
  const [isCreatable, setIsCreatable] = useState<boolean>(false)
  const [filteredUserSelectOptions, setFilteredUserSelectOptions] = useState<any[]>()

  const chakraStyles = {
    menu: (provided) => ({
      ...provided,
      background: 'white',
      border: '1.5px solid var(--chakra-colors-borderLight)',
      borderBottomRadius: 8,
      marginTop: 0,
      width: useBreakpointValue({ base: 'full', md: '568px' }, { ssr: false }),
      left: useBreakpointValue({ base: '0', md: '-143px' }, { ssr: false }),
      zIndex: 12,
    }),
    menuList: (provided) => ({
      ...provided,
      background: 'white',
      boxShadow: 'none',
      maxH: menuHeight,
      '&::-webkit-scrollbar': {
        width: '0',
      },
      '&::-webkit-scrollbar-track': {
        width: '0',
      },
    }),
    input: (provided) => ({
      ...provided,
      background: 'transparent',
      color: 'textBlack',
      fontSize: '14px',
      boxShadow: 'none',
      border: 'none',
      _focus: { border: 'none', boxShadow: 'none' },
      _active: { border: 'none', boxShadow: 'none' },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'textRegular',
      fontSize: '14px',
    }),
    control: (provided) => ({
      ...provided,
      variant: 'unstyled',
      background: 'white',
      border: 'none',
      rounded: 'lg',
      _hover: { cursor: 'text' },
      _focus: { cursor: 'text', boxShadow: 'none' },
      _active: { cursor: 'text' },
      zIndex: 9,
    }),
  }

  const handleInputChange = (value: string) => {
    const isEmail = regs.email.test.test(value)
    const isMyEmail = user?.email === value
    const isOptionAlready = userSelectOptions?.some((opt) => opt.value === value)
    setIsCreatable(isEmail && !isMyEmail && !isOptionAlready)
  }

  const handleSelect = (value: SRSOption[]) => {
    if (!value || value?.length === 0) return
    setRecipientsToList((prev = []) => {
      const previousRecipients = prev || []
      let newUser: any = value[0]
      if (newUser.value && !newUser.firstName) {
        newUser = {
          email: value[0].value,
          firstName: value[0].value.split('@')[0],
          lastName: value[0].value.split('@')[1],
        }
      }
      return [...previousRecipients, newUser]
    })
    setSelectValue([])
  }

  useEffect(() => {
    let newUserSelectOptions = userSelectOptions
    if (recipientsToList) {
      newUserSelectOptions = newUserSelectOptions?.filter(
        (userSelectOption) => !recipientsToList.some((user) => user.email === userSelectOption.email),
      )
    } else {
      setFilteredUserSelectOptions(userSelectOptions)
    }
    // Only update the state if necessary
    if (newUserSelectOptions !== userSelectOptions) {
      setFilteredUserSelectOptions(newUserSelectOptions)
    }
  }, [recipientsToList, userSelectOptions])

  if (usersData) {
    return (
      <Grid bg={'white'} opacity={1} w="full">
        <Box gridRowStart={1} gridColumnStart={1}>
          <CreatableSelect
            menuIsOpen={isOpen || undefined}
            onMenuOpen={setIsOpen ? () => setIsOpen(true) : undefined}
            onMenuClose={setIsOpen ? () => setIsOpen(false) : undefined}
            isMulti
            size="lg"
            value={selectValue}
            options={filteredUserSelectOptions}
            placeholder={
              <Flex flex={1}>
                <Text color="textBlack" fontWeight={400}>
                  Enter a name or email address
                </Text>
              </Flex>
            }
            noOptionsMessage={() => null}
            onChange={handleSelect}
            onInputChange={handleInputChange}
            isValidNewOption={() => isCreatable}
            chakraStyles={chakraStyles}
            menuPosition={'fixed'}
            isClearable={false}
            formatCreateLabel={(userInput) => (
              <Flex mx={2} pl={9} py={3} bg="cardBackgroundAccent" rounded={'lg'} cursor={'pointer'}>
                <Text fontFamily={'Inter'} fontWeight={'300'} fontSize={14} color={'white'}>
                  Add guest: <span style={{ fontWeight: 'bold' }}>{userInput}</span>?
                </Text>
              </Flex>
            )}
            components={{
              DropdownIndicator: () => null, // arrow
              IndicatorSeparator: () => null, // seperator before arrow
              MultiValueContainer: (props) => {
                const newInnerProps = {
                  ...props.innerProps,
                  css: {
                    ...props.innerProps.css,
                    // custom styles here
                    borderRadius: '6px',
                    padding: '0',
                    backgroundColor: '#E9EAEC',
                  },
                }
                return <components.MultiValueContainer {...props} innerProps={newInnerProps} />
              },
              Option: ({ innerProps, data }: { innerProps: any; data: any }) => {
                return (
                  <Box {...innerProps} bg="white" overflow={'hidden'}>
                    {data.email ? (
                      <motion.div initial={{ x: 0 }} whileHover={{ x: 7 }} transition={{ duration: 0.2 }}>
                        <Flex
                          px={6}
                          py={2}
                          gap={2}
                          _hover={{ bg: '#F5F5F5' }}
                          justifyContent="flex-start"
                          w="full"
                          bg="inherit"
                          cursor="pointer"
                        >
                          <Avatar
                            name={data.avatarSecureUrl ? '' : `${data.firstName} ${data.lastName}`}
                            size={'sm'}
                            src={data?.avatarSecureUrl ? getResizedImage(data.avatarSecureUrl, 'profile.account') : '#'}
                          />
                          <Flex flexDir="column" color="textBlack" alignItems="flex-start">
                            <Text color="inherit" fontSize="sm" mb={-1}>
                              {data.firstName} {data.lastName}
                            </Text>
                            {data.organization?.name && (
                              <Text color="inherit" fontSize="2xs">
                                {data.organization?.name}
                              </Text>
                            )}
                          </Flex>
                          {data.organizationId !== user.organizationId && (
                            <Text ml={'auto'} pr={48} alignSelf="center" color={'tipbox.dark.text.charcoalGrey'}>
                              Guest
                            </Text>
                          )}
                        </Flex>
                      </motion.div>
                    ) : (
                      <Text p={2}>{data.label}</Text>
                    )}
                  </Box>
                )
              },
            }}
          />
        </Box>
      </Grid>
    )
  }
  return <></>
}

export default ShareRecipientSelect
