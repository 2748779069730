import { useState } from 'react'
import { useAppSlice, useProjectSlice, useBoxSlice, useTipSlice } from 'features/redux'

export default function useDragAndCombine() {
  const {
    dispatch,
    user: { followedNodes },
  } = useAppSlice()
  const { currentProject, updateProject } = useProjectSlice()
  const { boxes, currentBox, setBoxes, moveBox, updateBox } = useBoxSlice()
  const { tips, moveTip, setTips } = useTipSlice()

  const [droppingIn, setDroppingIn] = useState<string>('')
  const [boxIndex, setBoxIndex] = useState<null | number>(null)
  const [shouldBoxReorder, setShouldBoxReorder] = useState<boolean>(true)
  const [shouldTipReorder, setShouldTipReorder] = useState<boolean>(true)

  // Helper
  /**
   *
   * @param oldArr array containing id of follow nodes
   * @param reArrangedArr array containing id of rearranged nodes in order
   * @returns array containing swapped/rearranged version of following ids
   */
  const rearrangeFollowIds = (oldArr: string[], reArrangedArr: string[]) => {
    if (!oldArr?.length || !reArrangedArr?.length) return []
    const newRearrangedIds = [...oldArr]
    reArrangedArr.forEach((item, i) => {
      const indexAtOldArr = newRearrangedIds.indexOf(item)
      const oldContent = newRearrangedIds[i]
      newRearrangedIds[i] = newRearrangedIds[indexAtOldArr]
      newRearrangedIds[indexAtOldArr] = oldContent
    })
    return newRearrangedIds
  }

  // Handle combine
  const handleCombineInsideBox = async (source: any, destinationIndex: any) => {
    const { index, droppableId } = source
    /**
     * When tip being dragged on a box, destination idnex is a Number
     * When box being dragged on a box, destination index is the id of the destionation box
     */
    let parentBoxId
    if (Number(destinationIndex) || Number(destinationIndex) === 0) {
      parentBoxId = boxes[destinationIndex]?.id
    } else {
      parentBoxId = destinationIndex
    }

    // If a tip is dragged on top of a box
    if (droppableId === '2') {
      // Remove the tip from the existing tip arr
      const newFilteredTipsArr = [...tips]
      const [tipAdded] = newFilteredTipsArr.splice(index, 1)
      dispatch(setTips(newFilteredTipsArr))
      await dispatch(moveTip({ resourceId: tipAdded.id, destinationId: parentBoxId }))
    } else {
      // If a box is dragged on top of a box
      const newFilteredBoxesArr = [...boxes]
      const [boxAdded] = newFilteredBoxesArr.splice(index, 1)
      dispatch(setBoxes(newFilteredBoxesArr))
      await dispatch(moveBox({ resourceId: boxAdded.id, destinationId: parentBoxId }))
      setDroppingIn('')
    }
  }

  // Handle drag
  const handleDragging = async (result: any, inFollowing = false) => {
    /**
     * droppableId: 1 --> BOX dropzone
     * droppableId: 2 --> TIP dropzone
     */
    const { source, destination, combine } = result
    const sourceIndex = source.index
    const destinationIndex = destination?.index || null
    const destinationDroppableId = destination?.droppableId || null
    //* Combine
    // Return if combining inside follows
    if (combine && inFollowing) return { shouldRefresh: false }
    // Applies when box being dragged on top of a box
    if (combine) {
      await handleCombineInsideBox(source, combine.draggableId)
      return { shouldRefresh: true }
    }
    // * Applies when tip being dragged on top of a box
    if (source?.droppableId === '2' && destinationDroppableId === '1') {
      await handleCombineInsideBox(source, result.destination.index)
      setBoxIndex(null)
      return { shouldRefresh: true }
    }
    // * Return if a box is dragged onto a Tip
    if (source?.droppableId === '1' && destination?.droppableId === '2') {
      return {}
    }
    // * Return if dragged outside of a droppable area
    if (!destinationDroppableId) return {}
    //* Rearrange the boxes
    if (destinationDroppableId === '1') {
      const rearrangedBoxesArr = [...boxes]
      const [removed] = rearrangedBoxesArr.splice(sourceIndex, 1)
      rearrangedBoxesArr.splice(destinationIndex, 0, removed)
      const rearrangedBoxIds = rearrangedBoxesArr.map(({ id }: IBox) => id)
      dispatch(setBoxes(rearrangedBoxesArr))
      if (currentBox) {
        dispatch(
          updateBox({
            id: currentBox.id,
            childrenIds: rearrangedBoxIds,
          }),
        )
      } else if (inFollowing) {
        // // TODO: refactor to new API
        // dispatch(
        //   updateMe({
        //     followIds: rearrangeFollowIds(followedNodes, rearrangedBoxIds),
        //   }),
        // )
      } else {
        await dispatch(
          updateProject({
            id: currentProject.id,
            childrenIds: rearrangedBoxIds,
          }),
        )
      }
      return { shouldRefresh: false }
    }

    //* Rearrange the tips
    const rearrangedTipsArr = [...tips]
    const [removed] = rearrangedTipsArr.splice(sourceIndex, 1)
    rearrangedTipsArr.splice(destinationIndex, 0, removed)
    const rearrangedTipIds = rearrangedTipsArr.map(({ id }: ITip) => id)
    dispatch(setTips(rearrangedTipsArr))
    if (currentBox) {
      dispatch(
        updateBox({
          id: currentBox.id,
          childrenIds: rearrangedTipIds,
        }),
      )
    } else if (inFollowing) {
      // dispatch(
      //   // TODO: refactor to new API
      //   updateMe({
      //     followIds: rearrangeFollowIds(followedNodes, rearrangedTipIds),
      //   }),
      // )
    } else {
      dispatch(
        updateProject({
          id: currentProject.id,
          childrenIds: rearrangedTipIds,
        }),
      )
    }
    return { shouldRefresh: false }
  }

  // Handle update
  const handleDragUpdate = (update: any) => {
    const { source, destination } = update

    // Box being dropped on to tip
    if (source?.droppableId === '1' && destination?.droppableId === '2') {
      setShouldTipReorder(false)
      setShouldBoxReorder(true)
    }

    // if box is held and drag started
    if (source?.droppableId === '1') {
      setShouldTipReorder(false)
    } else {
      setShouldTipReorder(true)
    }
    // if tip is held and drag started
    if (source?.droppableId === '2') {
      setShouldBoxReorder(false)
    } else {
      setShouldBoxReorder(true)
    }

    // Tip being dragged on top of box
    if (source?.droppableId === '2' && destination?.droppableId === '1') {
      setShouldTipReorder(true)
      setShouldBoxReorder(false)
      setBoxIndex(destination.index)
    } else {
      setBoxIndex(null)
    }
  }

  // Handle UndoMove
  const handleUndoMove = async (result: any) => {
    const { source } = result
    const params = {
      resourceId: result.draggableId,
      destinationId: '',
    }
    // Moving a tip inside a box
    if (source.droppableId === '2') {
      params.destinationId = tips[source.index].parent.id
      await dispatch(moveTip(params))
    } else {
      // Moving a box inside a box
      params.destinationId = boxes.find((b: any) => b.id === result.combine.draggableId)?.parent.id || ''
      await dispatch(moveBox(params))
    }
    // return { refresh: true }
    // refreshCurrentProject()
    // dispatch(getProject(currentProject.id))
  }

  return {
    handleDragging,
    handleDragUpdate,
    handleUndoMove,
    setDroppingIn,
    droppingIn,
    boxIndex,
    shouldBoxReorder,
    shouldTipReorder,
  }
}
