import React from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Box, Flex, Text, IconButton, useBreakpointValue } from '@chakra-ui/react'
import { SkeletonProjectCard } from 'components'
import { icons } from 'theme'
import { ProjectCard } from 'features/project/components'
import { Node } from 'types/graphqlSchema'

type PropsCarouselProps = {
  projects: IProject[] | Node[]
  isLoading: boolean
  pageType: string
}

const Arrow = (props) => {
  const { onClick, left, className } = props
  return (
    <Flex
      display={className.includes('slick-disabled') ? 'none' : 'flex'}
      pl={left ? 0 : 6}
      pr={left ? 6 : 0}
      zIndex={2}
      align="center"
      gap={2}
      flexDir={left ? 'row-reverse' : 'row'}
    >
      <Text variant="tiny" color="textRegular" textAlign="center">
        {left ? 'Prev' : 'More'}
      </Text>
      <IconButton
        aria-label="carousel button"
        colorScheme="tipbox.primary"
        variant="projectsCarouselBtn"
        onClick={onClick}
        icon={left ? <icons.FaChevronLeft /> : <icons.FaChevronRight />}
      />
    </Flex>
  )
}

export default ({ projects, isLoading, pageType }: PropsCarouselProps) => {
  const isMobile = useBreakpointValue({ xs: false, lg: true })
  const settings: Settings = {
    arrows: isMobile,
    swipe: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    variableWidth: true,
    slidesToScroll: 4,
    prevArrow: <Arrow left />,
    nextArrow: <Arrow left={false} />,
  }
  return (
    <Box pos="absolute" left={0} right={0} top={0} bottom={0} w="full">
      {isLoading ? (
        <Flex as={Slider} {...settings} pos="relative">
          <Box
            maxWidth={['calc(90vw + 16px)', null, '14rem']}
            minW={['calc(90vw + 16px)', null, '14rem']}
            pr={4}
            pl={[4, null, null, 0]}
          >
            <SkeletonProjectCard />
          </Box>
          <Box maxWidth={['90vw', null, '14rem']} minW={['90vw', null, '14rem']} pr={4}>
            <SkeletonProjectCard />
          </Box>
          <Box maxWidth={['90vw', null, '14rem']} minW={['90vw', null, '14rem']} pr={4}>
            <SkeletonProjectCard />
          </Box>
          <Box maxWidth={['90vw', null, '14rem']} minW={['90vw', null, '14rem']} pr={4}>
            <SkeletonProjectCard />
          </Box>
        </Flex>
      ) : (
        <Flex as={Slider} {...settings} alignItems="center">
          {projects
            ?.filter((project) => project !== null)
            .map((project, index) => (
              <Box
                key={project.id}
                pl={index === 0 ? [4, null, null, 0] : [0]}
                pr={4}
                maxW="14rem"
                minW="14rem"
                h="full"
              >
                <ProjectCard projectInfo={project} alerts={0} pageType={pageType} noBorder />
              </Box>
            ))}
        </Flex>
      )}
    </Box>
  )
}
