import React from 'react'
import { Outlet } from 'react-router-dom'
import { Flex, Spinner } from '@chakra-ui/react'
import { useAppSelector } from 'hooks'

const AuthLayout = () => {
  const { isLoading } = useAppSelector((state) => state.app)
  const showSpinner = isLoading && window.location.href.includes('/link')

  return (
    <Flex>
      {/* {showSpinner && ( */}
      {/*   <Spinner */}
      {/*     position="absolute" */}
      {/*     bottom={['100', null, null, '10']} */}
      {/*     right="10" */}
      {/*     thickness="4px" */}
      {/*     speed="0.65s" */}
      {/*     emptyColor="gray.200" */}
      {/*     color="accent" */}
      {/*     size="lg" */}
      {/*   /> */}
      {/* )} */}
      <Outlet />
    </Flex>
  )
}

export default AuthLayout
