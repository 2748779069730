import React from 'react'
import { Flex, Box, Grid, Text, useBreakpointValue } from '@chakra-ui/react'
import { SelectPopover } from 'components'
import { sortOptions, listDisplayLabels } from 'helpers/constant'
import { icons } from 'theme'

interface ListDisplayBarProps {
  sortBy: string
  setSortBy: (sortBy: string) => void
  pageType: string
  alternativeName?: string
}

const ListDisplayBar = ({ sortBy, setSortBy, pageType, alternativeName }: ListDisplayBarProps) => {
  const isMobile = useBreakpointValue({ xs: true, md: false })
  return (
    <Grid
      templateColumns={
        pageType === 'search'
          ? ['4rem auto 15% 4rem', null, null, '4rem auto 70px 10% 15% 12% 4rem']
          : ['auto 15.5% 30.5% 4.5rem', null, null, 'auto 12% 25% 4.5rem']
      }
      w="full"
      maxW="full"
      overflow="hidden"
      pb={6}
    >
      <Flex align="flex-end" pl={0} minW={4}>
        {alternativeName && (
          <Text color="textAsh" fontSize={12}>
            {alternativeName}
          </Text>
        )}

        {listDisplayLabels[pageType].includes('name') && (
          <Text color="textAsh" fontSize={12}>
            Name
          </Text>
        )}

        {listDisplayLabels[pageType].includes('sort') && (
          <SelectPopover
            options={sortOptions[pageType]}
            currentOption={sortBy}
            setCurrentOption={setSortBy}
            triggerIcon={icons.downArrow}
            btnStyle={{ alignContent: 'flex-end' }}
            triggerAlign="flex-end"
          />
        )}
      </Flex>
      {pageType === 'search' && <Box w="16px" h="full" />}
      <Flex align="flex-end" justify="flex-start" display={isMobile ? 'none' : 'flex'}>
        <Text color="textAsh" fontSize={12}>
          {listDisplayLabels[pageType].includes('type') ? 'Type' : ''}
        </Text>
      </Flex>
      {pageType === 'search' && <Box w="16px" h="full" />}

      <Flex align="flex-end" w="full">
        <Text color="textAsh" fontSize={12} display={isMobile ? 'none' : 'unset'}>
          {listDisplayLabels[pageType].includes('lastUpdated') ? 'Last updated' : 'Deleted'}
        </Text>
      </Flex>

      {listDisplayLabels[pageType].includes('size') && !isMobile && (
        <Flex justify="flex-start" align="flex-end">
          <Text color="textAsh" fontSize={12} textAlign="right" pl={4}>
            Size
          </Text>
        </Flex>
      )}
    </Grid>
  )
}

export default ListDisplayBar
