export default {
  PROJECT_NAME: 'Tipbox App',
  VERSION: '0.0.1',
  FILE_SIZE: '15mb',
  PORT: process.env.REACT_APP_PORT || 8080,
  ENV: process.env.NODE_ENV || 'production',
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || 'prod',
  REACT_ENV: process.env.REACT_APP_ENV || 'development',
  APP: {
    URL: process.env.REACT_APP_APP_URL || 'http://localhost:8080',
    API_URL: process.env.REACT_APP_API_URL || 'http://localhost:9090',
    FILES_API_URL: process.env.REACT_APP_FILES_API_URL || 'http://localhost:3000',
    WS_URL: process.env.REACT_APP_WS_URL || 'ws://localhost:8080/graphql',
  },
  AWS: {
    REGION: process.env.REACT_APP_AWS_REGION,
    CLOUDFRONT: {
      STORAGE_URL: process.env.REACT_APP_STORAGE_CLOUDFRONT,
    },
    S3: {
      STORAGE_BUCKET: process.env.REACT_APP_S3_BUCKET,
      STORAGE_URL: process.env.REACT_APP_S3_URL,
      PUBLIC_URL: `https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/public/`,
    },
    COGNITO: {
      URI: process.env.REACT_APP_COGNITO_URI,
      REDIRECT_URI: process.env.REACT_APP_COGNITO_REDIRECT_URI,
      USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
      CALLBACK: process.env.REACT_APP_COGNITO_REDIRECT_URI,
      JWKS_URL: process.env.REACT_APP_COGNITO_JWKS_URL,
    },
  },
  ADOBE: {
    CLIENT_ID: process.env.REACT_APP_ADOBE_CLIENT_ID || '',
  },
  PRODUCT_FRUITS: {
    WORKSPACE: process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE || '',
    LANGUAGE: process.env.REACT_APP_PRODUCT_FRUITS_LANGUAGE || '',
  },
  TESTING: {
    TEST_USERS_NAME: process.env.TEST_USERS_NAME || '',
    TEST_USERNAME: process.env.TEST_USERNAME || '',
    TEST_EMAIL: process.env.TEST_EMAIL || '',
    TEST_PASSWORD: process.env.TEST_PASSWORD || '',
  },
}
