import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { imageExts } from 'helpers/constant'
import {
  Box,
  BoxProps,
  chakra,
  Flex,
  FormControl,
  Grid,
  InputGroup,
  InputProps,
  Heading,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { Button, Loader, Toast } from 'components'
import { useDebounceEffect } from 'hooks'
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop'
import { getCroppedImage } from 'components/fragments/ImageResize/cropImage'
import imageCompression from 'browser-image-compression'
import { uploadFile } from 'helpers/storage'
import { useAppSlice, useUserSettingsSlice } from 'features/redux'
import { motion } from 'framer-motion'

const AvatarSettings = ({ showImgCrop, setShowImgCrop }) => {
  const { user, dispatch, updateMe } = useAppSlice()
  const { setShowAvatarChangePanel, setShowModalBody } = useUserSettingsSlice()
  const [file, setFile] = useState<File | null>(null)
  const [warning, setWarning] = useState<string>('')
  const [avatarPreview, setAvatarPreview] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isMobile = useBreakpointValue({ xs: true, md: false })

  const onChangePicture = (avatarFile: File) => {
    if (!avatarFile) {
      return
    }
    const extension = avatarFile.name.split('.').pop()?.toLowerCase() || ''
    const isSuccess = imageExts.indexOf(extension) > -1
    if (!isSuccess) {
      setWarning('Uploading file is not an image')
      setAvatarPreview('')
      setFile(null)
      return
    }
    const avatarUrl = URL.createObjectURL(avatarFile)
    setWarning('')
    setAvatarPreview(avatarUrl)
    setFile(avatarFile)
    setShowImgCrop(!showImgCrop)
  }

  const onResizeSave = (croppedFile: File) => {
    onChangePicture(croppedFile)
    // await handleEditAvatar(file)
  }

  const handleEditAvatar = async () => {
    if (!file) {
      return
    }
    try {
      setIsLoading(true)
      const userPicturePath = await uploadFile(file)
      // await invalidateCoverImage(userPicturePath)
      const input: IUserUpdateInput = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        avatar: userPicturePath.fileUrl,
      }
      await dispatch(updateMe(input))
      dispatch(setShowAvatarChangePanel(false))
      dispatch(setShowModalBody(true))
      Toast.show({ icon: 'check', message: `Avatar changed successfully` })
    } catch (error) {
      Toast.show({ icon: 'error', message: `Something went wrong, please try again later` })
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    dispatch(setShowAvatarChangePanel(false))
    dispatch(setShowModalBody(true))
  }

  useEffect(() => {
    return () => {
      setAvatarPreview('')
      setFile(null)
    }
  }, [])

  if (showImgCrop && file) {
    return <ImageResize imageFile={file} onCancel={() => setShowImgCrop(false)} onSave={onResizeSave} />
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        type: 'ease',
        duration: 0.6,
        opacity: { delay: 0.6 },
      }}
      exit={{ opacity: 0 }}
    >
      <FormControl>
        <Box w="full">
          <FileInput
            placeHolder="Upload an image from your device"
            name="avatar"
            file={file}
            onSelectFile={onChangePicture}
            preview={avatarPreview}
            locked={false}
          />
          {warning && (
            <Text pt={3} variant="errorText">
              {warning}
            </Text>
          )}
        </Box>
        <Flex w="100%" justifyContent={['space-between', null, null, 'flex-end']} py={4}>
          <Box pr={4}>
            <Button label="Cancel" variant="btnOutline" onClick={handleCancel} />
          </Box>
          <Button
            label="Change Avatar"
            variant="defaultButton"
            style={isMobile ? { flex: 1 } : {}}
            onClick={handleEditAvatar}
            isLoading={isLoading}
          />
        </Flex>
      </FormControl>
    </motion.div>
  )
}

interface FileInputProps extends InputProps {
  name: string
  placeHolder: string | JSX.Element
  file: File | null
  onSelectFile: (file: File) => void
  onCancelFile?: () => void
  preview: string | undefined
  noPreviewFileName?: boolean
  styleProps?: BoxProps
  locked?: boolean
}

const FileInput = ({
  name,
  placeHolder,
  file,
  onSelectFile,
  onCancelFile,
  preview,
  noPreviewFileName,
  styleProps,
}: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [hovering, setHovering] = useState(false)
  const [resizeModal, setResizeModal] = useState(false)
  const isInputImage = true

  const onFileInputChange = (e: ChangeEvent): void => {
    const input = e?.target as HTMLInputElement
    if (input && input.files) {
      if (isInputImage) {
        onSelectFile(input.files[0])
        setResizeModal(true)
      } else {
        onSelectFile(input.files[0])
      }
      input.value = ''
    }
  }

  const onResizeSave = (croppedFile: File) => {
    setResizeModal(false)
    onSelectFile(croppedFile)
  }

  return (
    <FormControl>
      <Heading color={'textBlack'} fontWeight={'500'} fontSize={'24px'} mb={'2rem'}>
        Edit or Add an account photo
      </Heading>
      <Box
        w="full"
        border="1px"
        borderColor={hovering ? 'accent' : 'borderLight'}
        rounded={8}
        mt={3}
        h="185px"
        overflow="hidden"
        {...styleProps}
        onClick={() => inputRef.current?.click()}
        onMouseEnter={() => {
          setHovering(true)
        }}
        onMouseLeave={() => {
          setHovering(false)
        }}
      >
        <InputGroup h="full">
          <input
            type="file"
            name={name}
            ref={inputRef}
            onChange={onFileInputChange}
            style={{ display: 'none' }}
            accept={isInputImage ? 'image/png, image/gif, image/jpeg' : '*'}
          />
          {preview ? (
            <Grid
              templateColumns={file ? 'minmax(79px, auto)' : 'auto'}
              gridAutoFlow="column"
              gridAutoColumns="1fr"
              maxW="full"
              w="full"
              overflowX="hidden"
            >
              <Box bgImage={preview} w="full" h="full" bgSize="contain" bgRepeat="no-repeat" bgPosition="center" />
            </Grid>
          ) : (
            <Flex flex="1" align="center" cursor="pointer">
              <Text
                as="span"
                width="100%"
                color={hovering ? 'accent' : 'textBlack'}
                p={2}
                fontSize={[12, null, null, 16]}
                textAlign="center"
              >
                Upload an <chakra.span color={'accent'}>image</chakra.span> from your device
              </Text>
            </Flex>
          )}
        </InputGroup>
      </Box>
    </FormControl>
  )
}

type ImageResizeProps = {
  imageFile: File | undefined
  onCancel: () => void
  onSave: (file: File) => void
  locked?: boolean
  kind?: string
}

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const ImageResize = ({ imageFile, onCancel, onSave, locked = true, kind = 'avatar' }: ImageResizeProps) => {
  const { onClose } = useDisclosure()
  const mobile = useBreakpointValue({ base: true, lg: false })
  const imgRef = useRef<HTMLImageElement>(null)
  const [imgSrc, setImgSrc] = useState('')
  const [crop, setCrop] = useState<Crop>()
  const [finalCrop, setFinalCrop] = useState<PixelCrop>()
  const [bannerCrop, setBannerCrop] = useState<PixelCrop>()
  const [bannerCroppedBlob, setBannerCroppedBlob] = useState<Blob>()

  useEffect(() => {
    const compressImageFileIfNeeded = async () => {
      if (!imageFile) return
      setCrop(undefined)

      // file reader
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
      })
      // compress image if image file size > 1MB
      const compressedFile =
        imageFile.size > 1000000
          ? await imageCompression(imageFile, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1500,
              maxIteration: 15,
            })
          : imageFile

      // read image file
      reader.readAsDataURL(compressedFile)
    }
    compressImageFileIfNeeded()
  }, [imageFile])

  useDebounceEffect(
    async () => {
      if (kind === 'projectCover' && bannerCrop?.width && bannerCrop?.height && imgRef.current) {
        const blob = await getCroppedImage(imgRef.current, bannerCrop, 1)
        setBannerCroppedBlob(blob)
      }
    },
    100,
    [bannerCrop, finalCrop],
  )

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 1))
  }

  const onCroppedComplete = (c: PixelCrop) => {
    setFinalCrop(c)

    if (kind === 'projectCover') {
      const bannerAspect = 8 / 3
      setBannerCrop({
        ...c,
        y: c.y + (c.height - c.width / bannerAspect) / 2,
        height: c.width / bannerAspect,
      })
    }
  }

  const onCropSave = async () => {
    if (imageFile && imgRef.current && finalCrop) {
      const finalCroppedBlob = await getCroppedImage(imgRef.current, finalCrop, 1)
      const finalImgFile = new File([finalCroppedBlob], imageFile?.name, {
        lastModified: new Date().getTime(),
        type: imageFile?.type,
      })
      onSave(finalImgFile)
    }
  }

  if (!imgSrc) {
    return (
      <Box h="full" w="full" alignItems="center">
        <Loader />
      </Box>
    )
  }

  return (
    <Box width={'full'}>
      <Text>Crop your photo</Text>
      <Flex justifyContent={'center'}>
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={onCroppedComplete}
          aspect={1}
          style={{
            maxHeight: '320px',
            maxWidth: '100%',
          }}
        >
          <img ref={imgRef} alt="crop me" src={imgSrc} onLoad={onImageLoad} />
        </ReactCrop>
      </Flex>
      <Flex align="center" justify={['space-between', null, null, 'flex-end']} w={'full'}>
        <Button label="Cancel" maxWidth="7.375rem" onClick={onCancel} variant={'btnOutline'} />
        <Button label="Save" onClick={onCropSave} variant="modalBtn" testId="save-photo" />
      </Flex>
    </Box>
  )
}

export default AvatarSettings
