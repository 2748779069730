import React from 'react'
import { icons } from 'theme'
import { Flex, Box, useDisclosure } from '@chakra-ui/react'
import { IconButton, ActionModal } from 'components'

interface CurrentNode {
  name: string
  type: string
  breadcrumbs: IBreadcrumb[]
}

interface BreadcrumbMobileMenuProps {
  currentNode: CurrentNode
  handleClick: (node: IBreadcrumb) => void
}

const BreadcrumbMobileMenu = ({ currentNode, handleClick }: BreadcrumbMobileMenuProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  return (
    <>
      <IconButton
        ariaLabel="breadCrumbButton"
        variant="passwordBtn"
        icon={icons.menuIcon}
        iconSize={{ width: 4, height: 4 }}
        onClick={onToggle}
        style={{
          color: 'textRegular',
          maxWidth: '16px',
          height: '20px',
        }}
      />
      <ActionModal
        onClose={() => {
          onClose()
        }}
        isOpen={isOpen}
        title="BreadCrumbs"
      >
        <Flex flexDir="column" mx={4} mt={8}>
          {currentNode?.breadcrumbs?.map((node) => (
            <Box key={node?.id} borderBottom="1px" borderColor="borderRegular">
              <IconButton
                ariaLabel="boxButtons"
                variant="breadcrumbBtn"
                justify={['space-between']}
                icon={icons.FaChevronRight}
                flexDir="row-reverse"
                buttonLabelWidth="full"
                iconSize={{ width: 4, height: 4 }}
                iconColor="textHighlight"
                label={node.name}
                onClick={() => {
                  handleClick(node)
                  onClose()
                }}
              />
            </Box>
          ))}
          <Box borderBottom="1px" borderColor="borderRegular">
            <IconButton
              ariaLabel="boxButtons"
              variant="breadcrumbBtn"
              justify={['space-between']}
              icon={icons.FaChevronRight}
              flexDir="row-reverse"
              buttonLabelWidth="full"
              iconSize={{ width: 4, height: 4 }}
              iconColor="textHighlight"
              label={currentNode.name}
              onClick={() => {
                onClose()
              }}
            />
          </Box>
        </Flex>
      </ActionModal>
    </>
  )
}

export default BreadcrumbMobileMenu
