import { chakra, Box, Input, Flex, FormLabel, Button, Heading, UnorderedList, ListItem } from '@chakra-ui/react'
import React, { useState, useEffect, ChangeEvent } from 'react'
import { changePassword } from 'helpers/auth'
import { useAppSlice } from '../../app'
import { AnimatePresence } from 'framer-motion'
import { Toast } from 'components'
import { regs, validate } from 'helpers/vali'
import CustomFade from 'components/elements/Transition'
import { useUserSettingsSlice } from '../userSettings.slice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../helpers/store'

type PasswordError = {
  incorrectPw: boolean
  pwTooShort: boolean
  pwChar: boolean
  pwDontMatch: boolean
}

const PasswordSettings = () => {
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')

  const [inputError, setInputError] = useState<PasswordError>({
    incorrectPw: false,
    pwTooShort: false,
    pwChar: false,
    pwDontMatch: false,
  })

  const dispatch = useDispatch<AppDispatch>()

  const { updateMe } = useAppSlice()
  const { setShowChangePasswordPanel, setShowModalBody } = useUserSettingsSlice()

  const disableButton =
    currentPassword.length === 0 ||
    newPassword.length === 0 ||
    confirmNewPassword.length === 0 ||
    inputError.pwDontMatch ||
    inputError.pwChar ||
    inputError.pwTooShort

  const handleChangePassword = async () => {
    try {
      await changePassword(currentPassword, newPassword)
      await dispatch(updateMe({ lastPasswordChange: new Date() }))
      dispatch(setShowChangePasswordPanel(false))
      dispatch(setShowModalBody(true))
      Toast.show({
        icon: 'check',
        message: 'Password changed successfully.',
      })
    } catch (error) {
      if (error.message.includes('Incorrect')) {
        setInputError({ ...inputError, incorrectPw: true })
        return
      }
      Toast.show({
        icon: 'error',
        message: 'Something went wrong, please try again later',
      })
    }
  }

  const handleCancel = () => {
    dispatch(setShowChangePasswordPanel(false))
    dispatch(setShowModalBody(true))
  }

  const onChangeCurrentPw = (event: ChangeEvent<HTMLInputElement>) => {
    setInputError({ ...inputError, incorrectPw: false })
    setCurrentPassword(event.target.value)
  }

  const onChangeNewPw = (event: ChangeEvent<HTMLInputElement>) => {
    setInputError({ ...inputError, pwTooShort: false, pwChar: false, pwDontMatch: false })
    setNewPassword(event.target.value)
  }

  const onChangeConfirmPw = (event: ChangeEvent<HTMLInputElement>) => {
    setInputError({ ...inputError, pwDontMatch: false })
    setConfirmNewPassword(event.target.value)
  }

  const handleOnBlurNewPw = () => {
    const errors = { ...inputError }

    if (newPassword.length < 10) {
      errors.pwTooShort = true
    }

    const res = validate({ password: newPassword }, regs)
    if (res.isError) {
      errors.pwChar = true
    }
    setInputError(errors)
  }

  const handleOnBlurConfirmPw = () => {
    if (newPassword !== confirmNewPassword) {
      setInputError({ ...inputError, pwDontMatch: true })
    }
  }

  useEffect(() => {
    return () => {
      setInputError({ incorrectPw: false, pwTooShort: false, pwChar: false, pwDontMatch: false })
    }
  }, [])

  return (
    <AnimatePresence>
      <CustomFade>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Box>
            <Heading color={'textBlack'} fontWeight={'500'} fontSize={'24px'} pb={'32px'} pt={'13px'}>
              Change Password
            </Heading>
            <FormLabel color={'textBlack'} fontSize={'14px'}>
              Current Password{' '}
              <chakra.span
                display={inputError.incorrectPw ? 'inline' : 'none'}
                color={'modalRed'}
                ml={3}
                fontWeight={400}
              >
                Incorrect password
              </chakra.span>
            </FormLabel>
            <Input value={currentPassword} onChange={onChangeCurrentPw} variant={'modal'} type={'password'}></Input>
          </Box>
          <Box>
            <FormLabel color={'textBlack'} fontSize={'14px'}>
              New Password{' '}
              <chakra.span
                display={inputError.pwDontMatch ? 'inline' : 'none'}
                color={'modalRed'}
                ml={3}
                fontWeight={400}
              >
                {' '}
                Password does not match
              </chakra.span>
            </FormLabel>
            <Input
              value={newPassword}
              onChange={onChangeNewPw}
              onBlur={handleOnBlurNewPw}
              variant={'modal'}
              type={'password'}
            ></Input>
          </Box>
          <Box>
            <FormLabel color={'textBlack'} fontSize={'14px'}>
              Confirm New Password{' '}
              <chakra.span
                display={inputError.pwDontMatch ? 'inline' : 'none'}
                color={'modalRed'}
                ml={3}
                fontWeight={400}
              >
                {' '}
                Password does not match
              </chakra.span>
            </FormLabel>
            <Input
              value={confirmNewPassword}
              onChange={onChangeConfirmPw}
              onBlur={handleOnBlurConfirmPw}
              variant={'modal'}
              type={'password'}
            />
          </Box>
          <Box>
            <UnorderedList pl={2}>
              <ListItem color={inputError.pwTooShort ? 'modalRed' : 'tipbox.dark.text.greyMedium'}>
                Password must be a minimum of 10 characters
              </ListItem>
              <ListItem color={inputError.pwChar ? 'modalRed' : 'tipbox.dark.text.greyMedium'} mt={1} mb={1}>
                Password must contain upper and lower case characters, symbols and numbers
              </ListItem>
            </UnorderedList>
          </Box>
          <Flex flexDirection={'row-reverse'} mt={'0.5rem'} mb={'2rem'} gap={'1rem'}>
            <Button variant="defaultButton" onClick={handleChangePassword} isDisabled={disableButton}>
              Change Password
            </Button>
            <Button variant="btnOutline" onClick={handleCancel} borderColor={'1A1A1A'}>
              Cancel
            </Button>
          </Flex>
        </Box>
      </CustomFade>
    </AnimatePresence>
  )
}

export default PasswordSettings
