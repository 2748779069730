import React from 'react'
import { Button as ChakraButton, ButtonOptions, HTMLChakraProps, ThemingProps } from '@chakra-ui/react'

interface ButtonProps extends HTMLChakraProps<'button'>, ButtonOptions, ThemingProps<'Button'> {
  label?: string
  maxWidth?: string | number
  disabled?: boolean
  testId?: string
  style?: React.CSSProperties
}

const Button = (props: ButtonProps) => {
  const { label, maxWidth, disabled, style, testId, ...rootProps } = props

  return (
    <ChakraButton
      {...rootProps}
      data-testid={testId}
      aria-label={label}
      maxW={maxWidth}
      flexWrap="wrap"
      isDisabled={disabled}
      alignContent="center"
      sx={{ ...style }}
    >
      {label}
    </ChakraButton>
  )
}

export default Button
