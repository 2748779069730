import { Box, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { AccountsTab } from './AccountsTab'
import NotificationsTab from './NotificationsTab'
import React from 'react'
import { useUserSettingsSlice } from 'features/redux'
import CustomFade from 'components/elements/Transition'

const tabStyles = {
  color: 'tipbox.dark.text.greyLight',
  fontWeight: 400,
  _hover: { color: 'accent', fontWeight: 500 },
  _active: { color: 'textBlack', fontWeight: 500 },
  _selected: { color: 'textBlack', fontWeight: 500 },
}

export const UserSettingsTabWrapper = () => {
  const { dispatch, tabIndex, setTabIndex } = useUserSettingsSlice()

  return (
    <CustomFade>
      <Tabs
        index={tabIndex}
        variant={'unstyled'}
        position={'relative'}
        onChange={(index) => dispatch(setTabIndex(index))}
      >
        <Box mb={6}>
          <TabList
            px={[6, null, null, 0]}
            bg={'inherit'}
            color="textBlack"
            // borderBottom={tabIndex !== 99 ? '1px solid var(--chakra-colors-borderRegular)' : ''}
          >
            <Tab fontSize={14} px={0} mr={[4, null, 8]} {...tabStyles}>
              Account
            </Tab>
            <Tab fontSize={14} px={0} mr={[4, null, 8]} {...tabStyles}>
              Notifications
            </Tab>
          </TabList>
          <Box position={'absolute'} width="100%" height="1px" bg="borderLight" />
          <TabIndicator height="2px" bg="accent" borderRadius="1px" maxW="20px" />
        </Box>
        <TabPanels>
          <TabPanel px={0} py={0}>
            <AccountsTab />
          </TabPanel>
          <TabPanel px={0} py={0}>
            <NotificationsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </CustomFade>
  )
}
