export const regs = {
  email: {
    test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    error: 'Please enter a valid email address',
  },
  password: {
    test: /(?=.*\d)(?=.*[$*.{}?"!@#%&/,><':;|_~`^\][)(]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    error:
      'Password must be at least 10 characters in length, and contain upper and lower case characters, symbols and numbers.',
  },
  // userType: {
  //   test: /[^\s]/,
  //   error: 'Account Type is required',
  // },
  // phone: {
  //   test: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  //   error: 'Please enter a valid phone number.',
  // },
  // website: {
  //   test: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  //   error: 'Please enter complete URL including http/https://',
  // },
  // name: {
  //   test: /^(?!\s*$).+/,
  //   error: 'Please enter your full name.',
  // },
  // code: {
  //   test: /^(\s*\d{6}\s*)(,\s*\d{6}\s*)*,?\s*$/,
  //   error: 'Please enter correct 6 digits code.',
  // },
}

/**
 * @param items: [key: any]
 * @param regElements: [key : { test, error }]
 * @param skipIfEmpty: bool
 */
export const validate = (items: any, regElements: any) => {
  const errors: any = {}
  let isError = false
  Object.keys(items).forEach((key) => {
    if (regElements && regElements[key]) {
      const { test, error, skipIfEmpty } = regElements[key]
      if (test && error) {
        const res = typeof test === 'function' ? test(items[key]) : test.test(items[key])
        const skip = skipIfEmpty && !(items[key].toString().length > 0)
        if (!res && !skip) {
          isError = true
          errors[key] = error
        }
      }
    }
  })
  return { isError, errors }
}
