import { Flex, Textarea, Button, Text } from '@chakra-ui/react'
import { Dispatch } from 'react'
import { isFirefox } from 'react-device-detect'

interface MessageFormProps {
  setShow: Dispatch<React.SetStateAction<boolean>>
  value: string
  onChange: (e) => void
}

const MessageForm = ({ setShow, value, onChange }: MessageFormProps) => {
  return (
    <Flex flexDir="column" w="full" flex={1} autoFocus={false}>
      <Textarea
        rounded={8}
        border="1.5px solid var(--chakra-colors-borderLight)"
        color="textBlack"
        fontWeight={400}
        fontSize="14px"
        value={value}
        onChange={onChange}
        placeholder="Add a message..."
        resize="none"
        h="6rem"
        overflowY="scroll"
        css={{
          '&::-webkit-scrollbar': {
            width: '3px',
          },
          '&::-webkit-scrollbar-track': {
            width: '1px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-textAsh)',
            borderRadius: '6px',
          },
          scrollbarWidth: isFirefox ? 'none' : 'initial',
        }}
        _hover={{ border: '1.5px solid var(--chakra-colors-borderLight)' }}
        _focusVisible={{ boxShadow: 'none', outline: 'none' }}
        _selection={{ backgroundColor: 'tipbox.dark.background.charcoal', color: 'tipbox.dark.text.pearl' }}
      />

      <Flex justify="space-between" flex={1}>
        <Text color="textAsh" fontWeight={400} fontSize="12px" pt={2} pl={4}>
          {value.length} / 250
        </Text>
        <Button variant="outline" color={'textBlack'} maxW="max" alignSelf={'center'} onClick={() => setShow(false)}>
          Done
        </Button>
      </Flex>
    </Flex>
  )
}

export default MessageForm
