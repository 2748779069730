import React, { useState } from 'react'
import { Flex, Divider, Text, Button, Input, Checkbox, Box, Image, Heading, chakra, useModal } from '@chakra-ui/react'
import { PhoneNumberInput } from 'components'
import { images } from 'theme'
import { useMutation } from '@apollo/client'
import { UPDATE_PHONE_NUMBER, VERIFY_PHONE_NUMBER } from 'features/graphql'
import { useAppSlice, useUserSettingsSlice } from 'features/redux'
import CustomFade from 'components/elements/Transition'

const SMSActivation = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [unParsedPhoneNumber, setUnParsedPhoneNumber] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [confirmStatus, setConfirmStatus] = useState('')
  const { dispatch, user, saveMe } = useAppSlice()
  const { setShowModalBody, setShowSMSActivation } = useUserSettingsSlice()

  const onInputChange = (field: string, { target: { value } }) => {
    if (field === 'code') {
      setVerificationCode(value)
    }
  }

  const onEditPhoneNumber = () => {
    setCodeSent(false)
    setPhoneNumber('')
    setVerificationCode('')
    setConfirmStatus('')
  }

  const onSendCode = () => {
    setCodeSent(true)
    onUpdatePhone()
  }

  const onConfirmCode = async () => {
    try {
      const res = await onVerifyPhone()
      setConfirmStatus(res.data?.verifyPhoneNumber ? 'success' : 'failed')
    } catch (e) {
      throw new Error(e.message)
    }
  }

  const onBack = () => {
    dispatch(setShowSMSActivation(false))
    dispatch(setShowModalBody(true))
  }

  const [updatePhoneNumber] = useMutation(UPDATE_PHONE_NUMBER, {
    onError: (err) => {
      throw new Error(err.message)
    },
  })
  const [verifyPhoneNumber] = useMutation(VERIFY_PHONE_NUMBER, {
    onError: (err) => {
      throw new Error(err.message)
    },
  })

  const onUpdatePhone = async () => {
    const res = await updatePhoneNumber({ variables: { phoneNumber } })
    if (res?.data.updatePhoneNumber) {
      const payload = {
        user: {
          ...user,
          phone: phoneNumber,
          phoneVerified: false,
        },
      }
      dispatch(saveMe(payload))
    }
    return res
  }

  const onVerifyPhone = async () => {
    const res = await verifyPhoneNumber({ variables: { verificationCode } })
    const payload = {
      user: {
        ...user,
        phoneVerified: res.data.verifyPhoneNumber,
      },
    }
    dispatch(saveMe(payload))
    return res
  }

  return (
    <CustomFade>
      <Heading color={'tipbox.dark.text.black'} fontWeight={'500'} fontSize={'24px'} pb={'20px'} pt={'13px'}>
        Account Settings
      </Heading>
      {!user?.phoneVerified ? (
        <Text color={'textBlack'} pt={4} fontWeight={'600'}>
          Your phone is not verified
        </Text>
      ) : (
        user?.phone && (
          <Text color={'greyLight'} pt={4}>
            <chakra.span fontWeight={'600'}>Your current phone number:</chakra.span>
            {user?.phone}
          </Text>
        )
      )}
      <Text color={'greyLight'} pt={4}>
        Please enter new 10 digit mobile number after the '+1' country code.
      </Text>
      <Flex pt={6} alignItems="center">
        <PhoneNumberInput
          value={unParsedPhoneNumber}
          onChange={(unParsedPhoneNumber) => {
            setUnParsedPhoneNumber(unParsedPhoneNumber)
            setPhoneNumber(unParsedPhoneNumber.replace(/[^+\d]+/g, ''))
          }}
          disabled={codeSent}
        />

        {!codeSent ? (
          <Button variant="defaultGreyDisabled" isDisabled={phoneNumber === ''} onClick={onSendCode}>
            Send Verification Code
          </Button>
        ) : (
          <Flex>
            <Button variant="ghost" onClick={onSendCode} _hover={{ textDecoration: 'underline', color: 'accent' }}>
              Resend Code
            </Button>
            <Button
              variant="ghost"
              onClick={onEditPhoneNumber}
              _hover={{ textDecoration: 'underline', color: 'accent' }}
            >
              Edit Phone Number
            </Button>
          </Flex>
        )}
      </Flex>
      {codeSent && (
        <Flex direction="column" pt={4}>
          <Divider height={4} />
          <Flex py={6}>
            <Checkbox variant="tipbox" isChecked={true} />
            <Text color={'textBlack'} px={2}>
              A verification code has been sent
            </Text>
          </Flex>
          <Flex alignItems="center">
            <Input
              type="text"
              mr={8}
              w="350px"
              placeholder="Enter verification code (case sensitive)"
              value={verificationCode}
              onChange={(e) => onInputChange('code', e)}
            />
            <Button variant="defaultGreyDisabled" isDisabled={verificationCode === ''} onClick={onConfirmCode}>
              Confirm
            </Button>
          </Flex>
          {confirmStatus === 'success' && (
            <Box>
              <Divider height={6} />
              <Flex pt={5}>
                <Checkbox variant="tipbox" isChecked={true} size="lg" />
                <Text px={2} color={'textBlack'}>
                  Success! Your phone number has been set up
                </Text>
              </Flex>
              <Divider height={6} />
              <Button variant="defaultGreyDisabled" isDisabled={verificationCode === ''} onClick={onBack} mt={'23px'}>
                Go Back
              </Button>
            </Box>
          )}
          {confirmStatus === 'failed' && (
            <Box>
              <Divider height={8} />
              <Flex pt={5}>
                <Image src={images.failureMark} alt="failure" w={5} />
                <Text px={2} color={'textBlack'}>
                  Sorry, please try the verification code again
                </Text>
              </Flex>
            </Box>
          )}
        </Flex>
      )}
    </CustomFade>
  )
}

export default SMSActivation
