import React, { useEffect, useState, useRef, useMemo } from 'react'
import imageCompression from 'browser-image-compression'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Button, Loader } from 'components'
import { useDebounceEffect } from 'hooks'
import canvasPreview from './canvasPreview'
import { getCroppedImage } from './cropImage'

type ImageResizeProps = {
  kind: string
  isOpen: boolean
  imageFile: File | undefined
  onCancel: () => void
  onSave: (file: File) => void
  locked?: boolean
}

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const ImageResize = ({ kind, isOpen, imageFile, onCancel, onSave, locked = true }: ImageResizeProps) => {
  const { onClose } = useDisclosure()
  const mobile = useBreakpointValue({ base: true, lg: false })
  const imgRef = useRef<HTMLImageElement>(null)
  const [imgSrc, setImgSrc] = useState('')
  const [crop, setCrop] = useState<Crop>()
  const [finalCrop, setFinalCrop] = useState<PixelCrop>()
  const [bannerCrop, setBannerCrop] = useState<PixelCrop>()
  const [bannerCroppedBlob, setBannerCroppedBlob] = useState<Blob>()
  const aspect = useMemo(() => {
    let ar = 1 // tip & avatar
    if (kind === 'projectCover') {
      ar = 8 / 5
    } else if (kind === 'boxCover') {
      ar = 31 / 21
    }
    return ar
  }, [kind])

  useEffect(() => {
    const compressImageFileIfNeeded = async () => {
      if (!imageFile) return
      setCrop(undefined)

      // file reader
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
      })
      // compress image if image file size > 1MB
      const compressedFile =
        imageFile.size > 1000000
          ? await imageCompression(imageFile, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1500,
              maxIteration: 15,
            })
          : imageFile

      // read image file
      reader.readAsDataURL(compressedFile)
    }
    compressImageFileIfNeeded()
  }, [imageFile])

  useDebounceEffect(
    async () => {
      if (kind === 'projectCover' && bannerCrop?.width && bannerCrop?.height && imgRef.current) {
        const blob = await getCroppedImage(imgRef.current, bannerCrop, 1)
        setBannerCroppedBlob(blob)
      }
    },
    100,
    [bannerCrop, finalCrop],
  )

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, aspect))
  }

  const onCroppedComplete = (c: PixelCrop) => {
    setFinalCrop(c)

    if (kind === 'projectCover') {
      const bannerAspect = 8 / 3
      setBannerCrop({
        ...c,
        y: c.y + (c.height - c.width / bannerAspect) / 2,
        height: c.width / bannerAspect,
      })
    }
  }

  const onCropSave = async () => {
    if (imageFile && imgRef.current && finalCrop) {
      const finalCroppedBlob = await getCroppedImage(imgRef.current, finalCrop, 1)
      const finalImgFile = new File([finalCroppedBlob], imageFile?.name, {
        lastModified: new Date().getTime(),
        type: imageFile?.type,
      })

      // const bannerImgFile = new File(
      //   [bannerCroppedBlob],
      //   `${imageFile?.name}__banner`,
      //   {
      //     lastModified: new Date().getTime(),
      //     type: imageFile?.type,
      //   },
      // )
      onSave(finalImgFile)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={mobile ? 'full' : 'lg'}
      isCentered
      motionPreset="none"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crop your photo</ModalHeader>
        <ModalBody overflowY="auto" className="no-scroll-bar">
          <Box>
            {imgSrc ? (
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={onCroppedComplete}
                aspect={1}
                locked={locked}
              >
                <img ref={imgRef} alt="crop me" src={imgSrc} onLoad={onImageLoad} />
              </ReactCrop>
            ) : (
              <Box h="full" w="full" alignItems="center">
                <Loader />
              </Box>
            )}
          </Box>
          <Box pt={2}>
            {kind === 'projectCover' && bannerCroppedBlob && (
              <Box>
                <Text>Banner Preview</Text>
                <img alt="Crop preview" src={URL.createObjectURL(bannerCroppedBlob)} style={{ borderRadius: '8px' }} />
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex align="center" justify={['space-between', null, null, 'flex-end']} w={['full', null, null, '18.75rem']}>
            <Button label="Cancel" maxWidth="7.375rem" onClick={onCancel} variant="modalbtnOutline" />
            <Button label="Save" onClick={onCropSave} variant="modalBtn" testId="save-photo" />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ImageResize
