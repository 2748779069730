import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { IconButton, ModalExternalButton } from 'components'
import SaveItem from 'components/fragments/Modal/SaveModal/SaveItem'
import { useAppSlice } from 'features/redux'
import { CreateNewProject } from 'pages/components'
import { customIcons, icons, images } from 'theme'
import { Node } from 'types/graphqlSchema'

const FlowFormProjectsPane = ({
  projects,
  setAttachedProject,
  setShowProjectsPane,
  setOpenCreateProject,
  openModal,
}) => {
  const { user } = useAppSlice()

  return (
    <Flex flexDir="column" w="full" flex={1}>
      <Flex
        gap={4}
        py={4}
        px={5}
        bg="transparent"
        border="1.5px solid var(--chakra-colors-borderLight)"
        roundedTop={8}
        alignItems="center"
      >
        <img src={images.attachmentClip} alt="Circled plus sign" />
        <Text color="textBlack" fontWeight={400}>
          Attach to a Project
        </Text>

        <button style={{ marginLeft: 'auto', transform: 'scaleY(-1)' }} onClick={() => setShowProjectsPane(false)}>
          <Icon as={icons.FaChevronDown} color="accent" />
        </button>
      </Flex>
      <Box
        border="1.5px solid var(--chakra-colors-borderLight)"
        borderTop="none"
        roundedBottom={8}
        px={6}
        maxH="300px"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '1px',
            background: 'var(--chakra-colors-borderLight)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#D9D9D9',
            borderRadius: '6px',
          },
        }}
        pt={2}
        pr={4}
      >
        {projects
          .filter((proj) => proj.id !== user.myProject.id)
          .map((project: Node) => (
            <SaveItem
              isFlowModal
              isProjects
              keyword={''}
              key={project.id}
              id={project.id}
              image={project.coverImageSecureUrl ? project.coverImageSecureUrl : undefined}
              iconImageLeft={<Image src={images.boxWhite} />}
              label={project.name}
              color={project.color || undefined}
              clickAction={() => {
                setAttachedProject(project)
                setShowProjectsPane(false)
              }}
            />
          ))}
      </Box>
      <Flex flex={1}>
        <IconButton
          variant="unstyled"
          ariaLabel="newProject"
          onClick={() => setOpenCreateProject('newProject')}
          icon={customIcons.projectIcon}
          iconSize={{ width: 4, height: 4 }}
          label="Create a new project"
          iconMarginRight={3}
          flexDir={['row']}
          style={{
            fontWeight: 400,
            color: 'textBlack',
            marginLeft: '1.5rem',
            marginTop: 'auto',
            fontSize: '14px',
          }}
        />
      </Flex>
      <ModalExternalButton
        header="New Project"
        isOpen={openModal === 'newProject'}
        close={() => setOpenCreateProject('')}
      >
        <CreateNewProject setOpenModal={setOpenCreateProject} />
      </ModalExternalButton>
    </Flex>
  )
}

export default FlowFormProjectsPane
