import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from 'helpers/store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export { default as useAutoFocus } from './useAutoFocus'
export { default as useDebounceEffect } from './useDebounceEffect'
export { default as useDragAndCombine } from './useDragAndCombine'
export { default as usePermissions } from './usePermissions'
export { default as usePhotoCompressor } from './usePhotoCompressor'
export { default as useStorage } from './useStorage'
export { default as useSort } from './useSort'
export { default as useOnScreen } from './useOnScreen'
export { default as useClipboard } from './useClipboard'
export * from './useDragging'
