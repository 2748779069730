import { CognitoUser, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { Auth, Amplify } from 'aws-amplify'
import ls from 'localstorage-slim'
import { User } from 'types/graphqlSchema'
import CONFIG from './config'

const SAVED_CREDENTIALS = 'saved_credentials'
const AUTHENTICATED_ORG_USERS_KEY = 'authenticated_org_users'
ls.config.encrypt = true

const config = {
  region: CONFIG.AWS.REGION,
  userPoolId: CONFIG.AWS.COGNITO.USER_POOL_ID,
  userPoolWebClientId: CONFIG.AWS.COGNITO.CLIENT_ID,
  callback: CONFIG.AWS.COGNITO.CALLBACK,
}

const oauth = {
  domain: String(CONFIG.AWS.COGNITO.URI)?.replace(/^https?:\/\//, ''),
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: CONFIG.AWS.COGNITO.REDIRECT_URI,
  redirectSignOut: `${window.location.protocol}//${window.location.host}`,
  responseType: 'code',
}

Amplify.configure({ Auth: config, oauth })

export const authSignUp = async ({ username, email, password, firstName, lastName }: ISignupParams) => {
  const { user } = await Auth.signUp({
    username,
    password,
    attributes: {
      email,
      family_name: lastName,
      given_name: firstName,
    },
  })
  return user
}

export const completeNewPassword = async (incompleteUser: CognitoUser, password: string) => {
  try {
    return await Auth.completeNewPassword(incompleteUser, password)
  } catch (error) {
    return error
  }
}

export const socialSignIn = (provider: string) => {
  if (provider === 'Google') {
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    })
  }
  if (provider === 'Facebook') {
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    })
  }
  return null
}

export const authLogin = async (username: string, password: string) => {
  try {
    return await Auth.signIn(username, password)
  } catch (error) {
    return error
  }
}

export const authLogout = async () => {
  try {
    await Auth.signOut({ global: true })
    return null
  } catch (err) {
    return err
  }
}

export const getIdToken = async () => {
  try {
    const session = await Auth.currentSession()
    if (!session) return null
    return session.getIdToken().getJwtToken()
  } catch {
    return null
  }
}

export const getAccessToken = async () => {
  try {
    const session = await Auth.currentSession()
    if (!session) return null
    return session.getAccessToken().getJwtToken()
  } catch {
    return null
  }
}

export const loggedIn = async () => {
  try {
    const session = await Auth.currentSession()
    if (!session) return false
    // if (session) {
    //   dispatch(
    //     setAuth({
    //       accessTokenKey: session.getAccessToken().getJwtToken(),
    //       idTokenKey: session.getIdToken().getJwtToken(),
    //     }),
    //   )
    //   return true
    // }
    // return false
    return true
  } catch (error) {
    return false
  }
}

export const checkAuthUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser()
  } catch (err) {
    return err
  }
}

// Send verification email during reset password if user is not verified
export const resendSignUp = (email: string) =>
  new Promise((resolve, reject) => {
    Auth.resendSignUp(email)
      .then((data) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })

// Forgot password: Send confirmation code to user's email
export const requestCode = (email: string) =>
  new Promise((resolve, reject) => {
    Auth.forgotPassword(email)
      .then((data) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })

// Forgot password: Collect confirmation code and new password, then
export const resetPassword = (email: string, confCode: string, newPass: string) =>
  new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(email, confCode, newPass)
      .then((data) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })

export const changeEmail = async (email: string) => {
  const user = await Auth.currentAuthenticatedUser()
  await Auth.updateUserAttributes(user, { email })
}

export const changePassword = (oldPassword: string, newPassword: string) =>
  new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword)
      })
      .then((data) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })

export const setupTOTP = async () =>
  new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.setupTOTP(user)
      })
      .then((secret) => {
        resolve(secret)
      })
      .catch((err) => reject(err))
  })

export const getQRCode = async () => {
  const {
    attributes: { email },
  } = await checkAuthUser()

  const code = (await setupTOTP()) as string
  const issuer = 'Tipbox'
  const str = `otpauth://totp/${email}?secret=${code}&issuer=${issuer}`
  return { str, code }
}

export const verifyTOTP = async (challengeAnswer: string) => {
  const user = await Auth.currentAuthenticatedUser()
  const req = new Promise((resolve, reject) => {
    Auth.verifyTotpToken(user, challengeAnswer)
      .then(() => {
        return Auth.setPreferredMFA(user, 'TOTP')
      })
      .then((status) => {
        resolve(status)
      })
      .catch((e) => {
        reject(e)
      })
  })
  return req
}

export const retrieveTotpStatus = async () =>
  new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.getPreferredMFA(user, {
          // If set to true, it will get the MFA type from server side instead of from local cache.
          bypassCache: false,
        })
      })
      .then((status) => {
        resolve(status)
      })
      .catch((err) => reject(err))
  })

export const disableMfa = async () =>
  new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.setPreferredMFA(user, 'NOMFA')
      })
      .then((status) => {
        resolve(status)
      })
      .catch((err) => reject(err))
  })

export const confirmSignInWithCode = async (
  user: any,
  code: string,
  mfaType: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | null | undefined,
) => {
  try {
    return await Auth.confirmSignIn(user, code, mfaType)
  } catch (err) {
    return err
  }
}

export const confirmSignUpWithCode = async (username: any, code: string) => {
  return await Auth.confirmSignUp(username, code)
}

export const randomPassword = () => {
  const chars = ['abcdefghijklmnopqrstuvwxyz', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', '1234567890', '!@#$%^&*()-+<>']
  let pass = ''
  let picker = 0
  // eslint-disable-next-line no-unmodified-loop-condition
  for (let x = 0; x < 8; x + 1) {
    const i = Math.floor(Math.random() * chars[picker].length)
    pass += chars[picker].charAt(i)
    picker += 1
    if (picker === 4) {
      picker = 0
    }
  }
  return pass
}

export const retrieveCredentials = async () => {
  try {
    // Retrieve the credentials
    const { usernameOrEmail, password }: any = ls.get(SAVED_CREDENTIALS) || {}
    if (usernameOrEmail && password) {
      return { usernameOrEmail, password }
    }
  } catch (error) {
    throw new Error(`Keychain couldn't be accessed! ${error}`)
  }
  return null
}

export const setGenericCredentials = async ({
  usernameOrEmail,
  password,
}: {
  usernameOrEmail: string
  password: string
}) => {
  ls.set(SAVED_CREDENTIALS, { usernameOrEmail, password })
}

export const resetGenericCredentials = () => {
  ls.remove(SAVED_CREDENTIALS)
}

export const manageOrgUsers = (action: 'add' | 'remove' | 'update', user: User): void => {
  // Retrieve stored orgUsers or initialize an empty array if none exists
  const storedOrgUsers: User[] = ls.get(AUTHENTICATED_ORG_USERS_KEY) || []
  const index = storedOrgUsers.findIndex((u) => u.username === user.username)

  if (action === 'update') {
    if (index > -1) {
      storedOrgUsers.splice(index, 1)
      storedOrgUsers.push(user)
    }
  }
  if (action === 'add') {
    if (index < 0) {
      storedOrgUsers.push(user)
    }
  }
  if (action === 'remove') {
    if (index > -1) {
      storedOrgUsers.splice(index, 1)
    }
  }
  // Update the stored orgusers
  ls.set(AUTHENTICATED_ORG_USERS_KEY, storedOrgUsers)
}

export const retrieveOrgUsers = (): User[] => {
  return ls.get(AUTHENTICATED_ORG_USERS_KEY) || []
}
