import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, useBreakpointValue, Divider } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { isEmpty, uniqBy } from 'lodash'
import { workflowConstant } from 'helpers/constant'
import { useNodeMenuActions } from 'features/node/hooks'
import { useFlowActions } from 'features/workflow/hooks'
import { useTags } from 'features/tags/hooks'
import { useAppSlice, useProjectSlice, useWorkflowSlice } from 'features/redux'
import { USERS, GET_USER_GUEST_SHARE_MEMBERS } from 'features/graphql'
import { MessageForm } from 'components'
import { Node, User, EntityType } from 'types/graphqlSchema'
import {
  FlowFormDueBy,
  FlowFormEditUsers,
  FlowFormFooter,
  FlowFormOptions,
  FlowFormParticipantsLists,
  FlowFormProjectsPane,
  FlowFormProjectsSection,
  FlowFormRecipientType,
  FlowParticipantSelect,
  FlowTagsPane,
} from './components'

// TYPES
interface UserSelectOption extends User {
  value: string
  label?: string
  __isNew__?: boolean
  focusRef: React.RefObject<HTMLInputElement>
}

const FlowForm = ({ node, flow, flowState, setFlowState, flowType, focusRef }) => {
  const { createFlow, startFlow } = useFlowActions()
  const { availableTags, attachedTags, setAttachedTags, addTag, fetchAvailableTags, fetchTags } = useTags()
  // Redux
  const { dispatch, workflows, getWorkflows } = useWorkflowSlice()
  const { projects, currentProject, clearCurrentProject, getProjects } = useProjectSlice()
  const { user } = useAppSlice()

  // Responsive
  const isMobile = useBreakpointValue({ base: true, md: false })

  // React Router
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const { projectId } = useParams()
  const isQuickstart = state?.quickstart
  // GraphQL
  const { loading, error, data: usersQueryData } = useQuery(USERS)
  const { data: guestUsersQueryData } = useQuery(GET_USER_GUEST_SHARE_MEMBERS)

  // Chakra / Visual
  const [containerHeight, setContainerHeight] = useState<number>(30)
  const [heightReference, setHeightReference] = useState<number>(0)

  useEffect(() => {
    if (focusRef.current) focusRef.current.focus()
  }, [focusRef])

  // Attaching Projects and Tags
  const isFirstRender = useRef(true)
  const [attachedProject, setAttachedProject] = useState<Node>()
  const [showProjectsPane, setShowProjectsPane] = useState(false)
  const { setOpenModal: setOpenCreateProject, openModal } = useNodeMenuActions()

  // Attach Tags
  const [showTagsPane, setShowTagsPane] = useState(false)

  // Message
  const [showMessageForm, setShowMessageForm] = useState(false)
  const [textAreaExpanded, setTextAreaExpanded] = useState<boolean>(false)

  // Flow Participants
  const [recipientType, setRecipientType] = useState('')
  const [usersData, setUsersData] = useState<User[]>()
  const [userSelectOptions, setUserSelectOptions] = useState<UserSelectOption[]>()
  const [userSelectValue, setUserSelectValue] = useState<UserSelectOption[]>()
  const [showEditPane, setShowEditPane] = useState(false)
  const [flowRecipientEmails, setFlowRecipientEmails] = useState<string[]>()
  const [watcherEmails, setWatcherEmails] = useState<string[]>()
  const [recipientsToList, setRecipientsToList] = useState<any[]>()
  const [watchersToList, setWatchersToList] = useState<any[]>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Check for Existing flows on this node
  useEffect(() => {
    const fetchWorkflows = async () => {
      if (isEmpty(workflows)) {
        await dispatch(getWorkflows())
      }
    }
    fetchWorkflows()
  }, [])

  // Populate Tags List
  useEffect(() => {
    const entityId = node ? node.id : flow?.id
    const entityType = node ? EntityType.Node : EntityType.Flow
    fetchTags(entityId, entityType)
    fetchAvailableTags()
  }, [])

  // Attach Project Automaticially (if file is in a Project)
  useEffect(() => {
    if (pathname.startsWith('/personal')) {
      dispatch(clearCurrentProject())
    }

    if (pathname.startsWith('/project') && projectId) {
      setAttachedProject(projects?.find((proj) => proj.id === projectId))
    } else if (currentProject && !isQuickstart && currentProject.category !== 'PERSONAL') {
      setAttachedProject(currentProject)
    } else if (flow && flow.project) {
      setAttachedProject(flow.project)
    } else {
      setAttachedProject(undefined)
    }
  }, [pathname, currentProject, isQuickstart, flow])

  // Populate Projects if user quickstarts immediately on login
  useEffect(() => {
    if (projects.length > 0) return
    const loadProjects = async () => {
      const params = {
        filter: {
          type: 'multi_conditions',
          conditions: { type: 'PROJECT', state: 'ACTIVE' },
        },
      }

      await dispatch(getProjects(params))
    }
    loadProjects()
  }, [projects])

  // Manage height of the modal
  useEffect(() => {
    let baseHeight
    if (flowType === 'Approval') baseHeight = isMobile ? 500 : 440
    if (flowType === 'Feedback') baseHeight = isMobile ? 540 : 460
    // setContainerHeight(baseHeight)
    // setHeightReference(baseHeight)

    let heightAddition = 0
    // Initial conditions based on emails and watchers
    if (flowRecipientEmails && flowRecipientEmails.length > 0 && watcherEmails && watcherEmails.length > 0) {
      if (flowType === 'Approval') {
        const newHeight = isMobile ? 100 : 120
        heightAddition = heightAddition + newHeight
      }
      if (flowType === 'Feedback') {
        const newHeight = isMobile ? 80 : 107
        heightAddition = heightAddition + newHeight
      }
    }

    if (
      (flowRecipientEmails && flowRecipientEmails.length > 0 && (!watcherEmails || watcherEmails.length === 0)) ||
      (watcherEmails && watcherEmails.length > 0 && (!flowRecipientEmails || flowRecipientEmails.length === 0))
    ) {
      if (flowType === 'Approval') {
        const newHeight = isMobile ? 40 : 120
        heightAddition = heightAddition + newHeight
      }
      if (flowType === 'Feedback') {
        const newHeight = isMobile ? 60 : 70
        heightAddition = heightAddition + newHeight
      }
    }

    if (showTagsPane) {
      setContainerHeight(290)
      return
    }

    // Adjustments based on additional UI interactions
    if (textAreaExpanded) {
      heightAddition = heightAddition + 40
    }

    if (attachedTags.length > 0 && attachedTags.length <= 3) {
      setContainerHeight(heightReference + 40)
      heightAddition = heightAddition + 40
    }
    if (attachedTags.length > 3 && attachedTags.length <= 6) {
      setContainerHeight(heightReference + 80)
      heightAddition = heightAddition + 80
    }
    if (attachedTags.length > 6) {
      setContainerHeight(heightReference + 120)
      heightAddition = heightAddition + 120
    }
    setContainerHeight(baseHeight + heightAddition)
  }, [
    flowRecipientEmails?.length,
    watcherEmails?.length,
    flowType,
    showTagsPane,
    isMobile,
    textAreaExpanded,
    attachedTags.length,
  ])

  // Flow Type --> RecipientType
  useEffect(() => {
    if (!flowType) return
    if (flowType === 'Feedback') {
      setRecipientType('feedbackProvider')
    }
    if (flowType === 'Approval') {
      setRecipientType('approver')
    }
  }, [flowType])

  // Auto Attach a newly created project
  useEffect(() => {
    // Skip the effect during the initial render
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    setAttachedProject(
      projects.reduce(function (r, a) {
        return r.createdAt > a.createdAt ? r : a
      }),
    )
    setShowProjectsPane(false)
  }, [projects.length])

  // Populate Users Select Options (userSelectOptions)
  useEffect(() => {
    if (!loading && !error) {
      const guestUsers = guestUsersQueryData?.me?.shareEmailSuggestions || []
      const orgUsers = usersQueryData.users
      let users = uniqBy([...orgUsers, ...guestUsers], 'id')

      if (flowType === workflowConstant.feedback.name) {
        // TA-737: remove requester from feedback's user options
        users = users.filter((u) => u.email !== user.email)
      }
      const userDataWithEmailValue = users.map((user) => ({
        ...user,
        value: user.email,
        label: user.firstName + ' ' + user.lastName,
      }))
      setUsersData(users)
      setUserSelectOptions(userDataWithEmailValue)
    }
    if (error) {
      console.error('An error occurred when setting the select options:', error.message)
    }
  }, [loading, error, usersQueryData, guestUsersQueryData?.me?.shareEmailSuggestions])

  // Create Avatar list-views of Flow Recipients and Watchers
  useEffect(() => {
    if (flowRecipientEmails) {
      const matchingRecipients = usersData?.filter((user) => flowRecipientEmails.includes(user.email))
      const nonMatchingEmails = flowRecipientEmails.filter(
        (email) => !(usersData || []).some((user) => user.email === email),
      )
      const guestRecipients = nonMatchingEmails.map((email) => {
        const [firstName, rest] = email.split('@')
        const [lastName] = rest.split('.')
        return {
          firstName,
          lastName,
          email,
          avatar: null,
          organization: {
            name: 'Guest',
          },
        }
      })
      const combinedRecipients = [...(matchingRecipients || []), ...(guestRecipients || [])]
      setRecipientsToList(combinedRecipients)
    }
    if (watcherEmails) {
      const matchingWatchers = usersData?.filter((user) => watcherEmails.includes(user.email))
      const nonMatchingEmails = watcherEmails.filter((email) => !(usersData || []).some((user) => user.email === email))
      const guestWatchers = nonMatchingEmails.map((email) => {
        const [firstName, rest] = email.split('@')
        const [lastName] = rest.split('.')
        return {
          firstName,
          lastName,
          email,
          avatar: null,
          organization: {
            name: 'Guest',
          },
        }
      })
      const combinedWatchers = [...(matchingWatchers || []), ...(guestWatchers || [])]

      setWatchersToList(combinedWatchers)
    }
  }, [flowRecipientEmails, watcherEmails, recipientType])

  // Get Workflow
  useEffect(() => {
    if (!isEmpty(workflows)) {
      setFlowState((prev) => ({
        ...prev,
        workflow: workflows?.find((w) => w.name === workflowConstant[flowType.toLowerCase()].name),
      }))
    }
  }, [workflows])

  // Add message
  const handleMessageChange = (e) => {
    let inputValue = e.target.value
    const maxLength = 250

    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength)
    }

    setFlowState((state) => ({
      ...state,
      message: inputValue,
    }))
  }

  // Start Flow --> mutate our Flow object
  const handleStartFlow = async () => {
    let startingFlow = flow
    setIsSubmitting(true)
    try {
      if (node) {
        if (!startingFlow) {
          // create new flow from node if none exists
          startingFlow = await createFlow({
            name: flowState.name,
            projectId: attachedProject?.id || node.projectId,
            organizationId: node.organizationId,
            nodeId: node.id,
          })
        }
      }
      try {
        const { flowId } = await startFlow(flowType, {
          flowId: startingFlow?.id,
          dueDate: flowState.due,
          urgent: flowState.urgent,
          uploadNeed: flowState.uploadNeed,
          requesterMessage: flowState.message,
          projectId: attachedProject?.id || startingFlow?.projectId || node?.projectId,
          workflowId: flowState.workflow?.id,
          participantsEmails: flowRecipientEmails,
          watchersEmails: watcherEmails,
          remindersFrequency: flowState.reminder.selected ? flowState.reminder.interval : 0,
        })

        if (attachedTags.length > 0) {
          if (!flowId) {
            console.error('Flow ID not found. Aborting tag addition.')
            return
          }
          attachedTags.forEach(async (tagName) => {
            const tag = availableTags.find((tag) => tag.name === tagName)
            if (tag) {
              const tagId = tag.id
              try {
                await addTag(flowId, EntityType.Flow, tagId)
              } catch (error) {
                console.error(`Error adding tag '${tagName}':`, error)
              }
            } else {
              console.warn(`Tag '${tagName}' not found in the list of available tags.`)
            }
          })
        }
        if (flowId) {
          navigate(`/flows/flow/${flowId}`, { replace: true, state: { newStartedFlow: true } })
        }
      } catch (error) {
        console.error('An error occurred while starting a flow:', error)
      }
    } catch (error) {
      console.error(error.message, error)
    }
  }

  return (
    <motion.div
      layout
      key={flowType}
      aria-label={flowType + ' Flow'}
      initial={{ height: 30, opacity: 0 }}
      animate={{
        height: containerHeight,
        opacity: 1,
      }}
      transition={{
        type: 'ease',
        duration: 0.6,
        opacity: { delay: 0.6 },
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '.5rem' : '1rem',
        width: '100%',
        padding: isMobile ? '0 1rem' : undefined,
        fontSize: isMobile ? '12px' : '14px',
        overflow: 'hidden',
      }}
    >
      {!showEditPane && !showProjectsPane && !showTagsPane && !showMessageForm && (
        <>
          <Flex align={{ base: 'flex-start', md: 'center' }} flexDir={{ base: 'column', md: 'row' }}>
            <Text color="textBlack" mx={{ base: 0, md: 6 }} minW="max">
              * Recipients:
            </Text>
            <Flex align="center" borderBottom="1.5px solid var(--chakra-colors-borderLight)" flex={1} w="full">
              <FlowFormRecipientType
                recipientType={recipientType}
                setRecipientType={setRecipientType}
                flowType={flowType}
              />
              <Divider orientation="vertical" borderColor="borderLight" borderWidth="1px" h="21px" ml="1rem" />
              <FlowParticipantSelect
                usersData={usersData}
                recipientType={recipientType}
                setWatcherEmails={setWatcherEmails}
                setActiveParticipantEmails={setFlowRecipientEmails}
                userSelectOptions={userSelectOptions}
                selectValue={userSelectValue}
                setSelectValue={setUserSelectValue}
                activeParticipantsToList={recipientsToList}
                watchersToList={watchersToList}
                menuHeight={'268px'}
              />
            </Flex>
          </Flex>

          <FlowFormParticipantsLists
            flowType={flowType}
            setRecipientType={setRecipientType}
            setShowEditPane={setShowEditPane}
            watchersToList={watchersToList}
            recipientsToList={recipientsToList}
          />
          <FlowFormProjectsSection
            isQuickstart={isQuickstart}
            attachedProject={attachedProject}
            setAttachedProject={setAttachedProject}
            setShowProjectsPane={setShowProjectsPane}
          />
          <FlowFormDueBy flowState={flowState} setFlowState={setFlowState} />
          <FlowFormOptions
            attachedTags={attachedTags}
            flowState={flowState}
            flowType={flowType}
            setAttachedTags={setAttachedTags}
            setFlowState={setFlowState}
            setShowTagsPane={setShowTagsPane}
            handleMessageChange={handleMessageChange}
            setTextAreaExpanded={setTextAreaExpanded}
          />
          <FlowFormFooter
            attachedProject={attachedProject}
            flowRecipientEmails={flowRecipientEmails}
            flowState={flowState}
            flowType={flowType}
            isSubmitting={isSubmitting}
            handleStartFlow={handleStartFlow}
          />
        </>
      )}
      {showProjectsPane && (
        <FlowFormProjectsPane
          openModal={openModal}
          projects={projects}
          setAttachedProject={setAttachedProject}
          setOpenCreateProject={setOpenCreateProject}
          setShowProjectsPane={setShowProjectsPane}
        />
      )}
      {showTagsPane && (
        <FlowTagsPane
          tags={availableTags}
          attachedTags={attachedTags}
          setAttachedTags={setAttachedTags}
          setShowTagsPane={setShowTagsPane}
        />
      )}
      {showMessageForm && (
        <MessageForm value={flowState?.message} onChange={handleMessageChange} setShow={setShowMessageForm} />
      )}
      {showEditPane && (
        <FlowFormEditUsers
          usersList={recipientType === 'watcher' ? watchersToList : recipientsToList}
          recipientType={recipientType}
          setFlowRecipientEmails={setFlowRecipientEmails}
          setWatcherEmails={setWatcherEmails}
          setShowEditPane={setShowEditPane}
        />
      )}
    </motion.div>
  )
}

export default FlowForm
