import React from 'react'
import { useAppSelector, usePermissions } from 'hooks'
import { Flex, Box, useBreakpointValue } from '@chakra-ui/react'
import { IconButton } from 'components'
import { icons, customIcons } from 'theme'
import { menuActions } from 'helpers/constant'
import { resourceType } from 'helpers/utils'
import { FollowedNode, Node } from 'types/graphqlSchema'

interface IListCardMenuProps {
  node: Node
  handleMenuAction: (action: string) => void
  menuType: string[]
  noBorder?: boolean
}

const ListCardMenu = ({ node, handleMenuAction, menuType, noBorder }: IListCardMenuProps) => {
  const { hasPermission } = usePermissions()
  const { followedNodes } = useAppSelector((state) => state.app.user)
  const isMobile = useBreakpointValue({ xs: true, md: true, lg: false })

  return (
    <>
      <Flex
        flexDir="column"
        overflow="hidden"
        overflowY="auto"
        rounded={8}
        maxH={['90vh', null, null, '45vh']}
        className="show_apple_scrollbar"
        bg="textHighlight"
      >
        <Flex flexWrap="wrap" flexDir="column" borderBottom={noBorder ? '0' : '1px'} borderColor="borderRegular">
          {/* SHARE */}
          <Box display={menuType.includes('share') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.share}
              onClick={handleMenuAction}
              icon={customIcons.menuShare}
              iconSize={{ width: 5, height: 5 }}
              label="Share"
              variant="dropDownButton"
              iconMarginRight={2}
              flexDir={['row']}
              style={{ paddingTop: '16px' }}
              isDisable={!hasPermission('share', node)}
            />
          </Box>
          {/* LINK */}
          <Box display={menuType.includes('link') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.copyLink}
              onClick={handleMenuAction}
              icon={customIcons.menuLink}
              iconSize={{ width: 5, height: 5 }}
              label="Copy link"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              isDisable={!hasPermission('share', node)}
            />
          </Box>
        </Flex>
        <Flex flexWrap="wrap" flexDir="column" borderBottom={noBorder ? '0' : '1px'} borderColor="borderRegular">
          {/* FOLLOW */}
          <Box display={menuType.includes('follow') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.follow}
              onClick={handleMenuAction}
              icon={customIcons.menuFollow}
              iconSize={{ width: 5, height: 5 }}
              label={
                followedNodes.some((f: FollowedNode) => f.nodeId === node.id)
                  ? `Unfollow this ${resourceType(node.type)?.toLowerCase()}`
                  : `Follow this ${resourceType(node.type)?.toLowerCase()}`
              }
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
            />
          </Box>
          {/* BOX PHOTO */}
          <Box display={menuType.includes('coverImage') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.coverImage}
              onClick={handleMenuAction}
              icon={customIcons.menuPhoto}
              iconSize={{ width: 5, height: 5 }}
              label="Add a cover photo"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              isDisable={!hasPermission('update', node)}
            />
          </Box>
          {/* TAGS */}
          {/* <Box>
            <IconButton
              ariaLabel={menuActions.tag}
              onClick={handleMenuAction}
              icon={customIcons.menuTag}
              iconSize={{ width: 5, height: 5 }}
              label="Add / Edit Tags"
              variant="dropDownButton"
              iconMarginRight={2}
              flexDir={['row']}
              isDisable={!hasPermission('update', node)}
            />
          </Box> */}
          {/* RENAME */}
          <Box display={menuType.includes('rename') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.rename}
              onClick={handleMenuAction}
              icon={customIcons.menuRename}
              iconSize={{ width: 5, height: 5 }}
              label="Rename"
              variant="dropDownButton"
              iconMarginRight={2}
              flexDir={['row']}
              isDisable={!hasPermission('update', node)}
            />
          </Box>
          {/* MOVE FOLDER */}
          <Box display={menuType.includes('moveTo') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.moveTo}
              onClick={handleMenuAction}
              icon={icons.moveTo}
              iconSize={{ width: 5, height: 5 }}
              label="Move to"
              variant="dropDownButton"
              iconMarginRight={2}
              flexDir={['row']}
              isDisable={!hasPermission('update', node)}
            />
          </Box>

          {/* DETAILS */}
          <Box display={isMobile && menuType.includes('details') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.details}
              onClick={handleMenuAction}
              icon={icons.preview}
              iconSize={{ width: 5, height: 5 }}
              label="Details"
              variant="dropDownButton"
              iconMarginRight={2}
              flexDir={['row']}
            />
          </Box>
        </Flex>
        <Flex flexWrap="wrap" flexDir="column">
          {/* DUPLICATE */}
          <Box display={menuType.includes('duplicate') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.duplicate}
              onClick={handleMenuAction}
              icon={customIcons.menuDuplicate}
              iconSize={{ width: 5, height: 5 }}
              label="Duplicate"
              variant="dropDownButton"
              iconMarginRight={2}
              flexDir={['row']}
              isDisable={!hasPermission('update', node)}
            />
            {/* DOWNLOAD */}
          </Box>
          <Box display={menuType.includes('download') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.download}
              onClick={handleMenuAction}
              icon={customIcons.menuDownload}
              iconSize={{ width: 5, height: 5 }}
              label="Download"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              isDisable={!hasPermission('download', node)}
            />
          </Box>
          {/* RESTORE */}
          <Box display={menuType.includes('restore') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.restore}
              onClick={handleMenuAction}
              icon={customIcons.restoreIcon}
              iconSize={{ width: 5, height: 5 }}
              label="Restore"
              variant="dropDownButton"
              iconMarginRight={2}
              flexDir="row"
              style={{ paddingTop: '16px' }}
            />
          </Box>
          {/* DELETE */}
          <Box display={menuType.includes('delete') ? 'block' : 'none'}>
            <IconButton
              ariaLabel={menuActions.delete}
              onClick={handleMenuAction}
              icon={customIcons.menuDelete}
              iconSize={{ width: 5, height: 5 }}
              label="Delete"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir="row"
              style={{ paddingBottom: '16px' }}
              isDisable={!hasPermission('delete', node)}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default ListCardMenu
