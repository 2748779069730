import { useEffect, useState } from 'react'
import { Divider, Flex, Grid, Box } from '@chakra-ui/react'
import { useNodeDetails, useNodeMenuActions } from 'features/node'
import { useAppSlice } from 'features/redux'
import { PrivacySwitch } from 'components'
import { TagsSection } from 'features/tags/components'
import { EntityType } from 'types/graphqlSchema'
import UserInfo from './UserInfo'
import NodeData from './NodeData'
import { useOutletContext } from 'react-router-dom'
import { MainLayoutContext } from 'components/layouts/MainLayout'

function InfoPanelDetails({ node }) {
  const { user } = useAppSlice()
  const { updateNode } = useNodeMenuActions()
  const { setOpenModal } = useOutletContext<MainLayoutContext>()
  const {
    totalSize,
    lastModifiedBy,
    createdDate,
    updatedDate,
    ownerName,
    members,
    ownerUrl,
    id,
    type,
    refetchNodeMembers,
  } = useNodeDetails(node)
  const ownerIsCurrentUser = user.id === node.ownerId
  const showPrivacySwitch = node && !node?.parent?.private
  const [isPrivate, setIsPrivate] = useState(() => !!node.private)

  const handlePrivacyChange = (privacySetting: boolean) => {
    if (isPrivate !== privacySetting) {
      setIsPrivate(privacySetting)
    }
  }

  useEffect(() => {
    if (node.private === isPrivate) return

    const handleUpdate = async () => {
      try {
        await updateNode({ private: isPrivate }, node)
        refetchNodeMembers()
      } catch (error) {
        console.error('Error updating node privacy:', error)
      }
    }
    handleUpdate()
  }, [isPrivate])

  return (
    <>
      <Grid
        gridTemplateColumns={'repeat(5, auto)'}
        h="auto"
        gap={{ base: '1rem', '2xl': '2.5rem' }}
        maxW="90%"
        maxH="40dvh"
      >
        <Box>
          <UserInfo
            nodeType={type}
            nodeId={id}
            ownerIsCurrentUser={ownerIsCurrentUser}
            ownerName={ownerName}
            ownerUrl={ownerUrl}
            members={members}
            setOpenShareModal={setOpenModal}
          />
          {ownerIsCurrentUser && showPrivacySwitch && (
            <PrivacySwitch user={user} node={node} onPrivacyChange={handlePrivacyChange} darkBg />
          )}
        </Box>
        <Divider orientation="vertical" />
        <NodeData
          createdDate={createdDate}
          lastModifiedBy={lastModifiedBy}
          totalSize={totalSize}
          updatedDate={updatedDate}
        />
        <Divider orientation="vertical" />
        {node && (
          <Flex fontWeight={400}>
            <TagsSection entity={node} entityType={EntityType.Node} paddingY="0px" darkBg />
          </Flex>
        )}
      </Grid>
    </>
  )
}

export default InfoPanelDetails
