import React from 'react'
import ReactDatePicker, { ReactDatePickerProps, CalendarContainer } from 'react-datepicker'
import { Box, Flex, Text, Spacer } from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css'
import { Button } from 'components/elements/Buttons'
import './date-picker.css'

interface IDatePicker extends ReactDatePickerProps {
  tipboxLayout?: boolean
}

const DatePicker = ({ tipboxLayout = false, ...props }: IDatePicker) => {
  const TipboxContainer = ({ className, children }: { className: any; children: any }) => {
    return (
      <Box px={8} zIndex={5}>
        <Text textAlign="center" py={4}>
          Add a due date
        </Text>
        <CalendarContainer className={className}>
          <Box>{children}</Box>
          <Flex py={6} w="full">
            <Button variant="defaultButton" label="Add" onClick={() => {}} />
            <Spacer />
            <Button variant="ghost" label="Reset" onClick={() => {}} />
          </Flex>
        </CalendarContainer>
      </Box>
    )
  }

  return (
    <ReactDatePicker
      dateFormat="MMMM d, yyyy"
      calendarContainer={tipboxLayout ? TipboxContainer : undefined}
      {...props}
    />
  )
}

export default DatePicker
