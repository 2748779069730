import { Flex, Text } from '@chakra-ui/react'
import { SelectPopover, DisplayToggle } from 'components'
import { sortOptions } from 'helpers/constant'
import { icons } from 'theme'

interface DisplayBarProps {
  justify?: string
  viewType: string
  setViewType: (viewType: string) => void
  viewTypeTitle?: string
  sortByTitle?: string
  sortByOptions?: any[]
  sortBy: string
  setSortBy: (sortBy: string) => void
}

const DisplayBar = ({
  justify = 'space-between',
  viewType,
  setViewType,
  sortByTitle = 'Sort by',
  sortByOptions = sortOptions.default,
  sortBy,
  setSortBy,
}: DisplayBarProps) => {
  const handleChange = () => {
    setViewType(viewType === 'grid' ? 'list' : 'grid')
  }
  return (
    <Flex align="center" justify={justify} w="full">
      <Flex align="center" mx="2">
        <Text color="textRegular" fontSize={15} mr={2}>
          {sortByTitle}
        </Text>
        <SelectPopover
          options={sortByOptions}
          currentOption={sortBy}
          setCurrentOption={setSortBy}
          btnStyle={{ color: 'textSoft', fontSize: '15px', fontWeight: 400 }}
        />
      </Flex>
      <DisplayToggle viewType={viewType} toggle={handleChange} />
    </Flex>
  )
}

export default DisplayBar
