import React, { useMemo } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { usePermissions } from 'hooks'
import { IconButton } from 'components'
import { icons, customIcons } from 'theme'
import { menuActions } from 'helpers/constant'
import { Node } from 'types/graphqlSchema'
import { useAppSlice } from 'features/redux'

interface ProjectCardMenuProps {
  projectInfo: Node
  follow: boolean
  handleMenuAction: (action: string) => void
  pageType: string[]
  noBorder?: boolean
  isMobile?: boolean
}

const ProjectCardMenu = ({
  projectInfo,
  follow,
  handleMenuAction,
  pageType,
  noBorder,
  isMobile,
}: ProjectCardMenuProps) => {
  const { hasPermission } = usePermissions()
  const { user } = useAppSlice()

  const isSharedWithMe = useMemo(() => {
    return projectInfo.sharedMembers?.some((sm) => sm?.sharedWithId === user.id && user.id !== projectInfo.ownerId)
    // TODO: propagate Remove Myself from Project Level (TA-1233)
    // if there are children shared but Project hasn't specifically been shared
    // || projectInfo?.children?.some((child) => child?.sharedMembers?.some((sm) => sm?.sharedWithId === user.id))
  }, [projectInfo, user])

  return (
    <Flex
      flexDir="column"
      overflow="hidden"
      overflowY="auto"
      rounded={8}
      maxH={['90vh', null, null, '55vh']}
      className="show_apple_scrollbar"
      py=".75rem"
      bg="textHighlight"
    >
      <Flex flexWrap="wrap" flexDir="column" borderBottom={noBorder ? '0' : '1px'} borderColor="textSoft" pb={2} mb={2}>
        {/* SHARE */}
        <Box display={pageType.includes('share') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.share}
            onClick={handleMenuAction}
            icon={customIcons.menuShare}
            iconSize={{ width: 4, height: 4 }}
            label="Share"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            style={{ paddingTop: '16px' }}
            isDisable={!hasPermission('share', projectInfo)}
          />
        </Box>
        {/* COPY LINK */}
        <Box display={pageType.includes('link') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.copyLink}
            onClick={handleMenuAction}
            icon={customIcons.menuLink}
            iconSize={{ width: 4, height: 4 }}
            label="Copy link"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('share', projectInfo)}
          />
        </Box>
      </Flex>
      <Flex flexWrap="wrap" flexDir="column" borderBottom={noBorder ? '0' : '1px'} borderColor="textSoft" pb={2} mb={2}>
        {/* COVER PHOTO */}
        <Box display={pageType.includes('coverImage') ? 'block' : 'none'}>
          <IconButton
            ariaLabel={menuActions.coverImage}
            onClick={handleMenuAction}
            icon={customIcons.menuPhoto}
            iconSize={{ width: 4, height: 4 }}
            label="Add a cover photo or colour"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', projectInfo)}
          />
        </Box>
        {/* TAGS */}
        <Box>
          <IconButton
            ariaLabel={menuActions.tag}
            onClick={handleMenuAction}
            icon={customIcons.menuTag}
            iconSize={{ width: 4, height: 4 }}
            label="Add / Edit Tags"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', projectInfo)}
          />
        </Box>
        <Box display={pageType.includes('rename') ? 'block' : 'none'}>
          {/* RENAME */}
          <IconButton
            ariaLabel="rename"
            onClick={handleMenuAction}
            icon={customIcons.menuRename}
            iconSize={{ width: 4, height: 4 }}
            label="Rename"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', projectInfo)}
          />
        </Box>
        <Box display={pageType.includes('follow') ? 'block' : 'none'}>
          {/* FOLLOWING */}
          <IconButton
            ariaLabel={menuActions.follow}
            onClick={handleMenuAction}
            icon={customIcons.menuFollow}
            iconSize={{ width: 4, height: 4 }}
            label={follow ? 'Unfollow' : 'Follow'}
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('follow', projectInfo)}
          />
        </Box>
        <Box display={isMobile ? 'block' : 'none'}>
          {/* DETAILS */}
          <IconButton
            ariaLabel={menuActions.details}
            onClick={handleMenuAction}
            icon={icons.preview}
            iconSize={{ width: 4, height: 4 }}
            label="Details"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
          />
        </Box>
      </Flex>
      <Flex flexWrap="wrap" flexDir="column">
        <Box display={pageType.includes('duplicate') ? 'block' : 'none'}>
          {/* DUPLICATE */}
          <IconButton
            ariaLabel={menuActions.duplicate}
            onClick={handleMenuAction}
            icon={customIcons.menuDuplicate}
            iconSize={{ width: 4, height: 4 }}
            label="Duplicate"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('update', projectInfo)}
          />
        </Box>
        <Box display={pageType.includes('download') ? 'block' : 'none'}>
          {/* DOWNLOAD */}
          <IconButton
            ariaLabel="download"
            onClick={handleMenuAction}
            icon={customIcons.menuDownload}
            iconSize={{ width: 4, height: 4 }}
            label="Download"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            isDisable={!hasPermission('download', projectInfo)}
          />
        </Box>
        {/* {!isSharedWithMe && ( */}
        <Box display={pageType.includes('delete') ? 'block' : 'none'}>
          {/* DELETE */}
          <IconButton
            ariaLabel={menuActions.delete}
            onClick={handleMenuAction}
            icon={customIcons.menuDelete}
            iconSize={{ width: 4, height: 4 }}
            label="Delete"
            variant="dropDownButton"
            iconMarginRight={3}
            flexDir={['row']}
            style={{
              paddingBottom: '16px',
            }}
            isDisable={!hasPermission('delete', projectInfo)}
          />
        </Box>
        {/* )} */}
        {/* TODO: propagate Remove Myself from Project Level (TA-1233) */}
        {/* {isSharedWithMe && (
          <Box>
            <IconButton
              ariaLabel={menuActions.unshare}
              onClick={handleMenuAction}
              icon={customIcons.menuDelete}
              iconSize={{ width: 4, height: 4 }}
              label="Remove Myself"
              variant="dropDownButton"
              iconMarginRight={3}
              flexDir={['row']}
              style={{ paddingBottom: '16px' }}
            />
          </Box>
        )} */}
      </Flex>
    </Flex>
  )
}

export default ProjectCardMenu
