import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = definePartsStyle({
  thumb: {
    bg: 'textHighlight',
  },
  track: {
    bg: 'textSoft',
    _focus: {
      boxShadow: '0 0 0 1px transparent',
      outline: '1px solid var(--chakra-colors-borderFocus)',
      outlineOffset: '2px',
    },
    _focusVisible: {
      boxShadow: '0 0 0 1px transparent',
      outline: '1px solid var(--chakra-colors-borderFocus)',
      outlineOffset: '2px',
    },
    _checked: {
      bg: 'accent',
    },
  },
})

export const switchTheme = defineMultiStyleConfig({ baseStyle })
