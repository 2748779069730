import React from 'react'
import ReactPlayer from 'react-player/lazy'
import './VideoPlayer.css'

interface IVideoPlayer {
  url: string
  style?: object
  controls?: boolean
  light?: boolean
  width?: string
  height?: string
  fallback?: any
}

const VideoPlayer = ({ url, style, controls = true, light, width, height, fallback }: IVideoPlayer) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        style={style}
        controls={true}
        light={light}
        width={width}
        height={height}
        fallback={fallback}
      />
    </div>
  )
}

export default VideoPlayer
