import { useState, useEffect } from 'react'
import {
  FormControl,
  Flex,
  Box,
  ModalBody,
  ModalFooter,
  FormLabel,
  Grid,
  Text,
  useRadioGroup,
  useColorModeValue,
} from '@chakra-ui/react'
import { Button, FileInput, ColorRadio } from 'components'
import { icons, colors } from 'theme'
import { NodeColor, Maybe } from 'types/graphqlSchema'
import { convertHexToNodeColor, convertNodeColorToHex } from 'helpers/utils'

interface UpdateCoverImageOrColorProps {
  currentCoverImage?: Maybe<string>
  currentFolderColor?: Maybe<NodeColor>
  onUpdateColor: (newColor: NodeColor) => void
  onUpdateCoverImage: (newImage: File) => void
  onCancel: () => void
}

const UpdateCoverImageOrColorModal = ({
  currentCoverImage,
  currentFolderColor,
  onUpdateColor,
  onUpdateCoverImage,
  onCancel,
}: UpdateCoverImageOrColorProps) => {
  // Hooks
  const initialColor = useColorModeValue(colors.folder.lightGray.body, colors.folder.darkGray.body)

  // Local State
  const [folderColor, setFolderColor] = useState<string>(initialColor)
  const [coverImage, setCoverImage] = useState<File | undefined>(undefined)
  const [imgData, setImgData] = useState<string>('')

  // Control a group of custom radios
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'colors',
    onChange: setFolderColor,
  })

  const onChangePicture = (pictureFile: File) => {
    if (pictureFile) {
      setCoverImage(pictureFile)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') setImgData(reader.result)
      })
      reader.readAsDataURL(pictureFile)
      setFolderColor('')
    }
  }

  const onRemovePicture = () => {
    setCoverImage(undefined)
    setImgData('')
    if (currentFolderColor) {
      const { body } = convertNodeColorToHex(currentFolderColor)
      setFolderColor(body)
    }
  }

  const handleSubmit = () => {
    if (coverImage) onUpdateCoverImage(coverImage)
    else onUpdateColor(convertHexToNodeColor(folderColor))
  }

  useEffect(() => {
    if (currentCoverImage) setImgData(currentCoverImage)
    if (currentFolderColor) {
      const { body } = convertNodeColorToHex(currentFolderColor)
      setFolderColor(body)
    } else {
      setFolderColor('')
    }
  }, [currentFolderColor])

  return (
    <>
      <ModalBody fontFamily="Inter">
        <FormControl as="fieldset">
          <Flex flexDir="column" w="full">
            <Flex justify="space-between" columnGap={4}>
              {/* Folder Image */}
              <Box maxWidth="8.875rem">
                <FormLabel
                  as="legend"
                  mr={0}
                  fontSize={[12, null, null, 15]}
                  fontWeight={400}
                  textAlign="inherit"
                  color="textBlack"
                >
                  Photo
                </FormLabel>
                <FileInput
                  placeHolder={<icons.plusThin fontSize="1.5rem" style={{ margin: '0 auto' }} />}
                  name="boxCover"
                  file={coverImage}
                  onSelectFile={onChangePicture}
                  onCancelFile={onRemovePicture}
                  preview={imgData}
                  noPreviewFileName
                  styleProps={{
                    height: ['3rem', null, null, '3.5rem'],
                    width: ['4rem', null, null, '5rem'],
                    marginTop: 0,
                    borderWidth: '.5px',
                    borderStyle: 'solid',
                  }}
                />
              </Box>

              <Flex flexDir="column" justify="center" align="center" flexGrow={1}>
                <Box minH={[8, null, null, 30]} />
                <Text as="span" alignSelf="center" color="textBlack" fontSize={[12, null, null, 15]}>
                  or
                </Text>
              </Flex>

              {/* Folder Color */}
              <FormControl as="fieldset" width="auto">
                <Box
                  position="relative"
                  _after={{
                    display: (coverImage || currentCoverImage) && imgData ? 'block' : 'none',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    content: '""',
                  }}
                >
                  <FormLabel
                    as="legend"
                    color="textBlack"
                    fontWeight={400}
                    mr={0}
                    fontSize={[12, null, null, 15]}
                    textAlign="inherit"
                  >
                    Colours
                  </FormLabel>
                  <Flex align="center" h={['3rem', null, null, '3.5rem']}>
                    <Grid {...getRootProps()} gridTemplateColumns="repeat(8, auto)" gap=".375rem">
                      {Object.entries(colors.folder).map(([, { body }]) => {
                        // Override Chakra UI's isChecked prop to be able to clear the radio value
                        const radio = { ...getRadioProps({ value: body }), isChecked: folderColor === body }
                        return <ColorRadio key={body} color={body} {...radio} />
                      })}
                    </Grid>
                  </Flex>
                </Box>
              </FormControl>
            </Flex>
            {(coverImage || currentCoverImage) && imgData && (
              <Box mt={2} textAlign="left">
                <Box as="button" type="button" fontSize={[12, null, null, 15]} onClick={onRemovePicture}>
                  Remove
                </Box>
              </Box>
            )}
          </Flex>
        </FormControl>
      </ModalBody>

      <ModalFooter flexDir="row" justifyContent="end">
        <Button label="Cancel" onClick={onCancel} variant="btnOutline" mr={2} h="44px" />
        <Button label="Save" onClick={handleSubmit} variant="defaultButton" ml={2} h="44px" testId="save-banner-edit" />
      </ModalFooter>
    </>
  )
}

export default UpdateCoverImageOrColorModal
