import { Flex, Text, Button } from '@chakra-ui/react'

const FlowFormFooter = ({
  flowType,
  handleStartFlow,
  flowState,
  isSubmitting,
  attachedProject,
  flowRecipientEmails,
}) => {
  return (
    <Flex
      bottom={1}
      pos={'absolute'}
      as="footer"
      w="full"
      justifyContent={'flex-end'}
      alignItems={'flex-end'}
      minH={'3rem'}
    >
      <Text color="textBlack" mr="auto" fontWeight={400} fontStyle="italic" ml="1.5rem">
        * required
      </Text>
      <Button
        isDisabled={
          !flowRecipientEmails?.length || !flowState.due || !attachedProject || !flowState.name || isSubmitting
        }
        onClick={handleStartFlow}
        variant={'defaultButton'}
        _disabled={{
          bg: 'var(--chakra-colors-textSoft)',
          color: 'var(--chakra-colors-textRegular)',
          border: '1px solid transparent',
          _hover: {
            backgroundColor: 'var(--chakra-colors-textSoft)',
            color: 'var(--chakra-colors-textRegular)',
            border: '1px solid textBlack',
            cursor: 'not-allowed',
          },
        }}
        fontWeight={500}
      >
        {flowType === 'Approval' && 'Send for Approval'}
        {flowType === 'Feedback' && 'Request Input'}
      </Button>
    </Flex>
  )
}

export default FlowFormFooter
