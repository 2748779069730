import { PixelCrop } from 'react-image-crop'
import canvasPreview from './canvasPreview'

let previewUrl = ''

const toBlob = (canvas: HTMLCanvasElement): Promise<Blob> => {
  return new Promise((resolve: any) => {
    canvas.toBlob(resolve, 'image/jpeg', 0.7)
  })
}

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

export const getCroppedImage = async (image: HTMLImageElement, crop: PixelCrop, scale = 1) => {
  const canvas = document.createElement('canvas')
  canvasPreview(image, canvas, crop, scale)

  const blob = await toBlob(canvas)
  return blob
}

// Returns an image source you should set to state
// and pass `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
export const imgPreview = async (image: HTMLImageElement, crop: PixelCrop, scale = 1) => {
  const blob = await getCroppedImage(image, crop, scale)

  if (previewUrl) {
    URL.revokeObjectURL(previewUrl)
  }

  previewUrl = URL.createObjectURL(blob)
  return previewUrl
}
