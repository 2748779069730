/* eslint-disable no-console */
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache,
  WatchQueryOptions,
  QueryOptions,
  MutationOptions,
  gql,
  split,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getAccessToken, getIdToken } from './auth'
import CONFIG from './config'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, extensions }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Extensions: ${
          extensions?.code
        }`,
      ),
    )
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})
const authLink = setContext(async (_, { headers }) => {
  const token = await getIdToken()
  const accessToken = await getAccessToken()
  return token ? { headers: { ...headers, authorization: `Bearer ${token}`, access: accessToken } } : { headers }
})
const httpLink = createHttpLink({ uri: `${CONFIG.APP.API_URL}/graphql` })
const wsLink = new GraphQLWsLink(
  createClient({
    url: CONFIG.APP.WS_URL,
    connectionParams: async () => {
      const token = await getIdToken()
      return { authToken: token }
    },
    shouldRetry: () => true,
    retryAttempts: 20,
    retryWait: async function waitForServerHealthyBeforeRetry() {
      // wait for 3 seconds + random 1-4s timeout
      // (avoid DDoSing yourself) and try connecting again
      await new Promise((resolve) => setTimeout(resolve, 3000 + Math.random() * 3000))
    },
  }),
)

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const link = ApolloLink.from([errorLink, authLink, splitLink])

const cache = new InMemoryCache({
  addTypename: false,
})

type DefaultOptions = {
  watchQuery?: Partial<WatchQueryOptions>
  query?: Partial<QueryOptions>
  mutate?: Partial<MutationOptions>
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  link,
  cache,
  defaultOptions,
})

const Provider = ({ children }: { children: JSX.Element }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

export { gql, client }
export default Provider
