import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { AvatarsTooltip, Button } from 'components'
import { motion } from 'framer-motion'
import { images } from 'theme'

const FlowFormEditUsers = ({ usersList, recipientType, setShowEditPane, setWatcherEmails, setFlowRecipientEmails }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingRight: '1rem',
      }}
    >
      <Flex
        flexDir={'column'}
        overflowY={'scroll'}
        css={{
          '&::-webkit-scrollbar': {
            width: '0',
          },
          '&::-webkit-scrollbar-track': {
            width: '0',
          },
        }}
      >
        <Text color={'textBlack'} pl={6} mb={4}>
          Remove Shared Users:
        </Text>
        {usersList.map((mem) => (
          <Flex key={mem.id} justifyContent={'space-between'} alignItems={'center'}>
            <Box px={6} py={2}>
              <AvatarsTooltip users={[mem]} />
            </Box>
            <Text ml={'auto'} pr={6} fontWeight={300} color={'textBlack'}>
              Click to remove <span style={{ fontWeight: 500, fontStyle: 'italic' }}>{mem.firstName}</span>
            </Text>
            <button
              style={{ padding: '1rem', alignSelf: 'flex-start' }}
              onClick={() => {
                if (recipientType === 'watcher') {
                  setWatcherEmails((prev) => prev?.filter((email) => email !== mem.email))
                } else {
                  setFlowRecipientEmails((prev) => prev?.filter((email) => email !== mem.email))
                }
                if (usersList.length === 1) {
                  setShowEditPane(false)
                }
              }}
            >
              <Image src={images.xSmall} />
            </button>
          </Flex>
        ))}
      </Flex>
      <Button
        variant="outline"
        color={'textBlack'}
        maxW="max"
        label="Done"
        alignSelf={'flex-end'}
        onClick={() => setShowEditPane(false)}
      />
    </motion.div>
  )
}

export default FlowFormEditUsers
