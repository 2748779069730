import React from 'react'
import {
  Text,
  Box,
  Icon,
  Modal as ChakraModal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
  ModalOverlay,
  ModalFooter,
  useBreakpointValue,
} from '@chakra-ui/react'
import { customIcons } from 'theme'
import { Button } from 'components'

interface ModalInfoProps {
  [key: string]: {
    headerSubText: string
    button: string
    secondaryButton?: string
    warning?: string
    icon: any
    noIcon?: boolean
  }
}
interface ConfirmationModalProps {
  name: string
  header: string
  itemName?: string
  confirmType: string
  confirmAction: () => void
  isOpen: boolean
  close: (isOpen: boolean) => void
}

const ConfirmationModal = ({
  name,
  header,
  itemName,
  confirmType,
  confirmAction,
  isOpen,
  close,
}: ConfirmationModalProps) => {
  const isMobile = useBreakpointValue({ xs: true, lg: false })

  const { onClose } = useDisclosure()

  const handleClose = () => {
    close(!isOpen)
  }

  const modalText: ModalInfoProps = {
    delete: {
      headerSubText: ' is about to be deleted from your Tipbox.',
      button: 'Delete',
      icon: customIcons.trashIcon,
    },
    deleteAllPermanently: {
      headerSubText: ' are about to be permanently deleted from your Tipbox.',
      warning: `Warning: This action can't be undone.`,
      button: 'Permanently Delete',
      icon: customIcons.trashIcon,
    },
    deletePermanently: {
      headerSubText: ` are about to be permanently deleted from your Tipbox.`,
      warning: `Warning: This action can't be undone.`,
      button: 'Permanently Delete',
      icon: customIcons.trashIcon,
    },
    restoreAndMove: {
      headerSubText: '',
      button: 'Yes',
      icon: '',
      noIcon: true,
    },
    restore: {
      headerSubText: ' in the trash is about to be restored.',
      button: 'Restore',
      icon: customIcons.restoreIcon,
    },
    unshare: {
      headerSubText: ' is about to be removed from ',
      button: 'Remove',
      icon: customIcons.trashIcon,
    },
    deleteLink: {
      headerSubText:
        'This will permanently remove the shared link. Any settings and expirations will be removed as well. Do you want to continue?',
      button: 'Okay',
      icon: '',
      noIcon: true,
    },
    removeGuest: {
      headerSubText: 'Are you sure you want to remove this person? They will lose access to all shared items.',
      button: 'Remove',
      icon: customIcons.trashIcon,
    },
    removeBlock: {
      headerSubText: `Are you sure you want to remove this block? This can't be undone.`,
      button: 'Remove',
      icon: customIcons.trashIcon,
    },
    cancelApproval: {
      headerSubText: `Are you sure you want to cancel this approval?`,
      button: 'Yes',
      icon: customIcons.trashIcon,
    },
    approveFlow: {
      headerSubText: `Are you sure you want to approve this?`,
      button: 'Yes',
      icon: '',
      noIcon: true,
    },
    rejectFlow: {
      headerSubText: `Are you sure you want to reject this?`,
      button: 'Yes',
      icon: '',
      noIcon: true,
    },
    submitFeedback: {
      headerSubText: `Click "Cancel" to edit or add more.`,
      secondaryButton: 'Cancel',
      button: 'Yes',
      icon: '',
      noIcon: true,
    },
    cancelFlow: {
      headerSubText: `Are you sure you want to cancel this flow?`,
      button: 'Yes',
      secondaryButton: 'No',
      icon: customIcons.trashIcon,
    },
    deleteFlow: {
      headerSubText: `Are you sure you want to delete this flow?`,
      button: 'Yes',
      icon: customIcons.trashIcon,
    },
    archiveFlow: {
      headerSubText: `Are you sure you want to archive this flow?`,
      button: 'Yes',
      icon: '',
      noIcon: true,
    },
    removeFromFlow: {
      headerSubText: `Are you sure you'd like to be removed from this Flow?`,
      button: 'Yes',
      icon: '',
      noIcon: true,
    },
    untitledFile: {
      headerSubText: `This file is untitled. Would you like to enter a file name?`,
      button: 'Yes',
      icon: '',
      noIcon: true,
    },
    removeOrg: {
      headerSubText: ' is about to be removed.',
      button: 'Confirm',
      icon: '',
      noIcon: true,
    },
  }

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} size={isMobile ? 'sm' : 'lg'} isCentered motionPreset="none">
      <ModalOverlay />
      <ModalContent m={0} rounded={8} h="fit-content" w="36.25rem" maxW="unset" bg="textHighlight" color="textBlack">
        <ModalHeader fontWeight="600" fontSize={[16, null, null, 24]} color="inherit">
          {header}
        </ModalHeader>
        <ModalCloseButton pos="absolute" top={4} onClick={handleClose} _focus={{ boxShadow: 'none' }} />
        <ModalBody overflowY="auto" className="no-scroll-bar">
          <Text whiteSpace="pre-line" color="textBlack">
            <Box as="span" fontWeight={name === 'This comment' ? '' : '600'} color="inherit">
              {name}
            </Box>
            {modalText[confirmType].headerSubText}
            <Box as="span" fontWeight="600" display={itemName ? 'inline-block' : 'none'} color="inherit">
              {`${itemName}.`}
            </Box>
          </Text>
          {modalText[confirmType].warning && (
            <Text fontWeight="600" py={3} color={'tipbox.dark.accent.purple'}>
              Warning: You can&apos;t undo this action.
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            label={modalText[confirmType].secondaryButton || 'Cancel'}
            onClick={handleClose}
            variant="btnOutline"
            style={{ marginRight: 4 }}
          />
          {modalText[confirmType].noIcon ? (
            <Button
              label={modalText[confirmType].button}
              onClick={confirmAction}
              variant="defaultButton"
              style={isMobile ? { height: '37px' } : { height: '44px' }}
            />
          ) : (
            <Button
              label={modalText[confirmType].button}
              onClick={confirmAction}
              variant="defaultButton"
              leftIcon={<Icon as={modalText[confirmType].icon} h={[5, null, null, 6]} w={[5, null, null, 6]} />}
              style={isMobile ? { height: '37px' } : { height: '44px' }}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  )
}

export default ConfirmationModal
