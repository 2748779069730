import { FC, useEffect, useState } from 'react'
import Loader from './Loader'

export interface LazyLoaderProps {
  delay?: number
  speed?: string
  emptyColor?: string
  color?: string
  size?: string
  marginBottom?: string | number
}

const LazyLoader: FC<LazyLoaderProps> = ({ delay = 300, size, color, emptyColor, speed, marginBottom }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  return show ? (
    <Loader color={color} size={size} emptyColor={emptyColor} speed={speed} marginBottom={marginBottom} />
  ) : null
}

export { LazyLoader as default }
