import { Toast } from 'components'

export default function useClipboard() {
  const copyToClipBoard = async (text: string, feedback = true) => {
    try {
      await navigator.clipboard.writeText(text)
    } catch (err) {
      document?.execCommand('copy', true, text)
    } finally {
      if (feedback)
        Toast.show({
          icon: 'check',
          message: 'Link Copied.',
        })
    }
  }

  return { copyToClipBoard }
}
