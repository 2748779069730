import React from 'react'
import { Flex, Heading, Text, Image, Box, Link } from '@chakra-ui/react'
import styles from './EmptyPanel.module.css'

interface EmptyPanelProps {
  icon?: any
  subHeading: string
  text: string
  linkBtnText?: string | null
  text2?: string | null
  minH?: number | string
  textMaxW?: number | string
  border?: boolean
  marginTop?: number | (number | null)[]
  paddingTop?: number | (number | null)[]
  handleIconClick?: () => void
}

const EmptyPanel = ({
  icon,
  subHeading,
  text,
  linkBtnText,
  text2,
  minH = '70vh',
  textMaxW = '29rem',
  border,
  marginTop = 0,
  paddingTop = [8, null, null, 6],
  handleIconClick,
}: EmptyPanelProps) => {
  const formatText = (string: string) => {
    if (string.includes('<br>')) {
      const strings = string.split('<br>')
      return (
        <>
          {strings.map((s, index) => {
            if (index === strings.length - 1) {
              return (
                <Text
                  key={index.toString()}
                  textAlign="center"
                  maxW={textMaxW}
                  mx="auto"
                  fontSize="1.25rem"
                  color="inherit"
                >
                  {`${s}`}
                  <Link variant="inlineText" fontSize="1.25rem" onClick={handleIconClick || (() => {})}>
                    {linkBtnText || ''}
                  </Link>
                  {text2}
                </Text>
              )
            } else {
              return (
                <Text
                  color="inherit"
                  key={index.toString()}
                  textAlign="center"
                  maxW={textMaxW}
                  mx="auto"
                  fontSize="1.25rem"
                >
                  {s}
                </Text>
              )
            }
          })}
        </>
      )
    }
    return (
      <Text textAlign="center" maxW={textMaxW} mx="auto" fontSize={14}>
        {`${text}`}
        <Link variant="inlineText" fontSize={14} onClick={handleIconClick || (() => {})}>
          {linkBtnText || ''}
        </Link>
        {text2}
      </Text>
    )
  }

  return (
    <Flex
      minH={minH}
      flexDir="column"
      alignContent="center"
      justify="center"
      rounded={8}
      color="textRegular"
      pt={paddingTop}
      mt={marginTop}
      w="full"
      className={border ? styles.dashed : ''}
    >
      {icon && (
        <Box display="flex" justifyContent="center">
          <Box style={{ cursor: 'pointer' }} onClick={handleIconClick}>
            <Image src={icon} h="auto" w={[16, null, '5rem']} mx="auto" mb="1rem" />
          </Box>
        </Box>
      )}
      <Heading
        as="h3"
        textStyle="h3"
        textAlign="center"
        mb={2}
        fontSize={[18, null, '2rem']}
        fontWeight={400}
        color="textSoft"
      >
        {subHeading}
      </Heading>
      {formatText(text)}
    </Flex>
  )
}

export default EmptyPanel
