import * as Sentry from '@sentry/react'
import { useAppSlice, useProjectSlice, useBoxSlice, useTipSlice, useWorkflowSlice } from 'features/redux'

export default () => {
  const { dispatch, isUploading, logout, setIsUploading } = useAppSlice()
  const { clearCurrentProject, clearProjects } = useProjectSlice()
  const { clearBoxes, clearCurrentBox } = useBoxSlice()
  const { clearCurrentTip, clearTips } = useTipSlice()
  const { clearWorkflows } = useWorkflowSlice()

  const handleLogout = () => {
    dispatch(logout())
    if (isUploading) {
      dispatch(setIsUploading(false))
    }
    dispatch(clearBoxes())
    dispatch(clearCurrentBox())
    dispatch(clearProjects())
    dispatch(clearCurrentProject())
    dispatch(clearTips())
    dispatch(clearCurrentTip())
    dispatch(clearWorkflows())
    const scope = Sentry.getCurrentScope()
    scope.setUser(null)
  }

  return {
    handleLogout,
  }
}
