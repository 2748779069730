import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

interface BadgeProps {
  message: string | number
  top?: number
  right?: number
  absolute?: boolean
  isMobile?: boolean
}

const Badge = ({ message, top = 4, right = 4, absolute = true, isMobile = false }: BadgeProps) => {
  return (
    <Flex
      pos={absolute ? 'absolute' : 'relative'}
      top={top}
      right={right}
      minW={[4, null, null, '20px']}
      h={[4, null, null, '20px']}
      color="white"
      justify="center"
      align="center"
      rounded="full"
      bg="accent"
    >
      <Text
        color="white"
        fontWeight="700"
        fontSize={[7, null, null, 10]}
        pos="absolute"
        textAlign="center"
        right={0}
        left={0}
        top={isMobile ? 0 : 0.5}
      >
        {message}
      </Text>
    </Flex>
  )
}

export default Badge
