import React from 'react'
import { Container, Grid, Flex, Image, Skeleton, useColorModeValue } from '@chakra-ui/react'
import { images } from 'theme'

const SkeletenListCard = () => {
  return (
    <Container variant="boxListCard" my={4}>
      <Grid templateColumns={['4rem auto']} h="full" w="full" maxW="full" overflow="hidden">
        <Flex justify="center" align="center" h="full" pl={4}>
          <Image src={useColorModeValue(images.Logo, images.LogoDark)} alt="item logo" />
        </Flex>
        <Flex align="center" overflow="hidden" w="full" h="full" justify="space-between" px={4}>
          <Skeleton
            h="20px"
            w={['80px', null, null, null, '200px', '250px']}
            startColor="textAsh"
            endColor="textRegular"
          />
          <Skeleton
            h="20px"
            w={['80px', null, null, null, '125px', '150px']}
            startColor="textAsh"
            endColor="textRegular"
          />
          <Skeleton
            h="20px"
            w={['0px', null, null, null, '125px', '150px']}
            startColor="textAsh"
            endColor="textRegular"
          />
          <Skeleton h="20px" w="30px" startColor="textAsh" endColor="textRegular" />
        </Flex>
      </Grid>
    </Container>
  )
}

export default SkeletenListCard
