import { Text, Grid, Flex, Box } from '@chakra-ui/react'
import { Select, chakraComponents } from 'chakra-react-select'
import { User } from 'types/graphqlSchema'
import { AnimatedMenuChevron } from 'components/elements'
import React, { useMemo } from 'react'
import UserSelectable from './UserSelectable'

export interface SelectOption extends User {
  value: string
  label?: string
  __isNew__?: boolean
}

interface UserSelectProps {
  isOpen?: boolean
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  userSelectOptions?: SelectOption[]
  usersData?: User[]
  selectValue?: SelectOption[]
  setSelectValue: React.Dispatch<React.SetStateAction<SelectOption[] | undefined>>
  setSelections: React.Dispatch<React.SetStateAction<User[]>>
  selections: User[]
  menuHeight?: string
}

const UsersSelect = ({
  isOpen = false,
  setIsOpen,
  userSelectOptions,
  usersData,
  selectValue,
  setSelectValue,
  setSelections,
  selections,
  menuHeight = '200px',
}: UserSelectProps) => {
  const chakraStyles = {
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      zIndex: 12,
    }),
    menuList: (provided) => ({
      ...provided,
      background: 'transparent',
      boxShadow: 'none',
      maxH: menuHeight,
      '&::-webkit-scrollbar': {
        width: '0',
      },
      '&::-webkit-scrollbar-track': {
        width: '0',
      },
    }),
    input: (provided) => ({
      ...provided,
      background: 'transparent',
      color: 'textBlack',
      fontSize: '14px',
      boxShadow: 'none',
      _focus: { border: 'none', boxShadow: 'none' },
      _active: { border: 'none', boxShadow: 'none' },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'textRegular',
      fontSize: '14px',
    }),
    control: (provided) => ({
      ...provided,
      variant: 'unstyled',
      background: 'white',
      border: '1.5px solid #E9EAEC',
      borderColor: '#E9EAEC !important',
      borderBottomRadius: isOpen ? '0' : '6px',
      _hover: { cursor: 'text' },
      _focus: { cursor: 'text', boxShadow: 'none' },
      _active: { cursor: 'text' },
      zIndex: 9,
    }),
  }

  const handleSelect = (value: SelectOption[]) => {
    if (!value || value?.length === 0) return
    setSelections((prev = []) => {
      const previousRecipients = prev || []
      let newUser: SelectOption = value[0]
      if (newUser.value && !newUser.firstName) {
        newUser = {
          ...value[0],
          email: value[0].value,
          firstName: value[0].value.split('@')[0],
          lastName: value[0].value.split('@')[1],
        }
      }
      return [...previousRecipients, newUser]
    })
    setSelectValue([])
  }

  const handleDeselect = (value: SelectOption[]) => {
    if (!value || value?.length === 0) return
    setSelections((prev = []) => {
      const previousRecipients = prev || []
      const deselection = previousRecipients.filter((recipient) => recipient.id !== value[0].id)
      return deselection
    })
    setSelectValue([])
  }

  if (usersData) {
    return (
      <Grid bg={'white'} opacity={1} w="full">
        <Box gridRowStart={1} gridColumnStart={1}>
          <Select
            menuIsOpen={isOpen || undefined}
            onMenuOpen={setIsOpen ? () => setIsOpen(true) : undefined}
            onMenuClose={setIsOpen ? () => setIsOpen(false) : undefined}
            isMulti
            size="lg"
            value={selectValue}
            options={userSelectOptions}
            placeholder={
              <Flex flex={1}>
                <Text color="textBlack" fontWeight={400}>
                  Enter a name or email address
                </Text>
              </Flex>
            }
            noOptionsMessage={() => null}
            onChange={handleSelect}
            chakraStyles={chakraStyles}
            menuPosition={'fixed'}
            isClearable={false}
            formatCreateLabel={(userInput) => (
              <Flex mx={2} pl={9} py={3} bg="cardBackgroundAccent" rounded={'lg'} cursor={'pointer'}>
                <Text fontFamily={'Inter'} fontWeight={'300'} fontSize={14} color={'white'}>
                  Add guest: <span style={{ fontWeight: 'bold' }}>{userInput}</span>?
                </Text>
              </Flex>
            )}
            components={{
              DropdownIndicator: useMemo(() => {
                return () => (
                  <Box mr={4} cursor="pointer">
                    <AnimatedMenuChevron isOpen={isOpen} />
                  </Box>
                )
              }, [isOpen]),
              IndicatorSeparator: () => null, // seperator before arrow

              Menu: ({ children, ...props }) => (
                <chakraComponents.Menu {...props}>
                  <div
                    style={{
                      borderLeft: '1.5px solid var(--chakra-colors-borderLight)',
                      borderRight: '1.5px solid var(--chakra-colors-borderLight)',
                      borderBottom: '1.5px solid var(--chakra-colors-borderLight)',
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  >
                    {children}
                  </div>
                </chakraComponents.Menu>
              ),

              Option: ({ innerProps, data }) => {
                const selected = selections.some((sel) => sel.id === data.id)

                return (
                  <Box
                    {...innerProps}
                    bg="white"
                    overflow={'hidden'}
                    onClick={(e) => {
                      if (selected) {
                        e.stopPropagation()
                        handleDeselect([data])
                        return
                      }
                      e.stopPropagation()
                      handleSelect([data])
                    }}
                  >
                    {data.id ? <UserSelectable data={data} selected={selected} /> : <Text p={2}>{data.label}</Text>}
                  </Box>
                )
              },
            }}
          />
        </Box>
      </Grid>
    )
  }
  return <></>
}

export default UsersSelect
