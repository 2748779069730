import React from 'react'
import { Grid, Skeleton } from '@chakra-ui/react'
import { SkeletonBoxCard } from '../SkeletonCards'

const SkeletionBoxGrid = () => {
  return (
    <Grid
      pt={8}
      gridTemplateColumns={[
        'repeat(auto-fill, minmax(103px, 1fr))',
        null,
        'repeat(auto-fill, minmax(90px, 1fr))',
        'repeat(auto-fill, minmax(130px, 1fr))',
        'repeat(auto-fill, minmax(180px, 1fr))',
      ]}
      position="relative"
      gap={[4, null, null, 6]}
    >
      <Skeleton gridColumn="1/-1" h={6} w="250px" startColor="textAsh" endColor="textRegular" />
      <SkeletonBoxCard />
      <SkeletonBoxCard />
      <SkeletonBoxCard />
    </Grid>
  )
}

export default SkeletionBoxGrid
