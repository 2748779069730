import { Button, Icon, Tooltip } from '@chakra-ui/react'
import { icons } from 'theme'
import { FlowDisplayType } from 'features/workflow/Flows.types'

type DisplayToggleProps = {
  viewType: FlowDisplayType | string
  toggle: () => void
  tooltipLabel?: string
}

const DisplayToggle = ({ viewType, toggle, tooltipLabel }: DisplayToggleProps) => {
  return (
    <Tooltip
      label={tooltipLabel}
      hasArrow
      fontSize={'13px'}
      rounded="lg"
      px=".75rem"
      py=".75rem"
      placement="top"
      bg="textBlack"
    >
      <Button
        p={0}
        m={0}
        w="46px"
        h="46px"
        variant="transparentButton"
        bg="menuBackground"
        borderColor="borderRegular"
        borderStyle="solid"
        borderWidth="1px"
        isDisabled={viewType === FlowDisplayType.archive}
        _hover={viewType !== FlowDisplayType.archive ? { borderColor: 'transparent', bg: 'accent' } : {}}
        _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
        onClick={toggle}
      >
        <Icon
          as={viewType === FlowDisplayType.list || viewType === FlowDisplayType.archive ? icons.BsListUl : icons.BsGrid}
          w="20px"
          h="20px"
          color="textSoft"
          m={0}
          p={0}
        />
      </Button>
    </Tooltip>
  )
}

export default DisplayToggle
