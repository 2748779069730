import { Box, Button, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { customIcons, icons, images } from 'theme'

const FlowTagsPane = ({ tags, attachedTags, setAttachedTags, setShowTagsPane }) => {
  return (
    <Flex flexDir="column" w="full" flex={1}>
      <Flex
        gap={4}
        py={3}
        px={5}
        minH="3.6rem"
        roundedTop={8}
        border="1.5px solid var(--chakra-colors-borderLight)"
        alignItems="center"
      >
        {attachedTags?.length === 0 ? (
          <>
            <Image src={images.tagGrey} alt="tag" />
            <Text color="textBlack" fontWeight={400}>
              Add Tags (optional)
            </Text>
          </>
        ) : (
          <Flex gap={4} overflowX="hidden">
            {attachedTags.map((tag) => (
              <Flex key={tag} gap={3} px={3} py={1} rounded="md" minW="max">
                <Text color="textBlack" cursor="default" onClick={(e) => e.stopPropagation()}>
                  {tag}
                </Text>
                <button
                  onClick={() => {
                    const nextTags = attachedTags.filter((at) => at !== tag)
                    setAttachedTags(nextTags)
                  }}
                >
                  <Box flex="none" minW="8px">
                    <img src={images.xSmall} />
                  </Box>
                </button>
              </Flex>
            ))}
          </Flex>
        )}
        <button style={{ marginLeft: 'auto', transform: 'scaleY(-1)' }} onClick={() => setShowTagsPane(false)}>
          <Icon as={icons.FaChevronDown} color="accent" />
        </button>
      </Flex>
      <Flex
        py={4}
        flexWrap="wrap"
        gap={6}
        px={6}
        overflowY="auto"
        border="1.5px solid var(--chakra-colors-borderLight)"
        borderTop="none"
        roundedBottom={8}
        css={{
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '1px',
            background: 'var(--chakra-colors-textRegular)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#D9D9D9',
            borderRadius: '6px',
          },
        }}
      >
        {tags.map((tag) => {
          const tagName = tag.name
          const included = attachedTags.includes(tagName)
          return (
            <Flex
              key={tagName}
              bg={included ? 'accent' : 'var(--chakra-colors-textSoft)'}
              as="button"
              px={2}
              py={1}
              rounded="lg"
              align="center"
              gap={2}
              color={included ? 'white' : 'textBlack'}
              onClick={() => {
                let nextTags
                if (included) {
                  nextTags = attachedTags.filter((tag) => tag !== tagName)
                } else {
                  nextTags = [...attachedTags, tagName]
                }
                setAttachedTags(nextTags)
              }}
            >
              <Icon as={customIcons.tagSolid} fill={included ? 'white' : '#1A1A1A'} w={3} h={3} />
              {tagName}
            </Flex>
          )
        })}
      </Flex>
      <Button
        mt="auto"
        variant={'outline'}
        color={'textBlack'}
        maxW="max"
        alignSelf={'flex-end'}
        onClick={() => setShowTagsPane(false)}
      >
        Done
      </Button>
    </Flex>
  )
}

export default FlowTagsPane
