/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { client as apollo } from 'helpers/apollo'
import { AppDispatch, RootState } from 'helpers/store'
import { GET_TEMPLATES } from './template.schema'

// ---------------------------------------
// Thunks
// ---------------------------------------

const getTemplates = createAsyncThunk('template/getTemplates', async () => {
  try {
    const {
      data: { templates },
      error,
    } = await apollo.query({
      query: GET_TEMPLATES,
    })
    if (error) {
      throw new Error(error.message)
    }
    return templates
  } catch (error: any) {
    throw new Error(error.message)
  }
})

const initialState = {}

// slice
const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {},
  // extraReducers: (builder) => {},
})

// export const { action } = templateSlice
export function useTemplateSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.template)
  return { dispatch, ...state, getTemplates, ...templateSlice.actions }
}

export default templateSlice.reducer
