import React, { useState, useEffect } from 'react'
import { Grid, Box, Icon, Text, Flex, Button, useBreakpointValue } from '@chakra-ui/react'
import { getResizedImage } from 'helpers/storage'
import { icons } from 'theme'
import { ReactComponent as Folder } from 'assets/folder_colored.svg'
import { motion, AnimatePresence } from 'framer-motion'

interface SaveItemProps {
  iconImageLeft?: React.ReactNode
  iconImageRight?: React.ReactNode
  label: string
  image?: string
  clickAction: () => void
  color?: string
  id: string
  key: string
  keyword: string
  isProjects?: boolean
  isPersonalButton?: boolean
  isFlowModal?: boolean
  isDisabled?: boolean
  isQuickstart?: boolean
}

const SaveItem = ({
  id,
  iconImageLeft,
  iconImageRight,
  label,
  image,
  clickAction,
  color,
  key,
  keyword,
  isProjects,
  isPersonalButton,
  isFlowModal,
  isQuickstart,
  isDisabled,
}: SaveItemProps) => {
  const [imageUrl, setImageUrl] = useState('')
  const isMobile = useBreakpointValue({ base: true, md: false })
  useEffect(() => {
    let active = true
    if (!active) return undefined
    if (!image) return undefined
    setImageUrl(getResizedImage(image, 'project.list_view') || '')

    return () => {
      active = false
    }
  }, [image])

  const renderThumb = () => {
    if (image) {
      return (
        <Box
          w="full"
          h="full"
          bgImage={imageUrl}
          backgroundSize="100%"
          bgRepeat="no-repeat"
          bgSize="cover"
          rounded={8}
        />
      )
    } else if (!image && iconImageLeft) {
      return (
        <Flex
          justify="center"
          align="center"
          h="full"
          w="full"
          backgroundColor={isProjects ? 'textBlack' : 'transparent'}
          rounded={8}
        >
          {iconImageLeft}
        </Flex>
      )
    }
    return <Folder fill={color === 'DARK_GRAY' || !color ? 'folder.darkGray.body' : color} />
  }

  return (
    <AnimatePresence key={key}>
      <motion.div
        key={id}
        initial={keyword.length < 2 && { x: 3, opacity: 0 }}
        animate={keyword.length < 2 && { x: 0, opacity: 1 }}
        transition={{ duration: 0.3, type: 'tween', ease: 'easeIn' }}
        style={{ width: '100%' }}
      >
        <Button
          variant={'unstyled'}
          fontWeight={'normal'}
          border="1px"
          rounded={8}
          borderColor={isQuickstart && isPersonalButton ? 'accent' : 'textSoft'}
          overflow="hidden"
          w="full"
          onClick={clickAction}
          mb={2}
          _hover={{ borderColor: 'accent' }}
          h={'46px'}
          flexShrink={'0'}
          disabled={isDisabled}
        >
          <Grid gridTemplateColumns={'46px auto 46px'} h={'46px'}>
            <Flex align="center" justify="center" w="full" h="full">
              {renderThumb()}
            </Flex>

            <Flex align="center" h="full">
              <Text color={'textBlack'} fontSize={14} ml="1.5rem">
                {label.length > 35 ? label.substring(0, 35) + '...' : label}
                {isPersonalButton && (
                  <span
                    style={{
                      fontStyle: 'italic',
                      marginLeft: isMobile ? '1rem' : '3rem',
                      color: isMobile ? '#1A1A1A' : '#979797',
                      fontSize: isMobile ? '.6rem' : 'inherit',
                    }}
                  >
                    *Move to project {isMobile ? '' : 'later'} to Share{isMobile ? '/' : ' or '}Flow
                  </span>
                )}
              </Text>
            </Flex>

            {iconImageRight && (
              <Flex align="center" justify="center" h="full" w="full">
                {iconImageRight}
              </Flex>
            )}

            {!iconImageRight && !isFlowModal && (
              <Flex align="center" justify="center" h="full" w="full">
                <Icon as={icons.FaChevronRight} w="18px" h="18px" />
              </Flex>
            )}
          </Grid>
        </Button>
      </motion.div>
    </AnimatePresence>
  )
}

export default SaveItem
