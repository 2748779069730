import { useAppSelector } from 'hooks'
import { permissionIndexes } from 'helpers/constant'
import { Node, Flow, EntityType } from 'types/graphqlSchema'

type ActionType = keyof typeof permissionIndexes

export default () => {
  const { currentProject } = useAppSelector((state) => state.project)
  const { user } = useAppSelector((state) => state.app)
  const { currentBox } = useAppSelector((state) => state.box)
  const { currentTip } = useAppSelector((state) => state.tip)
  const { publicLink } = useAppSelector((state) => state.linkShare)

  /**
   * @param actionType ActionType
   * @param entity Node | Flow
   * @returns Boolean
   * @description returning TRUE means the action is enabled.
   */
  const hasUserPermissions = (actionType: ActionType, entity: Node | Flow) => {
    if (!user) return false

    // determine the entity type
    const entityType =
      entity?.__typename === 'Node' ? EntityType.Node : entity.__typename === 'Flow' ? EntityType.Flow : null
    if (!entityType) return false

    // check if current user is the owner and is in the same org
    // if not in the same org, means user created a resource inside
    // a shared resource
    if (entityType === EntityType.Node) {
      if (user.id === (entity as Node)?.owner?.id && user.organizationId === entity.organizationId) return true
    }

    if (entityType === EntityType.Flow) {
      if (user.id === (entity as Flow)?.requester?.id && user.organizationId === entity.organizationId) return true
    }

    // check whether user is in the same org of the node and privacy setting of node
    if (entity.organizationId === user.organizationId && !(entity as Node).private) {
      return true // node is not private
    }

    // check if user is project owner
    if (currentProject && user.id === currentProject.owner?.id) return true

    // check if current user has permission in shared members
    if (!entity.sharedMembers || entity.sharedMembers.length === 0) return false

    // iterate through shared members to find matching permissions
    for (const sharedMember of entity.sharedMembers) {
      if (
        sharedMember &&
        sharedMember.sharedWithId === user.id &&
        sharedMember.permission &&
        sharedMember.permission.permissions &&
        sharedMember.entityType === entityType
      ) {
        const permission = sharedMember.permission.permissions.find(
          (perm) => perm && perm.permissionName === actionType && perm.allowed,
        )
        if (permission) {
          if (
            permission?.scope === 'ALL' ||
            (permission?.scope === 'SELF_MANAGED' && user.id === entity.owner?.id) ||
            (permission?.scope === 'ORG' && user.organizationId === entity.organizationId) ||
            (!permission?.scope && permission.allowed)
          ) {
            return true
          }
        }
      }
    }

    return false
  }

  const hasLinkSharePermission = (actionType: ActionType) => {
    return publicLink.permissions ? publicLink.permissions[permissionIndexes[actionType]] === 1 : false
  }

  /**
   * @param actionType ActionType
   * @param entity Node | Flow
   * @returns Boolean
   * @description returning TRUE means the action is enabled.
   */
  const hasPermission = (actionType: ActionType, entity: Node | Flow | undefined = undefined, isPublicLink = false) => {
    if (isPublicLink) return hasLinkSharePermission(actionType)
    if (currentTip && !entity) return hasUserPermissions(actionType, currentTip)
    if (currentBox && !entity) return hasUserPermissions(actionType, currentBox)
    if (currentProject && !entity) return hasUserPermissions(actionType, currentProject)
    return entity ? hasUserPermissions(actionType, entity) : false
  }

  return { hasPermission }
}
