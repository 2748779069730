import { useState, useEffect } from 'react'
import { Box, FormControl, FormLabel, Icon, Switch, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { User, Node } from 'types/graphqlSchema'
import { privacySetting } from 'helpers/constant'
import { icons } from 'theme'
import { AlertWithAction } from 'components/fragments'

interface PrivacySwitchProps {
  user: User
  node?: Node
  currentPrivacySetting?: boolean | null
  onPrivacyChange: (isPrivate: boolean) => void
  justifyContent?: string
  width?: string
  darkBg?: boolean
  useConfirmation?: boolean
}

const PrivacySwitch = ({
  user,
  node,
  currentPrivacySetting,
  onPrivacyChange,
  justifyContent = 'flex-start',
  width = '100%',
  darkBg = false,
  useConfirmation = true,
}: PrivacySwitchProps) => {
  const orgWidePrivacy = user?.organization?.privacySetting === privacySetting.organizationWide
  const {
    isOpen: isOpenPrivacyConfirmation,
    onOpen: onOpenPrivacyConfirmation,
    onClose: onClosePrivacyConfirmation,
  } = useDisclosure()
  const [isPrivate, setIsPrivate] = useState(() => {
    if (currentPrivacySetting !== null && currentPrivacySetting !== undefined) {
      return currentPrivacySetting
    }
    if (node) {
      return node.private ?? false
    }
    return !orgWidePrivacy
  })

  const labelColor = darkBg ? (isPrivate ? 'textSoft' : 'textRegular') : isPrivate ? 'textBlack' : 'textRegular'

  useEffect(() => {
    onPrivacyChange(isPrivate)
  }, [isPrivate, onPrivacyChange])

  const getConfirmationType = (node: Node): string => {
    switch (node.type) {
      case 'PROJECT':
        return 'project'
      case 'BOX':
        return 'folder'
      default:
        return 'item'
    }
  }

  return (
    <>
      <FormControl display="flex" gap={3} justifyContent={justifyContent} maxH="min" width={width}>
        <Switch
          id="private"
          size="md"
          onChange={(e) => {
            if (useConfirmation) {
              onOpenPrivacyConfirmation()
            } else {
              setIsPrivate(e.target.checked)
            }
          }}
          isChecked={isPrivate}
          alignSelf="center"
        />
        <FormLabel htmlFor="private" color={labelColor} display="flex" flexDir="column" margin={0} minW="max" gap="2px">
          <Text color="inherit" fontSize="1rem" fontWeight={500} lineHeight={1}>
            Invite-only
          </Text>
          <Text color="inherit" fontSize="11px" fontWeight={400} lineHeight={1}>
            {isPrivate ? 'On' : 'Off'}
          </Text>
        </FormLabel>
        <Tooltip
          label={
            <Text fontSize=".75rem" fontWeight={500} color="inherit">
              On = Not visible to others unless shared
              <br />
              Off = Visible to everyone in your organization
            </Text>
          }
          placement="bottom"
          alignItems="flex-start"
          bgColor={darkBg ? 'textHighlight' : 'textBlack'}
          color={darkBg ? 'textBlack' : 'textHighlight'}
          border="0"
          width="full"
          rounded={6}
          px={5}
          py={2}
          hasArrow
        >
          <Box mt="-1px">
            <Icon as={icons.outlineQuestionMark} boxSize={5} color={darkBg ? 'textRegular' : 'textBlack'} />
          </Box>
        </Tooltip>
      </FormControl>
      <AlertWithAction
        action={() => {
          setIsPrivate((prev) => !prev)
          onClosePrivacyConfirmation()
        }}
        isOpen={isOpenPrivacyConfirmation}
        onClose={onClosePrivacyConfirmation}
        actionText={`Make this ${node?.type && getConfirmationType(node)} ${isPrivate ? 'Public' : 'Invite-only'}?`}
        warningText={[
          isPrivate
            ? 'This will allow access for everyone in your organization.'
            : `Only you will have access to this ${
                node?.type && getConfirmationType(node)
              }. You can invite others by sharing.`,
        ]}
        confirmButtonText="Yes"
      />
    </>
  )
}

export default PrivacySwitch
