/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'helpers/store'
import { client as apollo } from 'helpers/apollo'
import { DELETE_TIP_ASSET_FILES, MOVE_TIP, SHARE_TIP, UNSHARE_TIP, UPDATE_SHARE_TIP } from './tip.schema'
import { GET_NODE, CREATE_NODE, UPDATE_NODE, DELETE_NODE, DUPLICATE_NODE } from '../node/node.schema'

// ---------------------------------------
// Thunks
// ---------------------------------------

const getTip = createAsyncThunk('tip/getTip', async (tipId: string) => {
  try {
    const {
      data: { node },
    } = await apollo.query({
      query: GET_NODE,
      variables: { id: tipId },
    })

    return node
  } catch (error: any) {
    throw new Error(error)
  }
})

const createNewTip = createAsyncThunk('tip/createNewTip', async (params: ITipInput) => {
  const { setCurrentTip } = params
  delete params.setCurrentTip
  try {
    const {
      data: { createNode },
    } = await apollo.mutate({
      mutation: CREATE_NODE,
      variables: {
        input: { ...params },
      },
    })
    return { createNode, setCurrentTip }
  } catch (err) {
    return err
  }
})

const updateTip = createAsyncThunk('tip/updateTip', async (input: ITipUpdate) => {
  const { setCurrentTip } = input
  delete input.setCurrentTip
  try {
    const {
      data: { updateNode },
    } = await apollo.mutate({
      mutation: UPDATE_NODE,
      variables: { input },
    })
    return { updateNode, setCurrentTip }
  } catch (error) {
    return error
  }
})

const deleteTip = createAsyncThunk('tip/deleteTip', async (id: string) => {
  try {
    const { data } = await apollo.mutate({
      mutation: DELETE_NODE,
      variables: { id },
    })
    if (data?.error) {
      throw new Error(data?.error)
    }
    return data.deleteNode
  } catch (err) {
    return err
  }
})

const deleteS3Assets = createAsyncThunk('tip/deleteS3Assets', async (s3Keys: string[]) => {
  try {
    const {
      data: { deleteTipAssetFiles },
    } = await apollo.mutate({
      mutation: DELETE_TIP_ASSET_FILES,
      variables: { s3Keys },
    })
    // Returns the size of the folder
    return deleteTipAssetFiles
  } catch (error: any) {
    throw new Error(error)
  }
})

const moveTip = createAsyncThunk('tip/moveTip', async (params: any) => {
  try {
    const { data } = await apollo.mutate({
      mutation: MOVE_TIP,
      variables: { ...params },
    })
    return data.moveNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const shareTip = createAsyncThunk('tip/shareTip', async (params: any) => {
  try {
    const { data } = await apollo.mutate({
      mutation: SHARE_TIP,
      variables: params,
    })
    if (data.error) {
      throw new Error(data.error.message)
    }
    return data.shareTip
  } catch (error: any) {
    throw new Error(error)
  }
})

const unshareTip = createAsyncThunk('tip/unshareTip', async (params: any) => {
  try {
    const { data } = await apollo.mutate({
      mutation: UNSHARE_TIP,
      variables: params,
    })
    if (data.error) {
      throw new Error(data.error.message)
    }
    return data.unshareTip
  } catch (error: any) {
    throw new Error(error)
  }
})

const updateShareTip = createAsyncThunk('tip/updateShareTip', async (params: any) => {
  try {
    const { data } = await apollo.mutate({
      mutation: UPDATE_SHARE_TIP,
      variables: params,
    })
    if (data.error) {
      throw new Error(data.error.message)
    }
    return data.updateShareTip
  } catch (error: any) {
    throw new Error(error)
  }
})

// Duplicate tip
const duplicateTip = createAsyncThunk('tip/duplicateTip', async (id: string) => {
  try {
    const {
      data: { duplicateNode },
    } = await apollo.mutate({
      mutation: DUPLICATE_NODE,
      variables: { id },
    })
    return duplicateNode
  } catch (error: any) {
    throw new Error(error)
  }
})

// INITIAL STATE
const initialState: ITipState = {
  isLoading: false,
  isUpdating: false,
  tips: [],
  currentTip: null,
  error: null,
}

// Slice
const tipSlice = createSlice({
  name: 'tip',
  initialState,
  reducers: {
    setCurrentTip(state, { payload }) {
      state.currentTip = payload
    },
    clearCurrentTip(state) {
      state.currentTip = null
    },
    clearTips(state) {
      state.tips = []
    },
    rearrangeTips(state, { payload }) {
      state.tips = payload
    },
    setTips(state, { payload }) {
      state.tips = payload
    },
    removeTip(state, { payload }) {
      if (!state.tips) return
      state.tips = state.tips.filter((tip) => tip.id !== payload.id)
    },
    setTipUpdating(state, { payload }) {
      state.isUpdating = payload
    },
    updateTipShareMembers(state, { payload }) {
      if (!payload) return

      if (state.currentTip && payload.id === state.currentTip.id) {
        state.currentTip.sharedMembers = payload.sharedMembers
      }

      if (state.tips) {
        state.tips = state.tips.map((tip) => {
          if (tip.id === payload.id) {
            tip.sharedMembers = payload.sharedMembers
          }
          return tip
        })
      }
    },
    updateTipLinkShare(state, { payload }) {
      if (!payload) return
      if (state.currentTip === payload.nodeId) {
        state.currentTip.linkShare = payload
      }
    },
    removeTipLinkShare(state, { payload }) {
      if (!payload) return
      if (state.currentTip === payload.nodeId) {
        state.currentTip.linkShare = undefined
      }
    },
  },
  extraReducers: (builder) => {
    // Get all tips
    // builder.addCase(getTips.fulfilled, (state, { payload }) => {
    //   if (payload.tips.includes(null)) {
    //     state.tips = payload.tips.filter((tip: ITip) => tip !== null)
    //   } else {
    //     state.tips = payload.tips
    //   }
    //   state.error = null
    // })
    // builder.addCase(getTips.rejected, (state) => {
    //   state.error = 'Failed to fetch all your tips.'
    // })
    // get Tip
    builder.addCase(getTip.fulfilled, (state, { payload }) => {
      state.currentTip = payload
      state.error = null
    })
    // Create tip
    builder.addCase(createNewTip.fulfilled, (state, { payload }: any) => {
      if (payload?.setCurrentTip) {
        state.currentTip = payload.createNode
      }
    })
    builder.addCase(createNewTip.rejected, (state) => {
      state.error = 'Something went wrong. Failed to create the tip.'
    })
    // Duplicate Tip
    // -----------------------------------------------------------------------
    builder.addCase(duplicateTip.fulfilled, (state, { payload }) => {
      state.tips.unshift(payload)
      state.error = null
    })
    builder.addCase(duplicateTip.rejected, (state) => {
      state.error = 'Cannot duplicate the tip. Something went wrong.'
    })

    // -----------------------------------------------------------------------
    // Delete file
    builder.addCase(deleteTip.pending, (state) => {
      state.error = null
    })
    builder.addCase(deleteTip.fulfilled, (state, { payload }: any) => {
      state.tips = state.tips.filter((tip) => {
        if (payload === tip.id) {
          return false
        }
        return tip
      })
    })
    builder.addCase(deleteTip.rejected, (state) => {
      state.error = 'Cannot delete the tip. Something went wrong.'
    })
    // ----------------------------------------------------------------------
    // Update
    builder.addCase(updateTip.pending, (state) => {
      state.isUpdating = true
    })
    builder.addCase(updateTip.fulfilled, (state, { payload }: any) => {
      const updatedTip = payload.updateNode
      state.tips = state.tips.map((tip) => {
        if (updatedTip.id === tip.id) {
          return updatedTip
        }
        return tip
      })
      state.error = null
      state.isUpdating = false
      if (state.currentTip?.id === updatedTip.id) {
        state.currentTip = updatedTip
      }
    })
    builder.addCase(updateTip.rejected, (state) => {
      state.error = 'Something went wrong. Failed to update the tip.'
      state.isUpdating = false
    })
    // Move tip
    builder.addCase(moveTip.fulfilled, (state, { payload }: any) => {
      state.tips = state.tips.filter((tip) => {
        if (payload.id === tip.id) return false
        return true
      })
      state.error = null
    })
    builder.addCase(moveTip.rejected, (state) => {
      state.error = 'Something went wrong moving the tip. Try again later.'
    })
    // Share tip
    builder.addCase(shareTip.fulfilled, (state, { payload }) => {
      if (state.currentTip) {
        state.currentTip = payload
      } else {
        state.tips = state.tips.map((tip) => {
          if (tip.id === payload.id) {
            return payload
          }
          return tip
        })
      }
    })
    // Unshare tip
    builder.addCase(unshareTip.fulfilled, (state, { payload }) => {
      if (state.currentTip) {
        state.currentTip = payload
      } else {
        state.tips = state.tips.map((tip) => {
          if (tip.id === payload.id) {
            return payload
          }
          return tip
        })
      }
    })
    // Update user Share type tip
    builder.addCase(updateShareTip.fulfilled, (state, { payload }) => {
      if (state.currentTip) {
        state.currentTip = payload
      } else {
        state.tips = state.tips.map((tip) => {
          if (tip.id === payload.id) {
            return payload
          }
          return tip
        })
      }
    })
  },
})

export function useTipSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.tip)
  return {
    dispatch,
    ...state,
    ...tipSlice.actions,
    createNewTip,
    updateTip,
    duplicateTip,
    moveTip,
    getTip,
    deleteTip,
    deleteS3Assets,
  }
}

export default tipSlice.reducer
