import { Flex } from '@chakra-ui/react'
import { IconButton } from 'components'
import { icons, customIcons } from 'theme'

interface MenuMobileProps {
  activeButton: string
  navigateTo: (path: string) => void
}

const MenuMobile = ({ activeButton, navigateTo }: MenuMobileProps) => {
  // * Render
  return (
    <Flex
      justify="center"
      px={4}
      bg="menuBackground"
      h="4.688rem"
      borderTop="1px"
      mx={[null, null, 'auto']}
      borderTopColor="borderRegular"
    >
      <Flex as="nav" w={['full', null, '300px']} justify="space-between" align="flex-start">
        {/* Flows */}
        <IconButton
          ariaLabel="Search Button"
          onClick={() => {
            navigateTo('/search')
          }}
          icon={icons.search}
          iconSize={{ width: 5, height: 5 }}
          variant={activeButton === '/' ? 'iconBtnSelected' : 'iconBtn'}
          flexDir={['column']}
          justify={['flex-end']}
          label="Search"
        />
        <IconButton
          ariaLabel="Dashboard"
          onClick={() => {
            navigateTo('/dashboard')
          }}
          icon={customIcons.dashboardIcon}
          iconSize={{ width: 5, height: 5 }}
          variant={activeButton === '/' ? 'iconBtnSelected' : 'iconBtn'}
          flexDir={['column']}
          justify={['flex-end']}
          label="Dashboard"
        />
        {/* Flows */}
        <IconButton
          ariaLabel="Flows Button"
          onClick={() => {
            navigateTo('/flows')
          }}
          icon={customIcons.flowIcon}
          iconSize={{ width: 5, height: 5 }}
          variant={activeButton === '/' ? 'iconBtnSelected' : 'iconBtn'}
          flexDir={['column']}
          justify={['flex-end']}
          label="Flows"
        />
        {/* PROJECT */}
        <IconButton
          ariaLabel="Projects Button"
          onClick={() => {
            navigateTo('/')
          }}
          icon={customIcons.projectIcon}
          iconSize={{ width: 5, height: 5 }}
          variant={activeButton === '/' ? 'iconBtnSelected' : 'iconBtn'}
          flexDir={['column']}
          justify={['flex-end']}
          label="Projects"
        />
        {/* Personal */}
        <IconButton
          ariaLabel="Personal Button"
          onClick={() => {
            navigateTo('/personal')
          }}
          icon={customIcons.personalIcon}
          variant={activeButton === '/personal' ? 'iconBtnSelected' : 'iconBtn'}
          flexDir={['column']}
          justify={['flex-end']}
          label="Personal"
        />
        {/* PROJECT */}
        <IconButton
          ariaLabel="Trash Button"
          onClick={() => {
            navigateTo('/trash')
          }}
          icon={customIcons.trashIcon}
          iconSize={{ width: 5, height: 5 }}
          variant={activeButton === '/' ? 'iconBtnSelected' : 'iconBtn'}
          flexDir={['column']}
          justify={['flex-end']}
          label="Trash"
        />
      </Flex>
    </Flex>
  )
}

export default MenuMobile
