import { Icon } from '@chakra-ui/icon'
import { motion } from 'framer-motion'
import { icons } from 'theme'

interface AMCProps {
  isOpen: boolean
  marginTop?: string
}

const AnimatedMenuChevron = ({ isOpen, marginTop = '2px' }: AMCProps) => {
  const rotation = isOpen ? 180 : 0
  return (
    <motion.div
      style={{ display: 'grid', placeContent: 'center', marginTop }}
      animate={{ rotate: rotation }}
      transition={{ duration: 0.2 }}
    >
      <Icon as={icons.IoChevronDown} w={5} h={5} />
    </motion.div>
  )
}

export default AnimatedMenuChevron
