import React from 'react'
import { Container, Flex, Image, Box, Skeleton, useColorModeValue } from '@chakra-ui/react'
import { images } from 'theme'

const SkeletonBoxCard = () => (
  <Box pos="relative" width="100%" pb="90%" h={0}>
    <Container variant="boxCard">
      <Box w="full" h="46%" bg="cardBackground" borderTopRadius={8} overflow="hidden" pos="relative">
        <Flex h="full" w="full" justify="center" align="center">
          <Image
            src={useColorModeValue(images.LogoWhite, images.LogoGrey)}
            fallbackSrc={useColorModeValue(images.LogoWhite, images.LogoGrey)}
            alt="tipbox logo"
            w={20}
          />
        </Flex>
      </Box>
      <Box h="54%" w="full" p={2} pt={4}>
        <Skeleton h="14px" startColor="textAsh" endColor="textRegular" mb={2} />
      </Box>
    </Container>
  </Box>
)

export default SkeletonBoxCard
