import {
  Avatar,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Flex,
  Divider,
} from '@chakra-ui/react'
import { IoSearch } from 'react-icons/io5'
import { useAppSlice } from 'features/redux'
import { getResizedImage } from 'helpers/storage'

interface User {
  name: string
  id: string
  avatar?: string
  avatarSecureUrl?: string
}

interface UserListModalProps {
  isOpen: boolean
  onClose: () => void
  filteredUsers: User[]
  searchTerm: string
  setSearchTerm: (searchTerm) => void
  pageType: string
  selectedUserId?: string
}

const UsersListModal = ({
  isOpen,
  onClose,
  filteredUsers,
  searchTerm,
  setSearchTerm,
  pageType,
}: UserListModalProps) => {
  const { dispatch, sortType, setSortType, setFlowRequesterId, setProjectOwnerId } = useAppSlice()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="textHighlight" color="textBlack">
        <ModalBody py={8}>
          <InputGroup variant="filled">
            <InputLeftElement fontSize="1.5rem" left={5} color="textRegular" pointerEvents="none">
              <IoSearch />
            </InputLeftElement>
            <Input
              fontSize="1.125rem"
              mx={8}
              maxW="90%"
              py={2}
              border="none"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
            />
          </InputGroup>
          <Divider mb={6} />
          <Flex flexDir={'column'} alignItems={'flex-start'} ml={5} gap={6}>
            {filteredUsers.map((user) => {
              return (
                <Button
                  display={'flex'}
                  gap={4}
                  key={user.id}
                  variant={'unstyled'}
                  value={user.name}
                  onClick={() => {
                    if (pageType === 'flows') {
                      dispatch(setFlowRequesterId(user.id))
                      dispatch(setSortType({ ...sortType, flow: 'Sender' }))
                    }
                    if (pageType === 'projects') {
                      dispatch(setProjectOwnerId(user.id))
                      dispatch(setSortType({ ...sortType, project: 'Owner' }))
                    }
                    setSearchTerm('')
                    onClose()
                  }}
                >
                  <Avatar
                    border={'1px'}
                    size="md"
                    name={user.name}
                    src={user.avatarSecureUrl ? getResizedImage(user.avatarSecureUrl, 'profile.main') : ''}
                  />
                  <Text color="textBlack">{user.name}</Text>
                </Button>
              )
            })}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default UsersListModal
