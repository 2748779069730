import { gql } from '@apollo/client'

// FRAGMENT
// email
const projectUser = `
  id
  firstName
  lastName
  avatar
`

const shareMember = `
  id
  sharedBy {
    id
    firstName
    lastName
    email
    avatar
  }
  sharedWith {
    id
    firstName
    lastName
    email
    avatar
    avatarSecureUrl
  }
  sharedWithId
  permission {
    id
    name
    permissions {
      permissionName
      entityType
      allowed
    }
  }
  permissionId
  isPublic
  entityType
  entityId
`

const linkShare = `
  randomPath
  linkType
  expiryDate
  hasPassword
  permissionType
`

const nodeOutput = `
  id
  name
  type
  createdAt
  updatedAt
  totalSize
  coverImage
  coverImageSecureUrl
  ownerId
  owner {
    ${projectUser}
  }
  updatedBy {
    ${projectUser}
  }
  projectId
  organizationId
  organization {
    id
    name
  }
  parent {
    id
    name
    type
  }
`
// sharedMembers {
//   ${shareMember}
// }
// linkShare {
//   ${linkShare}
// }
// breadcrumbs {
//   name
//   id
//   type
// }

// ---------------------------
// QUERIES
// ---------------------------

// ---------------------------
// MUTATIONS
// ---------------------------

export const UNSHARE_PROJECT = gql`
 mutation unshareProject ($shareItemId: String, $unshareUserId: String,) {
    unshareProject(shareItemId: $shareItemId, unshareUserId: $unshareUserId,  ) {
      id
      name
      coverImage
      coverImageSecureUrl
      type
      state
      updatedAt
      totalSize
      children {
        ${nodeOutput}
      }
      owner {
        ${projectUser}
      }
      updatedBy {
        ${projectUser}
      }
    }
  }
`

export const SHARE_PROJECT = gql`
 mutation shareProject (
      $shareItemId: String,
      $shareUsersEmails: [String],
      $shareType: String) {
    shareProject(
      shareItemId: $shareItemId, 
      shareUsersEmails: $shareUsersEmails,
      shareType: $shareType
    ) {
      id
      name
      coverImage
      coverImageSecureUrl
      type
      state
      updatedAt
      totalSize
      children {
        ${nodeOutput}
      }
      owner {
        ${projectUser}
      }
      updatedBy {
        ${projectUser}
      }
      sharedMembers {
        ${shareMember}
      }
    }
  }
`

export const UPDATE_SHARE_PROJECT = gql`
  mutation updateShareProject (
      $shareItemId: String,
      $shareUserId: String,
      $shareType: String) {
    updateShareProject(
      shareItemId: $shareItemId, 
      shareUserId: $shareUserId,
      shareType: $shareType
    ) {
      id
      name
      type
      state
      updatedAt
      coverImage
      coverImageSecureUrl
      totalSize
      children {
        ${nodeOutput}
      }
      owner {
        ${projectUser}
      }
      updatedBy {
        ${projectUser}
      }
    }
  }
`
