import { gql } from '@apollo/client'

// ---------------------------
// QUERIES
// --

export const FETCH_AVAILABLE_TAGS = gql`
  query Tags {
    tags {
      id
      createdAt
      name
    }
  }
`
export const FETCH_TAGS_FROM_NODE = gql`
  query LoadTagsByNodeId($id: ID!) {
    node(id: $id) {
      tags {
        name
        id
        createdAt
      }
    }
  }
`

export const FETCH_TAGS_FROM_FLOW = gql`
  query LoadTagsByFlowId($id: ID!) {
    flowById(id: $id) {
      error
      flow {
        tags {
          name
          id
          createdAt
        }
      }
    }
  }
`

// ---------------------------
// MUTATIONS
// ---

export const CREATE_TAG = gql`
  mutation CreateTag($name: String!) {
    createTag(name: $name) {
      id
      createdAt
      name
    }
  }
`

export const TAG_ENTITY = gql`
  mutation TagEntity($tagId: String!, $entityId: String!, $entityType: EntityType!) {
    associateTagWithEntity(tagId: $tagId, entityId: $entityId, entityType: $entityType)
  }
`

export const UNTAG_ENTITY = gql`
  mutation UntagEntity($tagId: String!, $entityId: String!, $entityType: EntityType!) {
    removeTagFromEntity(tagId: $tagId, entityId: $entityId, entityType: $entityType)
  }
`
