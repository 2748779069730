import { Button, Image, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react'
import { DatePicker } from 'components'
import { endOfDay, setHours, setMinutes } from 'date-fns'
import { formatForDate, formatForTime, isMidnight, isTime1159PM } from 'helpers/utils'
import { useState } from 'react'
import { images } from 'theme'

type DueDatePopoverProps = {
  flowState?: FlowState
  onChange: (flow: any) => void
  timeOnly?: boolean
  isRequired?: boolean
  noMinDate?: boolean
  selected?: Date | undefined
}

const DueDatePopover = ({ flowState, timeOnly, onChange, isRequired, noMinDate, selected }: DueDatePopoverProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectedDateTime = flowState ? flowState.due : selected

  const handleCalendarChange = (dateTime: any) => {
    if (flowState) {
      const newDueDate = isMidnight(dateTime) ? endOfDay(dateTime) : dateTime
      const change = { ...flowState, due: newDueDate }
      onChange(change)
      setIsOpen(false)
    } else {
      onChange(dateTime)
      setIsOpen(false)
    }
  }

  return (
    <Popover colorScheme="tipboxPrimary" isOpen={isOpen} placement="bottom-start">
      <PopoverTrigger>
        <Button
          colorScheme="tipboxPrimary"
          fontSize="inherit"
          display="flex"
          justifyContent={'flex-start'}
          gap="1rem"
          px={flowState ? 1 : 6}
          variant="unstyled"
          flexGrow={1}
          w={'13rem'}
          border={flowState ? undefined : '1px solid #A3A2A9'}
          borderBottom={flowState ? '1.5px solid var(--chakra-colors-borderLight)' : undefined}
          rounded={flowState ? 'none' : 'lg'}
          py={6}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {!timeOnly && (
            <>
              <Image src={selectedDateTime ? images.calendarRedThick : images.calendarGreyThick} alt="calendar" />
              <Text color="textBlack" fontWeight={300}>
                {!selectedDateTime ? 'Date' : formatForDate(selectedDateTime.toString())}
                {!selectedDateTime && isRequired && (
                  <span style={{ marginLeft: '.25rem', fontStyle: 'italic' }}> *required</span>
                )}
              </Text>
            </>
          )}

          {timeOnly && (
            <>
              <Image
                src={selectedDateTime && !isTime1159PM(selectedDateTime) ? images.clockRed : images.clockGrey}
                alt="clock"
              />
              {!selectedDateTime && (
                <Text color={'#666666'} fontWeight={'300'}>
                  Time
                  <span style={{ marginLeft: '.5rem', color: '#979797' }}>(optional)</span>
                </Text>
              )}
              {selectedDateTime && (
                <>
                  <Text color={'#666666'} fontWeight={'300'}>
                    {isTime1159PM(selectedDateTime) ? 'Time' : formatForTime(selectedDateTime.toString())}
                    {isTime1159PM(selectedDateTime) && (
                      <span style={{ marginLeft: '.5rem', color: '#979797' }}>(optional)</span>
                    )}
                  </Text>
                </>
              )}
              {selectedDateTime && !isTime1159PM(selectedDateTime) && (
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    const prevDate = new Date(selectedDateTime)
                    const replaced = prevDate.toString().replace(/\d{2}:\d{2}:\d{2}/, '00:00:00')
                    const replacedDate = new Date(replaced)
                    handleCalendarChange(replacedDate)
                  }}
                  style={{
                    position: 'absolute',
                    marginLeft: 'auto',
                    zIndex: 20,
                    padding: '1rem',
                    right: 6,
                  }}
                >
                  <Image src={images.xSmall} />
                </button>
              )}
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent maxW={'min-content'} bg="transparent" border="none" shadow={'md'}>
        <PopoverBody p={0} h="15rem">
          <DatePicker
            onChange={handleCalendarChange}
            selected={selectedDateTime}
            minDate={!noMinDate ? new Date() : null}
            showTimeSelectOnly={timeOnly}
            showTimeSelect={timeOnly}
            excludeTimes={[setHours(setMinutes(new Date(), 0), 0)]}
            inline
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default DueDatePopover
