import { HStack, Text, Button } from '@chakra-ui/react'

type PopoverOptionButtonProps = {
  title: string
  onClick: () => void
  selected: boolean
}

const PopoverOptionButton = ({ title, onClick, selected = false }: PopoverOptionButtonProps) => {
  return (
    <Button
      p={0}
      variant="transparentButton"
      color={selected ? 'textHighlight' : 'textBlack'}
      bg={selected ? 'menuBackground' : 'initial'}
      borderRadius={'0px'}
      _hover={{ bg: 'menuBackground', color: 'textHighlight', fill: 'textHighlight', borderRadius: 0 }}
      onClick={onClick}
    >
      <HStack w="full" py={4} px={8}>
        <Text color="inherit" fontSize="16px" fontFamily="Inter" fontWeight={500} mr="20px">
          {title}
        </Text>
      </HStack>
    </Button>
  )
}

export default PopoverOptionButton
