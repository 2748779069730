// NOTE: Not sure why we needed all these manual settings for opening and closing popover?
// That causes problem with https://tipbox.atlassian.net/browse/TA-428
// These seem to be unneccessary, hence commented out those code until we find the reason for this.
import React, { useEffect, useRef } from 'react'
import { IconType } from 'react-icons'
import _ from 'lodash'
import { isSafari } from 'react-device-detect'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Flex,
  PopoverProps,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { IconButton } from 'components'

interface IconPopoverProps extends PopoverProps {
  icon: IconType
  children: React.ReactNode
  variantBtn?: string
  iconSize?:
    | { height: number | string; width: number | string }
    | { height: (number | string | null)[]; width: (number | string | null)[] }
  iconColor?: string
  variantPopover?: string
  buttonStyle?: React.CSSProperties
  style?: React.CSSProperties
  showPortal?: boolean
  flexMinWidth?: number | string
  xMargin?: number | string
  menuOpen?: boolean
  setMenuOpen?: (menuOpen: boolean) => void
  itemId?: string
  menuType?: 'project' | 'box' | 'tip' | 'flow' | null | undefined
  testId?: string
}

const IconPopover = ({
  icon,
  variantBtn = 'iconBtn',
  iconSize,
  iconColor,
  variantPopover = 'autoWidth',
  placement = 'bottom-end',
  buttonStyle,
  children,
  style,
  menuOpen,
  setMenuOpen,
  itemId,
  menuType,
  flexMinWidth = 4,
  showPortal = true,
  xMargin = 'auto',
  testId,
}: IconPopoverProps) => {
  const popoverRef = useRef<HTMLElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    document.addEventListener('mousedown', onClose)
    return () => {
      document.removeEventListener('mousedown', onClose)
    }
  }, [popoverRef, onOpen, onClose, isOpen])

  // const [closeable, setCloseable] = useState(true)
  // const {
  //   dispatch,
  //   setPopupMenuBoxes,
  //   setPopupMenuProjects,
  //   setPopupMenuTips,
  //   setPopupMenuFlows,
  //   popupMenuBoxes,
  //   popupMenuProjects,
  //   popupMenuTips,
  //   popupMenuFlows,
  // } = useAppSlice()
  // const isWindowOpen = (): boolean | undefined => {
  //   const isOpen = (arr: IPupMenu[]) => {
  //     const res = _.find(arr, (item: IPupMenu) => {
  //       return item.itemId === itemId
  //     })
  //     if (res) return res.isOpen
  //     return undefined
  //   }
  //   if (menuType === 'box') {
  //     if (popupMenuBoxes) {
  //       return isOpen(popupMenuBoxes)
  //     }
  //   }
  //   if (menuType === 'project') {
  //     if (popupMenuProjects) {
  //       return isOpen(popupMenuProjects)
  //     }
  //   }
  //   if (menuType === 'tip') {
  //     if (popupMenuTips) {
  //       return isOpen(popupMenuTips)
  //     }
  //   }
  //   if (menuType === 'flow') {
  //     if (popupMenuFlows) {
  //       return isOpen(popupMenuFlows)
  //     }
  //   }
  //   return menuOpen
  // }

  // const settingMenuOpen = () => {
  //   const setOpenMenu = (arr: IPupMenu[], func) => {
  //     const array = arr?.map((item: { itemId: string; isOpen: boolean }) => ({
  //       itemId: item.itemId,
  //       isOpen: false,
  //     }))

  //     const newArray = array?.map((item: { itemId: string | undefined }) => {
  //       if (item.itemId === itemId) {
  //         return { itemId: item.itemId, isOpen: true }
  //       }
  //       return item
  //     })

  //     const makeAllFalse = (arrMenu: IPupMenu[], fnc) => {
  //       const setFalse = arrMenu?.map((item: { itemId: string; isOpen: boolean }) => ({
  //         itemId: item.itemId,
  //         isOpen: false,
  //       }))
  //       dispatch(fnc(setFalse))
  //     }

  //     switch (menuType) {
  //       case 'box':
  //         if (popupMenuTips) makeAllFalse(popupMenuTips, setPopupMenuTips)
  //         break
  //       case 'tip':
  //         if (popupMenuBoxes) makeAllFalse(popupMenuBoxes, setPopupMenuBoxes)
  //         break
  //       case 'flow':
  //         if (popupMenuFlows) makeAllFalse(popupMenuFlows, setPopupMenuFlows)
  //         break
  //       default:
  //         break
  //     }
  //     dispatch(func(newArray))
  //   }

  //   switch (menuType) {
  //     case 'project':
  //       if (popupMenuProjects) setOpenMenu(popupMenuProjects, setPopupMenuProjects)
  //       break
  //     case 'box':
  //       if (popupMenuBoxes) setOpenMenu(popupMenuBoxes, setPopupMenuBoxes)
  //       break
  //     case 'tip':
  //       if (popupMenuTips) setOpenMenu(popupMenuTips, setPopupMenuTips)
  //       break
  //     case 'flow':
  //       if (popupMenuFlows) setOpenMenu(popupMenuFlows, setPopupMenuFlows)
  //       break
  //     default:
  //       if (setMenuOpen) setMenuOpen(!menuOpen)
  //       break
  //   }
  // }

  // const settingMenuClose = () => {
  //   const setCloseMenu = (arr: IPupMenu[], func) => {
  //     const array = arr?.map((item: IPupMenu) => ({
  //       itemId: item.itemId,
  //       isOpen: false,
  //     }))
  //     dispatch(func(array))
  //   }
  //   switch (menuType) {
  //     case 'project':
  //       if (popupMenuProjects) setCloseMenu(popupMenuProjects, setPopupMenuProjects)
  //       break
  //     case 'box':
  //       if (popupMenuBoxes) setCloseMenu(popupMenuBoxes, setPopupMenuBoxes)
  //       break
  //     case 'tip':
  //       if (popupMenuTips) setCloseMenu(popupMenuTips, setPopupMenuTips)
  //       break
  //     case 'flow':
  //       if (popupMenuFlows) setCloseMenu(popupMenuFlows, setPopupMenuFlows)
  //       break
  //     default:
  //       break
  //   }
  // }

  return (
    // <Popover placement={placement} variant={variantPopover} isOpen={isWindowOpen()} isLazy>
    <Popover placement={placement} variant={variantPopover} isLazy isOpen={isOpen}>
      <PopoverTrigger>
        <Flex minW={flexMinWidth} mx={xMargin} align="center" sx={{ ...style }}>
          <IconButton
            ariaLabel="popover button"
            // onClick={settingMenuOpen}
            onClick={() => onOpen()}
            iconSize={iconSize}
            icon={icon}
            flexDir={['column', null, 'column']}
            iconColor={iconColor}
            variant={variantBtn}
            justify={['center']}
            style={buttonStyle}
            testId={testId}
          />
        </Flex>
      </PopoverTrigger>
      {showPortal ? (
        <Portal>
          <PopoverContent
            ref={popoverRef}
            p={0}
            _focus={{ boxShadow: 'none' }}
            borderRadius={8}
            // onMouseLeave={() => {
            //   setCloseable(true)
            // }}
            // onMouseEnter={() => {
            //   setCloseable(false)
            // }}
            // onBlur={() => {
            //   if (closeable && settingMenuClose) settingMenuClose()
            // }}
          >
            <PopoverBody p={0} borderRadius={8}>
              {children}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      ) : (
        <PopoverContent p={0} _focus={{ boxShadow: 'none' }} borderRadius={8} bg="whiteAlpha.600" zIndex="10">
          <PopoverBody p={0} borderRadius={8}>
            {children}
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  )
}

export default IconPopover
