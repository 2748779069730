import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { completeNewPassword, authLogin } from 'helpers/auth'
import { path } from 'helpers/constant'

interface INewPasswordRequiredModalProps {
  data: {
    isOpen: boolean
    email: string
    newPassword: string
    userData: any
  }
  onUpdate(data: any): void
}

const NewPasswordRequiredModal = ({ data, onUpdate }: INewPasswordRequiredModalProps) => {
  const navigate = useNavigate()
  const [, setErrors] = useState({})
  const { isOpen, email, newPassword, userData } = data
  const onInputChange = ({ target: { value } }: IInputParams) => {
    onUpdate({ ...data, newPassword: value })
  }

  const onClose = () => {
    onUpdate({ ...data, isOpen: false })
  }

  const onSubmitClick = async () => {
    try {
      await completeNewPassword(userData, newPassword)
      await authLogin(email, newPassword)
      navigate(path.home.index)
    } catch (err: any) {
      setErrors((prev) => ({ ...prev, [err.code]: err.message }))
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="none">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Password Required</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Please enter a new password:
          <Input
            // label="Password"
            onChange={onInputChange}
            type="password"
            value={newPassword}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmitClick}>Submit</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NewPasswordRequiredModal
