import { gql } from '@apollo/client'

const projectUser = `
  firstName
  lastName
  id
`
const nodeOutput = `
  id
  __typename
  name
  type
  private
  coverImage
  coverImageSecureUrl
  color
  children {
    id
    name
    __typename
    private
  }
  owner {
    ${projectUser}
  }
  updatedBy {
    ${projectUser}
  }
  extension
  preview
  previewSecureUrl
  s3Key
  parent {
    id
    name
  }
  breadcrumbs {
    name
    id
    type
  }
`

const tipOutput = `
  id
  name
  type
  coverImage
  coverImageSecureUrl
  totalSize
  updatedAt
  extension
  preview
  previewSecureUrl
  owner {
    ${projectUser}
  }
  updatedBy {
    ${projectUser}
  }
`
// ---------------------------
// QUERIES
// ---------------------------

export const LINK_SHARE_VALID = gql`
  query linkShareValid($randomPath: String!) {
    linkShareValid(randomPath: $randomPath) {
      nodeId
      nodeType
      expiried
      hasPassword
      linkType
      valid
      nonvalidReason
      nodeName
      permissions
      randomPath
    }
  }
`

export const RANDOM_PATH_NODE = gql`
  query randomPathNode($randomPath: String!) {
    randomPathNode(randomPath: $randomPath) {
      node {
        ${nodeOutput}
      }
      children {
        ${tipOutput}
      }
    }
  }
`

export const RANDOM_PATH_NODE_CHILD = gql`
  query randomPathChild($randomPath: String!, $nodeId: String!) {
    randomPathChild(randomPath:$randomPath, nodeId: $nodeId) {
      node {
        ${nodeOutput}
      }
      children {
        ${tipOutput}
      }
    }
  }
`

export const LINK_SHARE_LOGIN = gql`
  query linkShareSignIn($randomPath: String!, $password: String!) {
    linkShareSignIn(randomPath: $randomPath, password: $password)
  }
`

export const LINK_SHARE_USER_ACCESS = gql`
  query checkUserAccess($randomPath: String!) {
    checkUserAccess(randomPath: $randomPath)
  }
`

// ---------------------------
// MUTATIONS
// ---------------------------

export const UPDATE_LINK_SHARE = gql`
  mutation updateLinkShare($input: LinkShareUpdates!) {
    updateLinkShare(input: $input) {
      nodeId
      nodeType
      linkType
      hasPassword
      expiryDate
      randomPath
      permissionType
    }
  }
`

export const CREATE_TIP_LINK_SHARE = gql`
  mutation createTipByLinkShare($createTipInput: TipInput!, $randomPath: String) {
    createTipByLinkShare(input: $createTipInput, randomPath: $randomPath){
       ${tipOutput}
    }
  }
`

export const UPDATE_TIP_LINK_SHARE = gql`
  mutation updateTipByLinkShare($createTipInput: TipInput!, $randomPath: String) {
    updateTipByLinkShare(input: $createTipInput, randomPath: $randomPath){
       ${tipOutput}
    }
  }
`

export const DELETE_LINK_SHARE = gql`
  mutation deleteLinkShare($nodeId: String!) {
    deleteLinkShare(nodeId: $nodeId)
  }
`
