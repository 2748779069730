import { Flow, Node } from 'types/graphqlSchema'

export interface Results {
  nodes: Node[]
  flows: Flow[]
}

export enum SEARCH_BOOLS {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
}

export interface SearchItem {
  item: string
  type: string
  indexes: string[]
  id: string
  bool?: SEARCH_BOOLS
}

export interface DateRange {
  0: Date | undefined
  1: Date | undefined
}

export enum SortDirection {
  ascending = 'Ascending',
  descending = 'Descending',
}

export enum SortOption {
  sentDate = 'Sent Date',
  dueDate = 'Due Date',
  /**
   * Sort by sender (on hold).
   */
  // sender = 'Sender',
}
