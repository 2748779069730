import React, { useState } from 'react'
import {
  FormControl,
  Input,
  Flex,
  Box,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  FormLabel,
  Grid,
  Text,
  useRadioGroup,
  useColorModeValue,
} from '@chakra-ui/react'
import { useNodeMenuActions } from 'features/node/hooks'
import { useAppSlice, useProjectSlice, useBoxSlice } from 'features/redux'
import { Button, FileInput, ColorRadio, Toast, PrivacySwitch } from 'components'
import { uploadFile } from 'helpers/storage'
import { convertHexToNodeColor } from 'helpers/utils'
import { icons, colors } from 'theme'
import { NodeCreateInput, NodeState } from 'types/graphqlSchema'

interface CreateNewFolderProps {
  openModal: string
  setOpenModal: (openModal: string) => void
  moveToCreate?: (boxName: string, coverImage: File | undefined) => void
  inMoveTo?: boolean
}

const CreateNewFolder = ({ setOpenModal, moveToCreate = () => {}, inMoveTo = false }: CreateNewFolderProps) => {
  // Hooks
  const isMobile = useBreakpointValue({ xs: true, lg: false })
  const { undoCreation } = useNodeMenuActions()
  const { dispatch, user } = useAppSlice()
  const { currentProject } = useProjectSlice()
  const { currentBox, createBox, deleteBox, updateBox } = useBoxSlice()
  const initialColor = useColorModeValue(colors.folder.lightGray.body, colors.folder.darkGray.body)
  // don't show switch if it's a private folder or project
  const showPrivacySwitch = currentBox ? !currentBox.private : currentProject && !currentProject.private

  // Local State
  const [boxName, setBoxName] = useState('')
  const [isPrivate, setIsPrivate] = useState(currentBox ? currentBox.private : currentProject?.private)
  const [folderColor, setFolderColor] = useState<string>(initialColor)
  const [coverImage, setCoverImage] = useState<File>()
  const [imgData, setImgData] = useState<string>('')
  const [, setErrorMessage] = useState('')

  const handleInputChange = ({ target: { value } }: IInputParams) => {
    setBoxName(value)
  }

  // Control a group of custom radios
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'colors',
    onChange: setFolderColor,
  })
  const group = getRootProps()

  const uploadPhoto = async () => {
    if (!coverImage) return {}
    try {
      const uploadResponse = await uploadFile(coverImage)
      return uploadResponse.fileUrl
    } catch (error: any) {
      setErrorMessage(error.message)
      return error
    }
  }

  const onChangePicture = (pictureFile: File) => {
    if (pictureFile) {
      setCoverImage(pictureFile)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') setImgData(reader.result)
      })
      reader.readAsDataURL(pictureFile)

      setFolderColor('')
    }
  }

  const handlePrivacyChange = (isPrivate: boolean) => {
    setIsPrivate(isPrivate)
  }

  const onRemovePicture = () => {
    setCoverImage(undefined)
    setImgData('')
    setFolderColor(initialColor)
  }

  const handleUndoCreateBox = async (boxId: string) => {
    await dispatch(deleteBox(boxId))
    await undoCreation([boxId])
  }

  const handleCreateBox = async () => {
    const defaultProject = currentProject || user.myProject
    const projectId = defaultProject.id
    const input: NodeCreateInput = {
      name: boxName,
      type: 'BOX',
      color: convertHexToNodeColor(folderColor),
      projectId,
      state: NodeState.Active,
      parentId: currentBox ? currentBox.id : projectId,
      private: isPrivate,
    }

    try {
      const res = await dispatch(createBox(input))
      const boxId = res.payload.id
      let coverImageLink = null
      if (coverImage) {
        coverImageLink = await uploadPhoto()
        dispatch(updateBox({ id: boxId, coverImage: coverImageLink }))
      }
      Toast.show({
        icon: 'check',
        message: `"${boxName}" folder was created successfully.`,
        onUndo: () => {
          handleUndoCreateBox(boxId)
        },
      })
      setOpenModal('')
    } catch (error: any) {
      setErrorMessage(error)
      Toast.show({ icon: 'error', message: `Something went wrong with creating your folder` })
    }
  }

  return (
    <>
      <ModalBody fontFamily="Inter">
        <FormControl mb={8}>
          {/* Folder Name */}
          <FormLabel htmlFor="boxName" mr={0} fontSize={[12, null, null, 15]} textAlign="inherit" color="inherit">
            Folder Name
          </FormLabel>
          <Input
            id="boxName"
            value={boxName}
            autoFocus
            placeholder="Enter a folder name"
            variant="outline"
            borderRadius={8}
            borderColor="borderLight"
            onChange={handleInputChange}
            _placeholder={{
              color: 'textRegular',
              fontSize: isMobile ? '12px' : '16px',
            }}
          />
        </FormControl>

        <FormLabel mr={0} fontSize={[12, null, null, 15]} textAlign="inherit" color="inherit">
          Add a Cover Photo or Colour
        </FormLabel>

        <FormControl as="fieldset" textAlign="center">
          <Flex flexDir="column" w="full">
            <Flex justify="space-between" align="center" columnGap={4}>
              {/* Folder Image */}
              <Box maxWidth="8.875rem">
                <FileInput
                  placeHolder={<icons.plusThin fontSize="1.5rem" style={{ margin: '0 auto' }} />}
                  name="boxCover"
                  file={coverImage}
                  onSelectFile={onChangePicture}
                  onCancelFile={onRemovePicture}
                  preview={imgData}
                  noPreviewFileName
                  styleProps={{
                    height: ['3rem', null, null, '3.5rem'],
                    width: ['4rem', null, null, '5rem'],
                    marginTop: 0,
                    borderWidth: '.5px',
                    borderStyle: 'solid',
                  }}
                />
              </Box>

              <Text as="span" alignSelf="center" color="textBlack" fontSize={[12, null, null, 16]}>
                or
              </Text>

              {/* Folder Color */}
              <FormControl as="fieldset" width="auto">
                <Box
                  position="relative"
                  _after={{
                    display: coverImage && imgData ? 'block' : 'none',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: useColorModeValue('rgba(255, 255, 255, .6)', 'rgba(0, 0, 0, .6)'),
                    content: '""',
                  }}
                >
                  <Grid {...group} gridTemplateColumns="repeat(8, auto)" gap=".375rem">
                    {Object.entries(colors.folder).map(([, { body }]) => {
                      // Override Chakra UI's isChecked prop to be able to clear the radio value
                      const radio = { ...getRadioProps({ value: body }), isChecked: folderColor === body }

                      return <ColorRadio key={body} color={body} {...radio} />
                    })}
                  </Grid>
                </Box>
              </FormControl>
            </Flex>
            {coverImage && imgData && (
              <Box mt={2} textAlign="left">
                <Box as="button" type="button" fontSize={[12, null, null, 15]} onClick={onRemovePicture}>
                  Remove
                </Box>
              </Box>
            )}
          </Flex>
        </FormControl>
      </ModalBody>

      <ModalFooter justifyContent="center">
        {showPrivacySwitch && (
          <PrivacySwitch
            user={user}
            currentPrivacySetting={isPrivate}
            onPrivacyChange={handlePrivacyChange}
            useConfirmation={false}
          />
        )}

        <Button
          disabled={!(boxName.trim().length > 0)}
          label="Create Folder"
          onClick={() => {
            if (inMoveTo) {
              moveToCreate(boxName, coverImage)
            } else {
              handleCreateBox()
            }
            setOpenModal('')
          }}
          variant="modalBtn"
          ml={0}
        />
      </ModalFooter>
    </>
  )
}

export default CreateNewFolder
