import {
  Center,
  Text,
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import { icons } from 'theme'
import PopoverOptionButton from '../ProjectPopover/PopoverOptionButton'
import { FlowSortOption, FlowSortDirection } from 'features/workflow/Flows.types'
import { SortOption, SortDirection } from 'features/search/Search.types'

const SortPopoverV2 = ({
  isMobile,
  sortOption,
  sortDirection,
  onChangeSortOption,
  onChangeSortDirection,
  openModal,
}: {
  isMobile?: boolean
  sortOption: FlowSortOption | SortOption
  sortDirection?: FlowSortDirection | SortDirection
  onChangeSortOption: (option: FlowSortOption | SortOption) => void
  onChangeSortDirection: (direction: FlowSortDirection | SortDirection) => void
  openModal?: () => void
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const handleChangeSortDirection = () => {
    if (sortDirection === FlowSortDirection.ascending) return onChangeSortDirection(FlowSortDirection.descending)
    onChangeSortDirection(FlowSortDirection.ascending)
  }

  return (
    <Center>
      <Popover isLazy closeOnBlur onClose={onClose} variant="autoWidth" placement="bottom-end" isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            variant="transparentButton"
            p={0}
            onClick={onToggle}
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
          >
            {isMobile ? (
              <Icon as={icons.PiSlidersHorizontalBold} h="16px" color="textSoft" />
            ) : (
              <Text color={useColorModeValue('textSoft', 'white')} fontSize="14px" fontFamily="Inter">
                {sortOption}
              </Text>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent p={0} bg="textHighlight" border="none">
          <PopoverBody display="flex" flexDir="column" borderRadius="8px" overflow="hidden" p={0}>
            {Object.values(SortOption).map((value) => (
              <PopoverOptionButton
                key={value}
                title={value}
                onClick={() => {
                  /**
                   * Sort by sender (on hold).
                   */
                  // if (openModal && sortOption === 'Sender') {
                  //   openModal()
                  // }
                  onClose()
                  onChangeSortOption(value)
                }}
              />
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Button
        variant="transparentButton"
        p={0}
        onClick={handleChangeSortDirection}
        _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
      >
        {!sortDirection && <Icon as={icons.PiArrowsDownUpBold} h="16px" color="textSoft" />}
        {sortDirection === FlowSortDirection.ascending && (
          <Icon as={icons.AiOutlineArrowUp} h="16px" color="textSoft" />
        )}
        {sortDirection === FlowSortDirection.descending && (
          <Icon as={icons.AiOutlineArrowDown} h="16px" color="textSoft" />
        )}
      </Button>
    </Center>
  )
}

export default SortPopoverV2
