import { Flex, Text, Image } from '@chakra-ui/react'

interface FlowModalToggleProps {
  toggleItem: boolean
  onChange: () => void
  falseImage: string
  trueImage: string
  children: React.ReactNode
  gap?: string | number
}

const FlowModalToggle = ({
  toggleItem,
  onChange,
  falseImage,
  trueImage,
  children,
  gap = '0.75rem',
}: FlowModalToggleProps) => {
  return (
    <Flex gap={gap}>
      <button onClick={onChange} style={{ width: '1.5rem', minWidth: '1.5rem' }}>
        {!toggleItem && <Image width="1.5rem" src={falseImage} alt="false" />}
        {toggleItem && <Image width="1.5rem" src={trueImage} alt="true" />}
      </button>
      <Text mr="auto" minW="max" color="textBlack" fontWeight="300">
        {children}
      </Text>
    </Flex>
  )
}

export default FlowModalToggle
