import React from 'react'
import { isSafari } from 'react-device-detect'
import { Flex } from '@chakra-ui/react'
import { IconButton } from 'components'
import { customIcons } from 'theme'
import { usePermissions } from 'hooks'

interface NewActionsMenuProps {
  openModal: string
  setOpenModal: (openModal: string) => void
  handleMenuAction: (action: string) => void
}

const NewActionsMenu = ({ openModal, setOpenModal, handleMenuAction }: NewActionsMenuProps) => {
  const { hasPermission } = usePermissions()
  return (
    <>
      <Flex flexDir="column" rounded={8} bg="textHighlight" px="3px" py=".75rem">
        <Flex flexWrap="wrap" flexDir="column" rounded={0}>
          {/* CREATE FILE */}
          <IconButton
            ariaLabel="createTip"
            onClick={() => handleMenuAction('newTip')}
            icon={customIcons.addTipIcon}
            iconSize={{ width: '15px', height: '16px' }}
            label="New document"
            variant="dropDownButton"
            iconMarginRight="10px"
            iconMarginLeft="2px"
            flexDir={['row']}
          />
          {/* CREATE FOLDER */}
          <IconButton
            ariaLabel="addBox"
            onClick={() => {
              setOpenModal(openModal !== 'newBox' ? 'newBox' : '')
            }}
            icon={customIcons.addBoxIcon}
            iconSize={{ width: 5, height: '20px' }}
            label="New folder"
            variant="dropDownButton"
            iconMarginRight="7px"
            flexDir={['row']}
          />
        </Flex>
        <Flex flexWrap="wrap" flexDir="column">
          {!isSafari && (
            /* Upload files */
            <IconButton
              ariaLabel="uploadTip"
              onClick={handleMenuAction}
              icon={customIcons.uploadTipIcon}
              iconSize={{ width: '15px', height: '16px' }}
              label="Upload files"
              variant="dropDownButton"
              iconMarginRight="10px"
              iconMarginLeft="2px"
              flexDir={['row']}
              isDisable={!hasPermission('upload')}
            />
          )}
          {/* UPLOAD FOLDERS */}
          <IconButton
            ariaLabel="uploadBox"
            onClick={handleMenuAction}
            icon={isSafari ? customIcons.uploadObject : customIcons.uploadBoxIcon}
            iconSize={{ width: 5, height: 5 }}
            label={isSafari ? 'Upload' : 'Upload folders'}
            variant="dropDownButton"
            iconMarginRight="7px"
            flexDir={['row']}
            isDisable={!hasPermission('upload')}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default NewActionsMenu
