import { useState, useEffect } from 'react'
import { Flex, Text, useBreakpointValue, Button, Image } from '@chakra-ui/react'
import { Toast } from 'components'
import { useClipboard } from 'hooks'
import CONFIG from 'helpers/config'
import { images } from 'theme'
import { motion } from 'framer-motion'

const ShareModalLinkTab = ({ node }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [linkCopied, setLinkCopied] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const { copyToClipBoard } = useClipboard()

  const createToast = (description = 'Link Copied.', error = false) => {
    Toast.show({
      icon: error ? 'error' : 'check',
      message: toastMessage || description,
    })
    if (toastMessage) {
      setToastMessage(undefined)
    }
  }

  const handleLinkButtonClick = async () => {
    if (!node?.id) return createToast('Shared link not created', true)
    copyToClipBoard(`${CONFIG.APP.URL}/link/${node.id}`)
    setLinkCopied(true)
  }

  useEffect(() => {
    if (linkCopied === true) {
      const timer = setTimeout(() => {
        setLinkCopied(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [linkCopied])

  return (
    <motion.div
      layout
      key="shareModal"
      aria-label="Share"
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{
        type: 'ease',
        duration: 0.7,
        opacity: { delay: 0.7 },
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '.5rem' : '1.5rem',
        width: '100%',
      }}
    >
      <Flex flexDir="column" gap={3} w="full">
        <Text color="textBlack" mx={6}>
          Link Access
        </Text>
        <Flex w="full" gap={4}>
          <Flex
            border="1px solid #E9EAEC"
            h="46px"
            w="11rem"
            align="center"
            justify="space-between"
            px={6}
            bg={linkCopied ? 'accent' : 'transparent'}
            transition="background-color 200ms linear"
            onClick={handleLinkButtonClick}
            cursor="pointer"
            rounded="md"
          >
            <Text
              fontWeight={linkCopied ? 600 : 400}
              color={linkCopied ? 'white' : 'accent'}
              transition="color 200ms linear"
            >
              {linkCopied ? 'Link copied.' : 'Copy Link'}
            </Text>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.53454 5.30691C2.91732 5.68969 2.91732 6.31031 2.53454 6.69309C1.76897 7.45866 1.76897 8.69989 2.53454 9.46546C3.25981 10.1907 4.412 10.2289 5.18221 9.57998L5.3924 9.3893C5.77714 9.08463 6.33765 9.11002 6.69309 9.46546C7.07588 9.84825 7.07588 10.4689 6.69309 10.8516C5.16195 12.3828 2.67949 12.3828 1.14835 10.8516C-0.382784 9.32051 -0.382784 6.83805 1.14835 5.30691C1.53114 4.92412 2.15175 4.92412 2.53454 5.30691ZM7.38618 4.61382C7.76897 4.9966 7.76897 5.61722 7.38618 6L6 7.38618C5.61722 7.76897 4.9966 7.76897 4.61382 7.38618C4.23103 7.0034 4.23103 6.38278 4.61382 6L6 4.61382C6.38278 4.23103 7.0034 4.23103 7.38618 4.61382ZM10.8516 1.14835C12.3828 2.67949 12.3828 5.16195 10.8516 6.69309C10.4689 7.07588 9.84825 7.07588 9.46546 6.69309C9.08268 6.31031 9.08268 5.68969 9.46546 5.30691C10.231 4.54134 10.231 3.30011 9.46546 2.53454C8.74019 1.80926 7.588 1.77109 6.81779 2.42002L6.6076 2.6107C6.22286 2.91537 5.66235 2.88998 5.30691 2.53454C4.92412 2.15175 4.92412 1.53114 5.30691 1.14835C6.83805 -0.382784 9.32051 -0.382784 10.8516 1.14835Z"
                fill={linkCopied ? '#fff' : '#3B3B3B'}
              />
            </svg>
          </Flex>

          <Button
            variant={'unstyled'}
            w="46px"
            h="46px"
            display={'grid'}
            placeContent={'center'}
            rounded={'md'}
            border="1px solid #E9EAEC"
          >
            <Image src={images.ellipsesBlack} />
          </Button>
        </Flex>
        <Text color="textBlack" mx={6} fontSize={14} fontWeight={400}>
          Only users within this project can access
        </Text>
      </Flex>
    </motion.div>
  )
}

export default ShareModalLinkTab
