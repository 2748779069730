/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState, useEffect } from 'react'
import { Flex, ModalBody, ModalFooter, Text } from '@chakra-ui/react'
import { TextInput, Button, FileInput } from 'components'
import { imageExts } from 'helpers/constant'

interface UpdateModalProps {
  placeholder: string
  label: string
  startValue: string
  onUpdate: (updatedValue: any) => void
  onCancel: () => void
  isImage?: boolean
  image?: any
  name?: string
}
const UpdateModal = ({
  placeholder,
  label,
  startValue,
  onCancel,
  onUpdate,
  isImage,
  image,
  name,
}: UpdateModalProps) => {
  const [inputValue, setInputValue] = useState(startValue)
  const [coverImage, setCoverImage] = useState<File>()
  const [imgData, setImgData] = useState<any>(undefined)
  const [warning, setWarning] = useState('')

  useEffect(() => {
    ;(async () => {
      setImgData(image) // TODO: download and set actual image
    })()
  }, [image])

  const handleChange = ({ target: { value } }: IInputParams) => {
    setInputValue(value)
  }

  const handleUpdate = () => {
    if (isImage) {
      onUpdate(coverImage)
    } else {
      onUpdate({ name: inputValue })
    }
  }

  const onChangePicture = (pictureFile: File) => {
    if (pictureFile) {
      const extension = pictureFile.name.split('.').pop()?.toLowerCase() || ''
      const isSuccess = imageExts.indexOf(extension) > -1
      if (isSuccess) {
        setCoverImage(pictureFile)
        setWarning('')
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          setImgData(reader.result)
        })
        reader.readAsDataURL(pictureFile)
      } else {
        setWarning('Uploading file is not an image')
        setImgData(undefined)
        setCoverImage(undefined)
      }
    }
  }

  return (
    <ModalBody roundedBottom={8}>
      <Text mb={1} fontSize={[12, null, null, 16]} fontWeight="600" color="inherit">
        {label}
      </Text>
      {isImage ? (
        <FileInput
          onSelectFile={onChangePicture}
          name={name || 'updateModalImage'}
          placeHolder={placeholder}
          file={coverImage}
          preview={imgData}
        />
      ) : (
        <TextInput placeholder={placeholder} value={inputValue} autoFocus handleChange={handleChange} />
      )}
      <Text pt={3} variant="errorText">
        {warning}
      </Text>
      <ModalFooter w="full" px={0} py={6}>
        <Flex justify="flex-end" w={['full', null, null, 'auto']}>
          <Button label="Cancel" variant="btnOutline" onClick={onCancel} style={{ marginRight: 4 }} />
          <Button label="Save" variant="defaultButton" onClick={handleUpdate} style={{ minWidth: '55%' }} />
        </Flex>
      </ModalFooter>
    </ModalBody>
  )
}

export default UpdateModal
