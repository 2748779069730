import { Input, InputGroup, InputRightElement, Tooltip } from '@chakra-ui/react'

type InputSettingsProps = {
  inputKey: string
  value: string
  type?: 'text' | 'password'
  tooltipLabel: string
}

const DisabledSettingsInput = ({ inputKey, value, type = 'text', tooltipLabel }: InputSettingsProps) => {
  return (
    <Tooltip label={tooltipLabel} bg={'black'} shouldWrapChildren mt="3">
      <InputGroup>
        <Input value={value} variant={'modal'} type={type} isDisabled={true} />
        <InputRightElement
          pr={'16px'}
          fontSize={'12px'}
          width={'default'}
          color={'tipbox.dark.text.greyLight'}
          fontWeight={500}
          cursor={'not-allowed'}
          opacity={0.75}
        >
          Change {inputKey}
        </InputRightElement>
      </InputGroup>
    </Tooltip>
  )
}

export default DisabledSettingsInput
