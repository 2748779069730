/* eslint-disable array-callback-return */
import { Flex } from '@chakra-ui/react'
import { useAppSelector } from 'hooks'
import { Breadcrumb } from './components'
import { HeaderActions } from '../HeaderActions'

interface SubHeaderProps {
  openModal: string
  setOpenModal: (openModal: string) => void
  hideActions?: boolean
}

const SubHeader = ({ openModal, setOpenModal, hideActions = false }: SubHeaderProps) => {
  const { currentTip } = useAppSelector((state) => state.tip)

  return (
    <Flex
      flexDir="column"
      w="full"
      pl={[4, null, null, '30px', currentTip ? 6 : 0]}
      bg={currentTip ? 'transparent' : 'background'}
      className={`subHeader${currentTip ? ' currentTip' : ''}`}
      zIndex={5}
    >
      <Flex flexDir="row" w="full" my="20px">
        <Flex align="center" w="full">
          <Breadcrumb />
        </Flex>
        {!hideActions && (
          <Flex justify="flex-end" h="full" align="center">
            <HeaderActions openModal={openModal} setOpenModal={setOpenModal} hideActions={hideActions} />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default SubHeader
