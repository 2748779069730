import { Flex } from '@chakra-ui/react'
import { AvatarsTooltip } from 'components'

const FlowFormParticipantsLists = ({
  flowType,
  setRecipientType,
  setShowEditPane,
  recipientsToList,
  watchersToList,
}) => {
  // Create Tooltip captions
  const formatAvatarsTooltipCaption = () => {
    let type = ''
    if (flowType === 'Approval') {
      type = 'Approver'
    }
    if (flowType === 'Feedback') {
      type = 'Input Provider'
    }
    const checkPlural = recipientsToList && recipientsToList.length > 1 ? 's' : ''

    return `${type}${checkPlural}`
  }

  if ((!recipientsToList || recipientsToList?.length === 0) && (!watchersToList || watchersToList?.length === 0))
    return null

  return (
    <Flex id="Flow Participant Lists" flexDir={'column'} px={6} py={2} gap={2}>
      {!!recipientsToList?.length && (
        <AvatarsTooltip
          users={recipientsToList}
          caption={formatAvatarsTooltipCaption()}
          clickAction={() => {
            if (flowType === 'Approval') {
              setRecipientType('approver')
            }
            if (flowType === 'Feedback') {
              setRecipientType('feedbackProvider')
            }
            setShowEditPane(true)
          }}
        />
      )}
      {!!watchersToList?.length && (
        <AvatarsTooltip
          users={watchersToList}
          caption={'In The Loop'}
          clickAction={() => {
            setRecipientType('watcher')
            setShowEditPane(true)
          }}
        />
      )}
    </Flex>
  )
}

export default FlowFormParticipantsLists
