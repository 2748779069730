import { Flex, Image, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import { images } from 'theme'
import { useDragging } from 'hooks'

function DragAndDropDisplay() {
  const { isDragging } = useDragging()
  const isMobile = useBreakpointValue({ xs: true, md: false, lg: false })
  if (isMobile || !isDragging) return null
  return (
    <Flex
      flexDir="column"
      align="center"
      justify="center"
      bg="black"
      pos="fixed"
      left={0}
      top={0}
      right={0}
      bottom={0}
      zIndex={99}
    >
      <Image src={images.dragAndDrop} w="177px" mb="48px" alt="drag and drop" />
      <Heading fontFamily="Inter" fontSize="64px" mb={0} color="accentIcon">
        Drop It Like It’s Hot
      </Heading>
      <Text fontFamily="Inter" fontSize="40px">
        Drop any item here.
      </Text>
    </Flex>
  )
}

export default DragAndDropDisplay
