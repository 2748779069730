import { gql } from '@apollo/client'

const boxUser = `
  id
  firstName
  lastName
  avatar
`
const shareMember = `
  id
  sharedBy {
    id
    firstName
    lastName
    email
    avatar
  }
  sharedWith {
    id
    firstName
    lastName
    email
    avatar
    avatarSecureUrl
  }
  sharedWithId
  permission {
    id
    name
    permissions {
      permissionName
      entityType
      allowed
    }
  }
  permissionId
  isPublic
  entityType
  entityId
`

const linkShare = `
  randomPath
  linkType
  expiryDate
  hasPassword
  permissionType
`

const children = `
  id
  name
  __typename
  type
  coverImage
  coverImageSecureUrl
  totalSize
  private
  s3Key
  createdAt
  updatedAt
  extension
  preview
  previewSecureUrl
  updatedBy {
    ${boxUser}
  }
  owner {
    ${boxUser}
  }
  project {
    id
    name
  }
  parent {
    id
    name
    type
    private
  }
  sharedMembers {
    ${shareMember}
  }
  linkShare {
    ${linkShare}
  }
  breadcrumbs {
    name
    id
    type
  }
`

const boxOutput = `
  id
  updatedAt
  createdAt
  name
  type
  coverImage
  coverImageSecureUrl
  totalSize
  children {
    ${children}
  }
  owner {
    ${boxUser}
  }
  updatedBy {
    ${boxUser}
  }
  project {
    id
    name
  }
  parent {
    id
    name
    type
    private
  }
  sharedMembers {
    ${shareMember}
  }
  linkShare {
    ${linkShare}
  }
  breadcrumbs {
    name
    id
    type
  }
`

// ---------------------------
// QUERIES
// ---------------------------

// ---------------------------
// MUTATIONS
// ---------------------------

export const MOVE_BOX = gql`
  mutation MoveNode($resourceId: ID!, $destinationId: ID!) {
    moveNode(resourceId: $resourceId, destinationId: $destinationId) {
      id
    }
  }
`
