import {
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Dispatch } from 'react'
import { images } from 'theme'

interface FlowFormRecipientTypeProps {
  recipientType: string
  flowType: string
  setRecipientType: Dispatch<React.SetStateAction<string>>
}

const FlowFormRecipientType = ({ recipientType, flowType, setRecipientType }: FlowFormRecipientTypeProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <Flex flexDir="column" gap={3} minW="max" maxW="max" py={2}>
      <Flex>
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-start">
          <PopoverTrigger>
            <button style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
              <Text color={'accent'} fontWeight={'500'}>
                {recipientType === 'watcher' && 'In The Loop'}
                {recipientType === 'approver' && 'Approver'}
                {recipientType === 'feedbackProvider' && 'Input Provider'}
              </Text>
              <Image src={images.chevronRedDown} alt="down arrow" />
            </button>
          </PopoverTrigger>
          <PopoverContent maxW={'max-content'} border={'none'}>
            <PopoverBody
              borderColor="gray.400"
              shadow={'md'}
              borderWidth={1}
              borderStyle={'solid'}
              bg={'white'}
              color={'textBlack'}
              display={'flex'}
              pr={8}
              py={4}
              gap={2}
              flexDir={'column'}
              alignItems={'flex-start'}
              fontWeight={'400'}
              rounded={'md'}
              minW="max"
            >
              <button
                onClick={() => {
                  if (flowType === 'Approval') {
                    setRecipientType('approver')
                  }
                  if (flowType === 'Feedback') {
                    setRecipientType('feedbackProvider')
                  }
                  onClose()
                }}
              >
                {flowType === 'Approval' && (
                  <Text color="textBlack" fontWeight={400}>
                    Approver <span style={{ color: 'var(--chakra-colors-textBlack)' }}>- users to approve</span>
                  </Text>
                )}
                {flowType === 'Feedback' && (
                  <Text color="textBlack" fontWeight={400}>
                    Input Provider{' '}
                    <span style={{ color: 'var(--chakra-colors-textBlack)' }}>- users to submit input</span>
                  </Text>
                )}
              </button>
              <button
                onClick={() => {
                  setRecipientType('watcher')
                  onClose()
                }}
              >
                <Text color="textBlack" fontWeight={400}>
                  In The Loop <span style={{ color: 'var(--chakra-colors-textBlack)' }}>- keep others informed</span>
                </Text>
              </button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Flex>
  )
}

export default FlowFormRecipientType
