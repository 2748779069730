import { Flex, useBreakpointValue, Text } from '@chakra-ui/react'
import { DueDatePopover } from 'components'
import { Dispatch } from 'react'

interface DueByButtonsProps {
  flowState: FlowState
  setFlowState: Dispatch<React.SetStateAction<FlowState>>
}

const FlowFormDueBy = ({ flowState, setFlowState }: DueByButtonsProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })

  return (
    <Flex
      w="full"
      aria-label="Due Date"
      alignItems={isMobile ? 'flex-start' : 'center'}
      flexDir={isMobile ? 'column' : 'row'}
    >
      <Text ml={isMobile ? 0 : 6} mr="45px" color="textBlack">
        * Due On:
      </Text>
      <Flex
        w="full"
        flex={1}
        gap={{ base: '.5rem', md: '1.75rem' }}
        alignItems={'center'}
        mb={{ base: '.5rem', md: 0 }}
      >
        <DueDatePopover flowState={flowState} onChange={(a) => setFlowState(a)} isRequired />
        {/* Time Option - Disabling for now */}
        {/* <DueDatePopover flowState={flowState} onChange={(a) => setFlowState(a)} timeOnly /> */}
      </Flex>
    </Flex>
  )
}

export default FlowFormDueBy
