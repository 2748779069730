import { ChangeEvent, useRef, useState } from 'react'
import { Text, Grid, Box, Flex, FormControl, InputGroup, InputProps, BoxProps } from '@chakra-ui/react'
import ImageResize from 'components/fragments/ImageResize'

interface FileInputProps extends InputProps {
  name: string
  placeHolder: string | JSX.Element
  file: File | undefined
  onSelectFile: (file: File) => void
  onCancelFile?: () => void
  preview: string | undefined
  noPreviewFileName?: boolean
  styleProps?: BoxProps
  locked?: boolean
}

const FileInput = ({
  name,
  placeHolder,
  file,
  onSelectFile,
  onCancelFile,
  preview,
  noPreviewFileName,
  styleProps,
  locked = true,
}: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [hovering, setHovering] = useState(false)
  const [resizeModal, setResizeModal] = useState(false)
  const isInputImage = name === 'avatar' || name === 'projectCover' || name === 'boxCover' || name === 'tipCover'
  // name === 'updateModalImage'

  const onFileInputChange = (e: ChangeEvent): void => {
    const input = e?.target as HTMLInputElement
    if (input && input.files) {
      if (isInputImage) {
        onSelectFile(input.files[0])
        setResizeModal(true)
      } else {
        onSelectFile(input.files[0])
      }
      input.value = ''
    }
  }

  const onResizeSave = (croppedFile: File) => {
    setResizeModal(false)
    onSelectFile(croppedFile)
  }

  return (
    <FormControl>
      <Box
        w="full"
        border="1px"
        borderStyle="dashed"
        borderColor={hovering ? 'accent' : 'textBlack'}
        rounded={8}
        mt={3}
        h="48.33px"
        overflow="hidden"
        {...styleProps}
        onClick={() => inputRef.current?.click()}
        onMouseEnter={() => {
          setHovering(true)
        }}
        onMouseLeave={() => {
          setHovering(false)
        }}
      >
        <InputGroup h="full">
          <input
            type="file"
            name={name}
            ref={inputRef}
            onChange={onFileInputChange}
            style={{ display: 'none' }}
            accept={isInputImage ? 'image/png, image/gif, image/jpeg' : '*'}
          />
          {preview ? (
            <Grid
              templateColumns={file ? 'minmax(79px, auto)' : 'auto'}
              gridAutoFlow="column"
              gridAutoColumns="1fr"
              maxW="full"
              w="full"
              overflowX="hidden"
            >
              <Box bgImage={preview} w="full" h="full" bgSize="cover" />
              {!noPreviewFileName && (
                <Flex align="center" overflowX="hidden">
                  <Text pl={4} color={hovering ? 'accent' : 'textRegular'} noOfLines={1}>
                    {file ? file.name : placeHolder}
                  </Text>
                </Flex>
              )}
            </Grid>
          ) : (
            <Flex flex="1" align="center">
              <Text
                as="span"
                width="100%"
                color={hovering ? 'accent' : 'textBlack'}
                p={2}
                fontSize={[12, null, null, 16]}
              >
                {placeHolder}
              </Text>
            </Flex>
          )}
        </InputGroup>
        {resizeModal && (
          <ImageResize
            kind={name}
            isOpen={resizeModal}
            imageFile={file}
            onCancel={() => {
              if (onCancelFile) onCancelFile()
              setResizeModal(false)
            }}
            onSave={onResizeSave}
            locked={locked}
          />
        )}
      </Box>
    </FormControl>
  )
}

export default FileInput
