import { gql } from '@apollo/client'

// ---------------------------
// QUERIES
// ---------------------------

export const GET_MY_NOTIFICATION_PREFERENCES = gql`
  query myNotificationPreferences {
    myNotificationPreferences {
      id
      eventType
      channels
      userId
    }
  }
`

export const NOTIFICATIONS = gql`
  query notifications($count: Int) {
    notifications(count: $count) {
      notifications {
        id
        senderName
        receiverName
        senderAvatar
        receiverName
        receiverId
        type
        shareType
        comment
        nodeId
        nodeName
        nodeType
        read
        createdAt
        updatedAt
        secondaryReceiverId
        follow
      }
    }
  }
`

// tip comment count
export const NOTIFICATION_COMMENT_TIP = gql`
  query notificationsTipCount($tipId: ID, $type: String) {
    notificationsTipCount(tipId: $tipId, type: $type)
  }
`

// Project/Box comment count
export const NOTIFICATION_NODE_COMMENT_TIPS_COUNT = gql`
  query notificationsNodeCount($nodeId: ID, $isTip: Boolean) {
    notificationsNodeCount(nodeId: $nodeId, isTip: $isTip)
  }
`

// notification menu count
export const NOTIFICATION_USER_UNREAD_COUNT = gql`
  query notificationUnreadCount {
    notificationUnreadCount
  }
`

export const GET_FLOW_REMINDERS = gql`
  query GetFlowReminders($flowId: ID!) {
    getFlowReminders(flowId: $flowId) {
      id
      payload
      receiverId
      sender {
        id
        email
        firstName
        lastName
        avatar
        avatarSecureUrl
      }
      receiver {
        id
        email
        firstName
        lastName
        avatar
        avatarSecureUrl
      }
      type
      dateToSend
      createdAt
      updatedAt
    }
  }
`

// ---------------------------
// MUTATIONS
// ---------------------------

export const UPDATE_MY_NOTIFICATION_PREFERENCE = gql`
  mutation updateMyNotificationPreference($input: NotificationPreferenceInput) {
    updateMyNotificationPreference(input: $input) {
      user {
        notificationsPreferences {
          eventType
          channels
        }
      }
    }
  }
`

export const NOTIFICATIONS_AS_READ = gql`
  mutation allNotificationsRead {
    allNotificationsRead {
      notifications {
        id
        senderName
        receiverName
        senderAvatar
        receiverName
        receiverId
        type
        shareType
        comment
        nodeId
        nodeName
        nodeType
        read
        createdAt
        updatedAt
        secondaryReceiverId
      }
    }
  }
`

export const FOLLOW_NOTIFICATIONS_AS_READ = gql`
  mutation allFollowNotificationsRead {
    allFollowNotificationsRead {
      notifications {
        id
        senderName
        receiverName
        senderAvatar
        receiverName
        receiverId
        type
        shareType
        comment
        nodeId
        nodeName
        nodeType
        read
        createdAt
        updatedAt
        secondaryReceiverId
      }
    }
  }
`

export const NOTIFICATION_AS_READ = gql`
  mutation readNotification($id: ID!) {
    notificationRead(id: $id) {
      id
      senderName
      receiverName
      senderAvatar
      receiverName
      receiverId
      type
      shareType
      comment
      nodeId
      nodeName
      nodeType
      read
      createdAt
      updatedAt
      secondaryReceiverId
    }
  }
`

export const SET_FLOW_INTERVAL_REMINDERS = gql`
  mutation SetFlowIntervalReminders($flowId: ID!, $frequency: Int!) {
    setFlowIntervalReminders(flowId: $flowId, frequency: $frequency)
  }
`

export const CANCEL_FLOW_REMINDERS_FROM_RECEIVER = gql`
  mutation CancelFlowRemindersFromReceiver($flowId: ID!) {
    cancelFlowRemindersFromReceiver(flowId: $flowId)
  }
`
