import { Button, Text, Image, Box, Fade } from '@chakra-ui/react'
import { useState } from 'react'

interface NavMenuButtonProps {
  activeButton: string
  image: string
  highlightImage: string
  label?: string | React.ReactNode | null
  path: string
  onClick: () => void
  isPersonal?: boolean
  isInPersonal?: boolean
  isFlows?: boolean
  flowsCount?: number
  showFlowsCount?: boolean
}

const NavMenuButton = ({
  label,
  path,
  onClick,
  image,
  highlightImage,
  activeButton,
  isFlows = false,
  isPersonal = false,
  isInPersonal = false,
  flowsCount,
  showFlowsCount,
}: NavMenuButtonProps) => {
  const [imageUrl, setImageUrl] = useState<string>(image)

  return (
    <Box ml="2px">
      <Button
        variant={activeButton === path || (isInPersonal && isPersonal) ? 'navMenuButtonSelect' : 'navMenuButton'}
        onClick={onClick}
        _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
        onMouseEnter={() => setImageUrl(highlightImage)}
        onMouseLeave={() => setImageUrl(image)}
        borderLeft="2px solid"
        borderColor={activeButton === path || (isInPersonal && isPersonal) ? 'accentIcon' : 'transparent'}
      >
        {image && <Image src={activeButton === path ? highlightImage : imageUrl} w={5} h={5} mr="14px" />}
        <Text fontSize="inherit" color="inherit">
          {label}
        </Text>
        {isFlows && (
          <Fade in={showFlowsCount} unmountOnExit>
            <Text color="textHighlight" position="absolute" top="50%" right="2.6rem" transform="translateY(-50%)">
              {flowsCount === 0 ? '' : flowsCount}
            </Text>
          </Fade>
        )}
      </Button>
    </Box>
  )
}

export default NavMenuButton
