import React from 'react'
import { Breadcrumb, BreadcrumbItem, Text, Icon } from '@chakra-ui/react'
import { icons } from 'theme'

interface MoveToBreadcrumbsProps {
  clickAction: (type: string, item: any, index: number) => void
  breadcrumbs: { label: string; type: string; item: any }[]
}

const MoveToBreadcrumbs = ({ clickAction, breadcrumbs }: MoveToBreadcrumbsProps) => (
  <Breadcrumb
    spacing="8px"
    separator={<Icon as={icons.FaChevronRight} h={4} w={4} color="textRegular" />}
    fontFamily="Inter"
    fontWeight={['600', null, null, '500']}
    fontSize={16}
    alignContent="center"
    w="full"
    maxW={['100%', null, null, '90%']}
    overflowX="hidden"
  >
    {breadcrumbs.map((crumb, index) => (
      <BreadcrumbItem
        key={crumb.item.id}
        onClick={() => {
          clickAction(crumb.type, crumb.item, index)
        }}
      >
        <Text color={index === breadcrumbs.length - 1 ? 'textBlack' : 'textRegular'} fontWeight="600">
          {crumb.label}
        </Text>
      </BreadcrumbItem>
    ))}
  </Breadcrumb>
)

export default MoveToBreadcrumbs
