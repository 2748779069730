import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { client as apollo } from 'helpers/apollo'
import { AppDispatch, RootState } from 'helpers/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  NOTIFICATIONS_AS_READ,
  NOTIFICATION_AS_READ,
  FOLLOW_NOTIFICATIONS_AS_READ,
  NOTIFICATIONS,
} from './notification.schema'

// ---------------------------------------
// Thunks
// ---------------------------------------

const getUserNotifications = createAsyncThunk('notification/getUserNotifications', async (count: number) => {
  try {
    const {
      data: {
        notifications: { notifications },
      },
    } = await apollo.query({
      query: NOTIFICATIONS,
      variables: { count },
    })

    return notifications
  } catch (error: any) {
    throw new Error(error)
  }
})

const notificationAsRead = createAsyncThunk('notification/notificationAsRead', async (id: string) => {
  try {
    const { data: notificationRead } = await apollo.mutate({
      mutation: NOTIFICATION_AS_READ,
      variables: { id },
    })
    return notificationRead.notificationRead
  } catch (error: any) {
    throw new Error(error)
  }
})

const allNotificationsAsRead = createAsyncThunk('notification/notificationAsRead', async () => {
  try {
    const {
      data: { allNotificationsRead },
    } = await apollo.mutate({
      mutation: NOTIFICATIONS_AS_READ,
    })
    return allNotificationsRead
  } catch (error: any) {
    throw new Error(error)
  }
})

const allFollowNotificationsAsRead = createAsyncThunk('notification/notificationAsRead', async () => {
  try {
    const {
      data: { allFollowNotificationsRead },
    } = await apollo.mutate({
      mutation: FOLLOW_NOTIFICATIONS_AS_READ,
    })
    return allFollowNotificationsRead
  } catch (error: any) {
    throw new Error(error)
  }
})

const initialState = {
  error: null,
  notifications: [],
}

const notificationSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {},
  // extraReducers: (builder) => {
  // },
})

export function useNotificationSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.notification)
  return {
    dispatch,
    ...state,
    ...notificationSlice.actions,
    allNotificationsAsRead,
    allFollowNotificationsAsRead,
    getUserNotifications,
    notificationAsRead,
  }
}

export default notificationSlice.reducer
