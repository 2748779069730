import React, { useState, useEffect } from 'react'
import { Grid, Box, Icon, Text, Flex, Image } from '@chakra-ui/react'
import { getResizedImage } from 'helpers/storage'
import { icons, images } from 'theme'
import { IconType } from 'react-icons'

interface MoveToItemProps {
  icon?: IconType | any
  label: string
  image?: string
  clickAction: () => void
}

const MoveToItem = ({ icon, label, image, clickAction }: MoveToItemProps) => {
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    let active = true
    if (!active) return undefined
    if (!image) return undefined
    setImageUrl(getResizedImage(image, 'project.list_view'))

    return () => {
      active = false
    }
  }, [image])

  const renderThumb = () => {
    if (image) {
      return <Box w="full" h="full" bgImage={imageUrl} backgroundSize="100%" bgRepeat="no-repeat" bgSize="cover" />
    } else if (icon) {
      return (
        <Flex justify="center" align="center" h="full" w="full" bg="textSoft">
          <Icon as={icon} w="22px" h="22px" />
        </Flex>
      )
    }
    return <Image src={images.folder} alt="Box Logo" />
  }

  return (
    <Box
      as="button"
      border="1px"
      rounded={8}
      borderColor="borderRegular"
      overflow="hidden"
      w="full"
      onClick={clickAction}
      mb={2}
      _hover={{ borderColor: 'accent' }}
    >
      <Grid gridTemplateColumns="56px auto 56px" h="56px" w="full">
        <Flex align="center" justify="center" w="full" h="full">
          {renderThumb()}
        </Flex>
        <Flex align="center" h="full" px={4}>
          <Text fontWeight="600" color="textBlack">
            {label}
          </Text>
        </Flex>
        <Flex align="center" justify="center" h="full" w="full">
          <Icon as={icons.FaChevronRight} w="18px" h="18px" />
        </Flex>
      </Grid>
    </Box>
  )
}

export default MoveToItem
