import React from 'react'
import { Spinner } from '@chakra-ui/react'

type LoaderProps = {
  speed?: string
  emptyColor?: string
  color?: string
  size?: string
  marginBottom?: string | number
}

const Loader = ({ speed = '0.65s', emptyColor = 'gray.200', color, size, marginBottom = 4 }: LoaderProps) => (
  <Spinner speed={speed} emptyColor={emptyColor} mb={marginBottom} color={color} size={size} />
)

export default Loader
