import React from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Box, Flex, IconButton, useBreakpointValue } from '@chakra-ui/react'
import { SkeletonProjectCard } from 'components'
import { icons } from 'theme'
import { ProjectCard } from 'features/project/components'
import { Node } from 'types/graphqlSchema'

interface SubheaderCarouselProps {
  projects: IProject[] | Node[]
  isLoading: boolean
  pageType: string
}

const Arrow = (props) => {
  const { onClick, postionRight, left, postionLeft, positionTop, className } = props
  return (
    <Box display={className.includes('slick-disabled') ? 'none' : 'block'}>
      <IconButton
        aria-label="carousel button"
        colorScheme="tipbox.primary"
        variant="carouselBtn"
        onClick={onClick}
        icon={left ? <icons.FaChevronLeft /> : <icons.FaChevronRight />}
        style={{
          position: 'absolute',
          left: `${postionLeft}`,
          right: `${postionRight}`,
          zIndex: 2,
          top: `${positionTop}`,
        }}
      />
    </Box>
  )
}

const SubheaderCarousel = ({ projects, isLoading, pageType }: SubheaderCarouselProps) => {
  const isMobile = useBreakpointValue({ xs: false, lg: true })
  const settings: Settings = {
    arrows: isMobile,
    swipe: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    variableWidth: true,
    prevArrow: <Arrow left positionLeft={0} positionTop="30%" />,
    nextArrow: <Arrow postionRight={0} left={false} positionTop="30%" />,
  }

  return (
    <Box pos="absolute" left={0} right={0} top={0} bottom={0} w="full">
      {isLoading ? (
        <Flex as={Slider} {...settings} pos="relative">
          <Box
            maxWidth={['calc(90vw + 16px)', null, '15rem']}
            minW={['calc(90vw + 16px)', null, '15rem']}
            pr={4}
            pl={[4, null, null, 0]}
          >
            <SkeletonProjectCard />
          </Box>
          <Box maxWidth={['90vw', null, '15rem']} minW={['90vw', null, '15rem']} pr={4}>
            <SkeletonProjectCard />
          </Box>
          <Box maxWidth={['90vw', null, '15rem']} minW={['90vw', null, '15rem']} pr={4}>
            <SkeletonProjectCard />
          </Box>
          <Box maxWidth={['90vw', null, '15rem']} minW={['90vw', null, '15rem']} pr={4}>
            <SkeletonProjectCard />
          </Box>
        </Flex>
      ) : (
        <Flex as={Slider} {...settings} mx={[0, null, null, 'auto']} h="auto" w="inherit" maxW="inherit" minW="inherit">
          {projects.map((project, index) => (
            <Box
              key={project.id}
              pl={index === 0 ? [4, null, null, 0] : [0]}
              pr={4}
              maxW="16rem"
              minW="16rem"
              h="16rem"
            >
              <ProjectCard projectInfo={project} alerts={0} pageType={pageType} noBorder />
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  )
}

export default SubheaderCarousel
