import React, { forwardRef } from 'react'
import { IconType } from 'react-icons'
import { Input, InputGroup, InputRightElement, InputLeftElement, Icon, useColorModeValue } from '@chakra-ui/react'
import { IconButton } from 'components'
import { colors } from 'theme'

interface TextInputProps {
  placeholder: string
  value: string
  isDisabled?: boolean
  rightIcon?: IconType | boolean
  leftIcon?: IconType
  rightIconVariant?: string
  rightIconOnClick?: any
  maxWidth?: (string | null)[]
  handleChange: ({ target: { name, value } }: IInputParams) => void
  handleKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
  handleOnBlur?: () => void
  inputRightPadding?: string
  autoFocus?: boolean
  isSaveModal?: boolean
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      isSaveModal,
      placeholder,
      value,
      isDisabled,
      leftIcon,
      rightIconVariant = 'passwordBtn',
      rightIconOnClick,
      rightIcon,
      maxWidth = ['full'],
      handleOnBlur,
      handleChange,
      handleKeyDown,
      inputRightPadding = 4,
      autoFocus = false,
    },
    ref,
  ) => {
    return (
      <InputGroup maxW={maxWidth} bg="textHighlight">
        {leftIcon && (
          <InputLeftElement pointerEvents="none">
            <Icon as={leftIcon} />
          </InputLeftElement>
        )}
        <Input
          placeholder={placeholder}
          value={value}
          disabled={isDisabled}
          borderRadius={8}
          autoFocus={autoFocus}
          ref={ref}
          onBlur={handleOnBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          pr={inputRightPadding}
          color={isSaveModal ? 'cardBackground' : 'textBlack'}
          borderColor={isSaveModal ? '#cccccc' : 'borderRegular'}
          backgroundColor={isSaveModal ? '#E9EAEC' : 'textHighlight'}
        />
        {rightIcon && (
          <InputRightElement>
            <IconButton
              variant={rightIconVariant}
              onClick={rightIconOnClick}
              icon={rightIcon}
              ariaLabel={`${rightIcon}`}
            />
          </InputRightElement>
        )}
      </InputGroup>
    )
  },
)

export default TextInput
