import React from 'react'
import { Avatar, Box, Divider, Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import { getResizedImage } from 'helpers/storage'
import { useAppSlice, useUserSettingsSlice } from 'features/redux'
import { BasicSettings } from './BasicSettings'
import { AdvanceSettings } from './AdvanceSettings'

export const AccountsTab = () => {
  const { user } = useAppSlice()
  const isMobile = useBreakpointValue({ xs: true, lg: false })

  const { dispatch, showChangePasswordPanel, showAvatarChangePanel, setShowAvatarChangePanel, setShowModalBody } =
    useUserSettingsSlice()

  const handleClickEditAvatar = () => {
    dispatch(setShowAvatarChangePanel(true))
    dispatch(setShowModalBody(false))
  }

  return (
    <>
      {!showChangePasswordPanel && !showAvatarChangePanel && (
        <Flex
          id={'flex-content-container'}
          w="full"
          direction={'row'}
          justify={'space-between'}
          fontSize={'14px'}
          color={'tipbox.dark.text.black'}
        >
          <Box mb={3}>
            <CustomAvatar
              name={user.avatarSecureUrl ? '' : `${user.firstName} ${user.lastName}`}
              size={isMobile ? 'md' : '85px'}
              src={user?.avatarSecureUrl ? getResizedImage(user.avatarSecureUrl, 'profile.account') : '#'}
            />
            <Text
              textAlign={'center'}
              color={'greyLight'}
              cursor={'pointer'}
              onClick={handleClickEditAvatar}
              mt={'6px'}
              _hover={{ textDecoration: 'underline', color: 'accent' }}
            >
              Edit
            </Text>
          </Box>
          <Flex direction={'column'} w={'79%'} gap={'0.75rem'}>
            <BasicSettings />
            <Divider style={{ borderColor: '#E9EAEC' }} />
            <AdvanceSettings />
            <Divider style={{ borderColor: '#E9EAEC' }} />
          </Flex>
        </Flex>
      )}
    </>
  )
}

const CustomAvatar = ({ size, ...props }) => {
  return (
    <Box boxSize={size}>
      <Avatar boxSize="100%" {...props} />
    </Box>
  )
}
