import React, { useMemo } from 'react'

// Components
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  Box,
  Flex,
  Heading,
  useBreakpointValue,
} from '@chakra-ui/react'
import { IconButton } from 'components'
import { icons } from 'theme'

type ActionModalProps = {
  title?: string
  title2?: string
  size?: string
  titleAlignCenter?: boolean
  isOpen?: boolean
  onClose?: () => void
  children?: JSX.Element | any
  goBack?: () => void
  isUsedForTutorial?: boolean
  style?: React.CSSProperties
  variant?: string
}
const ActionModal = ({
  title,
  title2,
  size,
  titleAlignCenter = false,
  isOpen = false,
  onClose = () => {},
  children,
  goBack,
  isUsedForTutorial = false,
  style,
  variant,
}: ActionModalProps) => {
  const mobile = useBreakpointValue({ xs: true, md: false })
  const modalSize = useMemo(() => {
    if (size) return size
    return isUsedForTutorial ? 'sm' : mobile ? 'full' : '2xl'
  }, [size])

  // Render different headers for the Action Modal
  const header = (
    <Box>
      {goBack && !mobile ? (
        <Flex w="full" align="center">
          <IconButton variant="iconBtnSm" onClick={goBack} ariaLabel="go back" icon={icons.backArrow} />
          <Heading w="94%" textAlign="center">
            {title}
          </Heading>
        </Flex>
      ) : (
        <Box w="85%">
          <Heading as="h1" fontSize={[16, null, null, 24]}>
            {title}
          </Heading>
          {title2 && (
            <Heading as="h1" fontSize={[16, null, null, 24]}>
              {title2}
            </Heading>
          )}
        </Box>
      )}
    </Box>
  )
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isUsedForTutorial || !mobile}
      size={modalSize}
      variant={variant}
      motionPreset="none"
    >
      <ModalOverlay bg={['white', null, null, 'blackAlpha.600']} />
      <ModalContent
        m={0}
        borderRadius={[0, null, null, 16]}
        w={isUsedForTutorial ? '80%' : 'full'}
        style={{
          borderRadius: isUsedForTutorial ? '20px' : '',
        }}
      >
        <Box sx={{ ...style }}>
          <ModalHeader
            bg={isUsedForTutorial ? 'white' : 'background'}
            borderRadius={isUsedForTutorial ? '20px' : [0, null, null, 16]}
            textAlign={titleAlignCenter ? 'center' : 'left'}
          >
            {header}
          </ModalHeader>
          <ModalCloseButton top={[3, null, null, 17]} color="textHighlight" />
          <ModalBody px={isUsedForTutorial ? '1rem' : [null, null, null, 6]} py={0}>
            {children}
          </ModalBody>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default ActionModal
