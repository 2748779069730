import { orderBy, capitalize } from 'lodash'
import { EVENT_TYPE, FOLLOWING_EVENT_TYPES, workflowConstant } from 'helpers/constant'
import { resourceType } from 'helpers/utils'
import { icons, images } from 'theme'
import { Flow, TrackingEvent, User } from 'types/graphqlSchema'
import { FlowStatus } from '../Flows.types'

export default function useFlowContents() {
  // HELPERS
  const unresolvedStatus = (flow) => {
    const { flowLogs, participants } = flow
    if (participants.length === 0) {
      return 'Unresolved'
    }
    const approvalParticipants = flowLogs.filter(
      (log) => log.toState?.stateName === workflowConstant.approval.state.approved,
    )
    return `${approvalParticipants.length} of ${participants.length} Approved`
  }

  const getDoneStateBeforeArchived = (archivedflow) => {
    const {
      workflow: { name },
      state: { stateName },
      flowLogs,
      participants,
    } = archivedflow
    const statesInDone = {
      [workflowConstant.approval.name]: [
        workflowConstant.approval.state.approved,
        workflowConstant.approval.state.rejected,
        workflowConstant.approval.state.unresolved,
        workflowConstant.approval.state.cancelled,
      ],
      [workflowConstant.feedback.name]: [
        workflowConstant.feedback.state.submitted,
        workflowConstant.feedback.state.unresolved,
        workflowConstant.feedback.state.cancelled,
      ],
    }
    const doneLogs = flowLogs.filter((log) => statesInDone[name].includes(log.toState.stateName))
    if (name === workflowConstant.approval.name) {
      // Approval flow
      if (doneLogs.some((log) => log.toState.stateName === workflowConstant.approval.state.cancelled)) {
        // Cancelled
        return workflowConstant.approval.state.cancelled
      } else if (doneLogs.every((log) => log.toState.stateName === workflowConstant.approval.state.approved)) {
        // Approved
        return workflowConstant.approval.state.approved
      } else if (doneLogs.every((log) => log.toState.stateName === workflowConstant.approval.state.rejected)) {
        // Rejected
        return workflowConstant.approval.state.rejected
      } else if (
        (doneLogs.some((log) => log.toState.stateName === workflowConstant.approval.state.rejected) &&
          doneLogs.some((log) => log.toState.stateName === workflowConstant.approval.state.approved)) ||
        participants.length === 0
      ) {
        // Unresolved
        return unresolvedStatus({ flowLogs: doneLogs, participants })
      }
    } else if (name === workflowConstant.feedback.name) {
      // Feedback flow
      if (doneLogs.some((log) => log.toState.stateName === workflowConstant.feedback.state.cancelled)) {
        // Cancelled
        return workflowConstant.feedback.state.cancelled
      } else if (doneLogs.every((log) => log.toState.stateName === workflowConstant.feedback.state.submitted)) {
        // Submitted
        return `Input ${workflowConstant.feedback.state.submitted}`
      }
      return workflowConstant.feedback.state.unresolved
    }
    // more flow type
    return stateName
  }

  /**
   * return flow status title
   * @param param
   */
  const getFlowTitleByStatus = ({ flow, status }: { flow: Flow; status?: FlowStatus }) => {
    const isApprovalFlow = flow.workflow?.name === workflowConstant.approval.name
    const isFeedbackFlow = flow.workflow?.name === workflowConstant.feedback.name
    const isSignatureFlow = flow.workflow?.name === workflowConstant.signature.name

    // TO DO
    if (status === FlowStatus.todo) {
      if (isApprovalFlow) {
        return `Your Approval's Requested`
      }
      if (isFeedbackFlow) {
        return `Your Input's Needed`
      }
      if (isSignatureFlow) {
        return `Your Signature's Needed`
      }
      return ''
    }
    // IN PROGRESS
    if (status === FlowStatus.inProgress) {
      if (isApprovalFlow) {
        return `Others to Approve`
      }
      if (isFeedbackFlow) {
        return `Others to Submit Input`
      }
      if (isSignatureFlow) {
        return `Others to Sign`
      }
      return ''
    }
    // IN THE LOOP
    if (status === FlowStatus.loop) {
      // return 'People in the loop'
      if (isApprovalFlow) {
        return `Others to Approve`
      }
      if (isFeedbackFlow) {
        return `Others to Submit Input`
      }
      if (isSignatureFlow) {
        return `Others to Sign`
      }
      return ''
    }
    // RESULT
    if (status === FlowStatus.result) {
      if (isApprovalFlow && flow.state?.stateName === workflowConstant.approval.state.unresolved) {
        return unresolvedStatus(flow)
      }
      if (isFeedbackFlow && flow.state?.stateName === workflowConstant.feedback.state.submitted) {
        return `Input Submitted`
      }
    }
    // ARCHIVED
    if (flow.state?.stateName === 'Archived') {
      return getDoneStateBeforeArchived(flow)
    }
    if (isFeedbackFlow && flow.state?.stateName === workflowConstant.feedback.state.submitted) {
      return `Input Submitted`
    }

    return flow.state?.stateName || ''
  }

  // https://tipbox.atlassian.net/browse/TA-867
  const getFlowStatusForRequester = (flow) => {
    const {
      workflow: { name },
      state: { stateName },
    } = flow
    if (stateName === 'Archived') {
      return getDoneStateBeforeArchived(flow)
    }
    if (name === 'Approval') {
      if (stateName === 'Pending') {
        return 'Approval Pending'
      } else if (stateName === 'Unresolved') {
        return unresolvedStatus(flow)
      }
    } else if (name === 'Feedback') {
      if (stateName === 'Pending') {
        return 'Input Pending'
      } else if (stateName === 'Submitted') {
        return 'Input Submitted'
      }
    }
    return stateName
  }

  // https://tipbox.atlassian.net/browse/TA-867
  const getFlowStatusForParticipant = (flow) => {
    const {
      workflow: { name },
      state: { stateName },
    } = flow
    if (stateName === 'Archived') {
      return getDoneStateBeforeArchived(flow)
    }
    if (name === 'Approval' && stateName === 'Unresolved') {
      return unresolvedStatus(flow)
    }
    if (name === 'Feedback' && stateName === 'Submitted') {
      return 'Input Submitted'
    }
    return stateName
  }

  const getParticipantState = (
    flow: Flow,
    participant: User,
  ): { state: string; workflowState: string; createdAt?: string; reason?: string } => {
    const participantLogs = orderBy(
      flow?.flowLogs?.filter((log) => log?.user?.id === participant.id),
      'createdAt',
      'desc',
    )
    const positiveLog = participantLogs.find(
      (log) =>
        log &&
        (log.toState?.stateName === workflowConstant.approval.state.approved ||
          log.toState?.stateName === workflowConstant.feedback.state.submitted),
    )
    const negativeLog = participantLogs.find(
      (log) =>
        log &&
        (log.toState?.stateName === workflowConstant.approval.state.rejected ||
          log.toState?.stateName === 'Cancelled' ||
          log.toState?.stateName === 'Unresolved'),
    )
    if (positiveLog) {
      return {
        state: 'Positive',
        workflowState: positiveLog.toState?.stateName || '',
        createdAt: positiveLog.createdAt,
      }
    }
    if (negativeLog) {
      return {
        state: 'Negative',
        workflowState: negativeLog.toState?.stateName || '',
        createdAt: negativeLog.createdAt,
        reason: negativeLog.metadata?.message,
      }
    }
    return { state: 'Pending', workflowState: 'Pending' }
  }

  const getFlowEventContent = (event: TrackingEvent) => {
    const {
      user,
      customFields: { flow, requesterFirstName, requesterLastName, workflowName, flowLogs },
    } = event
    const flowType = workflowName === 'Approval' ? 'Approval' : 'General'
    let status = ''
    if (event.eventType === EVENT_TYPE.FLOW_UNRESOLVED) {
      status = unresolvedStatus({ flowLogs, participants: flow.participants })
    }

    switch (event.eventType) {
      case EVENT_TYPE.FLOW_SENT:
        return {
          image: images.lightning,
          headerIcon: flow.urgent ? icons.BsExclamationSquareFill : null,
          header: `${flow.urgent ? 'Urgent' : ''} ${flowType} Request`,
          subHeader: `${requesterFirstName} ${requesterLastName} would like ${
            workflowName === 'Approval' ? 'you to approve' : 'your input'
          } -`,
        }
      case EVENT_TYPE.FLOW_APPROVED:
        return {
          image: images.approved,
          headerIcon: null,
          header: null,
          subHeader: 'Your Flow has been approved',
        }
      case EVENT_TYPE.FLOW_CANCELLED:
        return {
          image: images.stop,
          headerIcon: null,
          header: null,
          subHeader: `${requesterFirstName} ${requesterLastName} has cancelled their Flow you were a part of`,
        }
      case EVENT_TYPE.FLOW_REJECTED:
        return {
          image: images.rejected,
          headerIcon: null,
          header: null,
          subHeader: 'Your Flow has been rejected',
        }
      case EVENT_TYPE.FEEDBACK_FLOW_SUBMITTED:
        return {
          image: images.plus,
          headerIcon: null,
          header: null,
          subHeader: 'Input was submitted on your Flow',
        }
      case EVENT_TYPE.FLOW_UNRESOLVED:
        return {
          image: status === 'Unresolved' ? images.question : images.slash,
          headerIcon: null,
          header: null,
          subHeader: status === 'Unresolved' ? 'Your Flow has been unresolved' : `${status} your Flow`,
        }
      case EVENT_TYPE.FLOW_USER_REMOVED:
        return {
          image: images.ghost,
          headerIcon: null,
          header: null,
          subHeader: `${user?.firstName} ${user?.lastName} removed themselves from your Flow`,
        }
      case EVENT_TYPE.IN_THE_LOOP:
        return {
          image: images.glasses,
          headerIcon: null,
          header: null,
          subHeader: `${requesterFirstName} ${requesterLastName} is keeping you "In the Loop" on a Flow`,
        }
      case EVENT_TYPE.FLOW_INTERVAL_REMINDER_CREATOR:
        return {
          image: images.bell,
          headerIcon: null,
          header: null,
          subHeader: 'Reminder: You set a reminder for your Flow',
        }
      case EVENT_TYPE.FLOW_INTERVAL_REMINDER_ASSIGNEE:
        return {
          image: images.bell,
          headerIcon: null,
          header: null,
          subHeader: `Reminder: You set a reminder for ${requesterFirstName} ${requesterLastName}'s Flow`,
        }
      default:
        return {
          image: images.lightning,
          headerIcon: null,
          header: null,
          subHeader: '',
        }
    }
  }

  const getFollowingEventContent = (event: TrackingEvent) => {
    const {
      user,
      customFields: { resourceName, followedResourceType, followingEventType },
    } = event
    const followedResourceTypeName = resourceType(followedResourceType)
    const resourceTypeName = resourceType(event.customFields.resourceType) || ''

    switch (followingEventType) {
      case FOLLOWING_EVENT_TYPES.ADD_NODE:
        return {
          header: `${user?.firstName} ${user?.lastName} updated to the ${followedResourceTypeName?.toLowerCase()} -`,
          subHeader: `${capitalize(resourceTypeName)}: ${resourceName}`,
        }
      case FOLLOWING_EVENT_TYPES.UPDATE_NODE:
        return {
          header: `${user?.firstName} ${user?.lastName} updated their ${followedResourceTypeName?.toLowerCase()} -`,
          subHeader: null,
        }
      case FOLLOWING_EVENT_TYPES.DELETE_NODE:
        return {
          header: `${user?.firstName} ${
            user?.lastName
          } deleted their item in the ${followedResourceTypeName?.toLowerCase()} -`,
          subHeader: `${capitalize(resourceTypeName)}: ${resourceName}`,
        }
      case FOLLOWING_EVENT_TYPES.COMMENT_ON_NODE:
        return {
          header: `${user?.firstName} ${user?.lastName} commented on the file -`,
          subHeader: event.customFields.commentMessage,
        }
      default:
        return {
          header: '',
          subHeader: '',
        }
    }
  }

  const getOtherEventContent = (event: TrackingEvent) => {
    const { user } = event
    const resourceTypeName = resourceType(event.customFields.resourceType) || ''
    switch (event.eventType) {
      case EVENT_TYPE.RESOURCE_SHARED:
        return {
          header: `${user?.firstName} ${user?.lastName} invited you to the ${resourceTypeName.toLowerCase()} `,
        }
      default:
        return {
          header: '',
        }
    }
  }

  return {
    getFlowTitleByStatus,
    getFlowStatusForRequester,
    getFlowStatusForParticipant,
    getParticipantState,
    getFlowEventContent,
    getFollowingEventContent,
    getOtherEventContent,
  }
}
