import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_TRACKING_EVENTS, ADD_TRACKING_EVENTS } from 'features/graphql'

export default () => {
  const [getTrackingEvents] = useLazyQuery(GET_TRACKING_EVENTS)
  const [mutateAddTrackingEvents] = useMutation(ADD_TRACKING_EVENTS)

  const addTrackingEvents = async (events) => {
    try {
      const {
        data: { addTrackingEvents },
      } = await mutateAddTrackingEvents({ variables: { events } })
      return addTrackingEvents
    } catch (error) {
      throw new Error(error)
    }
  }

  return {
    getTrackingEvents,
    addTrackingEvents,
  }
}
