import React from 'react'
import {
  Box,
  Modal as ChakraModal,
  // useDisclosure,
  ModalContent,
  ModalBody,
  ModalBodyProps,
} from '@chakra-ui/react'
import { IconButton } from 'components'
import { icons } from 'theme'

interface PopoverModalProps extends ModalBodyProps {
  menuOpen: boolean
  setMenuOpen: (menuOpen: boolean) => void
  iconSize?: { height: number; width: number }
  style?: React.CSSProperties
  iconColor?: string
}
const PopoverModal = ({
  menuOpen,
  setMenuOpen,
  iconSize = { height: 4, width: 4 },
  children,
  style,
  iconColor,
}: PopoverModalProps) => {
  return (
    <Box sx={{ ...style }}>
      <ChakraModal isOpen={menuOpen} onClose={() => {}} size="sm" isCentered motionPreset="none">
        <ModalContent p={0} maxW="unset" w="unset" onBlur={() => setMenuOpen(false)}>
          <ModalBody p={0} w="16rem">
            {children}
          </ModalBody>
        </ModalContent>
      </ChakraModal>
      <IconButton
        ariaLabel="popover modal button"
        variant="iconBtnSm"
        icon={icons.menuIcon}
        iconSize={iconSize}
        onClick={() => {
          setMenuOpen(true)
        }}
        iconColor={iconColor}
      />
    </Box>
  )
}

export default PopoverModal
