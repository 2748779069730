import React from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  Text,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { images } from 'theme'

interface TipSaveBreadcrumbsProps {
  clickAction: (type: string, item: any, index: number) => void
  breadcrumbs: { label: string; type: string; item: any }[]
  isMoveTo?: boolean
}

interface ButtonIconProps {
  imageUrl: string
  style?: React.CSSProperties
}

const breadcrumbOllistStyles = {
  display: 'flex',
  flexWrap: 'wrap',
}

const SaveBreadcrumbs = ({ clickAction, breadcrumbs, isMoveTo = false }: TipSaveBreadcrumbsProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const ButtonIcon = ({ imageUrl, style }: ButtonIconProps) => <Image src={imageUrl} style={style}></Image>

  return (
    <Breadcrumb
      separator={<ButtonIcon imageUrl={images.chevronRightBreadcrumb} style={{ marginTop: '-2px' }} />}
      my={'1rem'}
      sx={{ ol: breadcrumbOllistStyles }}
    >
      <Text mb={'.5rem'} mr={'.75rem'} color={'textBlack'} fontWeight={'semibold'} fontSize={14}>
        {isMoveTo ? 'Moving' : 'Saving'} To:
      </Text>
      {breadcrumbs.length > 3 && (
        <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start">
          <PopoverTrigger>
            <Button variant={'unstyled'} mt={'-10px'} ml={'-11px'} alignSelf={'flex-start'} onClick={onToggle}>
              ...
            </Button>
          </PopoverTrigger>
          <PopoverContent
            bg={'white'}
            border={'none'}
            shadow={'dark-lg'}
            minW={'max-content'}
            maxW={'200px'}
            px={4}
            py={2}
          >
            <PopoverCloseButton _hover={{ border: '1px solid lightGray' }} />
            <PopoverBody display={'flex'} flexDir={'column'}>
              {breadcrumbs.map((crumb, index) => {
                const uniqueKey = crypto.randomUUID()
                return (
                  <div key={uniqueKey}>
                    <BreadcrumbItem
                      marginY={'.5rem'}
                      cursor="pointer"
                      minWidth={'max-content'}
                      onClick={() => {
                        clickAction(crumb.type, crumb.item, index)
                        onClose()
                      }}
                      display={index <= breadcrumbs.length - 4 ? 'inline-flex' : 'none'}
                    >
                      <Text
                        color={'textBlack'}
                        fontWeight={'semibold'}
                        fontSize={14}
                        _hover={{ textDecoration: 'underline' }}
                      >
                        {crumb.label.length > 35 ? crumb.label.substring(0, 35) + '...' : crumb.label}
                      </Text>
                    </BreadcrumbItem>
                  </div>
                )
              })}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
      {breadcrumbs.map((crumb, index) => (
        <BreadcrumbItem
          key={crumb.item.id}
          mb={'.5rem'}
          cursor={index !== breadcrumbs.length - 1 ? 'pointer' : ''}
          minWidth={'max-content'}
          onClick={() => {
            if (index !== breadcrumbs.length - 1) {
              clickAction(crumb.type, crumb.item, index)
            }
          }}
          display={index >= breadcrumbs.length - 3 ? 'inline-flex' : 'none'}
        >
          <Text
            color={'textBlack'}
            fontWeight={'semibold'}
            fontStyle={index !== breadcrumbs.length - 1 || index === 0 ? '' : 'italic'}
            fontSize={14}
            _hover={index !== breadcrumbs.length - 1 ? { textDecoration: 'underline' } : {}}
          >
            {breadcrumbs.length === 1
              ? 'Personal'
              : crumb.label.length > 25
              ? crumb.label.substring(0, 25) + '...'
              : crumb.label}
          </Text>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}
export default SaveBreadcrumbs
