import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import styles from './styles'
import breakpoints from './breakpoints'
import colors from './colors'
import components from './components'

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

// Base rebass theme, copied from @rebass-preset
const theme = extendTheme({
  config,
  styles,
  breakpoints,
  colors,
  sizes: {
    section: {
      md: '480px',
    },
  },
  textStyles: {
    h1: {
      fontSize: [24, null, null, 40],
    },
    h2: {
      fontSize: [20],
    },
    h3: {
      fontSize: [16, null, null, 14],
    },
    h4: {
      fontSize: [12, null, null, 14],
    },
    h5: {
      fontSize: [14],
      fontWeight: 'normal',
    },
    h6: {
      fontSize: [12],
      fontFamily: 'Inter',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    tableHeader: {
      fontSize: [12, null, null, 16],
      fontWeight: '600',
    },
  },
  components,
  semanticTokens: {
    colors: colors.semanticTokens,
  },
})

export default theme
