import { useState, useEffect } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import Helmet from 'react-helmet'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'
import Routes from 'routes'
import { initializeGTM, trackPageView } from 'helpers/gtm'
import store, { persistor } from 'helpers/store'
import ApolloProvider from 'helpers/apollo'
import { ErrorBoundary } from 'components/fragments'
import { theme, Fonts } from 'theme'
import { DraggingProvider } from 'hooks'
import './theme/reset.css'

const App = () => {
  const [gtmInitialized, setGtmInitialized] = useState(false)
  useEffect(() => {
    if (!gtmInitialized) {
      initializeGTM()
      setGtmInitialized(true)
    }
    trackPageView()
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider>
          <ChakraProvider theme={theme} resetCSS>
            <Fonts />
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <Helmet
                titleTemplate="%s | tipbox-app"
                meta={[
                  { charset: 'utf-8' },
                  {
                    'http-equiv': 'X-UA-Compatible',
                    content: 'IE=edge',
                  },
                  {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1',
                  },
                ]}
              />
              <ErrorBoundary>
                <DraggingProvider>
                  <Routes />
                </DraggingProvider>
              </ErrorBoundary>
            </DndProvider>
          </ChakraProvider>
        </ApolloProvider>
      </PersistGate>
      <ToastContainer limit={4} />
    </Provider>
  )
}

export default Sentry.withProfiler(App)
