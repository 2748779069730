import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { useUserSettingsSlice } from '../userSettings.slice'

export const AdvanceSettings = () => {
  const { dispatch, setShowDeleteSettingsPanel, setShowModalBody } = useUserSettingsSlice()

  const handleClickDeleteAccount = () => {
    dispatch(setShowDeleteSettingsPanel(true))
    dispatch(setShowModalBody(false))
  }

  return (
    <Flex fontSize={'14px'} direction={'column'} gap={'1.75rem'} pt={'12px'}>
      {/* Not implemented yet */}
      {/* -------------------- */}
      {/* <Flex direction={'column'}> */}
      {/* <Box>Preferences</Box> */}
      {/* <Flex alignItems={'center'} justifyContent={'space-between'} mb={'10px'}> */}
      {/*   <Box>Set Timezone:</Box> */}
      {/*   <Flex gap={'8px'}> */}
      {/*     <UserSettingsSelect /> */}
      {/*     <TimezoneSelect /> */}
      {/*   </Flex> */}
      {/* </Flex> */}
      {/* </Flex> */}
      <Flex direction={'column'}>
        <Box mb={'0.75rem'}>Danger Zone</Box>
        <Box
          color={'accent'}
          mb={'0.75rem'}
          onClick={handleClickDeleteAccount}
          cursor={'pointer'}
          _hover={{ textDecoration: 'underline' }}
        >
          Permanently Delete Account
        </Box>
      </Flex>
    </Flex>
  )
}
