import { Flex, Text } from '@chakra-ui/react'

const NodeData = ({ totalSize, lastModifiedBy, createdDate, updatedDate }) => {
  return (
    <Flex
      flexDir="column"
      gap="1.25rem"
      fontSize="14px"
      maxH="40dvh"
      minW="14ch"
      overflow="auto"
      css={{
        '&::-webkit-scrollbar': {
          width: '0',
        },
        '&::-webkit-scrollbar-track': {
          width: '0',
        },
      }}
    >
      <Flex flexDir="column" textAlign="left">
        <Text color="textRegular" fontWeight={400}>
          Created
        </Text>
        <Text color="textSoft" fontWeight={400}>
          {createdDate}
        </Text>
      </Flex>
      <Flex flexDir="column" textAlign="left">
        <Text color="textRegular" fontWeight={400}>
          Last Modified
        </Text>
        <Text color="textSoft" fontWeight={400}>
          {updatedDate}
        </Text>
        <Text color="textSoft" fontWeight={400}>
          {`by ${lastModifiedBy}`}
        </Text>
      </Flex>

      <Text color="textRegular" fontWeight={400} textAlign="left">
        Size <span style={{ color: 'var(--chakra-colors-textSoft)' }}>{totalSize}</span>
      </Text>
    </Flex>
  )
}

export default NodeData
