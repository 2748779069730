import { Box, useRadio, UseRadioProps } from '@chakra-ui/react'
import { icons } from 'theme'

interface ColorRadioProps extends UseRadioProps {
  color: string
}

const ColorRadio = ({ color, ...radioProps }: ColorRadioProps) => {
  const { state, getInputProps, getRadioProps } = useRadio(radioProps)

  const input = getInputProps()
  const checkbox = getRadioProps()

  // Styles
  const size = ['1.5rem', null, null, '1.875rem']

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={size}
        height={size}
        bg={color}
        textAlign="center"
        cursor="pointer"
      >
        {state.isChecked && <icons.check color="black" fontSize="1.5rem" />}
      </Box>
    </Box>
  )
}

export default ColorRadio
