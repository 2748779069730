import { colors } from 'theme'
import { Flex, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'

interface AnimatedCheckLargeProps {
  caption?: string
}

const AnimatedCheckLarge = ({ caption = 'Document Saved.' }: AnimatedCheckLargeProps) => {
  return (
    <Flex flexDir={'column'} mx="auto" alignItems={'center'} gap={'1rem'}>
      <svg width="100" height="104" viewBox="0 0 100 104" fill="none" xmlns="http://www.w3.org/2000/svg">
        <motion.path
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.4, type: 'spring' }}
          d="M85.94 59.6907C85.94 82.8707 67.15 101.661 43.97 101.661C20.79 101.661 2 82.8707 2 59.6907C2 36.5107 20.79 17.7207 43.97 17.7207C67.15 17.7207 85.94 36.5107 85.94 59.6907V59.6907Z"
          stroke="#7A1ADB"
          strokeWidth="3.32"
          strokeMiterlimit="10"
        />
        <motion.path
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.6 }}
          d="M42.1802 87.0891C40.1702 87.0891 38.2401 86.479 36.5901 85.3091L16.5301 71.0991C12.1801 68.0091 11.1502 61.969 14.2302 57.619C16.0402 55.069 18.9902 53.5391 22.1202 53.5391C24.1302 53.5391 26.0701 54.1491 27.7101 55.3191L39.6702 63.7991L75.9202 9.81906C77.7202 7.13906 80.7201 5.53906 83.9501 5.53906C85.8701 5.53906 87.7401 6.10906 89.3401 7.17906C93.7601 10.1491 94.9502 16.1691 91.9802 20.5991L50.2001 82.8091C48.7601 84.9591 46.4702 86.459 43.9202 86.929C43.3202 87.039 42.7402 87.0891 42.1702 87.0891"
          fill="#7A1ADB"
        />
        <motion.path
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.6 }}
          d="M83.9607 11.05C84.7507 11.05 85.5607 11.28 86.2707 11.75C88.1707 13.03 88.6807 15.61 87.4007 17.51L45.6307 79.72C45.0107 80.65 44.0307 81.28 42.9307 81.49C42.6807 81.53 42.4307 81.56 42.1807 81.56C41.3307 81.56 40.4907 81.3 39.7807 80.8L19.7307 66.59C17.8607 65.26 17.4207 62.67 18.7407 60.8C19.5507 59.66 20.8307 59.05 22.1307 59.05C22.9607 59.05 23.8007 59.3 24.5307 59.81L41.1107 71.56L80.5106 12.88C81.3106 11.69 82.6207 11.04 83.9607 11.04M83.9607 0C78.8807 0 74.1707 2.52001 71.3407 6.73001L38.2507 56L30.9107 50.8C28.3307 48.97 25.2907 48 22.1307 48C17.2107 48 12.5707 50.4 9.73067 54.41C4.89067 61.24 6.51066 70.74 13.3407 75.58L33.4007 89.8C35.9907 91.63 39.0207 92.6 42.1807 92.6C43.0807 92.6 43.9906 92.52 44.8906 92.36C48.9406 91.61 52.5307 89.25 54.8007 85.88L96.5706 23.67C101.241 16.71 99.3707 7.25001 92.4307 2.59001C89.9107 0.900012 86.9907 0.0100098 83.9607 0.0100098"
          fill="white"
        />
      </svg>

      <Text fontSize={20} fontWeight={'semibold'} color={'textBlack'}>
        {caption}
      </Text>
    </Flex>
  )
}

export default AnimatedCheckLarge
