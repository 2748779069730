import { useEffect, useRef } from 'react'

/**
 * useAutoFocus
 *
 * This custom hook automatically focuses an HTML input element when the component is mounted.
 * It returns a `ref` that should be assigned to the element you want to focus.
 **/
const useAutoFocus = <T extends HTMLInputElement>() => {
  const inputRef = useRef<T | null>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return inputRef
}

export default useAutoFocus
