const colors = {
  tipbox: {
    dark: {
      text: {
        white: '#FFFFFF',
        pearl: '#E2E2E2',
        ash: '#B0AFB6',
        grey: '#A3A2A9',
        greyMedium: '#666666',
        greyLight: '#979797',
        black: '#1A1A1A',
        charcoalGrey: '#595959',
        darkGrey: '#474747',
        neutralGrey: '#7A7979',
      },
      background: {
        midnight: '#0A0C16',
        navy: '#131628',
        charcoal: '#242529',
        charcoalAccent: '#343539',
        flowAccent: '#3A5B6E',
      },
      accent: {
        purple: '#7A1ADB',
        purpleAccent: '#56129A',
        purpleDisable: '#3D2654',
        techno: '#02F7B6',
        alert: '#FF8F6B',
        success: '#3CA32B',
        successAccent: '#207513',
        reject: '#D67924',
        modalRed: '#AF1A12',
      },
      border: {
        regular: '#393952',
        light: '#E9EAEC',
        lightGrey: '#E7E7E7',
        fade: '#4F4F4F',
        velvet: '#33344B',
        focus: '#676794',
      },
    },
  },
  folder: {
    green: {
      body: '#81C784',
      tab: '#578859',
      text: '#1A1A1A',
    },
    yellow: {
      body: '#FFF076',
      tab: '#B5AA54',
      text: '#1A1A1A',
    },
    red: {
      body: '#E57373',
      tab: '#9E5050',
      text: '#1A1A1A',
    },
    purple: {
      body: '#9575CD',
      tab: '#67518D',
      text: '#1A1A1A',
    },
    orange: {
      body: '#FFB74D',
      tab: '#A67731',
      text: '#1A1A1A',
    },
    blue: {
      body: '#50C2F7',
      tab: '#337EA1',
      text: '#1A1A1A',
    },
    lightGray: {
      body: '#D9D9D9',
      tab: '#9E9E9E',
      text: '#1A1A1A',
    },
    darkGray: {
      body: '#5B5B5B',
      tab: '#4B4B4B',
      text: '#E2E2E2',
    },
  },
  semanticTokens: {
    background: { default: 'tipbox.dark.text.pearl', _dark: 'tipbox.dark.background.midnight' },
    menuBackground: { default: 'white', _dark: 'tipbox.dark.background.navy' },
    cardBackground: { default: 'tipbox.dark.text.ash', _dark: 'tipbox.dark.background.charcoal' },
    cardBackgroundAccent: { default: 'tipbox.dark.text.ash', _dark: 'tipbox.dark.background.charcoalAccent' },
    borderFocus: 'tipbox.dark.border.focus',
    borderRegular: 'tipbox.dark.border.regular',
    borderLight: { default: 'tipbox.dark.border.regular', _dark: 'tipbox.dark.border.light' },
    textHighlight: { default: 'black', _dark: 'tipbox.dark.text.white' },
    textSoft: { default: 'tipbox.dark.text.black', _dark: 'tipbox.dark.text.pearl' },
    textRegular: { default: 'tipbox.dark.background.charcoal', _dark: 'tipbox.dark.text.grey' },
    textAsh: { default: 'tipbox.dark.background.charcoal', _dark: 'tipbox.dark.text.ash' },
    textBlack: { default: 'tipbox.dark.text.white', _dark: 'tipbox.dark.text.black' },
    accentIcon: { default: 'black', _dark: 'tipbox.dark.accent.techno' },
    accent: 'tipbox.dark.accent.purple',
    flowAccent: 'tipbox.dark.background.flowAccent',
    accentHighlight: 'tipbox.dark.accent.purpleAccent',
    accentDisabled: 'tipbox.dark.accent.purpleDisabled',
    alert: 'tipbox.dark.accent.alert',
    success: 'tipbox.dark.accent.success',
    successAccent: 'tipbox.dark.accent.successAccent',
    reject: 'tipbox.dark.accent.reject',
    white: 'tipbox.dark.text.white',
    modalRed: 'tipbox.dark.accent.modalRed',
    modalSubText: 'tipbox.dark.text.charcoalGrey',
  },
}

export default colors
