import React from 'react'
import { Flex, Box, Text, Heading } from '@chakra-ui/react'
import { Button, Toast } from 'components'
import { useUserSettingsSlice } from '../userSettings.slice'
import { useAppSlice } from 'features/redux'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'helpers/store'
import CustomFade from 'components/elements/Transition'

type DeleteAccountProps = {
  onCancel?: () => void
  onDelete?: () => void
}
const DeleteAccountSettings = ({ onDelete }: DeleteAccountProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const { setShowModalBody, setShowDeleteSettingsPanel } = useUserSettingsSlice()

  const { deleteMyAccount } = useAppSlice()

  const handleDeleteAccount = async () => {
    try {
      await dispatch(deleteMyAccount())
    } catch (error) {
      Toast.show({
        icon: 'error',
        message: 'Something went wrong. Could not delete account. Please try again later',
      })
    }
  }

  const handleCancel = () => {
    dispatch(setShowModalBody(true))
    dispatch(setShowDeleteSettingsPanel(false))
  }

  return (
    <Box pt={[0, null, null, 2]}>
      <CustomFade>
        <Heading color={'textBlack'} fontWeight={'500'} fontSize={'24px'} pb={'30px'}>
          Delete Account
        </Heading>
        <Text color={'greyLight'} mb={'2rem'}>
          Are you sure you want to permanently delete your account?
        </Text>
        {/** ACTION SECTION */}
        <Flex direction={['column', null, null, 'row']} w="100%" justifyContent="flex-end" py={4}>
          <Box px={[0, null, null, 4]}>
            <Button label="Cancel" variant="btnOutline" onClick={handleCancel} />
          </Box>
          <Box>
            <Button label="Confirm" variant="defaultButton" onClick={handleDeleteAccount} />
          </Box>
        </Flex>
      </CustomFade>
    </Box>
  )
}

export default DeleteAccountSettings
