import React from 'react'
import { forwardRef, Box, BoxProps } from '@chakra-ui/react'

interface DotProps extends BoxProps {
  visible: boolean
  size?: string
  bgColor?: string
}

const Dot = forwardRef<DotProps, 'div'>((props, ref) => {
  const { visible = false, size = '6px', bgColor = 'alert', ...rootProps } = props

  return (
    <Box
      display={visible ? 'block' : 'none'}
      bgColor={bgColor}
      ml={1}
      borderRadius="100%"
      w={size}
      h={size}
      ref={ref}
      {...rootProps}
    />
  )
})

export default Dot
