import { mode } from '@chakra-ui/theme-tools'
import type { StyleFunctionProps } from '@chakra-ui/styled-system'

export default {
  global: (props: StyleFunctionProps) => ({
    'html:': {
      height: '-webkit-fill-available',
    },
    'html, body': {
      backgroundColor: 'background',
      lineHeight: 'tall',
      // minHeight:'100vh',
      /* mobile viewport bug fix */
      minHeight: '-webkit-fill-available',
    },
    '.checkbox': {
      borderColor: '#E6E6E6',
    },
    '.textarea': {
      borderColor: '#E6E6E6 !important',
    },
    '.radioButton': {
      border: '2px solid #E6E6E6 !important',
    },
    '.no-scroll-bar': {
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: '0px',
        height: '0px',
      },
      '&::-webkit-scrollbar-track': {
        width: '0px',
        height: '0px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '0px',
      },
    },
    // style scroll bar
    '.show_apple_scrollbar': {
      '&::-webkit-scrollbar': {
        display: 'inherit',
      },
      '&:hover::-webkit-scrollbar': {
        width: '8px',
      },
      '&:hover::-webkit-scrollbar-track': {
        WebkitBoxShadow: 'none',
        borderRadius: '8px',
        WebkitBorderRadius: ' 8px',
      },

      '&:hover::-webkit-scrollbar-thumb': {
        WebkitBorderRadius: '10px',
        borderRadius: '10px',
        background: '#C0C0C0',
        WebkitBoxShadow: 'none',
      },
    },
  }),
}
