import { Tab, Text } from '@chakra-ui/react'

const FlowTab = ({ index, label, tabIndex }) => {
  const TabStyles = {
    fontSize: 'inherit',
    p: '0 0 8px 0 ',
    _hover: { color: 'textBlack' },
    _selected: { color: 'textBlack', borderColor: 'textBlack' },
  }

  return tabIndex === index ? (
    <Text h={8} color="textBlack" borderBottom={'2px solid black'}>
      {label}
    </Text>
  ) : (
    <Tab {...TabStyles} display={tabIndex >= 0 ? 'none' : 'initial'}>
      {label}
    </Tab>
  )
}

export default FlowTab
