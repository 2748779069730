/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { client as apollo } from 'helpers/apollo'
import { MOVE_BOX } from './box.schema'
import { GET_NODE, CREATE_NODE, UPDATE_NODE, DUPLICATE_NODE, DELETE_NODE } from '../node/node.schema'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'helpers/store'
import { NodeCreateInput } from 'types/graphqlSchema'

// ---------------------------------------
// Thunks
// ---------------------------------------

const getBox = createAsyncThunk('box/getBox', async (boxId: string) => {
  try {
    const {
      data: { node },
    } = await apollo.query({
      query: GET_NODE,
      variables: {
        id: boxId,
      },
    })
    return node
  } catch (error: any) {
    throw new Error(error)
  }
})

const createBox = createAsyncThunk('box/createBox', async (input: NodeCreateInput) => {
  try {
    const {
      data: { createNode },
    } = await apollo.mutate({
      mutation: CREATE_NODE,
      variables: {
        input,
      },
    })
    return createNode
  } catch (error) {
    return error
  }
})

const updateBox = createAsyncThunk('box/updateBox', async (params: any) => {
  try {
    const {
      data: { updateNode },
    } = await apollo.mutate({
      mutation: UPDATE_NODE,
      variables: {
        input: { ...params },
      },
    })
    return updateNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const deleteBox = createAsyncThunk('box/deleteBox', async (id: string) => {
  try {
    const { data } = await apollo.mutate({
      mutation: DELETE_NODE,
      variables: { id },
    })

    return data.deleteNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const duplicateBox = createAsyncThunk('box/dulicateBox', async (id: string) => {
  try {
    const {
      data: { duplicateNode },
    } = await apollo.mutate({
      mutation: DUPLICATE_NODE,
      variables: { id },
    })
    return duplicateNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const moveBox = createAsyncThunk('box/moveBox', async (params: any) => {
  try {
    const { data } = await apollo.mutate({
      mutation: MOVE_BOX,
      variables: { ...params },
    })
    return data.moveNode
  } catch (error: any) {
    throw new Error(error)
  }
})

interface BoxState {
  currentBox?: any
  boxes: IBox[]
  error: any
  pendingInfo: {
    getBox: string | undefined
  }
}

const initialState: BoxState = {
  currentBox: null,
  boxes: [],
  error: null,
  pendingInfo: {
    getBox: undefined,
  },
}

// slice
const boxSlice = createSlice({
  name: 'box',
  initialState,
  reducers: {
    clearBoxes(state) {
      state.boxes = []
    },
    clearCurrentBox(state) {
      state.currentBox = null
    },
    setCurrentBox(state, { payload }) {
      state.currentBox = payload
    },
    setBoxes(state, { payload }) {
      state.boxes = payload
    },
    addNewChildtoBox(state, { payload }) {
      if (!payload) return
      state.boxes = state.boxes.map((box) => {
        if (payload.parent === box.id) {
          box.children = [...box.children, payload.moved]
        }
        return box
      })
    },
    removeBox(state, { payload }) {
      if (!state.boxes) return
      state.boxes = state.boxes.filter((box) => box.id !== payload.id)
    },
    removeBoxChildren(state, { payload }) {
      state.currentBox.children = state.currentBox.children.filter((node: INode) => node.id !== payload.nodeId)
    },
    updateBoxShareMembers(state, { payload }) {
      if (!payload) return

      if (state.currentBox && payload.id === state.currentBox.id) {
        state.currentBox.sharedMembers = payload.sharedMembers
      }

      if (state.boxes) {
        state.boxes = state.boxes.map((box) => {
          if (box.id === payload.id) {
            box.sharedMembers = payload.sharedMembers
          }
          return box
        })
      }
    },
    updateBoxLinkShare(state, { payload }) {
      if (!payload) return
      if (payload.nodeType === 'BOX') {
        if (state.currentBox && state.currentBox.id === payload.nodeId) {
          state.currentBox.linkShare = payload
        }
        state.boxes = state.boxes.map((box) => {
          if (payload.nodeId === box.id) {
            box.linkShare = payload
          }
          return box
        })
      }
      if (state.currentBox && payload.nodeType === 'TIP') {
        state.currentBox.children = state.currentBox.children.map((tip: ITip) => {
          if (payload.nodeId === tip.id) {
            tip.linkShare = payload
          }
          return tip
        })
      }
    },
    removeBoxLinkShare(state, { payload }) {
      if (!payload) return
      if (payload.nodeType === 'BOX') {
        if (state.currentBox && state.currentBox.id === payload.nodeId) {
          state.currentBox.linkShare = undefined
        }
        state.boxes = state.boxes.map((box) => {
          if (payload.nodeId === box.id) {
            box.linkShare = undefined
          }
          return box
        })
      }
      if (state.currentBox && payload.nodeType === 'TIP') {
        state.currentBox.children = state.currentBox.children.map((tip: ITip) => {
          if (payload.nodeId === tip.id) {
            tip.linkShare = undefined
          }
          return tip
        })
      }
    },
    clearBoxPendingInfo(state) {
      state.pendingInfo.getBox = undefined
    },
  },
  extraReducers: (builder) => {
    // GET BOX
    builder.addCase(getBox.pending, (state, { meta }) => {
      const { requestId } = meta
      state.pendingInfo = {
        getBox: requestId,
      }
      state.error = null
    })
    builder.addCase(getBox.fulfilled, (state, { payload }: any) => {
      // const { requestId } = meta
      // this would cause box won't load, need to figure out why they do this
      // if (requestId !== state.pendingInfo?.getBox) return
      // if (payload.children.includes(null)) {
      //   payload.children = payload.children.filter((node: INode) => node)
      // }
      state.currentBox = payload
    })
    builder.addCase(getBox.rejected, (state, { error }) => {
      state.error = error.message
      state.currentBox = null
    })
    // CREATE FOLDER
    builder.addCase(createBox.fulfilled, (state, { payload }: any) => {
      if (payload.coverImage) {
        payload.coverImage = 'coverImage'
      }
      state.boxes.unshift(payload)
      if (state.currentBox && payload.id !== state.currentBox?.id) {
        state.currentBox.children.unshift(payload)
      }
    })
    builder.addCase(createBox.rejected, (state, { payload }) => {
      state.error = payload
    })
    // UPDATE FOLDER
    builder.addCase(updateBox.fulfilled, (state, { payload }: any) => {
      if (state.currentBox?.id === payload.id) {
        state.currentBox = payload
      }
      state.boxes = state.boxes.map((box) => {
        if (payload.id === box.id) {
          return payload
        }
        return box
      })
      if (state.currentBox && payload.id !== state.currentBox?.id) {
        state.currentBox.children = state.currentBox.children.map((node: INode) => {
          if (payload.id === node.id) {
            return payload
          }
          return node
        })
      }
    })
    // DELETE FOLDER
    builder.addCase(deleteBox.fulfilled, (state, { payload }: any) => {
      state.boxes = state.boxes.filter((box) => {
        if (payload.id === box.id) {
          return false
        }
        return true
      })
    })
    // DUPLICATE FOLDER
    builder.addCase(duplicateBox.fulfilled, (state, { payload }: any) => {
      state.boxes.unshift(payload)
    })
    // MOVE FOLDER
    builder.addCase(moveBox.fulfilled, (state, { payload }: any) => {
      state.boxes = state.boxes.filter((box) => {
        if (payload.id === box.id) {
          return false
        }
        return true
      })
    })
  },
})

export function useBoxSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.box)
  return { dispatch, ...state, getBox, createBox, moveBox, updateBox, duplicateBox, deleteBox, ...boxSlice.actions }
}

export default boxSlice.reducer
