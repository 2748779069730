import { gql } from '@apollo/client'

export const blockOutput = `
  id
  nodeId
  flowId
  type
  content
  name
  contentSecureUrl
  order
  extension
  preview
  previewSecureUrl
  size
  owner {
    id
    email
    firstName
    lastName
    avatar
  }
  createdAt
  updatedAt
`

// ---------------------------
// QUERIES
// ---------------------------

export const GET_BLOCKS = gql`
  query getBlocks($nodeId: ID!) {
    blocks(nodeId: $nodeId) {
      ${blockOutput}
    }
  }
`

export const GET_FLOW_BLOCKS = gql`
  query getFlowBlocks($flowId: ID!) {
    flowBlocks(flowId: $flowId) {
      ${blockOutput}
    }
  }
`

// ---------------------------
// MUTATIONS
// ---------------------------

export const CREATE_BLOCK = gql`
  mutation createBlock($input: BlockCreateInput!) {
    createBlock(input: $input) {
      ${blockOutput}
    }
  }
`

export const UPDATE_BLOCK = gql`
  mutation updateBlock($input: BlockUpdateInput!) {
    updateBlock(input: $input) {
      ${blockOutput}
    }
  }
`

export const DELETE_BLOCK = gql`
  mutation deleteBlock($id: ID!) {
    deleteBlock(id: $id) {
      ${blockOutput}
    }
  }
`
