import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useBreakpointValue } from '@chakra-ui/react'
// import { useStorage, usePermissions } from 'hooks'
// import { useNodeMenuActions } from 'features/node/hooks'
// import { useAppSlice, useProjectSlice, useBoxSlice } from 'features/redux'
// import { Toast } from 'components'
import MenuMobile from './MenuMobile'
import MenuDesktop from './MenuDesktop'

const Menus = () => {
  // const { dispatch, user, setIsUploading } = useAppSlice()
  // const { currentProject, getProject } = useProjectSlice()
  // const { currentBox, getBox } = useBoxSlice()
  // const { createNewTipNode, undoCreation } = useNodeMenuActions()
  // const { onDropUpload } = useStorage()
  // const { hasPermission } = usePermissions()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const mobile = useBreakpointValue({ base: true, lg: false }, { ssr: false })

  const [activeButton, setActiveButton] = useState('/')
  const [isLoading, setIsLoading] = useState(true)

  const navigateTo = (path: string) => {
    if (!isLoading) {
      setActiveButton(path)
      navigate(path)
    }
  }

  // const handleUndo = async (ids: [string]) => {
  //   await undoCreation(ids)
  // }

  // const onUpload = async (acceptedFiles: File[]) => {
  //   if (!hasPermission('upload')) return
  //   dispatch(setIsUploading(true))
  //   const tipInput = {
  //     ownerId: user.id,
  //     projectId: currentProject?.id,
  //     organizationId: currentProject?.organization?.id,
  //     parentId: currentBox?.id || currentProject?.id,
  //     updatedById: user.id,
  //   }

  //   const results = await onDropUpload(acceptedFiles, tipInput)
  //   const newlyCreatedNodeIds = results && results.newlyCreatedNodeIds ? results.newlyCreatedNodeIds : []

  //   if (newlyCreatedNodeIds.length > 0) {
  //     Toast.show({
  //       icon: 'check',
  //       message: `${newlyCreatedNodeIds?.length} ${newlyCreatedNodeIds.length === 1 ? 'item' : 'items'} created.`,
  //       onUndo: () => {
  //         handleUndo(newlyCreatedNodeIds)
  //       },
  //     })
  //   }
  //   if (results?.error?.length > 0) {
  //     results.error.forEach((error: any) => {
  //       Toast.show({
  //         icon: 'error',
  //         message: error.file.name,
  //         option: { autoClose: false },
  //       })
  //     })
  //   }
  //   if (currentBox) {
  //     dispatch(getBox(currentBox.id))
  //   } else if (currentProject) {
  //     dispatch(getProject(currentProject.id))
  //   }
  //   dispatch(setIsUploading(false))
  // }

  // const handleUploadNode = async () => {
  //   const input = document.createElement('input')
  //   input.setAttribute('type', 'file')
  //   input.setAttribute('directory', '')
  //   input.setAttribute('webkitdirectory', '')
  //   input.click()
  //   input.onchange = () => {
  //     if (input.files?.length) {
  //       const myFiles: File[] = []
  //       // convert file item to File type
  //       for (let i = 0; i < input.files.length; i += 1) {
  //         const newFile: any = input.files.item(i)
  //         newFile.path = `/${newFile?.webkitRelativePath}` || ''
  //         myFiles.push(input.files.item(i) as File)
  //       }
  //       onUpload(myFiles)
  //     }
  //   }
  // }

  // const handleMenuAction = (action: string) => {
  //   switch (action) {
  //     case 'uploadBox':
  //       handleUploadNode()
  //       break
  //     default:
  //       break
  //   }
  // }

  // Subscription
  useEffect(() => {
    const path = pathname
    setActiveButton(path === '/' || path.includes('/project') ? '/' : path)
    setIsLoading(false)
  }, [pathname])

  return (
    <>
      {mobile ? (
        <MenuMobile activeButton={activeButton} navigateTo={navigateTo} />
      ) : (
        <MenuDesktop activeButton={activeButton} navigateTo={navigateTo} />
      )}
    </>
  )
}

export default Menus
