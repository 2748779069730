import { useEffect, useState } from 'react'
import { Box, Flex, Container, Grid, Text, Image, Icon, useBreakpointValue } from '@chakra-ui/react'
import { useAppSlice } from 'features/app/app.slice'
import { NodeInfoPopover } from 'features/node/components'
import {
  formatTimeString,
  formatTimeSince,
  formatBytes,
  resourceType,
  getFileIconFromNode,
  isVideoFile,
  convertNodeColorToHex,
} from 'helpers/utils'
import { getResizedImage } from 'helpers/storage'
import { images, icons, colors } from 'theme'
import { Node } from 'types/graphqlSchema'
import { ReactComponent as Folder } from 'assets/folder_colored.svg'
import { isSafari } from 'react-device-detect'
import { nodeType, menuListCard } from 'helpers/constant'
import { IconPopover, IconSection, ListCardMenu } from 'components'

interface ListCardProps {
  item: Node
  handleClick: (node: Node) => void
  menuType: string
  handleMenuAction: (action: string, item: Node) => void
  noborder?: boolean
  background?: string
  showMenu?: boolean
}
const ListCard = ({
  item,
  handleClick,
  handleMenuAction,
  menuType,
  background,
  noborder,
  showMenu = true,
}: ListCardProps) => {
  const { name, updatedAt, coverImageSecureUrl, type, project, color, updatedBy } = item
  const folderColor = color ? convertNodeColorToHex(color) : ''

  const {
    user: { followedNodes },
  } = useAppSlice()
  const isVideo = isVideoFile(item.blocks)

  // check for new comments
  // const { data: notificationsNodeCount } = useQuery(
  //   NOTIFICATION_NODE_COMMENT_TIPS_COUNT,
  //   {
  //     variables: { nodeId: item.id, isTip: type === 'TIP' },
  //   },
  // )

  const [preview, setPreview] = useState<{ image: string; type: string }>({
    image: images.LogoGrey,
    type: 'image',
  })
  const [resizedCoverImage, setResizedCoverImage] = useState('')
  const [check, setCheck] = useState(true)

  const isMobile = useBreakpointValue({ xs: true, md: false })

  const handleAction = (action: string) => {
    handleMenuAction(action, item)
  }

  useEffect(() => {
    let active = true
    async function getUrl() {
      if (!coverImageSecureUrl) {
        if (type === nodeType.box) {
          setPreview({ image: images.folder, type: 'icon' })
        } else if (type === nodeType.project) {
          setPreview({ image: images.project, type: 'icon' })
        } else {
          const extensionIcon = getFileIconFromNode(item)
          setPreview({ image: extensionIcon, type: 'icon' })
        }
      } else {
        if (!active) return
        setResizedCoverImage(getResizedImage(coverImageSecureUrl, `${type.toLowerCase()}.list_view`) || '')
      }
    }
    getUrl()
    return () => {
      active = false
    }
  }, [coverImageSecureUrl, item])

  useEffect(() => {
    let timer
    if (check) {
      timer = setTimeout(() => {
        setCheck(false)
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [check])

  return (
    <Container
      variant="boxListCard"
      background={background}
      my={4}
      onClick={() => handleClick(item)}
      style={{ cursor: 'pointer' }}
      onMouseEnter={() => {
        setCheck(true)
      }}
      onMouseLeave={() => {
        setCheck(true)
      }}
    >
      <Grid
        templateColumns={
          menuType === 'search'
            ? ['4rem auto 15% 4rem', null, null, '4rem auto 70px 10% 15% 12% 6rem']
            : ['4rem auto 15% 4rem', null, null, '4rem auto 10% 28% 6rem']
        }
        h="full"
        w="full"
        maxW="full"
        overflow="hidden"
      >
        {/* ICON */}
        <Box w="4rem" h="4rem" overflow="hidden" pos="relative" gridRow={['1/3', null, null, 'unset']}>
          {resizedCoverImage ? (
            <Box bgImage={resizedCoverImage} h="98%" bgRepeat="no-repeat" bgSize="cover" rounded={8} />
          ) : (
            <Flex justify="center" align="center" h="full">
              {preview.type === 'image' ? (
                <Image src={preview.image} alt="item logo" />
              ) : (
                <Flex
                  justify="center"
                  align="center"
                  w={['4.188rem', null, null, '4rem']}
                  bg={background || 'transparent'}
                >
                  {type === nodeType.box ? (
                    <Folder fill={folderColor ? folderColor.body : colors.folder.darkGray.body} />
                  ) : (
                    <Image src={preview.image} alt="item logo" w="44%" h="64%" />
                  )}
                </Flex>
              )}
            </Flex>
          )}
          {isVideo && (
            <Flex pos="absolute" left={0} right={0} bottom={0} top={0} justify="center" align="center">
              <Image src={images.playVideo} alt="play" w="30%" />
            </Flex>
          )}
        </Box>

        {/* NAME */}
        {type === nodeType.project || isMobile ? (
          <Flex
            align="center"
            overflow="hidden"
            gridRow={['1/2', null, null, 'unset']}
            gridColumn={['2/4', null, null, 'unset']}
          >
            <Text fontWeight={400} pl={4} pr={[0, null, null, 4]} noOfLines={1}>
              {name || 'Untitled'}
            </Text>
          </Flex>
        ) : (
          <Flex
            direction="column"
            align="start"
            justify="center"
            overflow="hidden"
            gridColumn={['2/4', null, null, 'unset']}
            noOfLines={1}
            pt={!isSafari ? 2 : 0}
          >
            <Text fontWeight={400} align="start" pr={[0, null, null, 4]} px={4} maxW="full" noOfLines={1}>
              {name || 'Untitled'}
            </Text>
            <Text variant="subtitlePath" fontWeight={400} px={4} maxW="full" noOfLines={1}>
              {'in '}
              <Text as="span" color="textHighlight" fontWeight={500}>
                {project?.name}
              </Text>
            </Text>
          </Flex>
        )}

        {/* TYPE trash */}
        {menuType === 'trash' && (
          <Flex align="center" pr={4} gridColumn="3/4" gridRow="1/2">
            <Text fontWeight="400" textTransform="capitalize">
              {resourceType(type)?.toLowerCase()}
            </Text>
          </Flex>
        )}

        {/* TYPE search */}
        {!isMobile && menuType === 'search' && (
          <Flex align="center" pr={4} gridColumn="3/4" gridRow="1/2">
            <Text fontWeight="400" textTransform="capitalize">
              {type?.toLowerCase()}
            </Text>
          </Flex>
        )}

        {/* DATE */}
        <Flex
          align="center"
          overflow="hidden"
          gridRow={['2/3', null, null, 'unset']}
          gridColumn={menuType === 'search' ? [null, null, null, '5/6'] : [null, null, null, '4/5']}
          pl={4}
        >
          {menuType === 'recent' && !isMobile ? (
            <Text noOfLines={1}>Modified {formatTimeSince(updatedAt, 'date')}</Text>
          ) : (
            <Text fontSize={[12, null, null, 16]} noOfLines={1}>
              {formatTimeString(updatedAt)}
              {menuType === 'search' && updatedBy && (
                <Text as="span">{` by ${updatedBy.firstName} ${updatedBy.lastName}`}</Text>
              )}
            </Text>
          )}
        </Flex>

        {/* ICONS */}
        {menuType !== 'trash' && (
          <Flex
            gridRow={['2/3', null, null, '1/2']}
            gridColumn={menuType === 'search' ? [null, null, null, '4/5'] : [null, null, null, '3/4']}
          >
            <IconSection node={item} isMobile={isMobile || false} followedNodes={followedNodes} nodeNotifications={0} />
          </Flex>
        )}

        {/* Size */}

        {menuType === 'search' && !isMobile && (
          <Flex align="center" gridColumn="6/7" pl={4}>
            <Text noOfLines={1}>{formatBytes(parseFloat(item?.totalSize?.toString() || '0'), 2)}</Text>
          </Flex>
        )}

        {/* MENU */}
        <Flex
          gridRow={['1/3', null, null, 'unset']}
          gridColumn={menuType === 'search' ? ['4/5', null, null, '7/8'] : ['4/5', null, null, 'unset']}
          align="center"
          justify="space-around"
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          {!isMobile && (
            <NodeInfoPopover node={item}>
              <Icon
                as={icons.infoIcon}
                color="textRegular"
                h={[3, null, null, 5]}
                w={[3, null, null, 5]}
                p={0}
                _hover={{
                  color: 'white',
                }}
              />
            </NodeInfoPopover>
          )}
          {showMenu && (
            <IconPopover
              icon={icons.menuIcon}
              variantBtn="iconBtnSm"
              iconSize={{ width: [4, null, null, 5], height: [4, null, null, 5] }}
              variantPopover="popoverListCardMenu"
              placement="bottom-start"
            >
              <ListCardMenu
                node={item}
                handleMenuAction={handleAction}
                menuType={menuListCard[item.type || ''][menuType]}
                noBorder={noborder}
              />
            </IconPopover>
          )}
        </Flex>
      </Grid>
    </Container>
  )
}

export default ListCard
