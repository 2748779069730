import React, { useState, useMemo } from 'react'
import {
  chakra,
  Flex,
  Box,
  Text,
  Checkbox,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  useCheckboxGroup,
  Tooltip,
} from '@chakra-ui/react'
import { images } from 'theme'
import { useMutation, useQuery } from '@apollo/client'
import { GET_MY_NOTIFICATION_PREFERENCES, UPDATE_MY_NOTIFICATION_PREFERENCE } from 'features/graphql'
import { EventType, NotificationChannel, NotificationPreference } from 'types/graphqlSchema'
import { EVENT_TYPE, NOTIFICATION_CHANNEL } from 'helpers/constant'

import { useAppSlice, useUserSettingsSlice } from 'features/redux'

const NotificationsTab = () => {
  const [notificationPreferences, setNotificationPreference] = useState([])
  const { user } = useAppSlice()

  const { dispatch, setShowModalBody, setShowSMSActivation } = useUserSettingsSlice()

  const preferences = useMemo(() => {
    if (!notificationPreferences) {
      return {}
    }
    return notificationPreferences.reduce((obj, item: NotificationPreference) => {
      obj[item.eventType] = item.channels
      return obj
    }, {})
  }, [notificationPreferences])

  useQuery(GET_MY_NOTIFICATION_PREFERENCES, {
    onCompleted: ({ myNotificationPreferences }) => {
      setNotificationPreference(myNotificationPreferences)
    },
    onError: (error) => {
      throw new Error(error.message)
    },
  })

  const [mutateUpdateNotificationPreference] = useMutation(UPDATE_MY_NOTIFICATION_PREFERENCE, {
    onCompleted: ({ updateMyNotificationPreference }) => {
      setNotificationPreference(updateMyNotificationPreference.user.notificationsPreferences)
    },
    onError: (err) => {
      throw new Error(err.message)
    },
  })

  if (notificationPreferences.length === 0) {
    return <></>
  }

  return (
    <Box>
      <Text color={'textBlack'} fontSize={'14px'}>
        Preferences
      </Text>
      <Text color={'tipbox.dark.text.greyLight'} fontWeight={'400'} fontSize={'14px'} mb={'14px'}>
        Please select one or more options where you would like to receive your Tipbox notifications. Click on the icon
        to edit your contact details
      </Text>
      <TableContainer>
        <Table variant="stripped">
          <Thead>
            <Tr>
              <Th paddingLeft={0}>Types of Alerts</Th>
              <Th>
                <Flex direction="column" justify="center" align="center" w="full">
                  <Image src={images.emailNotificationV2} pb={'14px'} />
                  Email
                </Flex>
              </Th>
              <Th>
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  w="full"
                  cursor={'pointer'}
                  onClick={() => {
                    dispatch(setShowSMSActivation(true))
                    dispatch(setShowModalBody(false))
                  }}
                  _hover={{ color: 'accent' }}
                >
                  <Image src={images.smsNotificationV2} pb={'14px'} />
                  SMS
                </Flex>
              </Th>
              <Th opacity={0.5}>
                <Tooltip
                  hasArrow
                  bg={'black'}
                  label="This feature is not enabled yet, check back soon"
                  shouldWrapChildren
                  mt="3"
                >
                  <Flex direction="column" justify="center" align="center" w="full" cursor={'not-allowed'}>
                    <Image src={images.pushNotificationV2} pb={'14px'} />
                    Push
                  </Flex>
                </Tooltip>
              </Th>
              <Th opacity={0.5}>
                <Tooltip
                  hasArrow
                  bg={'black'}
                  label="This feature is not enabled yet, check back soon"
                  shouldWrapChildren
                  mt="3"
                >
                  <Flex direction="column" justify="center" align="center" w="full" cursor={'not-allowed'}>
                    <Image src={images.slackNotificationV2} pb={'14px'} />
                    Slack
                  </Flex>
                </Tooltip>
              </Th>
              <Th opacity={0.5}>
                <Tooltip
                  hasArrow
                  bg={'black'}
                  label="This feature is not enabled yet, check back soon"
                  shouldWrapChildren
                  mt="3"
                >
                  <Flex direction="column" justify="center" align="center" w="full" cursor={'not-allowed'}>
                    <Image src={images.teamsNotificationV2} pb={'14px'} />
                    Teams
                  </Flex>
                </Tooltip>
              </Th>
            </Tr>
          </Thead>
          <NotificationToggle
            preferences={preferences}
            mutateUpdateNotificationPreference={mutateUpdateNotificationPreference}
          />
        </Table>
      </TableContainer>
    </Box>
  )
}

const NotificationToggle = ({ preferences, mutateUpdateNotificationPreference }) => {
  const { user } = useAppSlice()
  const { dispatch, setShowSMSActivation, setShowModalBody } = useUserSettingsSlice()

  const flowActivityGroup = useCheckboxGroup({
    defaultValue: preferences[EVENT_TYPE.FLOW_ACTIVITY],
    onChange: async (value: string[]): Promise<void> => {
      if (value.includes(NOTIFICATION_CHANNEL.SMS) && !user.phoneVerified) {
        dispatch(setShowSMSActivation(true))
        dispatch(setShowModalBody(false))
        return
      }
      await mutateUpdateNotificationPreference({
        variables: {
          input: {
            eventType: EVENT_TYPE.FLOW_ACTIVITY as EventType,
            channels: value as NotificationChannel[],
          },
        },
      })
    },
  })

  const userMentionedGroup = useCheckboxGroup({
    defaultValue: preferences[EVENT_TYPE.USER_MENTIONED],
    onChange: async (value: string[]): Promise<void> => {
      if (value.includes(NOTIFICATION_CHANNEL.SMS) && !user.phoneVerified) {
        dispatch(setShowSMSActivation(true))
        dispatch(setShowModalBody(false))
        return
      }
      await mutateUpdateNotificationPreference({
        variables: {
          input: {
            eventType: EVENT_TYPE.USER_MENTIONED as EventType,
            channels: value as NotificationChannel[],
          },
        },
      })
    },
  })

  const resourceFollowedGroup = useCheckboxGroup({
    defaultValue: preferences[EVENT_TYPE.RESOURCE_FOLLOWED],
    onChange: async (value: string[]): Promise<void> => {
      if (value.includes(NOTIFICATION_CHANNEL.SMS) && !user.phoneVerified) {
        dispatch(setShowSMSActivation(true))
        dispatch(setShowModalBody(false))
        return
      }
      await mutateUpdateNotificationPreference({
        variables: {
          input: {
            eventType: EVENT_TYPE.RESOURCE_FOLLOWED as EventType,
            channels: value as NotificationChannel[],
          },
        },
      })
    },
  })

  const resourceSharedGroup = useCheckboxGroup({
    defaultValue: preferences[EVENT_TYPE.RESOURCE_SHARED],
    onChange: async (value: string[]): Promise<void> => {
      if (value.includes(NOTIFICATION_CHANNEL.SMS) && !user.phoneVerified) {
        dispatch(setShowSMSActivation(true))
        dispatch(setShowModalBody(false))
        return
      }
      await mutateUpdateNotificationPreference({
        variables: {
          input: {
            eventType: EVENT_TYPE.RESOURCE_SHARED as EventType,
            channels: value as NotificationChannel[],
          },
        },
      })
    },
  })

  const inTheLoopGroup = useCheckboxGroup({
    defaultValue: preferences[EVENT_TYPE.IN_THE_LOOP],
    onChange: async (value: string[]): Promise<void> => {
      if (value.includes(NOTIFICATION_CHANNEL.SMS) && !user.phoneVerified) {
        dispatch(setShowSMSActivation(true))
        dispatch(setShowModalBody(false))
      }
      await mutateUpdateNotificationPreference({
        variables: {
          input: {
            eventType: EVENT_TYPE.IN_THE_LOOP as EventType,
            channels: value as NotificationChannel[],
          },
        },
      })
    },
  })

  return (
    <Tbody>
      {/* Flow Activity */}
      <Tr>
        <Td paddingLeft={0} paddingRight={0} textAlign={'left'}>
          Flow activity (Default)
        </Td>
        {Object.keys(NOTIFICATION_CHANNEL).map((channel: string) => (
          <Td key={channel}>
            <Checkbox
              variant="tipbox"
              {...flowActivityGroup.getCheckboxProps({ value: channel })}
              isChecked={preferences[EVENT_TYPE.FLOW_ACTIVITY]?.includes(channel)}
              isDisabled={
                channel === NOTIFICATION_CHANNEL.PUSH ||
                channel === NOTIFICATION_CHANNEL.SLACK ||
                channel === NOTIFICATION_CHANNEL.MS_TEAMS
              }
            />
          </Td>
        ))}
      </Tr>
      <Tr backgroundColor={'#F8F8F8'}>
        {/* Mentioned */}
        <Td paddingLeft={0} paddingRight={0} textAlign={'left'}>
          What I am @mentioned (Default)
        </Td>
        {Object.keys(NOTIFICATION_CHANNEL).map((channel: string) => (
          <Td key={channel}>
            <Checkbox
              variant="tipbox"
              {...userMentionedGroup.getCheckboxProps({ value: channel })}
              isChecked={preferences[EVENT_TYPE.USER_MENTIONED]?.includes(channel)}
              isDisabled={
                channel === NOTIFICATION_CHANNEL.PUSH ||
                channel === NOTIFICATION_CHANNEL.SLACK ||
                channel === NOTIFICATION_CHANNEL.MS_TEAMS
              }
            />
          </Td>
        ))}
      </Tr>
      <Tr>
        <Td paddingLeft={0} paddingRight={0} textAlign={'left'}>
          Changes to items I follow
        </Td>
        {Object.keys(NOTIFICATION_CHANNEL).map((channel: string) => (
          <Td key={channel}>
            <Checkbox
              variant="tipbox"
              {...resourceFollowedGroup.getCheckboxProps({ value: channel })}
              isChecked={preferences[EVENT_TYPE.RESOURCE_FOLLOWED]?.includes(channel)}
              isDisabled={
                channel === NOTIFICATION_CHANNEL.PUSH ||
                channel === NOTIFICATION_CHANNEL.SLACK ||
                channel === NOTIFICATION_CHANNEL.MS_TEAMS
              }
            />
          </Td>
        ))}
      </Tr>
      <Tr backgroundColor={'#F8F8F8'}>
        <Td paddingLeft={0} paddingRight={0} textAlign={'left'}>
          All activity with shared items
        </Td>
        {Object.keys(NOTIFICATION_CHANNEL).map((channel: string) => (
          <Td key={channel}>
            <Checkbox
              variant="tipbox"
              {...resourceSharedGroup.getCheckboxProps({ value: channel })}
              isChecked={preferences[EVENT_TYPE.RESOURCE_SHARED]?.includes(channel)}
              isDisabled={
                channel === NOTIFICATION_CHANNEL.PUSH ||
                channel === NOTIFICATION_CHANNEL.SLACK ||
                channel === NOTIFICATION_CHANNEL.MS_TEAMS
              }
            />
          </Td>
        ))}
      </Tr>
      {/*  */}
      <Tr>
        <Td paddingLeft={0} paddingRight={0} textAlign={'left'}>
          When I am kept "In the Loop" <br />
          <chakra.span fontSize={'10px'} fontWeight={500} color={'tipbox.dark.text.greyLight'}>
            * Informed of a Flow, but no action is required
          </chakra.span>
        </Td>
        {Object.keys(NOTIFICATION_CHANNEL).map((channel: string) => (
          <Td key={channel}>
            <Checkbox
              variant="tipbox"
              {...inTheLoopGroup.getCheckboxProps({ value: channel })}
              isDisabled={
                channel === NOTIFICATION_CHANNEL.PUSH ||
                channel === NOTIFICATION_CHANNEL.SLACK ||
                channel === NOTIFICATION_CHANNEL.MS_TEAMS
              }
            />
          </Td>
        ))}
      </Tr>
    </Tbody>
  )
}

export default NotificationsTab
