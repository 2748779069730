import { Avatar, Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { AvatarsTooltip } from 'components/elements'
import { AlertWithAction } from '../Alerts'
import { useNodeMenuActions } from 'features/node'
import { menuActions } from 'helpers/constant'

const UserInfo = ({ ownerName, members, ownerUrl, ownerIsCurrentUser, nodeId, nodeType, setOpenShareModal }) => {
  const { handleLeaveSharedNode } = useNodeMenuActions()
  const {
    isOpen: isOpenLeaveSharedNode,
    onOpen: onOpenLeaveSharedNode,
    onClose: onCloseLeaveSharedNode,
  } = useDisclosure()

  return (
    <>
      <Flex flexDir="column" textAlign="left" color="textRegular">
        <Flex gap=".5rem" alignItems="center" mb="1rem">
          <Avatar size="sm" name={ownerName} src={ownerUrl} />
          <Flex fontSize="14px" fontWeight={400} flexDir="column" textAlign="left">
            <Text color="textRegular">Owner</Text>
            <Text color="textSoft">{ownerIsCurrentUser ? 'You (' + ownerName + ')' : ownerName}</Text>
          </Flex>
        </Flex>
        {!!members.length && (
          <>
            <Text fontSize="15px" fontWeight="normal" color="inherit" mb="8px">
              Shared with
            </Text>
            <Box mb={2}>
              <AvatarsTooltip
                users={members}
                max={3}
                size="sm"
                darkBg={true}
                clickAction={() => setOpenShareModal(menuActions.share)}
              />
            </Box>
            {!ownerIsCurrentUser && nodeType === 'BOX' && (
              <Text
                as="button"
                color="textRegular"
                fontWeight={400}
                fontSize=".75rem"
                mt={2}
                pb={1}
                textDecoration="underline"
                maxW="max-content"
                onClick={onOpenLeaveSharedNode}
              >
                Remove Myself
              </Text>
            )}
          </>
        )}
      </Flex>
      <AlertWithAction
        action={() => {
          onCloseLeaveSharedNode()
          handleLeaveSharedNode(nodeId)
        }}
        isOpen={isOpenLeaveSharedNode}
        onClose={onCloseLeaveSharedNode}
        actionText="Remove Myself"
        warningText={['You will no longer have access to this folder.']}
      />
    </>
  )
}

export default UserInfo
