import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { Button } from 'components'
import { images } from 'theme'

const NotFound = () => (
  <Box pos="absolute" w="100vw" h="100vh" bg="white" zIndex={100} style={{ left: 0, top: 0 }} display="flex">
    <Box
      style={{
        margin: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          justifyContent: 'center',
          display: 'flex',
          marginBottom: 30,
        }}
      >
        <img src={images.errorPic} alt="logo" />
      </Box>
      <Box
        style={{
          width: '200px',
          height: '29px',
          fontFamily: 'Inter',
          fontSize: '22px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          textAlign: 'center',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          margin: '20px auto',
        }}
      >
        <Text color="accent">Error 404</Text>
      </Box>
      <Box
        style={{
          width: '400px',
          height: '21px',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: '20px',
          textAlign: 'center',
          letterSpacing: 'normal',
          color: 'textBlack',
          margin: '20px auto 0px auto',
        }}
      >
        <Text color="textBlack">We can’t find what you’re looking for.</Text>
      </Box>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Text color="textBlack" textAlign="center" maxW="23rem" mx="auto" fontSize={14} lineHeight="1.2">
          Back to
          <Button
            onClick={() => {
              window.location.href = '/'
            }}
            label="home"
            variant="inlineLinkButton"
            style={{ marginTop: '0.225rem' }}
          />
        </Text>
      </div>
    </Box>
  </Box>
)

export default NotFound
