import { Box, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react'
import FlowModalToggle from './FlowModalToggle'
import { images } from 'theme'
import FlowFormMessage from './FlowFormMessage'
import React from 'react'

const FlowFormOptions = ({
  flowType,
  flowState,
  setFlowState,
  setShowTagsPane,
  attachedTags,
  setAttachedTags,
  handleMessageChange,
  setTextAreaExpanded,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })

  return (
    <Flex mb="auto" flexDir={'column'} gap={isMobile ? 4 : '14px'} mt={'0.75rem'}>
      <Text minW="6.75rem" color="textBlack" ml={isMobile ? 0 : 6}>
        Optional
      </Text>
      <Flex flexDir={'column'} gap={'1.25rem'}>
        <FlowFormMessage
          value={flowState?.message}
          onChange={handleMessageChange}
          setTextAreaExpanded={setTextAreaExpanded}
        />
        <Flex flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? '0.75rem' : '2rem'} ml={isMobile ? 0 : 6}>
          {/* Upload */}
          {flowType === 'Feedback' && (
            <FlowModalToggle
              falseImage={images.flowToggleNegative}
              trueImage={images.checkmark}
              toggleItem={flowState.uploadNeed}
              onChange={() => {
                setFlowState((prev) => ({
                  ...prev,
                  uploadNeed: !prev.uploadNeed,
                }))
              }}
            >
              File Upload Required
            </FlowModalToggle>
          )}
          {/* Urgent */}
          <FlowModalToggle
            falseImage={images.flowToggleNegative}
            trueImage={images.flowToggleExclamation}
            toggleItem={flowState.urgent}
            onChange={() => {
              setFlowState((prev) => ({
                ...prev,
                urgent: !prev.urgent,
              }))
            }}
          >
            {flowState.urgent ? 'Urgent' : 'Mark Urgent'}
          </FlowModalToggle>
          {/* Tags */}
          <Flex alignItems="center">
            <FlowModalToggle
              falseImage={images.flowToggleNegative}
              trueImage={images.flowTogglePositive}
              toggleItem={!!attachedTags.length}
              onChange={() => setShowTagsPane(true)}
            >
              {attachedTags?.length ? 'Tags Added' : 'Add Tags'}
            </FlowModalToggle>
          </Flex>
        </Flex>
        <Flex gap={'1rem'} ml={6} wrap={'wrap'} mt={'4px'}>
          {attachedTags.map((tag) => {
            return (
              <Flex
                key={tag}
                bg="var(--chakra-colors-textSoft)"
                gap={2}
                pl={3}
                pr={3}
                py="3px"
                rounded={8}
                align="center"
                minW="max"
              >
                <Image src={images.tagGrey} alt="tag" />
                <Text color="textBlack" cursor="default" onClick={(e) => e.stopPropagation()}>
                  {tag}
                </Text>
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    const nextTags = attachedTags.filter((at) => at !== tag)
                    setAttachedTags(nextTags)
                  }}
                >
                  <Box minW="8px">
                    <img src={images.xSmall} />
                  </Box>
                </button>
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FlowFormOptions
