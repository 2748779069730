import React from 'react'
import { Breadcrumb as ChakraBreadcrumb, BreadcrumbItem, BreadcrumbLink, Icon } from '@chakra-ui/react'
import { icons } from 'theme'
import BreadcrumbMobileMenu from './BreadcrumbMobileMenu'
import { Node } from 'types/graphqlSchema'

interface CurrentNode {
  name: string
  type: string
  breadcrumbs: IBreadcrumb[]
}

interface BreadcrumbMobileMenuProps {
  currentNode: CurrentNode | undefined
  project: Node | undefined
  mainLabel: { label: string; path: string; bold: boolean }
  handleClick: (node: IBreadcrumb) => void
}

const BreadcrumbMobile = ({ currentNode, project, mainLabel, handleClick }: BreadcrumbMobileMenuProps) => (
  <>
    <ChakraBreadcrumb
      spacing="8px"
      separator={<Icon as={icons.FaChevronRight} h={4} w={4} color="textRegular" />}
      fontFamily="Inter"
      fontWeight={700}
      fontSize={16}
      w="full"
      maxW={['100%', null, null, '90%']}
      overflowX="hidden"
      noOfLines={1}
    >
      {!currentNode && !project && (
        <BreadcrumbItem color="textRegular" fontSize={20} fontWeight={700}>
          <BreadcrumbLink onClick={() => {}}>{mainLabel.label}</BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {!currentNode && project && mainLabel.label === 'Personal' && (
        <BreadcrumbItem color="textRegular" fontSize={20} fontWeight={700}>
          <BreadcrumbLink onClick={() => {}}>{mainLabel.label}</BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {mainLabel.label !== 'Personal' && !currentNode && project && (
        <BreadcrumbItem color="textRegular" fontSize={20}>
          <BreadcrumbLink onClick={() => {}}>{project.name}</BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {currentNode && (
        <BreadcrumbItem>
          <BreadcrumbMobileMenu currentNode={currentNode} handleClick={handleClick} />
        </BreadcrumbItem>
      )}

      {currentNode && (
        <BreadcrumbItem color="textRegular" fontSize={20} fontWeight={700}>
          <BreadcrumbLink onClick={() => {}} noOfLines={1} maxW="88%">
            {currentNode.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </ChakraBreadcrumb>
  </>
)

export default BreadcrumbMobile
