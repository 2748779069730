import React, { useState, useEffect } from 'react'
import {
  Divider,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { AnimatedCheckLarge } from 'components'
import { Flow } from 'types/graphqlSchema'

interface FSCMProps {
  isOpen: boolean
  onClose: () => void
  flow: Flow
}

const FlowStartConfirmationModal = ({ isOpen, onClose, flow }: FSCMProps) => {
  const navigate = useNavigate()
  const [requestName, setRequestName] = useState('')

  useEffect(() => {
    if (flow && flow.workflow) {
      setRequestName(flow.workflow?.name === 'Feedback' ? 'General' : flow.workflow?.name ?? '')
    }
  }, [flow.workflow])

  const handleSaveAsDocument = async (savingFlow) => {
    if (!savingFlow) return
    onClose()
    navigate(`/flows/flow/${savingFlow.id}`, { replace: true, state: { savingFlowId: savingFlow.id } })
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          display="flex"
          flexDir="column"
          p="1.5rem"
          bg="white"
          color="textBlack"
          fontFamily="Inter"
          w="600px"
          maxW="80%"
        >
          <ModalHeader>
            <Text fontSize="1rem" color={'textBlack'} textAlign={'center'}>
              {flow.name || ''}
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody justifySelf="center">
            <Flex
              flexDir="column"
              my="auto"
              minH="10rem"
              overflowY="scroll"
              overflowX="hidden"
              css={{
                '&::-webkit-scrollbar': {
                  width: '0',
                },
                '&::-webkit-scrollbar-track': {
                  width: '0',
                },
              }}
            >
              <motion.div key="saved" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Flex flexDir={'column'} mx="auto" alignItems={'center'} maxH={'342px'} py={'0.75rem'}>
                  <AnimatedCheckLarge caption={requestName + ' Request Sent'} />
                  <Text
                    fontWeight={400}
                    color={'modalSubText'}
                    fontSize="1rem"
                    w="44ch"
                    textAlign="center"
                    whiteSpace="pre-line"
                    mt={'0.5rem'}
                    mb={'3rem'}
                  >
                    View and track the progress of this Flow{'\n'} within the{' '}
                    <Link href="/flows" color="accent">
                      Flows Page.
                    </Link>
                  </Text>
                  <Divider borderColor="textRegular" w="50px" />
                  <Text
                    fontWeight={400}
                    color={'modalSubText'}
                    fontSize="1rem"
                    w="44ch"
                    textAlign="center"
                    whiteSpace="pre-line"
                    mt={'0.75rem'}
                  >
                    You can also{' '}
                    <Link color="accent" onClick={() => handleSaveAsDocument(flow)}>
                      save this as a document.
                    </Link>
                  </Text>
                </Flex>
              </motion.div>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FlowStartConfirmationModal
