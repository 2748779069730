import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useRef, useState, useEffect } from 'react'

type InputSettingsProps = {
  inputKey: string
  value: string
  setValue: (val: string) => void
  type?: 'text' | 'password'
  handleData: () => void
  handeClickRightElement?: () => void
}

const SettingsInput = ({
  inputKey,
  value,
  setValue,
  type = 'text',
  handleData,
  handeClickRightElement = () => {},
}: InputSettingsProps) => {
  const [isDisabled, setIsDisabled] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleOnBlur = () => {
    setIsDisabled(true)
    handleData()
  }

  const handleButtonClick = () => {
    handeClickRightElement()
    setIsDisabled(false)
  }

  useEffect(() => {
    if (!isDisabled && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isDisabled])

  return (
    <InputGroup>
      <Input
        value={value}
        variant={'modal'}
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleOnBlur}
        type={type}
        isDisabled={isDisabled}
        ref={inputRef}
      />
      <InputRightElement
        pr={'16px'}
        fontSize={'12px'}
        width={'default'}
        color={'tipbox.dark.text.greyLight'}
        onClick={handleButtonClick}
        cursor={'pointer'}
        _hover={{ color: 'accent' }}
      >
        Change {inputKey}
      </InputRightElement>
    </InputGroup>
  )
}

export default SettingsInput
