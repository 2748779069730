import React from 'react'
// Components
import { Container, UnorderedList, ListItem } from '@chakra-ui/react'

const AuthError = ({ errorMessage, inputError }: IAuthErrorProps) => (
  <Container centerContent data-testid="login-error">
    {errorMessage ? (
      <Container variant="badgeFlexAlert" data-testid="auth-error">
        <UnorderedList>
          <ListItem color="alert" fontWeight={600}>
            {errorMessage}
          </ListItem>
        </UnorderedList>
      </Container>
    ) : (
      ''
    )}
    {inputError && Object.values(inputError).length ? (
      <Container variant="badgeFlexAlert" data-testid="auth-error">
        <UnorderedList>
          {Object.keys(inputError).map((k) => (
            <ListItem key={k} color="alert" fontWeight={600} fontSize={[12, null, null, 16]}>
              {inputError[k]}
            </ListItem>
          ))}
        </UnorderedList>
      </Container>
    ) : (
      ''
    )}
  </Container>
)

export default AuthError
