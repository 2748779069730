import React, { useEffect, useRef } from 'react'
import { Flex, Box, useColorModeValue } from '@chakra-ui/react'
import WaveSurfer from 'wavesurfer.js'
import './style.css'

interface IAudioProp {
  id?: string
  src: string
}
const AudioPlayer = ({ id, src }: IAudioProp) => {
  const playerRef = useRef<any>('')
  const wrapperRef = useRef<any>('')
  const progressColor = useColorModeValue('#D52D2C', '#7A1ADB')
  const waveColor = useColorModeValue('#E6E6E6', '#b3b3b3')

  // Subscription
  useEffect(() => {
    const audioPlayer = playerRef.current
    const wrapper = wrapperRef.current
    const waveform = WaveSurfer.create({
      barWidth: 3,
      cursorWidth: 1,
      container: `#waveform-${id}`,
      backend: 'WebAudio',
      height: wrapper.offsetHeight - audioPlayer.offsetHeight,
      progressColor,
      responsive: true,
      waveColor,
      cursorColor: 'transparent',
    })

    waveform.on('seek', () => {
      // audioPlayer.currentTime = waveform.getCurrentTime()
    })

    waveform.load(audioPlayer)
    waveform.backend.setVolume(0)
    audioPlayer.onplay = () => {
      waveform.play()
    }
    audioPlayer.onpause = () => {
      waveform.pause()
    }
    audioPlayer.onseeked = () => {
      waveform.setCurrentTime(audioPlayer.currentTime)
    }
    audioPlayer.onended = () => {
      audioPlayer.currentTime = 0
      waveform.stop()
    }
    return () => {
      // Removes all waveform event listeners
      waveform.unAll()
    }
  }, [])

  return (
    <Flex ref={wrapperRef} direction="column" h="25vh" w="full">
      <Box id={`waveform-${id}`} w="100%" bgColor={useColorModeValue('white', 'transparent')} />
      <audio ref={playerRef} id="audioPlayer" src={src} controls />
    </Flex>
  )
}

export default AudioPlayer
