import { gql } from '@apollo/client'

export const workflow = `
  id
  name
  active
  nodeTypes
  workflowStates {
    id
    workflowId
    stateName
  }
  workflowTransitions {
    id
    workflowId
    fromStateId
    toStateId
    transitionLabel
  }
`

export const flow = `
  id
  __typename
  name
  flowState
  node {
    id
    name
    projectId
    project {
      id
      name
    }
  }
  projectId
  project {
    id
    name
  }
  workflowId
  workflow {
    id
    name
    organizationId
  }
  requester {
    id
    firstName
    lastName
    email
    avatar
    avatarSecureUrl
  }
  state {
    id
    workflowId
    stateName
  }
  tags {
    name
  }
  participants {
    id
    firstName
    lastName
    email
    avatar
    avatarSecureUrl
  }
  watchers {
    id
    firstName
    lastName
    email
  }
  blocks {
    id
    name
    order
    extension
  }
  sharedMembers {
    id
    sharedBy {
      id
      email
    }
    sharedWith {
      id
      email
    }
    sharedWithId
    permission {
      id
      name
      permissions {
        permissionName
        entityType
        allowed
      }
    }
    permissionId
    isPublic
    entityType
    entityId
  }
  dueDate
  urgent
  uploadNeed
  requesterMessage
  totalSize
  metadata
  updatedAt
  createdAt
  flowLogs {
    id
    flowId
    user {
      id
      firstName
      lastName
      email
    }
    transitionId
    fromState {
      id
      workflowId
      stateName
    }
    toState {
      id
      workflowId
      stateName
    }
    metadata
    updatedAt
    createdAt
  }
`

export const flowLog = `
  id
  flowId
  user {
    id
    firstName
    lastName
    email
  }
  transition {
    id
    fromStateId
    toStateId
    transitionLabel
    actions {
      id
      name
      functionName
    }
  }
  fromState {
    id
    stateName
  }
  toState {
    id
    stateName
  }
  metadata
  updatedAt
  createdAt
`

// ---------------------------
// QUERIES
// ---------------------------

export const GET_WORKFLOW = gql`
  query GetWorkflow($workflowId: ID!) {
    workflow(id: $workflowId) {
      ${workflow}
    }
  }
`

export const GET_WORKFLOWS = gql`
  query Workflows($filter: WorkflowInput!) {
    workflows(filter: $filter) {
      ${workflow}
    }
  }
`

export const GET_FLOWS = gql`
  query Flows($filter: FlowFilterInput) {
    flows(filter: $filter) {
      ${flow}
    }
  }
`

export const GET_FLOW = gql`
  query GetFlowById($id: ID!) {
    flowById(id: $id) {
      error
      flow {
        id
        __typename
        name
        flowState
        parentId
        children {
          id
        }
        projectId
        project {
          id
          name
        }
        organizationId
        organization {
          id
          name
        }
        node {
          id
          name
          type
          ownerId
          organizationId
          projectId
          project {
            id
            name
          }
          owner {
            id
            avatar
            avatarSecureUrl
            firstName
            lastName
          }
        }
        workflowId
        workflow {
          id
          name
          organizationId
        }
        requesterId
        requester {
          id
          firstName
          lastName
          email
          avatar
          avatarSecureUrl
        }
        state {
          id
          workflowId
          stateName
        }
        participants {
          id
          firstName
          lastName
          email
          avatar
          avatarSecureUrl
        }
        watchers {
          id
          firstName
          lastName
          email
        }
        blocks {
          id
          type
          name
          content
          contentSecureUrl
          order
          extension
          preview
          previewSecureUrl
          ownerId
          owner {
            id
            email
            firstName
            lastName
            avatar
            avatarSecureUrl
          }
          createdAt
          updatedAt
        }
        sharedMembers {
          id
          sharedBy {
            id
            email
          }
          sharedWith {
            id
            email
          }
          sharedWithId
          permission {
            id
            name
            permissions {
              permissionName
              entityType
              allowed
            }
          }
          permissionId
          isPublic
          entityType
          entityId
        }
        dueDate
        urgent
        uploadNeed
        requesterMessage
        totalSize
        metadata
        updatedAt
        createdAt
        tags {
          name
          id
        }
        flowLogs {
          id
          flowId
          user {
            id
            firstName
            lastName
            email
            avatarSecureUrl
          }
          transitionId
          fromState {
            id
            workflowId
            stateName
          }
          toState {
            id
            workflowId
            stateName
          }
          metadata
          updatedAt
          createdAt
        }
      }
    }
  }
`

export const GET_FLOW_WITH_DESCENDANTS = gql`
  query GetFlowById($id: ID!) {
    flowById(id: $id) {
      error
      flow {
        id
        name
        flowState
        parentId
        children {
          id
        }
        projectId
        project {
          id
          name
        }
        organizationId
        organization {
          id
          name
        }
        workflowId
        workflow {
          id
          name
        }
        state {
          id
          stateName
        }
        requesterId
        requester {
          id
          firstName
          lastName
          email
          avatar
          avatarSecureUrl
        }
        participants {
          id
          firstName
          lastName
          email
          avatar
          avatarSecureUrl
        }
        watchers {
          id
          firstName
          lastName
          email
        }
        sharedMembers {
          id
          sharedBy {
            id
            email
          }
          sharedWith {
            id
            email
          }
          sharedWithId
          permission {
            id
            name
            permissions {
              permissionName
              entityType
              allowed
            }
          }
          permissionId
          isPublic
          entityType
          entityId
        }
        dueDate
        urgent
        uploadNeed
        requesterMessage
        totalSize
        metadata
        updatedAt
        createdAt
        tags {
          name
          id
        }
        flowLogs {
          id
          flowId
          user {
            id
            firstName
            lastName
            email
          }
          transitionId
          fromState {
            id
            stateName
          }
          toState {
            id
            stateName
          }
          metadata
          updatedAt
          createdAt
        }
      }
    }
  }
`

// ---------------------------
// MUTATIONS
// ---------------------------

export const CREATE_FLOW = gql`
  mutation CreateFlow($input: FlowCreatedInput!) {
    createFlow(input: $input) {
      error
      flow {
        id
        name
        flowState
        projectId
        project {
          id
          name
        }
        organizationId
        organization {
          id
          name
        }
        requester {
          id
          firstName
          lastName
          email
          avatar
          avatarSecureUrl
        }
        updatedAt
        createdAt
      }
    }
  }
`

export const UPDATE_FLOW = gql`
  mutation UpdateFlow($input: FlowUpdateInput!) {
    updateFlow(input: $input) {
      error
      flow {
        id
        name
        flowState
        parentId
        children {
          id
        }
        projectId
        project {
          id
          name
        }
        organizationId
        organization {
          id
          name
        }
        node {
          id
          name
          type
          ownerId
          organizationId
          projectId
          project {
            id
            name
          }
          owner {
            id
            avatar
            avatarSecureUrl
            firstName
            lastName
          }
        }
        workflowId
        workflow {
          id
          name
          organizationId
        }
        requesterId
        requester {
          id
          firstName
          lastName
          email
          avatar
          avatarSecureUrl
        }
        state {
          id
          workflowId
          stateName
        }
        participants {
          id
          firstName
          lastName
          email
          avatar
          avatarSecureUrl
        }
        watchers {
          id
          firstName
          lastName
          email
        }
        blocks {
          id
          type
          content
          contentSecureUrl
          order
          extension
          preview
          previewSecureUrl
          ownerId
          owner {
            id
            email
            firstName
            lastName
            avatar
            avatarSecureUrl
          }
          createdAt
          updatedAt
        }
        dueDate
        urgent
        uploadNeed
        requesterMessage
        totalSize
        metadata
        updatedAt
        createdAt
        tags {
          name
          id
        }
        flowLogs {
          id
          flowId
          user {
            id
            firstName
            lastName
            email
          }
          transitionId
          fromState {
            id
            workflowId
            stateName
          }
          toState {
            id
            workflowId
            stateName
          }
          metadata
          updatedAt
          createdAt
        }
      }
    }
  }
`

export const START_WORKFLOW = gql`
  mutation StartWorkflow($input: StartWorkflowInput) {
    startWorkflow(input: $input) {
      error
      flowLog {
        ${flowLog}
      }
    }
  }
`

export const EXECUTE_TRANSITION = gql`
  mutation ExecuteTransition($input: ExecuteTransitionInput) {
    executeTransition(input: $input) {
      error
      flowLog {
        ${flowLog}
      }
    }
  }
`

export const CANCEL_FLOW = gql`
  mutation CancelFlow($flowId: ID!) {
    cancelFlow(flowId: $flowId) {
      error
      flowLog {
        ${flowLog}
      }
    }
  }
`

export const ARCHIVE_FLOW = gql`
  mutation ArchiveFlow($flowId: ID!) {
    archiveFlow(flowId: $flowId) {
      error
      flowLog {
        ${flowLog}
      }
    }
  }
`
export const DELETE_FLOW = gql`
  mutation DeleteFlow($id: ID!) {
    deleteFlow(id: $id) {
      error
      flow {
        id
        name
        flowState
      }
    }
  }
`

export const REMOVE_ME_FROM_FLOW = gql`
  mutation RemoveMeFromFlow($flowId: ID!) {
    removeMeFromFlow(flowId: $flowId) {
      error
      flowLog {
        ${flowLog}
      }
    }
  }
`

// ---------------------------
// SUBSCRIPTIONS
// ---------------------------

export const FLOWS_SUBSCRIPTION = gql`
  subscription OnFlowsUpdated {
    flowsUpdated {
      flowLog {
        ${flowLog}
      }
    }
  }
`

export const FLOW_SUBSCRIPTION = gql`
  subscription OnFlowUpdated($key: String!) {
    flowUpdated(key: $key) {
      flowLog {
        ${flowLog}
      }
    }
  }
`
