import { gql } from '@apollo/client'

const tipUser = `
  id
  email
  firstName
  lastName
  avatar
`

const shareMember = `
  id
  sharedBy {
    id
    firstName
    lastName
    email
    avatar
  }
  sharedWith {
    id
    firstName
    lastName
    email
    avatar
    avatarSecureUrl
  }
  sharedWithId
  permission {
    id
    name
    permissions {
      permissionName
      entityType
      allowed
    }
  }
  permissionId
  isPublic
  entityType
  entityId
`

const linkShare = `
  randomPath
  linkType
  expiryDate
  hasPassword
  permissionType
`

const tipOutput = `
  id
  name
  __typename
  type
  state
  private
  coverImage
  coverImageSecureUrl
  totalSize
  createdAt
  updatedAt
  updatedBy {
    ${tipUser}
  }
  owner {
    ${tipUser}
  }
  projectId
  project {
    id
    name
    category
  }
  parent {
    id
    name
    type
    private
  }
  sharedMembers {
    ${shareMember}
  }
  breadcrumbs {
    name
    id
    type
  }
`

// ---------------------------
// MUTATIONS
// ---------------------------

export const DELETE_TIP_ASSET_FILES = gql`
  mutation Mutation($s3Keys: [String]) {
    deleteTipAssetFiles(s3Keys: $s3Keys)
  }
`

export const MOVE_TIP = gql`
  mutation MoveNode($resourceId: ID!, $destinationId: ID!) {
    moveNode(resourceId: $resourceId, destinationId: $destinationId) {
      id
      name
      state
      projectId
      project {
        id
        name
        category
      }
      breadcrumbs {
        name
        id
        type
      }
    }
  }
`

export const SHARE_TIP = gql`
  mutation shareTip(
    $shareItemId: String
    $shareUsersEmails: [String]
    $shareType: String
  ) {
    shareTip(
      shareItemId: $shareItemId
      shareUsersEmails: $shareUsersEmails
      shareType: $shareType
    ) {
      ${tipOutput}
    }
  }
`

export const UNSHARE_TIP = gql`
  mutation unshareTip(
   $shareItemId: String, $unshareUserId: String,
  ) {
    unshareTip(
     shareItemId: $shareItemId, unshareUserId: $unshareUserId,
    ) {
      ${tipOutput}
    }
  }
`

export const UPDATE_SHARE_TIP = gql`
  mutation updateShareTip(
   $shareItemId: String, $shareUserId: String, $shareType: String
  ) {
    updateShareTip(
     shareItemId: $shareItemId, shareUserId: $shareUserId, shareType: $shareType
    ) {
      ${tipOutput}
    }
  }
`
