import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { client as apollo } from 'helpers/apollo'
import { AppDispatch, RootState } from 'helpers/store'
import { NodeUpdateInput } from 'types/graphqlSchema'
import {
  GET_NODE,
  DELETE_NODE,
  UPDATE_NODE,
  CREATE_NODE_SHARE_MEMBER,
  UPDATE_NODE_SHARE_MEMBER,
  UNSHARE_NODE_SHARE_MEMBER,
  USER_NODE_GUESTS,
  REMOVE_USER_FROM_SHARED_NODES,
  REMOVE_COVER_IMAGE,
  UPLOAD_NODE_FOLDERS,
  GET_TRASH_NODES,
} from './node.schema'

// ---------------------------------------
// Thunks
// ---------------------------------------

const getNode = createAsyncThunk('node/getNode', async (nodeId: string) => {
  try {
    const {
      data: { node },
    } = await apollo.query({
      query: GET_NODE,
      variables: { id: nodeId },
    })
    return node
  } catch (error: any) {
    throw new Error(error)
  }
})

const deleteNode = createAsyncThunk('node/deleteNode', async (id: string) => {
  try {
    const { data } = await apollo.mutate({
      mutation: DELETE_NODE,
      variables: { id },
    })

    return data.deleteNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const updateNode = createAsyncThunk('node/updateNode', async (input: NodeUpdateInput) => {
  try {
    const { data } = await apollo.mutate({
      mutation: UPDATE_NODE,
      variables: { input },
    })
    return data?.updateNode
  } catch (error) {
    throw new Error(error?.message)
  }
})

const removeCoverImage = createAsyncThunk('node/removeCoverImage', async (id: string) => {
  try {
    const {
      data: { deleteCoverImage },
    } = await apollo.mutate({
      mutation: REMOVE_COVER_IMAGE,
      variables: { id },
    })
    return deleteCoverImage
  } catch (error: any) {
    throw new Error(error.message)
  }
})

const createNodeShareMembers = createAsyncThunk('node/createNodeShareMembers', async (params: any) => {
  try {
    const { data } = await apollo.mutate({
      mutation: CREATE_NODE_SHARE_MEMBER,
      variables: {
        input: {
          ...params,
          shareType: params.shareType,
        },
      },
    })
    if (data.error) {
      throw new Error(data.error.message)
    }
    return data.shareNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const updateNodeShareMember = createAsyncThunk('node/updateShareMember', async (params: any) => {
  try {
    const {
      data: { updateNodeShare, error },
    } = await apollo.mutate({
      mutation: UPDATE_NODE_SHARE_MEMBER,
      variables: {
        input: {
          ...params,
          shareType: params.shareType,
        },
      },
    })
    if (error) {
      throw new Error(error.message)
    }
    return updateNodeShare
  } catch (error: any) {
    throw new Error(error)
  }
})

const unshareNodeShareMember = createAsyncThunk('node/unshareNodeShareMember', async (params: any) => {
  try {
    const {
      data: { unshareNode, error },
    } = await apollo.mutate({
      mutation: UNSHARE_NODE_SHARE_MEMBER,
      variables: {
        input: {
          ...params,
          shareType: params.shareType,
        },
      },
    })
    if (error) {
      throw new Error(error.message)
    }
    return unshareNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const nodeGuests = createAsyncThunk('node/nodeGuests', async () => {
  try {
    const {
      data: {
        sharedNodes: { sharedMembers },
        error,
      },
    } = await apollo.query({
      query: USER_NODE_GUESTS,
    })

    if (error) {
      throw new Error(error.message)
    }
    return sharedMembers
  } catch (error: any) {
    throw new Error(error)
  }
})

const removeUserFromSharedNodes = createAsyncThunk('node/removeUserFromSharedNodes', async (id: string) => {
  try {
    const { data } = await apollo.mutate({
      mutation: REMOVE_USER_FROM_SHARED_NODES,
      variables: { id },
    })

    if (data.error) {
      throw new Error(data.error.message)
    }
    return data
  } catch (error: any) {
    throw new Error(error)
  }
})
const createUploadFolder = createAsyncThunk('node/createUploadFolder', async (variables: any) => {
  try {
    const { data } = await apollo.mutate({
      mutation: UPLOAD_NODE_FOLDERS,
      variables,
    })
    return data.uploadNodes
  } catch (error) {
    throw new Error(error)
  }
})

const getTrashNode = createAsyncThunk('node/trashNodes', async (variables: any) => {
  try {
    const { data } = await apollo.mutate({
      mutation: GET_TRASH_NODES,
      variables,
    })
    return data.trashNodes
  } catch (error) {
    throw new Error(error)
  }
})

interface NodeState {
  currentNode: INode | null
  trashNodes: INode[]
  error: any
}

const initialState: NodeState = {
  currentNode: null,
  trashNodes: [],
  error: null,
}

// slice
const nodeSlice = createSlice({
  name: 'node',
  initialState,
  reducers: {
    setTrashNodes(state, { payload }) {
      state.trashNodes = payload
    },
  },
})

export function useNodeSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.node)
  return {
    dispatch,
    ...state,
    ...nodeSlice.actions,
    getNode,
    getTrashNode,
    updateNode,
    nodeGuests,
    createUploadFolder,
    createNodeShareMembers,
    updateNodeShareMember,
    unshareNodeShareMember,
    removeCoverImage,
    removeUserFromSharedNodes,
  }
}

export default nodeSlice.reducer
