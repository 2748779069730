import { gql } from '@apollo/client'

const messageFragment = `
  id
  sender {
    id
    firstName
    lastName
    avatar
    avatarSecureUrl
    email
  }
  receiver {
    id
    firstName
    lastName
    avatar
    avatarSecureUrl

    email
  }
  mentions
  nodeId
  node {
    id
    name
  }
  flowId
  flow {
    id
    name
  }
  message
  messageType
  createdAt
  updatedAt
`

// ---------------------------
// QUERIES
// ---------------------------
export const GET_COMMENTS = gql`
  query getComments($nodeId: ID, $flowId: ID, $skip: Int, $take: Int) {
    comments(nodeId: $nodeId, flowId: $flowId, skip: $skip, take: $take) {
      id
      sender {
        id
        firstName
        lastName
        avatar
        avatarSecureUrl
      }
      mentions
      message
      messageType
      reactions {
        id
        userId
        user {
          firstName
          lastName
          avatar
          avatarSecureUrl
          email
        }
        types
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`

// ---------------------------
// MUTATIONS
// ---------------------------

export const ADD_COMMENT = gql`
  mutation addComment($input: MessageAddInput!) {
    addComment(input: $input) {
      ${messageFragment}
    }
  }
`

export const EDIT_COMMENT = gql`
  mutation editComment($input: MessageEditInput!) {
    editComment(input: $input) {
      ${messageFragment}
    }
  }
`

export const REMOVE_COMMENT = gql`
  mutation removeComment($messageId: ID!) {
    removeComment(messageId: $messageId) {
      ${messageFragment}
    }
  }
`

export const TOGGLE_REACTION_TYPE = gql`
  mutation toggleReactionType($input: ReactionInput!) {
    toggleReactionType(input: $input) {
      id
      messageId
      userId
      types
    }
  }
`

export const ADD_MESSAGE = gql`
  mutation addMessage($input: MessageAddInput!) {
    addMessage(input: $input) {
      ${messageFragment}
    }
  }
`
// ---------------------------
// SUBSCRIPTIONS
// ---------------------------

export const COMMENTS_SUBSCRIPTION = gql`
  subscription OnCommentModified($key: String!) {
    commentModified(key: $key) {
      id
      sender {
        id
        firstName
        lastName
        avatar
        avatarSecureUrl
      }
      message
      messageType
      createdAt
      updatedAt
    }
  }
`
