import { useState } from 'react'
import { Button, Grid, useBreakpointValue, Input, InputGroup, InputLeftElement, Icon, Flex } from '@chakra-ui/react'
import { icons } from 'theme'

interface TagsGridProps {
  tagsList: string[]
  attachedTags: string[]
  setAttachedTags: React.Dispatch<React.SetStateAction<string[]>>
}

const TagsGrid = ({ tagsList, attachedTags, setAttachedTags }: TagsGridProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const isMobile = useBreakpointValue({ base: true, md: false })

  const handleClick = (event) => {
    const newTag = event.target.value
    if (typeof newTag === 'undefined') {
      return
    }
    let nextTags
    if (attachedTags.includes(newTag)) {
      nextTags = attachedTags.filter((tag) => tag !== newTag)
    } else {
      nextTags = [...attachedTags, newTag]
    }
    setAttachedTags(nextTags)
    setSearchTerm('')
  }

  return (
    <>
      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none">
          <Icon as={icons.search} />
        </InputLeftElement>
        <Input
          placeholder={'Search Tags'}
          value={searchTerm}
          variant="outline"
          borderRadius={8}
          onChange={({ target: { value } }) => {
            setSearchTerm(value)
          }}
          color="textBlack"
          borderColor="textSoft"
          backgroundColor="white"
        />
      </InputGroup>
      <Flex flexWrap="wrap" gap={isMobile ? 2 : 4}>
        {tagsList
          .filter((tag) => tag.trim().toLowerCase().includes(searchTerm.trim().toLocaleLowerCase()))
          .map((tag) => (
            <Button
              key={tag}
              variant={'unstyled'}
              onClick={handleClick}
              value={tag}
              bg={attachedTags.includes(tag) ? 'accent' : 'textSoft'}
              color={attachedTags.includes(tag) ? 'white' : 'textBlack'}
              fontWeight={500}
              display={'flex'}
              flex={1}
              justifyContent={'flex-start'}
              minW="max"
              maxW="max"
              overflowX="visible"
              px={isMobile ? 2 : 4}
              h="2rem"
              leftIcon={
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.12564 0C5.59897 0 6.05291 0.188029 6.38761 0.522724L11.4773 5.61239C12.1742 6.30936 12.1742 7.43936 11.4773 8.13633L8.13633 11.4773C7.43936 12.1742 6.30936 12.1742 5.61239 11.4773L0.522724 6.38761C0.188029 6.05291 0 5.59897 0 5.12564V1.78469C0 0.799033 0.799033 0 1.78469 0H5.12564ZM3.5 2C2.67157 2 2 2.67157 2 3.5C2 4.32843 2.67157 5 3.5 5C4.32843 5 5 4.32843 5 3.5C5 2.67157 4.32843 2 3.5 2Z"
                    fill={attachedTags.includes(tag) ? 'white' : '#1A1A1A'}
                  />
                </svg>
              }
            >
              {tag}
            </Button>
          ))}
      </Flex>
    </>
  )
}

export default TagsGrid
